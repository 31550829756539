export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENTS_RESPONSE = 'GET_PATIENTS_RESPONSE';
export const GET_PATIENTS_ERROR = 'GET_PATIENTS_ERROR';

export const PATIENT_MIGRATION = 'PATIENT_MIGRATION';
export const PATIENT_MIGRATION_RESPONSE = 'PATIENT_MIGRATION_RESPONSE';
export const PATIENT_MIGRATION_ERROR = 'PATIENT_MIGRATION_ERROR';

export const UPDATE_PATIENTS_PAGE = 'UPDATE_PATIENTS_PAGE';
export const UPDATE_PATIENTS_PAGE_SIZE = 'UPDATE_PATIENTS_PAGE_SIZE';

export const UPDATE_PATIENT_FILTER_VALUES = 'UPDATE_PATIENT_FILTER_VALUES';
export const CLEAR_PATIENT_FILTERS = 'CLEAR_PATIENT_FILTERS';

export const CREATE_NEW_PATIENT = 'CREATE_NEW_PATIENT';
export const CREATE_NEW_PATIENT_RESPONSE = 'CREATE_NEW_PATIENT_RESPONSE';
export const CREATE_NEW_PATIENT_ERROR = 'CREATE_NEW_PATIENT_ERROR';

// use for patient edition
export const SET_PATIENT_DETAILS = 'SET_PATIENT_DETAILS';
export const CLEAR_PATIENT_DETAILS = 'CLEAR_PATIENT_DETAILS';

export const EDIT_PATIENT = 'EDIT_PATIENT';
export const EDIT_PATIENT_RESPONSE = 'EDIT_PATIENT_RESPONSE';
export const EDIT_PATIENT_ERROR = 'EDIT_PATIENT_ERROR';

export const EDIT_PATIENT_WITH_ROLLBACK = 'EDIT_PATIENT_WITH_ROLLBACK';
export const EDIT_PATIENT_WITH_ROLLBACK_RESPONSE = 'EDIT_PATIENT_WITH_ROLLBACK_RESPONSE';
export const EDIT_PATIENT_WITH_ROLLBACK_ERROR = 'EDIT_PATIENT_WITH_ROLLBACK_ERROR';

export const SEARCH_PATIENTS = 'SEARCH_PATIENTS';

export const GET_PROGRAMED_DOSE = 'GET_PROGRAMED_DOSE';
export const GET_PROGRAMED_DOSE_RESPONSE = 'GET_PROGRAMED_DOSE_RESPONSE';
export const GET_PROGRAMED_DOSE_ERROR = 'GET_PROGRAMED_DOSE_ERROR';

export const GET_AUDITOR_PROGRAMED_DOSE = 'GET_AUDITOR_PROGRAMED_DOSE';
export const GET_AUDITOR_PROGRAMED_DOSE_RESPONSE = 'GET_AUDITOR_PROGRAMED_DOSE_RESPONSE';
export const GET_AUDITOR_PROGRAMED_DOSE_ERROR = 'GET_AUDITOR_PROGRAMED_DOSE_ERROR';

export const CLEAR_PROGRAMMED_DOSES = 'CLEAR_PROGRAMMED_DOSES';

export const EDIT_PROGRAMMED_DOSES = 'EDIT_PROGRAMMED_DOSES';

export const GET_BASIC_PATIENTS = 'GET_BASIC_PATIENTS';
export const GET_BASIC_PATIENTS_RESPONSE = 'GET_BASIC_PATIENTS_RESPONSE';
export const GET_BASIC_PATIENTS_ERROR = 'GET_BASIC_PATIENTS_ERROR';

export const EDIT_PATIENT_AUDITOR = 'EDIT_PATIENT_AUDITOR';

export default {
    getPatients: () => ({ type: GET_PATIENTS }),
    getPatientsResponse: (response) => ({ type: GET_PATIENTS_RESPONSE, response }),
    getPatientsError: (error) => ({ type: GET_PATIENTS_ERROR, error }),

    patientMigration: () => ({ type: PATIENT_MIGRATION }),
    patientMigrationResponse: (response) => ({ type: PATIENT_MIGRATION_RESPONSE, response }),
    patientMigrationError: (error) => ({ type: PATIENT_MIGRATION_ERROR, error }),

    updatePage: (pageNumber) => ({ type: UPDATE_PATIENTS_PAGE, pageNumber }),
    updatePageSize: (pageSize) => ({ type: UPDATE_PATIENTS_PAGE_SIZE, pageSize }),

    updateFilterValues: (filter) => ({ type: UPDATE_PATIENT_FILTER_VALUES, filter }),
    clearFilters: () => ({ type: CLEAR_PATIENT_FILTERS }),

    createNewPatient: (patient) => ({ type: CREATE_NEW_PATIENT, patient }),
    createNewPatientResponse: (response) => ({ type: CREATE_NEW_PATIENT_RESPONSE, response }),
    createNewPatientError: (error) => ({ type: CREATE_NEW_PATIENT_ERROR, error }),

    setPatientDetails: (patient, update) => ({ type: SET_PATIENT_DETAILS, patient, update }),
    clearPatientDetails: () => ({ type: CLEAR_PATIENT_DETAILS }),

    editPatient: (id, patient) => ({ type: EDIT_PATIENT, id, patient }),
    editAuditorPatient: (id, patient, osId) => ({ type: EDIT_PATIENT_AUDITOR, id, patient, osId }),
    editPatientResponse: (response) => ({ type: EDIT_PATIENT_RESPONSE, response }),
    editPatientError: (error) => ({ type: EDIT_PATIENT_ERROR, error }),

    editPatientWithRollback: (previousPatientData, id, newPatientData, newAccesses) => ({
        type: EDIT_PATIENT_WITH_ROLLBACK,
        previousPatientData,
        id,
        newPatientData,
        newAccesses,
    }),
    editPatientWithRollbackResponse: (response) => ({
        type: EDIT_PATIENT_WITH_ROLLBACK_RESPONSE,
        response,
    }),
    editPatientWithRollbackError: (error) => ({ type: EDIT_PATIENT_WITH_ROLLBACK_ERROR, error }),

    searchPatients: (search) => ({ type: SEARCH_PATIENTS, search }),

    getProgramedDose: (id) => ({ type: GET_PROGRAMED_DOSE, id }),
    getProgramedDoseError: (error) => ({ type: GET_PROGRAMED_DOSE_ERROR, error }),
    getProgramedDoseResponse: (response) => ({ type: GET_PROGRAMED_DOSE_RESPONSE, response }),

    getAuditorProgramedDose: (data) => ({
        type: GET_AUDITOR_PROGRAMED_DOSE,
        data,
    }),
    getAuditorProgramedDoseError: (error) => ({ type: GET_AUDITOR_PROGRAMED_DOSE_ERROR, error }),
    getAuditorProgramedDoseResponse: (response) => ({
        type: GET_AUDITOR_PROGRAMED_DOSE_RESPONSE,
        response,
    }),

    clearProgrammedDoses: () => ({ type: CLEAR_PROGRAMMED_DOSES }),

    editProgrammedDoses: (doses) => ({ type: EDIT_PROGRAMMED_DOSES, doses }),

    getBasicPatients: () => ({ type: GET_BASIC_PATIENTS }),
    getBasicPatientsResponse: (response) => ({ type: GET_BASIC_PATIENTS_RESPONSE, response }),
    getBasicPatientsError: (error) => ({ type: GET_BASIC_PATIENTS_ERROR, error }),
};
