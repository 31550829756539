import { connect } from 'react-redux';
import ProgramedDoses from '../components/ProgramedDoses/ProgramedDoses';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    programmedDosesStatus: state.patient.programmedDosesStatus,
    programedDoses: state.patient.programedDoses,
});

const mapDispatchToProps = (dispatch) => ({
    getProgramedDoses: (contract) => dispatch(actions.patient.getProgramedDose(contract)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramedDoses);
