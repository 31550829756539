import React, { useState, useEffect } from 'react';
import './ChangePasswordModal.scss';
import { SimpleModal } from '../../../common/components/Modal';
import Input from '../../../common/components/Input';
import { I18n } from 'react-redux-i18n';
import Button from '../../../common/components/Button';
import PropTypes from 'prop-types';
import ErrorBox from '../../../common/components/ErrorBox';
import { validators } from '../../../utils';

const ChangePasswordModal = (props) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showRepeatNewPassword, setShowRepeatNewPassword] = useState(false);

    const [errorField, setErrorField] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (props.changePasswordStatus.success) {
            resetState();
            props.close();
        } else if (props.changePasswordStatus.error) {
            setErrorMessage(props.changePasswordStatus.errorMessage);
        }
    }, [props.changePasswordStatus]);

    const validate = () => {
        if (!currentPassword || currentPassword === '') {
            setErrorField('currentPassword');
            setErrorMessage(I18n.t('profile.changePasswordModal.errors.currentPasswordIsRequired'));
            return false;
        }
        if (!newPassword || newPassword === '') {
            setErrorField('newPassword');
            setErrorMessage(I18n.t('profile.changePasswordModal.errors.newPasswordIsRequired'));
            return false;
        }
        if (!repeatNewPassword || repeatNewPassword === '') {
            setErrorField('repeatNewPassword');
            setErrorMessage(
                I18n.t('profile.changePasswordModal.errors.repeatNewPasswordIsRequired'),
            );
            return false;
        }
        if (newPassword !== repeatNewPassword) {
            setErrorField('');
            setErrorMessage(I18n.t('profile.changePasswordModal.errors.passwordsNotMatch'));
            return false;
        }
        if (!validators.validateMinLength(newPassword, 8)) {
            setErrorField('');
            setErrorMessage(I18n.t('profile.changePasswordModal.errors.passwordMinLength'));
            return false;
        }
        setErrorField('');
        setErrorMessage('');
        return true;
    };

    const onSubmit = () => {
        if (validate()) {
            props.onSubmit(currentPassword, newPassword, repeatNewPassword);
        }
    };

    const resetState = () => {
        setCurrentPassword('');
        setNewPassword('');
        setRepeatNewPassword('');
        setShowCurrentPassword(false);
        setShowNewPassword(false);
        setShowRepeatNewPassword(false);
        setErrorField('');
        setErrorMessage('');
    };

    return (
        <SimpleModal show={props.show} className='auto'>
            <div className='change-password-modal'>
                <span className='title'>{I18n.t('profile.changePasswordModal.title')}</span>
                <div className='fix-height'>
                    <ErrorBox show={errorMessage !== ''} errorMessage={errorMessage} />
                </div>
                <Input
                    type={showCurrentPassword ? 'text' : 'password'}
                    value={currentPassword}
                    icon={<i className='icon-key' />}
                    handleChange={setCurrentPassword}
                    showPassword={() => setShowCurrentPassword(!showCurrentPassword)}
                    error={errorField === 'currentPassword'}
                    pillStyle
                    password
                    placeholder={I18n.t('profile.changePasswordModal.currentPassword')}
                />
                <Input
                    type={showNewPassword ? 'text' : 'password'}
                    value={newPassword}
                    icon={<i className='icon-key' />}
                    handleChange={setNewPassword}
                    showPassword={() => setShowNewPassword(!showNewPassword)}
                    error={errorField === 'newPassword'}
                    pillStyle
                    password
                    placeholder={I18n.t('profile.changePasswordModal.newPassword')}
                />
                <Input
                    type={showRepeatNewPassword ? 'text' : 'password'}
                    value={repeatNewPassword}
                    icon={<i className='icon-key' />}
                    handleChange={setRepeatNewPassword}
                    showPassword={() => setShowRepeatNewPassword(!showRepeatNewPassword)}
                    error={errorField === 'repeatNewPassword'}
                    pillStyle
                    password
                    placeholder={I18n.t('profile.changePasswordModal.repeatNewPassword')}
                />
                <div className='buttons-container'>
                    <Button
                        className='secondary small'
                        onClick={() => {
                            resetState();
                            props.close();
                        }}
                        text={I18n.t('common.cancel')}
                    />
                    <Button
                        className='primary with-gradient small'
                        onClick={onSubmit}
                        text={I18n.t('profile.changePasswordModal.confirm')}
                        loading={props.changePasswordStatus.loading}
                    />
                </div>
            </div>
        </SimpleModal>
    );
};

ChangePasswordModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
};

export default ChangePasswordModal;
