import { connect } from 'react-redux';
import actions from '../../actions';
import AccessScreen from '../Components/ContactsTableScreen/AccessScreen';

const mapStateToProps = (state) => ({
    owner: state.patient.editionDetails.owner,
    access: state.roleAccess.access,
    patientCse: state.patient.editionDetails.cse,
    // editPatientStatus: state.patient.editPatientStatus,
    getAccessStatus: state.roleAccess.getAccessStatus,
    addPatientAccessStatus: state.roleAccess.addPatientAccessStatus,
    deletePatientAccessStatus: state.roleAccess.deletePatientAccessStatus,
});

const mapDispatchToProps = (dispatch) => ({
    editPatient: (id, patient) => dispatch(actions.patient.editPatient(id, patient)),
    addPatientAccess: (patientId, accessData, patientCse) =>
        dispatch(actions.roleAccess.addPatientAccess(patientId, accessData, patientCse)),
    deletePatientAccess: (patientId, accessId) =>
        dispatch(actions.roleAccess.deletePatientAccess(patientId, accessId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessScreen);
