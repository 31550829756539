import { connect } from 'react-redux';
import AuditorTransactionsScreen from '../components/AuditorTransactionsScreen/';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    auditorTransactions: state.transaction.filteredAuditorTransactions,
});

const mapDispatchToProps = (dispatch) => ({
    getAuditorTransactions: () => dispatch(actions.transaction.getAuditorTransactions()),
    setAuditorPatientDetail: (patient) =>
        dispatch(actions.transaction.setAuditorPatientDetail(patient)),
    searchTransactions: (search) => dispatch(actions.transaction.searchAuditorTransactions(search)),
    downloadAuditorTransactions: () => dispatch(actions.transaction.downloadAuditorTransactions()),
    getPatientAuditor: (id) => dispatch(actions.transaction.getPatientAuditor(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditorTransactionsScreen);
