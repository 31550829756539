import { SimpleModal } from '../../../common/components/Modal';
import { I18n } from 'react-redux-i18n';
import React, { useEffect } from 'react';
import { UserTypes } from '../../../constants';
import Button from '../../../common/components/Button';
import './MigrationModal.scss';

const MigrationModal = ({ userType, migrationStatus, migratePatients, osMigrated, logout }) => {
    const {
        inProgress: migrationInProgress,
        error,
        errorMessage,
    } = migrationStatus || { inProgress: false, completed: false, error: false, errorMessage: {} };

    useEffect(() => {
        // Flag to block users migration process.env.REACT_APP_MIGRATE_PATIENTS === 'true'
        if (userType === UserTypes.OS_USER && !osMigrated) {
            migratePatients();
        }
    }, [userType]);

    // Flag to hide modal if not migrating
    // process.env.REACT_APP_MIGRATE_PATIENTS === 'true'
    return (
        <SimpleModal show={migrationInProgress || error}>
            <div className='migration-modal'>
                <div className='header'>
                    <span className='title'>{I18n.t('patient.migration.modal.title')}</span>
                </div>
                <div className='footer'>
                    <div>
                        <label>
                            {I18n.t(
                                !error
                                    ? 'patient.migration.modal.text'
                                    : 'patient.migration.modal.error.text',
                            )}
                        </label>
                        {error && (
                            <div className='error-container'>
                                <span className='error-message show'>{errorMessage.message}</span>
                            </div>
                        )}
                    </div>
                    {error && (
                        <Button
                            className='primary with-gradient'
                            onClick={() => logout()}
                            text={I18n.t('patient.migration.modal.error.button')}
                        />
                    )}
                </div>
            </div>
        </SimpleModal>
    );
};

export default MigrationModal;
