import { webApi, get, post, put, deleteRequest } from '../utils/http';
import BigNumber from 'bignumber.js';
import { gasPrice, loadWeb3, maxGasForTokenSend, newTokenContract } from '../utils/wallet';
import { nex_abi } from './containers/abi';
import Web3 from 'web3';

export default {
    getContacts: () => get(webApi + `api/wallet/contacto/`),
    getContact: (contactId) => {
        return get(webApi + `api/wallet/contacto/${contactId}/`);
    },

    getWalletBalance: (address, fundsKey) => {
        const w3 = loadWeb3(fundsKey);
        const tokenContract = new w3.eth.Contract(nex_abi, process.env.REACT_APP_TOKEN_NEX);
        return new Promise((resolve, reject) => {
            tokenContract.methods.balanceOf(address).call((error, balance) => {
                if (error) reject(error);
                else resolve(balance);
            });
        });
    },

    getAddressValidation: (address) => get(webApi + `api/blockchain/descr/address/${address}`),

    addContact: (contact) => post(`${webApi}api/wallet/contacto/`, contact),
    editContact: (contact) =>
        put(`${webApi}api/wallet/contacto/${contact.id}/`, {
            nombre: contact.nombre,
            address: contact.address,
            email: contact.email,
        }),

    deleteContact: (contactId) => deleteRequest(`${webApi}api/wallet/contacto/${contactId}/`, {}),

    postSendNex: (addAddress, amount, address, fundsKey) => {
        return new Promise((resolve, reject) => {
            const { w3, tokenContract } = newTokenContract(fundsKey);
            tokenContract.methods
                .transfer(addAddress, w3.utils.toWei(amount, 'ether'))
                .send({ from: address, gas: maxGasForTokenSend, gasPrice })
                .on('transactionHash', function () {})
                .on('error', function (error) {
                    reject(error);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt);
                });
        });
    },
    postSendNexFrom: (addressPD, addressR, amount, fundsKey, nonce) => {
        return new Promise((resolve, reject) => {
            const { w3, tokenContract } = newTokenContract(fundsKey);

            tokenContract.methods
                .transferFrom(addressPD, addressR, w3.utils.toWei(amount, 'ether'))
                .send({ from: addressR, gas: maxGasForTokenSend, gasPrice, nonce })
                .on('transactionHash', function () {})
                .on('error', function (error) {
                    reject(error);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt);
                });
        });
    },
    addressValidation: (address, fundsKey) => {
        const w3 = loadWeb3(fundsKey);
        return w3.utils.isAddress(address);
    },

    getBalanceJac: (address, fundsKey) => {
        const w3 = loadWeb3(fundsKey);
        return new Promise((resolve, reject) => {
            w3.eth.getBalance(address, (err, data) => {
                if (err !== null) reject(err);
                else resolve(data);
            });
        });
    },

    getRecentTransactions: () => get(webApi + `api/blockchain/txns/addresses`),

    getWeb3MM: () => {
        return new Promise((resolve, reject) => {
            let w3mm;
            if (window.ethereum) {
                w3mm = new Web3(window.ethereum);
                return window.ethereum.enable().then(() => {
                    resolve(w3mm);
                });
            } else {
                reject('Install MetaMask');
            }
        });
    },

    getBalanceNexMonetaryBase: (web3MM) => {
        const tokenContractMM = new web3MM.eth.Contract(nex_abi, process.env.REACT_APP_TOKEN_NEX);
        const mmAddress = web3MM.currentProvider.selectedAddress;
        return new Promise((resolve, reject) => {
            tokenContractMM.methods.balanceOf(mmAddress).call((error, balance) => {
                if (error) reject(error);
                else resolve(balance);
            });
        });
    },

    getTotalPublished: (web3MM) => {
        const tokenContractMM = new web3MM.eth.Contract(nex_abi, process.env.REACT_APP_TOKEN_NEX);
        return new Promise((resolve, reject) => {
            tokenContractMM.methods.totalSupply().call((error, balance) => {
                if (error) reject(error);
                else resolve(balance);
            });
        });
    },

    toEmitMint: (web3MM, mmAddress, mint) => {
        return new Promise((resolve, reject) => {
            const tokenContract = new web3MM.eth.Contract(nex_abi, process.env.REACT_APP_TOKEN_NEX);

            const Gwei = (1.0e9).toString();
            const gasPrice = new BigNumber(10).times(Gwei).toNumber();
            tokenContract.methods
                .mint(mmAddress, web3MM.utils.toWei(mint, 'ether'))
                .send({ from: mmAddress, gas: maxGasForTokenSend, gasPrice })
                .on('transactionHash', function () {})
                .on('error', function (error) {
                    reject(error);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt);
                });
        });
    },

    toremoveBurn: (web3MM, mmAddress, burn) => {
        return new Promise((resolve, reject) => {
            const tokenContract = new web3MM.eth.Contract(nex_abi, process.env.REACT_APP_TOKEN_NEX);

            const Gwei = (1.0e9).toString();
            const gasPrice = new BigNumber(10).times(Gwei).toNumber();
            tokenContract.methods
                .burn(web3MM.utils.toWei(burn, 'ether'))
                .send({ from: mmAddress, gas: maxGasForTokenSend, gasPrice })
                .on('transactionHash', function () {})
                .on('error', function (error) {
                    reject(error);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt);
                });
        });
    },

    addressIsWhiteLister: (address, fundsKey) => {
        const w3 = loadWeb3(fundsKey);
        const tokenContract = new w3.eth.Contract(nex_abi, process.env.REACT_APP_TOKEN_NEX);

        return new Promise((resolve, reject) => {
            tokenContract.methods.isWhiteLister(address).call((error, receipt) => {
                if (error) {
                    reject(error);
                } else {
                    if (!receipt) reject('Addres no válida');
                    resolve(receipt);
                }
            });
        });
    },

    getWhitelisters: (web3MM) => {
        return new Promise((resolve, reject) => {
            const tokenContractMM = new web3MM.eth.Contract(
                nex_abi,
                process.env.REACT_APP_TOKEN_NEX,
            );
            tokenContractMM.methods.getWhitelisters().call((error, whitelisters) => {
                if (error) reject(error);
                else resolve(whitelisters);
            });
        });
    },

    addToWhitelisters: (address, web3MM, mmAddress) => {
        return new Promise((resolve, reject) => {
            const Gwei = (1.0e9).toString();
            const gasPrice = new BigNumber(10).times(Gwei).toNumber();
            const tokenContractMM = new web3MM.eth.Contract(
                nex_abi,
                process.env.REACT_APP_TOKEN_NEX,
            );
            tokenContractMM.methods
                .addToWhitelisters(address)
                .send({ from: mmAddress, gas: maxGasForTokenSend, gasPrice })
                .on('transactionHash', function () {})
                .on('error', function (error) {
                    reject(error);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt);
                });
        });
    },

    removeFromWhitelisters: (address, web3MM, mmAddress) => {
        return new Promise((resolve, reject) => {
            const Gwei = (1.0e9).toString();
            const gasPrice = new BigNumber(10).times(Gwei).toNumber();
            const tokenContractMM = new web3MM.eth.Contract(
                nex_abi,
                process.env.REACT_APP_TOKEN_NEX,
            );
            tokenContractMM.methods
                .removeFromWhitelisters(address)
                .send({ from: mmAddress, gas: maxGasForTokenSend, gasPrice })
                .on('transactionHash', function () {})
                .on('error', function (error) {
                    reject(error);
                })
                .on('receipt', function (receipt) {
                    resolve(receipt);
                });
        });
    },

    getTransactionCount: (address, fundsKey) => {
        const w3 = loadWeb3(fundsKey);
        return new Promise((resolve, reject) => {
            w3.eth.getTransactionCount(address, (err, data) => {
                if (err !== null) reject(err);
                else resolve(data);
            });
        });
    },

    getPaymentRegister: () => get(webApi + `api/wallet/registropago/`),
};
