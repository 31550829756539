import { connect } from 'react-redux';
import actions from '../../actions';
import ShippingAddressScreen from '../components/ShippingAddress/ShippingAddressScreen';

const mapStateToProps = (state) => ({
    userContacts: state.wallet.contacts,
    deleteContactStatus: state.wallet.deleteContactStatus,
    contactId: state.wallet.selectedContactId,
    loading: state.wallet.getContactsStatus.loading,
});

const mapDispatchToProps = (dispatch) => ({
    getAddressValidation: (address) => dispatch(actions.wallet.getAddressValidation(address)),
    getAddressValidationResponse: (address) =>
        dispatch(actions.wallet.getAddressValidationResponse(address)),
    saveSendNex: (body) => dispatch(actions.wallet.saveSendNex(body)),
    getContacts: (id) => dispatch(actions.wallet.getContacts(id)),
    deleteContact: (contactId) => dispatch(actions.wallet.deleteContact(contactId)),
    setContactId: (contactId) => dispatch(actions.wallet.setContactId(contactId)),
    setContact: (contact) => dispatch(actions.wallet.setContact(contact)),
    searchContacts: (search) => dispatch(actions.wallet.searchContacts(search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressScreen);
