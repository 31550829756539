import React, { useState, useEffect } from 'react';
import './LoginScreen.scss';
import Input from '../../../common/components/Input';
import Button from '../../../common/components/Button';
import { I18n } from 'react-redux-i18n';
import { useHistory } from 'react-router-dom';

const LoginForm = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [errorField, setErrorField] = useState();

    useEffect(() => {
        if (props.loginStatus.error) setErrorMessage(props.loginStatus.errorMessage);
    }, [props.loginStatus.error, props.loginStatus.errorMessage]);

    const history = useHistory();

    const submit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!email) {
            setErrorField('email');
            setErrorMessage(I18n.t('login.enterAUser'));
        } else if (!password) {
            setErrorField('password');
            setErrorMessage(I18n.t('login.enterAPassword'));
        } else {
            setErrorField('');
            setErrorMessage('');
            props.login(email, password);
        }
    };

    return (
        <form className='form' onSubmit={submit} noValidate>
            <div className={`error-container ${errorMessage ? 'show' : ''}`}>
                <i className='icon-exclamation' />
                <div className='error-msj'>{errorMessage}</div>
            </div>
            <h1 className='title'>{I18n.t('login.loginYourAccount')}</h1>
            <div className='login-field'>
                <Input
                    type='email'
                    value={email}
                    icon={<i className='icon-user' />}
                    handleChange={setEmail}
                    placeholder={I18n.t('login.enterYourUser')}
                    label={I18n.t('login.user')}
                    error={errorField === 'email'}
                    pillStyle
                />
            </div>
            <div className='login-field'>
                <Input
                    password
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    icon={<i className='icon-lock' />}
                    showPassword={() => setShowPassword(!showPassword)}
                    handleChange={setPassword}
                    placeholder={I18n.t('login.enterYourPassword')}
                    label={I18n.t('login.password')}
                    error={errorField === 'password'}
                    pillStyle
                />
            </div>
            <label className='forgot-password' onClick={() => history.push('/recover-password')}>
                {I18n.t('login.iForgotMyPassword')}
            </label>
            <div className='buttons'>
                <Button
                    type='submit'
                    text={I18n.t('login.enter')}
                    loading={props.loginStatus.loading}
                />
            </div>
        </form>
    );
};

export default LoginForm;
