export const TOGGLE_SIDEBAR = 'TOGGLE SIDEBAR';

export const GET_OS_CONTRACTS = 'GET_OS_CONTRACTS';
export const GET_OS_CONTRACTS_RESPONSE = 'GET_OS_CONTRACTS_RESPONSE';
export const GET_OS_CONTRACTS_ERROR = 'GET_OS_CONTRACTS_ERROR';

export const GET_OS_CONTRACT = 'GET_OS_CONTRACT';
export const GET_OS_CONTRACT_RESPONSE = 'GET_OS_CONTRACT_RESPONSE';
export const GET_OS_CONTRACT_ERROR = 'GET_OS_CONTRACT_ERROR';

export const GET_ALL_OS = 'GET_ALL_OS';
export const GET_ALL_OS_RESPONSE = 'GET_ALL_OS_RESPONSE';
export const GET_ALL_OS_ERROR = 'GET_ALL_OS_ERROR';

export const ACCEPT_TERMS_AND_CONDITIONS = 'ACCEPT_TERMS_AND_CONDITIONS';
export const ACCEPT_TERMS_AND_CONDITIONS_RESPONSE = 'ACCEPT_TERMS_AND_CONDITIONS_RESPONSE';
export const ACCEPT_TERMS_AND_CONDITIONS_ERROR = 'ACCEPT_TERMS_AND_CONDITIONS_ERROR';

export const GET_PRODUCTS_BY_OS = 'GET_PRODUCTS_BY_OS';
export const GET_PRODUCTS_BY_OS_RESPONSE = 'GET_PRODUCTS_BY_OS_RESPONSE';
export const GET_PRODUCTS_BY_OS_ERROR = 'GET_PRODUCTS_BY_OS_ERROR';

export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';

const common = {
    toggleSidebar: () => ({ type: TOGGLE_SIDEBAR }),

    getOSContracts: (extra) => ({ type: GET_OS_CONTRACTS, extra }),
    getOSContractsResponse: (response) => ({ type: GET_OS_CONTRACTS_RESPONSE, response }),
    getOSContractsError: (error) => ({ type: GET_OS_CONTRACTS_ERROR, error }),

    getOSContract: (id) => ({ type: GET_OS_CONTRACT, id }),
    getOSContractResponse: (response) => ({ type: GET_OS_CONTRACT_RESPONSE, response }),
    getOSContractError: (error) => ({ type: GET_OS_CONTRACT_ERROR, error }),

    getOsList: () => ({ type: GET_ALL_OS }),
    getOsListResponse: (response) => ({ type: GET_ALL_OS_RESPONSE, response }),
    getOsListError: (error) => ({ type: GET_ALL_OS_ERROR, error }),

    acceptTermsAndConditions: () => ({ type: ACCEPT_TERMS_AND_CONDITIONS }),
    acceptTermsAndConditionsResponse: () => ({ type: ACCEPT_TERMS_AND_CONDITIONS_RESPONSE }),
    acceptTermsAndConditionsError: (error) => ({ type: ACCEPT_TERMS_AND_CONDITIONS_ERROR, error }),

    setBreadcrumbs: (breadcrumbs) => ({ type: SET_BREADCRUMBS, breadcrumbs }),
};

export default common;
