import React from 'react';

const DetailedLabel = ({ label, detail }) => {
    return (
        <div className='detailedLabel'>
            <div className='dlabel-text'>{label}</div>
            <div className='detail'>{detail}</div>
        </div>
    );
};
export default DetailedLabel;
