import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Bonification, BonificationResult } from '../../types';
import { get, put, webApi } from '../../utils/http';
import { parseDate, parseToCsvAndDownload } from '../../utils';
import { I18n } from 'react-redux-i18n';

export const useGetBonifications = (
    statusFilter: { id: string; checked: boolean }[],
    page?: number,
    searchText?: string,
    startDate?: string,
    endDate?: string,
    sortProperty?: string,
) => {
    let result = [];
    const status = statusFilter.filter((s) => {
        if (s.checked && s.id !== 'all') {
            return s;
        }
    });
    status.length > 0 && result.push(`estado=${status.map((s) => s.id).join(',')}`);
    page && result.push(`page=${page}`);
    searchText && result.push(`search_text_str=${searchText}`);
    startDate && result.push(`start_date_str=${startDate}`);
    endDate && result.push(`end_date_str=${endDate}`);
    sortProperty && result.push(`order_by=${sortProperty}`);
    const query = result.length > 0 ? `?${result.join('&')}` : '';
    return useQuery<Bonification>(['bonifications', query], () =>
        get(webApi + `api/bonificaciones/${query}`),
    );
};

export const useUpdateDelivered = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (data: { id: number; observaciones: string }[]) =>
            put(webApi + `api/bonificaciones/entrega/`, data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['bonifications'] });
            },
        },
    );
};

export const useUpdateRejected = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (data: { id: number; observaciones: string }[]) =>
            put(webApi + `api/bonificaciones/rechaza/`, data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['bonifications'] });
            },
        },
    );
};

export const useUpdateBonificationObservations = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (data: { id: number; observation: string }) =>
            put(webApi + `api/bonificaciones/observaciones/${data.id}/`, {
                observaciones: data.observation,
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: ['bonifications'] });
            },
        },
    );
};

export const downloadBonifications = (bonifications: BonificationResult[]) => {
    const fileName = 'Bonificaciones.csv';
    const data = bonifications.map((bonification) => ({
        ID: bonification.id,
        'ID del Paciente': bonification.paciente.id,
        'Obra Social': bonification.paciente.obra_social,
        Fecha: parseDate(new Date(bonification.fechas[0].created_at)),
        Contrato: bonification.contrato.descripcion,
        Producto: bonification.denominacion,
        'Nro de Ciclo': bonification.ciclo,
        Estado: I18n.t(`bonification.status.${bonification.estado}`),
    }));

    parseToCsvAndDownload(data, fileName);
};
