import React from 'react';
import PropTypes from 'prop-types';
import './NexBalance.scss';
import Loader from '../../../../common/components/Loader';
import { I18n } from 'react-redux-i18n';

const NexBalance = ({ balance, style }) => {
    return (
        <span className='nex-balance-text' style={style}>
            {balance !== 0 && !balance ? (
                <Loader />
            ) : balance ? (
                `${balance}  NEX`
            ) : (
                `${I18n.t('wallet.monetaryBase.errorMetaMask')}`
            )}
        </span>
    );
};

NexBalance.propTypes = {
    balance: PropTypes.number.isRequired,
    style: PropTypes.object,
};
export default NexBalance;
