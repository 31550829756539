import { connect } from 'react-redux';
import GrantingMonitorScreen from '../components/GrantingMonitorScreen';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    addresses: state.transaction.grantingMonitorFilteredResults,
    total: state.transaction.grantingMonitorFilteredResults.length,
    totalPages: Math.ceil(state.transaction.grantingMonitorFilteredResults.length / 10),
    currentPage: state.transaction.grantingMonitorCurrent,

    allAddresses: state.transaction.grantingMonitorResults,
    filteredAddressesBySearch: state.transaction.grantingMonitorFilteredResultsBySearch,

    loading: state.transaction.getGrantingMonitorAddressesStatus.loading,
    downloadStatus: state.transaction.downloadGrantingMonitorAddressesStatus,

    grantAddressesStatus: state.transaction.grantAddressesStatus,
    grantAddressesResults: state.transaction.lastGrantAddressesResults,

    userIsGranter: state.profile.user.groups?.includes('granters'),
});

const mapDispatchToProps = (dispatch) => ({
    getAddresses: (filter) => dispatch(actions.transaction.getGrantingMonitorAddresses(filter)),

    updateFilterValues: (filter) =>
        dispatch(actions.transaction.updateGrantingMonitorFilterValues(filter)),

    updatePage: (pageNumber) => dispatch(actions.transaction.updateGrantingMonitorPage(pageNumber)),
    updatePageSize: (pageSize) =>
        dispatch(actions.transaction.updateGrantingMonitorPageSize(pageSize)),

    searchAddresses: (search) =>
        dispatch(actions.transaction.searchGrantingMonitorAddresses(search)),

    grantAddresses: (transactions) => dispatch(actions.transaction.grantAddresses(transactions)),

    downloadAddresses: () => dispatch(actions.transaction.downloadGrantingMonitorAddresses()),

    clearUI: () => dispatch(actions.transaction.clearUI()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GrantingMonitorScreen);
