import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// Middlewares
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import sessionMiddleware from './session/session.middleware';
import profileMiddleware from './profile/profile.middleware';
import commonMiddleware from './common/common.middleware';
import patientMiddleware from './patient/patient.middleware';
import registrationMiddleware from './registration/registration.middleware';
import transactionMiddleware from './transaction/transaction.middleware';
import registrarMiddleware from './registrar/registrar.middleware';
import indicationMiddleware from './indication/indication.middleware';
import contractMiddleware from './contract/contract.middleware';
import roleAccessMiddleware from './roleAccess/roleAccess.middleware';
// Reducers
import reducer from './reducers';
import walletMiddleware from './wallet/wallet.middleware';

const persistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'persistweb:',
};

const persistedReducer = persistReducer(persistConfig, reducer);

const history = createBrowserHistory();

const middleware = routerMiddleware(history);

const middlewares =
    process.env.REACT_APP_ENVIRONMENT === 'production'
        ? [
              thunk,
              middleware,
              sessionMiddleware,
              profileMiddleware,
              commonMiddleware,
              patientMiddleware,
              registrationMiddleware,
              transactionMiddleware,
              registrarMiddleware,
              indicationMiddleware,
              contractMiddleware,
              roleAccessMiddleware,
              walletMiddleware,
          ]
        : [
              thunk,
              logger,
              middleware,
              sessionMiddleware,
              profileMiddleware,
              commonMiddleware,
              patientMiddleware,
              registrationMiddleware,
              transactionMiddleware,
              registrarMiddleware,
              indicationMiddleware,
              contractMiddleware,
              roleAccessMiddleware,
              walletMiddleware,
          ];

const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
    compose;

const store = createStore(
    connectRouter(history)(persistedReducer),
    undefined,
    composeEnhancers(applyMiddleware(...middlewares)),
);

const persistor = persistStore(store);

export default { store, persistor, history };
