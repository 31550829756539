import { connect } from 'react-redux';
import actions from '../../actions';
import SendNexScreen from '../components/SendNex/SendNexScreen';

const mapStateToProps = (state) => ({
    directionSelection: state.wallet.directionSelection,
    addressValidationStatus: state.wallet.addressValidationStatus,
    transactionValidationStatus: state.wallet.transactionValidationStatus,
    transactionHash: state.wallet.transactionHash,
    balanceNex: state.wallet.data,
    username: state.profile.user.username,
});

const mapDispatchToProps = (dispatch) => ({
    saveSendNex: (body) => dispatch(actions.wallet.saveSendNex(body)),
    setContact: (contact) => dispatch(actions.wallet.setContact(contact)),
    getWalletDetails: (username) => dispatch(actions.wallet.getWalletDetails(username)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendNexScreen);
