import React, { useState } from 'react';
import './ProfileScreen.scss';
import ProfileCard from '../../components/ProfileCard';
import ProfileConfigurationCard from '../ProfileConfigurationCard';
import ChangePasswordModal from '../ChangePasswordModal';
import MigrationModal from '../../../dashboard/components/MigrationModal/MigrationModal';

const ProfileScreen = (props) => {
    const [showChangePasswordModal, setShowPasswordModal] = useState(false);

    return (
        <div className='profile-screen'>
            <MigrationModal
                migrationStatus={props.migrationStatus}
                migratePatients={props.migratePatients}
                userType={props.userType}
                osMigrated={props.osMigrated}
                logout={props.logout}
                commentMigrationStatus={props.commentMigrationStatus}
            />
            <ProfileCard
                user={props.user}
                osName={props.osName}
                showChangePasswordModal={() => setShowPasswordModal(true)}
            />
            <ProfileConfigurationCard
                user={props.user}
                osName={props.osName || props.drugName || props.user.audit_group.razon_social}
                updateUser={props.updateUser}
                updateUserStatus={props.updateUserStatus}
                validatePasswordStatus={props.validatePasswordStatus}
                validatePassword={props.validatePassword}
            />
            {/* Modals */}
            <ChangePasswordModal
                show={showChangePasswordModal}
                onSubmit={props.changePassword}
                close={() => setShowPasswordModal(false)}
                changePasswordStatus={props.changePasswordStatus}
            />
        </div>
    );
};

export default ProfileScreen;
