import {
    GET_CONTRACTS,
    GET_CONTRACTS_RESPONSE,
    GET_CONTRACTS_ERROR,
    GET_CONTRACT,
    GET_CONTRACT_RESPONSE,
    GET_CONTRACT_ERROR,
    SET_CONTRACT_SELECTED,
    PUT_CONTRACT,
    PUT_CONTRACT_RESPONSE,
    PUT_CONTRACT_ERROR,
    POST_CONTRACT,
    POST_CONTRACT_RESPONSE,
    POST_CONTRACT_ERROR,
    GET_AUDITOR_GROUPS,
    GET_AUDITOR_GROUPS_RESPONSE,
    GET_AUDITOR_GROUPS_ERROR,
    GET_FAMILY_PROD_RESPONSE,
    SEARCH_CONTRACTS,
    GET_CONTRACT_OS,
    GET_CONTRACT_OS_ERROR,
    GET_CONTRACT_OS_RESPONSE,
    GET_CONTRACT_TYPES,
    GET_CONTRACT_TYPES_ERROR,
    GET_CONTRACT_TYPES_RESPONSE,
    CLEAN_CONTRACT_TYPES,
} from './contract.actions';

const initialState = {
    results: [],
    filteredResults: [],
    count: 0, // table rows (all contracts)
    current: 1, // actual page
    total_pages: 1,
    page_size: 10, // TODO - is harcoded in implementation
    customFilter: {
        ordering: '',
        search: '',
        status: '',
    },

    // UI related
    getContractsStatus: { success: false, loading: false, error: false, message: '' },
    setContractStatus: { success: false, loading: false, error: false, message: '' },
    getOsContractStatus: { success: false, loading: false, error: false, message: '' },

    // Contract Details
    selectedContract: {},

    // AUDITORS - TODO
    auditorGroups: [],

    contractTypes: [],
};

const filterContracts = (contracts, searchText) => {
    return contracts.filter((contract) => {
        const collection = [
            contract.descripcion,
            contract.obra_social_descr,
            contract.id,
            contract.tipo_contrato,
        ];
        const mapped = collection.map((x) => x.toString().toLowerCase());
        const joined = mapped.join('');
        return joined.includes(searchText.toLowerCase());
    });
};

const contractReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTRACTS:
        case GET_CONTRACTS_ERROR:
        case GET_CONTRACTS_RESPONSE:
            return {
                ...state,
                results:
                    action.type == GET_CONTRACTS_RESPONSE
                        ? action.response?.sort((a, b) => (a.id < b.id ? 1 : -1))
                        : [],
                filteredResults:
                    action.type == GET_CONTRACTS_RESPONSE
                        ? action.response?.sort((a, b) => (a.id < b.id ? 1 : -1))
                        : [],
                getContractsStatus: {
                    success: action.type == GET_CONTRACTS_RESPONSE,
                    loading: action.type == GET_CONTRACTS,
                    error: action.type == GET_CONTRACTS_ERROR && action.err,
                },
            };
        case GET_CONTRACT:
        case GET_CONTRACT_ERROR:
        case GET_CONTRACT_RESPONSE:
            return {
                ...state,
                selectedContract: action.type == GET_CONTRACT_RESPONSE ? action.response : {},
                getContractsStatus: {
                    success: action.type == GET_CONTRACT_RESPONSE,
                    loading: action.type == GET_CONTRACT,
                    error: action.type == GET_CONTRACT_ERROR && action.err,
                },
            };
        case SET_CONTRACT_SELECTED:
            return {
                ...state,
                selectedContract: action.body,
            };
        case PUT_CONTRACT:
        case PUT_CONTRACT_ERROR:
        case PUT_CONTRACT_RESPONSE:
            return {
                ...state,
                selectedContract:
                    action.type === PUT_CONTRACT_RESPONSE
                        ? action.response
                        : state.selectedContract,
                setContractStatus: {
                    success: action.type === PUT_CONTRACT_RESPONSE,
                    loading: action.type === PUT_CONTRACT,
                    error: action.type === PUT_CONTRACT_ERROR,
                    message:
                        action.type === PUT_CONTRACT_ERROR &&
                        (action?.error?.data
                            ? Object.values(action?.error?.data)[0][0]
                            : action?.error),
                },
            };
        case POST_CONTRACT:
        case POST_CONTRACT_ERROR:
        case POST_CONTRACT_RESPONSE:
            return {
                ...state,
                selectedContract:
                    action.type === POST_CONTRACT_RESPONSE
                        ? action.response
                        : state.selectedContract,
                setContractStatus: {
                    success: action.type == POST_CONTRACT_RESPONSE,
                    loading: action.type == POST_CONTRACT,
                    error: action.type == POST_CONTRACT_ERROR,
                    message:
                        action.type == POST_CONTRACT_ERROR &&
                        (action?.error?.data
                            ? Object.values(action?.error?.data)[0][0]
                            : action?.error),
                },
            };
        case GET_AUDITOR_GROUPS:
        case GET_AUDITOR_GROUPS_ERROR:
        case GET_AUDITOR_GROUPS_RESPONSE:
            return {
                ...state,
                auditorGroups: action.type === GET_AUDITOR_GROUPS_RESPONSE ? action.response : [],
            };
        case GET_CONTRACT_TYPES:
        case GET_CONTRACT_TYPES_ERROR:
        case GET_CONTRACT_TYPES_RESPONSE:
            return {
                ...state,
                contractTypes: action.type === GET_CONTRACT_TYPES_RESPONSE ? action.response : [],
            };
        case CLEAN_CONTRACT_TYPES:
            return {
                ...state,
                contractTypes: initialState.contractTypes,
            };
        // case GET_FAMILY_PROD:
        // case GET_FAMILY_PROD_ERROR:
        case GET_FAMILY_PROD_RESPONSE:
            return {
                ...state,
                prodFamilys: {
                    ...state.prodFamilys,
                    ...action.response,
                },
            };
        case SEARCH_CONTRACTS:
            return {
                ...state,
                filteredResults: filterContracts(state.results, action.search),
            };
        case GET_CONTRACT_OS:
        case GET_CONTRACT_OS_RESPONSE:
        case GET_CONTRACT_OS_ERROR:
            return {
                ...state,
                selectedContract: action.type === GET_CONTRACT_OS ? action.response : {},
                getContractsStatus: {
                    success: action.type === GET_CONTRACT_OS_RESPONSE,
                    loading: action.type === GET_CONTRACT_OS,
                    error: action.type === GET_CONTRACT_OS_ERROR && action.err,
                },
            };
        default:
            return state;
    }
};

export default contractReducer;
