import React from 'react';
import Trash from '../../../../common/components/Icon/Trash';
import '../AccessTableScreen.scss';

const DeleteCell = (props) => {
    return (
        <>
            <div
                onClick={() => {
                    if (props.owner && props.disable) {
                        props.showModal();
                        props.setId(props.id);
                    }
                }}
                className={
                    'action-icons-container ' + (!props.owner || !props.disable ? 'disabled' : ' ')
                }
            >
                <Trash
                    height={trash.width}
                    width={trash.height}
                    color={props.owner && props.disable ? trash.color : disabledTrash.color}
                />
            </div>
        </>
    );
};
const trash = { width: 30, height: 24, color: 'black' };
const disabledTrash = { width: 30, height: 24, color: 'gray' };

export default DeleteCell;
