import {
    GET_PATIENTS,
    UPDATE_PATIENTS_PAGE,
    CREATE_NEW_PATIENT,
    EDIT_PATIENT,
    EDIT_PATIENT_WITH_ROLLBACK,
    GET_PROGRAMED_DOSE,
    GET_AUDITOR_PROGRAMED_DOSE,
    GET_BASIC_PATIENTS,
    PATIENT_MIGRATION,
    EDIT_PATIENT_AUDITOR,
} from './patient.actions';
import actions from '../actions';
import service from './patient.services';
import contractService from '../contract/contract.services';
import { encryptString, decryptString, generateCse } from '../utils/encryption';
import { I18n } from 'react-redux-i18n';
import { Cipher, KeyManager } from 'jasper-roche-crypto';
import roleAccessServices from '../roleAccess/roleAccess.services';
import { encryptAccess } from '../roleAccess/roleAccess.middleware';

const getKeysFromKeystore = (username) => {
    const keyManager = new KeyManager(username);
    const privateKey = keyManager.deriveDataKey();
    const publicKey = keyManager.getPublicKey(privateKey);
    return { privateKey, publicKey };
};
const patientMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        switch (action.type) {
            case GET_PATIENTS:
                service
                    .getPatients()
                    .then((response) => {
                        const { privateKey } = getKeysFromKeystore(
                            getState().profile.user.username,
                        );
                        const cipher = new Cipher();
                        // delete unwanted response prop
                        delete response['links'];
                        dispatch(
                            actions.patient.getPatientsResponse({
                                ...response,
                                results: response.map((encryptedPatient) => {
                                    const oldPrivateKey = getState().profile.user?.privateKey;
                                    try {
                                        if (
                                            !encryptedPatient.accesos?.length ||
                                            encryptedPatient.accesos.length === 0
                                        ) {
                                            return mapEncryptedPatient(encryptedPatient);
                                        }
                                        // This is not the best solution
                                        // In the future we should manage roles and ids elsewhere
                                        const osId = getState().session?.os_id;
                                        const drugId = getState().session?.drug_id;
                                        if (osId) {
                                            const decryptionKey = cipher.decrypt(
                                                privateKey,
                                                encryptedPatient.accesos.find(
                                                    (access) => access.obra_social === osId,
                                                ).csee,
                                            );
                                            return decryptPatient(
                                                encryptedPatient,
                                                decryptionKey,
                                                decryptionKey !== oldPrivateKey && decryptionKey,
                                            );
                                        } else if (drugId) {
                                            const decryptionKey = cipher.decrypt(
                                                privateKey,
                                                encryptedPatient.accesos.find(
                                                    (access) => access.drogueria === drugId,
                                                ).csee,
                                            );
                                            return decryptPatient(
                                                encryptedPatient,
                                                decryptionKey,
                                                decryptionKey !== oldPrivateKey && decryptionKey,
                                            );
                                        }
                                    } catch (e) {
                                        return mapEncryptedPatient(encryptedPatient);
                                    }
                                }),
                            }),
                        );
                    })
                    .catch((error) => {
                        dispatch(actions.patient.getPatientsError(error));
                    });
                break;
            case PATIENT_MIGRATION:
                service
                    .getPatients(getState().session.os_id)
                    .then((response) => {
                        const { publicKey } = getKeysFromKeystore(getState().profile.user.username);
                        delete response['links'];
                        Promise.all(
                            response
                                .filter((e) => !e.accesos?.length || e.accesos.length === 0)
                                .map(async (encryptedPatient) => {
                                    const oldPrivateKey = getState().profile.user?.oldPrivateKey;
                                    const patient = decryptPatient(encryptedPatient, oldPrivateKey);
                                    const newCse = generateCse();
                                    let idsWithAccess = [];
                                    const auditorsAccess = await Promise.all(
                                        patient.contracts.flatMap(async (contract) => {
                                            const res = await contractService.getContractOs(
                                                contract.contrato,
                                            );
                                            const currentContract = res[0];
                                            if (!currentContract?.grupo_auditor_id)
                                                return undefined;
                                            else if (
                                                !currentContract?.grupo_auditor_pubkey_data ||
                                                currentContract?.grupo_auditor_pubkey_data === ''
                                            ) {
                                                console.error(
                                                    `AUDITOR NOT MIGRATED for patient ID:${patient.id} and auditor ID:${currentContract.grupo_auditor_id}`,
                                                );
                                                throw Error(
                                                    `Auditor (ID:${currentContract.grupo_auditor_id}) not migrated for patient (ID:${patient.id})`,
                                                );
                                            } else {
                                                const id = currentContract.grupo_auditor_id;
                                                if (!idsWithAccess.includes(id)) {
                                                    idsWithAccess.push(id);
                                                    return {
                                                        publicKey:
                                                            currentContract.grupo_auditor_pubkey_data,
                                                        id,
                                                    };
                                                }
                                            }
                                        }),
                                    );
                                    const filteredAuditorAccess = auditorsAccess.filter(
                                        (a) => a !== undefined,
                                    );
                                    const newPatient = {
                                        ...patient,
                                        accesos: patient.accesos
                                            .concat([
                                                {
                                                    obra_social: getState().session.os_id,
                                                    owner: true,
                                                    publicKey: publicKey,
                                                },
                                            ])
                                            .concat(
                                                filteredAuditorAccess.map((access) => ({
                                                    grupo_auditor: access.id,
                                                    owner: false,
                                                    publicKey: access.publicKey,
                                                })),
                                            ),
                                        contracts: patient.contracts.map((contract) => ({
                                            ...contract,
                                            evidencia: contract.evidencia.map((evidencia) =>
                                                migrateEvidence(evidencia, oldPrivateKey, newCse),
                                            ),
                                        })),
                                    };
                                    try {
                                        return await service.editPatient(
                                            encryptedPatient.id,
                                            encryptPatient(
                                                newPatient,
                                                getState().session.os_id,
                                                newCse,
                                            ),
                                        );
                                    } catch (e) {
                                        if (
                                            e?.message?.includes(
                                                'Expected public key to be an Uint8Array',
                                            ) ||
                                            e?.message?.includes('Invalid hex string')
                                        )
                                            throw new Error(
                                                `Error during encryption of patient (ID:${
                                                    encryptedPatient.id
                                                }) with access to \n${JSON.stringify(
                                                    newPatient.accesos,
                                                    null,
                                                    '\t',
                                                )}`,
                                            );
                                        else {
                                            throw e;
                                        }
                                    }
                                }),
                        )
                            .then(() => {
                                dispatch(actions.transaction.migrateAuditorTransactionComments());
                            })
                            .catch((e) => {
                                dispatch(actions.patient.patientMigrationError(e));
                            });
                    })
                    .catch((error) => {
                        dispatch(actions.patient.getPatientsError(error));
                    });
                break;
            case UPDATE_PATIENTS_PAGE:
                dispatch(actions.patient.getPatients());
                break;
            case CREATE_NEW_PATIENT: {
                const creationCse = generateCse();
                service
                    .newPatient(
                        encryptPatient(action.patient, getState().session.os_id, creationCse),
                    )
                    .then((res) => {
                        // we assume no response data due to encryption
                        dispatch(actions.patient.createNewPatientResponse(res));
                        // get the first page
                        dispatch(actions.patient.updatePage(1));
                        // update the patient
                        dispatch(
                            actions.patient.setPatientDetails(
                                {
                                    ...action.patient, // UNNEEDED, leaving it for now, just in case
                                    ...res,
                                },
                                true,
                            ),
                        );
                    })
                    .catch((error) => {
                        dispatch(actions.patient.createNewPatientError(translateError(error.data)));
                    });
                break;
            }
            case EDIT_PATIENT: {
                const editCse = action.patient.cse || generateCse();
                service
                    .editPatient(
                        action.id,
                        encryptPatient(action.patient, getState().session.os_id, editCse),
                    )
                    .then((res) => {
                        let promises = [];
                        action.patient.contracts?.forEach((contract) => {
                            if (contract.evidencia) {
                                contract.evidencia?.forEach((ev) => {
                                    if (ev.adjunto) {
                                        ev.adjunto?.forEach((adj) => {
                                            if (adj._fileUrlToUpload) {
                                                promises.push(
                                                    service.uploadFileToS3(
                                                        adj._fileUrlToUpload,
                                                        adj._file,
                                                    ),
                                                );
                                            }
                                        });
                                    }
                                });
                            }
                        });
                        Promise.all(promises).then(() => {
                            // we assume no response data due to encryption
                            dispatch(actions.patient.editPatientResponse(res));
                            // get the first page
                            dispatch(actions.patient.updatePage(1));
                            // update the patient
                            dispatch(
                                actions.patient.setPatientDetails(
                                    {
                                        ...action.patient, // UNNEEDED, leaving it for now, just in case
                                        ...res,
                                    },
                                    true,
                                ),
                            );
                        });
                    })
                    .catch((err) => {
                        dispatch(
                            actions.patient.editPatientError(
                                err.data?.contratos
                                    ? err.data.contratos[0]
                                    : 'Ha ocurrido un error',
                            ),
                        );
                    });
                break;
            }
            case EDIT_PATIENT_WITH_ROLLBACK: {
                const { previousPatientData, id: patientId, newPatientData, newAccesses } = action;
                const editCse = newPatientData.cse || generateCse();
                // edit patient with all data without `accesos`
                service
                    .editPatient(
                        patientId,
                        encryptPatient(newPatientData, getState().session.os_id, editCse),
                    )
                    .then((res) => {
                        let promises = [];
                        newPatientData.contracts?.forEach((contract) => {
                            if (contract.evidencia) {
                                contract.evidencia?.forEach((ev) => {
                                    if (ev.adjunto) {
                                        ev.adjunto?.forEach((adj) => {
                                            if (adj._fileUrlToUpload) {
                                                promises.push(
                                                    service.uploadFileToS3(
                                                        adj._fileUrlToUpload,
                                                        adj._file,
                                                    ),
                                                );
                                            }
                                        });
                                    }
                                });
                            }
                        });
                        // add/remove accesses
                        newAccesses.forEach((newAccess) => {
                            promises.push(
                                roleAccessServices.addPatientAccess(
                                    patientId,
                                    encryptAccess(newAccess, editCse, patientId),
                                ),
                            );
                        });

                        Promise.all(promises)
                            .then(() => {
                                // all promises OK
                                // we assume no response data due to encryption
                                dispatch(actions.patient.editPatientWithRollbackResponse(res));
                                // get the first page
                                dispatch(actions.patient.updatePage(1));
                                // update the patient
                                dispatch(
                                    actions.patient.setPatientDetails(
                                        {
                                            ...newPatientData, // UNNEEDED, leaving it for now, just in case
                                            ...res,
                                        },
                                        true,
                                    ),
                                );
                            })
                            .catch(() => {
                                // some access gave error, should rollback
                                // edit patient but with previous patient data
                                service.editPatient(
                                    patientId,
                                    encryptPatient(
                                        previousPatientData,
                                        getState().session.os_id,
                                        editCse,
                                    ),
                                );
                                dispatch(
                                    actions.patient.editPatientWithRollbackError(
                                        'Ha ocurrido un error agregando los accesos',
                                    ),
                                );
                            });
                    })
                    .catch((err) => {
                        dispatch(
                            actions.patient.editPatientWithRollbackError(
                                err.data?.contratos
                                    ? err.data.contratos[0]
                                    : 'Ha ocurrido un error',
                            ),
                        );
                    });
                break;
            }
            case EDIT_PATIENT_AUDITOR: {
                const editAuditorCse = action.patient.cse;
                service
                    .editPatient(
                        action.id,
                        encryptPatient(action.patient, action.osId, editAuditorCse),
                    )
                    .then((res) => {
                        let promises = [];
                        Promise.all(promises).then(() => {
                            // we assume no response data due to encryption
                            dispatch(actions.patient.editPatientResponse(res));
                            // get the first page
                            dispatch(actions.patient.updatePage(1));
                            // update the patient
                            dispatch(
                                actions.patient.setPatientDetails(
                                    {
                                        ...action.patient, // UNNEEDED, leaving it for now, just in case
                                        ...res,
                                    },
                                    true,
                                ),
                            );
                        });
                    })
                    .catch((err) => {
                        dispatch(
                            actions.patient.editPatientError(
                                err.data?.contratos
                                    ? err.data.contratos[0]
                                    : 'Ha ocurrido un error',
                            ),
                        );
                    });
                break;
            }
            case GET_PROGRAMED_DOSE:
                service
                    .getProgramedDose(encodeURIComponent(action.id))
                    .then((res) => {
                        dispatch(actions.patient.getProgramedDoseResponse(res));
                    })
                    .catch((err) => {
                        dispatch(actions.patient.getProgramedDoseError(translateError(err.data)));
                    });
                break;
            case GET_AUDITOR_PROGRAMED_DOSE:
                service
                    .getAuditorProgramedDose(action.data.auditorId, action.data.id)
                    .then((res) => {
                        dispatch(actions.patient.getAuditorProgramedDoseResponse(res));
                    })
                    .catch((err) => {
                        dispatch(
                            actions.patient.getAuditorProgramedDoseError(translateError(err.data)),
                        );
                    });
                break;
            case GET_BASIC_PATIENTS:
                service
                    .getBasicPatients()
                    .then((res) => {
                        const { privateKey } = getKeysFromKeystore(
                            getState().profile.user.username,
                        );
                        const cipher = new Cipher();
                        // Currently we are filtering patients that failed decryption.
                        const decryptedPatients = res
                            .map((patient) => {
                                try {
                                    const cse = cipher.decrypt(privateKey, patient.csee);
                                    return decryptPatient(patient, cse);
                                } catch (e) {
                                    return undefined;
                                }
                            })
                            .filter((p) => !!p);
                        dispatch(actions.patient.getBasicPatientsResponse(decryptedPatients));
                    })
                    .catch((err) => {
                        dispatch(actions.patient.getBasicPatientsError(err));
                    });
                break;
        }
    };

const encryptPatient = (patient, osID, key) => {
    const cipher = new Cipher();
    const dni = patient.dni?.replaceAll(/[._]/g, '');
    let encryptedPatient = {
        nombre_enc: patient.name ? encryptString(patient.name, key) : null,
        dni_enc: patient.dni ? encryptString(dni, key) : null,
        afiliado_enc: patient.affiliate ? encryptString(patient.affiliate, key) : null,
        contratos:
            patient.contracts?.length > 0
                ? encryptContracts(
                      patient.contracts,
                      key,
                      patient.name,
                      dni,
                      patient.affiliate,
                      patient.birthDate,
                  )
                : [],
        activo: patient.status ? patient.status === 'active' : null,
        obra_social: osID ? osID : null,
        anio_nac: patient.birthDate ? patient.birthDate?.split('-')[0] : null,
        sexo: patient.sex,
        fecha_nacimiento_enc: patient.birthDate ? encryptString(patient.birthDate, key) : null,
        ...(patient.weight !== undefined && { historial_peso: patient.weight ?? [] }),
        ...(patient.accesos !== undefined && {
            accesos: patient.accesos.map((access) => {
                return {
                    grupo_auditor: access?.grupo_auditor || undefined,
                    drogueria: access?.drogueria || undefined,
                    obra_social: access?.obra_social || undefined,
                    owner: access.owner,
                    csee: access.publicKey ? cipher.encrypt(access.publicKey, key) : access.csee,
                };
            }),
        }),
    };

    // deletes all the props that are null
    Object.keys(encryptedPatient).forEach((key) => {
        if (encryptedPatient[`${key}`] === null) delete encryptedPatient[`${key}`];
    });

    return encryptedPatient;
};

const encryptContracts = (contracts, key, name, dni, affiliate, birthDate) => {
    return contracts.map((cnt) => {
        if (cnt.tipo_contrato_tipo === 'S') {
            return { ...cnt };
        } else {
            return {
                ...cnt,
                paciente_audit_enc: {
                    id: cnt?.paciente_audit_enc?.id,
                    nombre_enc: encryptString(name, key),
                    dni_enc: encryptString(dni, key),
                    afiliado_enc: encryptString(affiliate, key),
                    fecha_nacimiento_enc: encryptString(birthDate, key),
                },
            };
        }
    });
};

export const migrateEvidence = (evidence, privateKey, cse) => {
    return {
        ...evidence,
        adjunto: evidence.adjunto.map((adj) => {
            if (adj.tipo_adjunto === 'T') {
                return {
                    ...adj,
                    contenido: encryptString(decryptString(adj.contenido, privateKey), cse),
                    encrypted_old_privatekey: false,
                };
            } else return adj;
        }),
    };
};

const decryptPatient = (encryptedPatient, key, cse) => {
    return {
        id: encryptedPatient.id,
        name: decryptString(encryptedPatient.nombre_enc, key),
        dni: decryptString(encryptedPatient.dni_enc, key),
        affiliate:
            encryptedPatient?.afiliado_enc && decryptString(encryptedPatient.afiliado_enc, key),
        contracts: encryptedPatient?.contratos && encryptedPatient.contratos,
        status:
            encryptedPatient.activo !== 'undefined' &&
            (encryptedPatient.activo ? 'active' : 'inactive'),
        sex: encryptedPatient?.sexo && encryptedPatient.sexo,
        birthDate:
            encryptedPatient?.fecha_nacimiento_enc &&
            decryptString(encryptedPatient.fecha_nacimiento_enc, key),
        weight: encryptedPatient?.historial_peso && encryptedPatient.historial_peso,
        cse: cse,
        owner: encryptedPatient?.isowner,
        accesos: encryptedPatient?.accesos && encryptedPatient.accesos,
        os: encryptedPatient?.obra_social && encryptedPatient?.obra_social,
        osId: encryptedPatient?.obra_social_id && encryptedPatient?.obra_social_id,
    };
};

const mapEncryptedPatient = (patient) => ({
    id: patient.id,
    name: '****',
    dni: '****',
    affiliate: '****',
    contracts: patient.contratos,
    status: patient.activo ? 'active' : 'inactive',
    sex: patient.sexo,
    birthDate: new Date(),
    weight: patient.historial_peso,
    cse: undefined,
});

const translateError = (error) => {
    if (!error) return I18n.t('errors.generic');
    const error_fields = Object.keys(error);
    const errorsTitles = [];
    error_fields.map((errorKey) => {
        errorsTitles[errorsTitles.length] = error[errorKey][0];
    });
    if (errorsTitles.length > 0) return errorsTitles.join('. ') + '.';
    else return I18n.t('errors.generic');
};

export default patientMiddleware;
