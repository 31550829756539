import React, { useState, useEffect } from 'react';
import './RecoverPrivateKeyModal.scss';
import Button from '../../../common/components/Button';
import { SimpleModal } from '../../../common/components/Modal';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import Input from '../../../common/components/Input';
import keysStorage from '../../../utils/storage/keys';
import ErrorBox from '../../../common/components/ErrorBox';

const RecoverPrivateKeyModal = (props) => {
    const [inputValue, setInputValue] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showMnemonic, setShowMnemonic] = useState(false);
    const [mnemonicWords, setMnemonicWords] = useState([]);
    const { loading, success, error, errorMessage } = props.validatePasswordStatus;

    useEffect(() => {
        if (success) {
            keysStorage.getMnemonicFromKeystore(props.username).then((mnemonic) => {
                setMnemonicWords(mnemonic.split(' '));
                setShowMnemonic(true);
            });
        }
    }, [success]);

    const submit = () => {
        if (showMnemonic) close();
        else props.validatePassword(inputValue);
    };

    const close = () => {
        setShowMnemonic(false);
        setInputValue('');
        props.close();
    };

    return (
        <SimpleModal show={props.show}>
            {!showMnemonic && (
                <div className='recover-private-key-modal'>
                    <div className='header'>
                        <span className='title'>
                            {I18n.t('profile.recoverPrivateKeyModal.title')}
                        </span>
                        <i className='icon-close' onClick={close} />
                    </div>
                    <div className='body'>
                        <p>{I18n.t('profile.recoverPrivateKeyModal.body')}</p>
                        <Input
                            type={showPassword ? 'text' : 'password'}
                            showPassword={() => setShowPassword(!showPassword)}
                            value={inputValue}
                            handleChange={setInputValue}
                            password
                        />
                        <div className='fix-height'>
                            <ErrorBox show={error} errorMessage={errorMessage} />
                        </div>
                    </div>
                    <div className='footer'>
                        <Button
                            className='primary'
                            text={I18n.t('profile.recoverPrivateKeyModal.button')}
                            loading={loading}
                            onClick={submit}
                        />
                    </div>
                </div>
            )}
            {showMnemonic && (
                <div className='recover-private-key-modal'>
                    <div className='header'>
                        <span className='title'>
                            {I18n.t('profile.recoverPrivateKeyModal.thisIsYourEncryptionKey')}
                        </span>
                        <i className='icon-close' onClick={close} />
                    </div>
                    <div className='body'>
                        <div className='mnemonic-container'>
                            {mnemonicWords.map((word, index) => (
                                <span key={index} className='word'>
                                    {word}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className='footer'>
                        <Button
                            className='primary'
                            text={I18n.t('profile.recoverPrivateKeyModal.button')}
                            loading={loading}
                            onClick={submit}
                        />
                    </div>
                </div>
            )}
        </SimpleModal>
    );
};

RecoverPrivateKeyModal.propTypes = {
    show: PropTypes.bool.isRequired,
    validatePasswordStatus: PropTypes.object.isRequired,
    validatePassword: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired,
};

export default RecoverPrivateKeyModal;
