import { connect } from 'react-redux';
import ImportPrivateKeyScreen from '../components/ImportPrivateKeyScreen/ImportPrivateKeyScreen';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    validatePrivateKeyStatus: state.profile.validatePrivateKeyStatus,
    userType: state.profile.user.type,
    pkEmail: state.session.pk_backup ? state.profile.user.email : state.session.email_recover_key,
    encKey: state.session.encKey,
    username: state.profile.user.username,
});
const mapDispatchToProps = (dispatch) => ({
    savePrivateKey: (privateKey, mnemonic) =>
        dispatch(actions.profile.savePrivateKey(privateKey, mnemonic)),
    saveOldPrivateKey: (privateKey, mnemonic) =>
        dispatch(actions.profile.importOldPrivateKey(privateKey, mnemonic)),
    validatePrivateKey: (privateKey, keyManager) =>
        dispatch(actions.profile.validatePrivateKey(privateKey, keyManager)),
    validateOldPrivateKey: (oldPrivateKey) =>
        dispatch(actions.profile.validateOldPrivateKey(oldPrivateKey)),
    sendPrivateKeyByEmail: () => dispatch(actions.profile.sendPrivateKeyByEmail()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportPrivateKeyScreen);
