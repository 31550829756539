export const VALIDATE_PRODUCT = 'VALIDATE_PRODUCT';
export const VALIDATE_PRODUCT_RESPONSE = 'VALIDATE_PRODUCT_RESPONSE';
export const VALIDATE_PRODUCT_ERROR = 'VALIDATE_PRODUCT_ERROR';

export const REGISTER_PRODUCTS = 'REGISTER_PRODUCTS';
export const REGISTER_PRODUCTS_RESPONSE = 'REGISTER_PRODUCTS_RESPONSE';
export const REGISTER_PRODUCTS_ERROR = 'REGISTER_PRODUCTS_ERROR';
export const DOWNLOAD_REGISTER_TRANSACTIONS = 'DOWNLOAD_REGISTER_TRANSACTIONS';
export const DOWNLOAD_REGISTER_TRANSACTIONS_RESPONSE = 'DOWNLOAD_REGISTER_TRANSACTIONS_RESPONSE';
export const DOWNLOAD_REGISTER_TRANSACTIONS_ERROR = 'DOWNLOAD_REGISTER_TRANSACTIONS_ERROR';

export const GET_ALL_PRODUCTS_BY_ID = 'GET_ALL_PRODUCTS_BY_ID';
export const GET_ALL_PRODUCTS_BY_ID_RESPONSE = 'GET_ALL_PRODUCTS_BY_ID_RESPONSE';
export const GET_ALL_PRODUCTS_BY_ID_ERROR = 'GET_ALL_PRODUCTS_BY_ID_ERROR';

export default {
    validateProduct: (os, patientId, product, successCallback) => ({
        type: VALIDATE_PRODUCT,
        os,
        patientId,
        product,
        successCallback,
    }),
    validateProductResponse: (response) => ({ type: VALIDATE_PRODUCT_RESPONSE, response }),
    validateProductError: (error) => ({ type: VALIDATE_PRODUCT_ERROR, error }),

    registerProducts: (os, patientId, traces, successCallback) => ({
        type: REGISTER_PRODUCTS,
        os,
        patientId,
        traces,
        successCallback,
    }),
    registerProductsResponse: (response) => ({ type: REGISTER_PRODUCTS_RESPONSE, response }),
    registerProductsError: (error) => ({ type: REGISTER_PRODUCTS_ERROR, error }),

    downloadRegisterTransactions: () => ({ type: DOWNLOAD_REGISTER_TRANSACTIONS }),
    downloadRegisterTransactionsResponse: (response) => ({
        type: DOWNLOAD_REGISTER_TRANSACTIONS_RESPONSE,
        response,
    }),
    downloadRegisterTransactionsError: (error) => ({
        type: DOWNLOAD_REGISTER_TRANSACTIONS_ERROR,
        error,
    }),

    getAllProductsById: (patientId) => ({ type: GET_ALL_PRODUCTS_BY_ID, patientId }),
    getAllProductsByIdResponse: (response) => ({ type: GET_ALL_PRODUCTS_BY_ID_RESPONSE, response }),
    getAllProductsByIdError: (error) => ({ type: GET_ALL_PRODUCTS_BY_ID_ERROR, error }),
};
