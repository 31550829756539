import React, { useState } from 'react';
import './Select.scss';

export interface SelectOption {
    label: string;
    value: string;
}

interface SelectProps {
    value: string | null;
    options: SelectOption[];
    onChange: (option: SelectOption) => void;
    className?: string;
    placeholder?: string;
}

export const Select = (props: SelectProps) => {
    const [show, setShow] = useState(false);
    const [focused, setFocused] = useState(false);
    const [filterText, setFilterText] = useState('');
    const foundOption = props.options.find((o) => o.value === props.value);
    const emptyOption: SelectOption = { label: props.placeholder || 'Selecciona', value: '' };

    const options: SelectOption[] = [...props.options, emptyOption].filter((o) =>
        o.label.toLowerCase().includes(filterText.toLowerCase()),
    );

    const getValue = () => {
        if (!focused) {
            if (foundOption) return foundOption.label;
            return props.placeholder || 'Selecciona';
        }
        return filterText;
    };

    return (
        <div className='nexus-select'>
            <input
                value={getValue()}
                onChange={(e) => setFilterText(e.target.value)}
                className={`value-container ${!foundOption ? 'placeholder' : ''}`}
                onClick={() => setShow(!show)}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
            />
            <div id='nexus-select-container' className={`select ${show ? 'show' : ''}`}>
                {options
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((o, i) => {
                        return (
                            <div
                                className={`option ${
                                    foundOption?.value === o.value ? 'selected' : ''
                                }`}
                                key={`nexus-select-option-${i}`}
                                onClick={() => {
                                    props.onChange(o);
                                    setShow(false);
                                }}
                            >
                                {o.label}
                            </div>
                        );
                    })}
            </div>
            {show ? <i className='icon-arrow-up' /> : <i className='icon-arrow-down' />}
            <div
                className={'cross' + (props.value ? ' show-cross' : '')}
                onClick={() => props.onChange(emptyOption)}
            >
                X
            </div>
        </div>
    );
};
