import React, { useEffect } from 'react';
import './RegistrarAccountActivationScreen.scss';
import { I18n } from 'react-redux-i18n';
import nexus_logo from '../../../assets/logos/nexus-white-small.png';
import { useHistory } from 'react-router-dom';
import Loader from '../../../common/components/Loader';

const RegistrarAccountActivationScreen = (props) => {
    const { loading, error } = props.activationStatus;
    const history = useHistory();

    const { uid, token } = props.match.params;

    useEffect(() => {
        if (uid && token) {
            props.activateAccount(uid, token);
        }
    }, [uid, token]);

    return (
        <div className='registrar-account-activation-screen'>
            <img alt='Nexus logo' src={nexus_logo} />
            <div className='card'>
                {loading ? (
                    <Loader />
                ) : !error ? (
                    <div className='activation-result success'>
                        <i className='icon-check' />
                        <label className='title'>
                            {I18n.t(
                                'registrarNewAccount.accountActivation.yourAccountWasActivatedCorrectly',
                            )}
                        </label>
                    </div>
                ) : (
                    <div className='activation-result error'>
                        <i className='icon-close' />
                        <label className='title'>
                            {I18n.t('registrarNewAccount.accountActivation.anErrorHasOccur')}
                        </label>
                    </div>
                )}
            </div>
            <label className='back-to-top' onClick={() => history.push('/')}>
                {I18n.t('registrarNewAccount.accountActivation.goToBack')}
            </label>
        </div>
    );
};

export default RegistrarAccountActivationScreen;
