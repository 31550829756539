import { connect } from 'react-redux';
import OSTransactionsScreen from '../components/OSTransactionsScreen';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    transactions: state.transaction.filteredResults,
    total: state.transaction.filteredResults?.length,
    totalPages: Math.ceil(state?.transaction?.filteredResults?.length / 10),
    currentPage: state.transaction.current,

    allTransactions: state.transaction.results,
    filteredTransactionsBySearch: state.transaction.filteredResultsBySearch,

    loading: state.transaction.getTransactionsStatus.loading,
    validateTransactionsStatus: state.transaction.validateTransactionsStatus,
    rejectTransactionsStatus: state.transaction.rejectTransactionsStatus,

    validationResults: state.transaction.lastValidationResults,
    rejectionResults: state.transaction.lastRejectionResults,
    downloadStatus: state.transaction.downloadTransactionsStatus,
});

const mapDispatchToProps = (dispatch) => ({
    getTransactions: (filter) => dispatch(actions.transaction.getTransactions(filter)),
    putTransactionsAplications: (body) =>
        dispatch(actions.transaction.putTransactionsAplications(body)),

    updateFilterValues: (filter) => dispatch(actions.transaction.updateFilterValues(filter)),
    clearFilters: () => dispatch(actions.transaction.clearFilters()),

    updatePage: (pageNumber) => dispatch(actions.transaction.updatePage(pageNumber)),
    updatePageSize: (pageSize) => dispatch(actions.transaction.updatePageSize(pageSize)),

    searchTransactions: (search) => dispatch(actions.transaction.searchTransactions(search)),

    updateFilteredTransactionsByDateRange: (newResults) =>
        dispatch(actions.transaction.updateFilteredTransactionsByDateRange(newResults)),
    clearDateRangeSelection: (textToSearch) =>
        dispatch(actions.transaction.clearDateRangeSelection(textToSearch)),

    validateTransactions: (transactions) =>
        dispatch(actions.transaction.validateTransactions(transactions)),
    rejectTransactions: (transactions) =>
        dispatch(actions.transaction.rejectTransactions(transactions)),

    downloadTransactions: () => dispatch(actions.transaction.downloadOSTransactions()),

    clearUI: () => dispatch(actions.transaction.clearUI()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OSTransactionsScreen);
