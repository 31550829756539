export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const SAVE_KEYS = 'SAVE_KEYS';
export const SAVE_KEYS_RESPONSE = 'SAVE_KEYS_RESPONSE';
export const SAVE_KEYS_ERROR = 'SAVE_KEYS_ERROR';

export const VALIDATE_PASSWORD = 'VALIDATE_PASSWORD';
export const VALIDATE_PASSWORD_RESPONSE = 'VALIDATE_PASSWORD_RESPONSE';
export const VALIDATE_PASSWORD_ERROR = 'VALIDATE_PASSWORD_ERROR';

export const VALIDATE_PRIVATE_KEY = 'VALIDATE_PRIVATE_KEY';
export const VALIDATE_OLD_PRIVATE_KEY = 'VALIDATE_OLD_PRIVATE_KEY';
export const VALIDATE_PRIVATE_KEY_RESPONSE = 'VALIDATE_PRIVATE_KEY_RESPONSE';
export const VALIDATE_PRIVATE_KEY_ERROR = 'VALIDATE_PRIVATE_KEY_ERROR';

export const SAVE_PK_TO_CLOUD = 'SAVE_PK_TO_CLOUD';
export const SAVE_PK_TO_CLOUD_RESPONSE = 'SAVE_PK_TO_CLOUD_RESPONSE';
export const SAVE_PK_TO_CLOUD_ERROR = 'SAVE_PK_TO_CLOUD_ERROR';

export const SEND_PRIVATE_KEY_BY_EMAIL = 'SEND_PRIVATE_KEY_BY_EMAIL';
export const SEND_PRIVATE_KEY_BY_EMAIL_RESPONSE = 'SEND_PRIVATE_KEY_BY_EMAIL_RESPONSE';
export const SEND_PRIVATE_KEY_BY_EMAIL_ERROR = 'SEND_PRIVATE_KEY_BY_EMAIL_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_RESPONSE = 'CHANGE_PASSWORD_RESPONSE';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const SAVE_PRIVATE_KEY = 'SAVE_PRIVATE_KEY';
export const SAVE_OLD_PRIVATE_KEY = 'SAVE_OLD_PRIVATE_KEY';
export const IMPORT_OLD_PRIVATE_KEY = 'IMPORT_OLD_PRIVATE_KEY';

export const UPDATE_PUBLIC_KEY = 'UPDATE_PUBLIC_KEY';

export const UPDATE_MIGRATED_STATUS = 'UPDATE_MIGRATED_STATUS';

const content = {
    updateUser: (user) => ({ type: UPDATE_USER, user }),
    updateUserResponse: (response) => ({ type: UPDATE_USER_RESPONSE, response }),
    updateUserError: (error) => ({ type: UPDATE_USER_ERROR, error }),
    /*
     * the saveKeys is use when the os don't have a public key, and the user needs to create one, it send the
     * public key to the os, save the private key on local storage and modify the user type.
     * */
    saveKeys: (keys, address, mnemonic) => ({ type: SAVE_KEYS, keys, address, mnemonic }),
    saveKeysResponse: (response) => ({ type: SAVE_KEYS_RESPONSE, response }),
    saveKeysError: (error) => ({ type: SAVE_KEYS_ERROR, error }),
    validatePassword: (password) => ({ type: VALIDATE_PASSWORD, password }),
    validatePasswordResponse: () => ({ type: VALIDATE_PASSWORD_RESPONSE }),
    validatePasswordError: (error) => ({ type: VALIDATE_PASSWORD_ERROR, error }),
    // the savePrivateKey saves the private key on local storage and modify the use type.
    savePrivateKey: (privateKey, mnemonic) => ({ type: SAVE_PRIVATE_KEY, privateKey, mnemonic }),
    saveOldPrivateKey: (privateKey) => ({ type: SAVE_OLD_PRIVATE_KEY, privateKey }),
    importOldPrivateKey: (oldPrivateKey, mnemonic) => ({
        type: IMPORT_OLD_PRIVATE_KEY,
        mnemonic,
        oldPrivateKey,
    }),

    updatePublicKey: (keys, address, mnemonic) => ({
        type: UPDATE_PUBLIC_KEY,
        keys,
        address,
        mnemonic,
    }),

    validatePrivateKey: (privateKey, keyManager) => ({
        type: VALIDATE_PRIVATE_KEY,
        privateKey,
        keyManager,
    }),
    validateOldPrivateKey: (oldPrivateKey) => ({ type: VALIDATE_OLD_PRIVATE_KEY, oldPrivateKey }),
    validatePrivateKeyResponse: (response) => ({ type: VALIDATE_PRIVATE_KEY_RESPONSE, response }),
    validatePrivateKeyError: (error) => ({ type: VALIDATE_PRIVATE_KEY_ERROR, error }),

    savePKToCloud: (mnemonic) => ({ type: SAVE_PK_TO_CLOUD, mnemonic }),
    savePKToCloudResponse: (response) => ({ type: SAVE_PK_TO_CLOUD_RESPONSE, response }),
    savePKToCloudError: (error) => ({ type: SAVE_PK_TO_CLOUD_ERROR, error }),

    sendPrivateKeyByEmail: () => ({ type: SEND_PRIVATE_KEY_BY_EMAIL }),
    sendPrivateKeyByEmailResponse: (response) => ({
        type: SEND_PRIVATE_KEY_BY_EMAIL_RESPONSE,
        response,
    }),
    sendPrivateKeyByEmailError: (error) => ({ type: SEND_PRIVATE_KEY_BY_EMAIL_ERROR, error }),

    changePassword: (currentPassword, newPassword, repeatNewPassword) => ({
        type: CHANGE_PASSWORD,
        currentPassword,
        newPassword,
        repeatNewPassword,
    }),
    changePasswordResponse: (response) => ({ type: CHANGE_PASSWORD_RESPONSE, response }),
    changePasswordError: (error) => ({ type: CHANGE_PASSWORD_ERROR, error }),

    updateMigratedStatus: () => ({ type: UPDATE_MIGRATED_STATUS }),
};

export default content;
