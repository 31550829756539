import React, { useEffect, useRef, useState } from 'react';
import './Header.scss';
import actions from '../../../actions';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import profilePlaceholder from '../../../assets/images/profile-placeholder.png';
import nexusLogo from '../../../assets/logos/nexus-color-small.png';
import ProfileTooltip from '../../../profile/components/ProfileTooltip';
import { UserTypes } from '../../../constants';

const Header = (props) => {
    const { isLoggedIn, user, osName, drugName } = props;
    const [showProfile, setShowProfile] = useState(false);

    const profileToolTip = useRef();

    useEffect(() => {
        // add when mounted
        document.addEventListener('mousedown', handleProfileButtonClick);

        // return function to be called when unmounted
        return () => {
            document.removeEventListener('mousedown', handleProfileButtonClick);
        };
    }, []);

    const handleProfileButtonClick = (e) => {
        if (!profileToolTip.current?.contains(e.target)) {
            // outside click
            setShowProfile(false);
        }
    };

    // If the user is of type "USER" (basic django user), avoid showing the header.
    if (!isLoggedIn || user?.type === UserTypes.USER) return null;

    const dashboardIsSelected = () => props.location.pathname === '/dashboard';

    const showDashboard = () =>
        user?.type === UserTypes.OS_USER ||
        user?.type === UserTypes.DRUGSTORE ||
        user?.type === UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY ||
        user?.type === UserTypes.ROCHE_USER;

    return (
        <div className='header'>
            <div className='dashboard'>
                {showDashboard() && (
                    <Link to='/dashboard'>
                        <i className={`icon-grid ${dashboardIsSelected() && 'selected'}`} />
                    </Link>
                )}
            </div>
            <div className='logo'>
                <img alt='Nexus Logo' src={nexusLogo} />
            </div>
            <div ref={profileToolTip}>
                <div
                    className='profile-button clickable'
                    onClick={() => setShowProfile(!showProfile)}
                >
                    <img src={profilePlaceholder} />
                    <div className='name-container'>
                        <span>{user.first_name || ''}</span>
                        <span>{user.last_name || ''}</span>
                    </div>
                    <i className='icon-arrow-down' />
                </div>
                <ProfileTooltip
                    user={user}
                    show={showProfile}
                    setShowProfile={setShowProfile}
                    drugName={drugName}
                    osName={osName}
                    myAddress={props.myAddress}
                    logout={() => {
                        setShowProfile(false);
                        props.logout();
                    }}
                    closeProfileTooltip={() => setShowProfile(false)}
                    userType={props.userType}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.profile.user,
    osName: state.session.os_name,
    drugName: state.session.drug_name,
    isLoggedIn: state.session.isLoggedIn,
    myAddress: state.wallet.data.address,
    userType: state.profile?.user?.type,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(actions.session.logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
