import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import sessionReducer from '../session/session.reducer';
import profileReducer from '../profile/profile.reducer';
import commonReducer from '../common/common.reducer';
import patient from '../patient/patient.reducer';
import registration from '../registration/registration.reducer';
import transaction from '../transaction/transaction.reducer';
import registrar from '../registrar/registrar.reducer';
import indication from '../indication/indication.reducer';
import contract from '../contract/contract.reducer';
import roleAccess from '../roleAccess/roleAccess.reducer';
import walletReducer from '../wallet/wallet.reducer';

const rootReducer = combineReducers({
    session: sessionReducer,
    profile: profileReducer,
    common: commonReducer,
    patient,
    i18n: i18nReducer,
    registration,
    transaction,
    registrar,
    indication,
    contract,
    roleAccess,
    wallet: walletReducer,
});

export default rootReducer;
