import { get, post, put, webApi } from '../utils/http';
export const services = {
    registrarSignUp: (body) => post(webApi + 'auth/users/', body),
    getInstitutionByCuit: (cuit) => get(webApi + `api/institucion?cuit=${cuit}`),
    createInstitution: (body) => post(webApi + 'api/institucion/', body),
    createBranch: (body) => post(webApi + 'api/sucursal/', body),
    getBranchByInstitutionId: (institutionId) =>
        get(webApi + `api/institucion/${institutionId}/sucursal`),
    createRegistrar: (body) => post(webApi + 'api/registrador/', body),
    updateBranchPubKey: (branchId, body) => put(webApi + `api/sucursal/${branchId}/`, body),
    // todo this route might be wrong
    updateRegistrar: (body) => put(webApi + `api/usuarioregistrador/me/`, body, {}),
    activateRegistrarAccount: (uid, token) =>
        post(webApi + `auth/users/activation/`, { uid, token }),
};
