import { connect } from 'react-redux';
import RegistrarProfileScreen from '../components/RegistrarProfileScreen';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    user: state.profile.user || {},
    institution: state.registrar.institution,
    branch: state.registrar.branch,
    // osName: state.session.os_name,
    // saveKeysStatus: state.profile.saveKeysStatus,
    updateUserStatus: state.profile.updateUserStatus,
    validatePasswordStatus: state.profile.validatePasswordStatus,
    changePasswordStatus: state.profile.changePasswordStatus,
});

const mapDispatchToProps = (dispatch) => ({
    // saveKeys: (keys) => dispatch(actions.profile.saveKeys(keys)),
    updateUser: (user) => dispatch(actions.profile.updateUser(user)),
    validatePassword: (password) => dispatch(actions.profile.validatePassword(password)),
    changePassword: (currentPassword, newPassword, repeatNewPassword) =>
        dispatch(actions.profile.changePassword(currentPassword, newPassword, repeatNewPassword)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrarProfileScreen);
