import React, { useState } from 'react';
import { SimpleModal } from '../Modal';
import PropTypes from 'prop-types';
import './TermsAndConditionsModal.scss';
import Checkbox from '../Checkbox';
import Button from '../Button';
import { I18n } from 'react-redux-i18n';

const TermsAndConditionsModal = (props) => {
    const [isChecked, setIsChecked] = useState(false);

    return (
        <SimpleModal show={props.show}>
            <div className='terms-and-conditions-modal'>
                <div className='header'>
                    <span className='title'>{props.title}</span>
                </div>
                <div className='footer'>
                    <div className='terms-and-conditions-row'>
                        <Checkbox
                            id='termsAndConditions'
                            checked={isChecked}
                            onChange={() => setIsChecked(!isChecked)}
                        />
                        <span className='checkbox-label'>
                            {props.titleChk}
                            <br />
                            <a onClick={props.redirect}>{props.titleRedirect}</a>
                        </span>
                    </div>
                    <div className='container-btns'>
                        <Button
                            className='primary with-gradient'
                            disabled={!isChecked}
                            loading={props.loading}
                            onClick={() => {
                                props.onSubmit();
                                setIsChecked(false);
                            }}
                            text={I18n.t('termsAndConditionsModal.confirm')}
                        />
                        <Button
                            className='decline'
                            onClick={() => {
                                props.onCancel();
                                setIsChecked(false);
                            }}
                            text={I18n.t('termsAndConditionsModal.cancel')}
                        />
                    </div>
                </div>
            </div>
        </SimpleModal>
    );
};

TermsAndConditionsModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

export default TermsAndConditionsModal;
