import { useEffect, useRef, useState } from 'react';

/**
 * value setValue state with internal debounce logic
 * triggers callback function when reaches timeout
 *
 * @param {any} initialValue
 * @param {() => void} callback
 * @param {number} timeoutDelay
 */

export const useDebounceInput = (props) => {
    const [value, setValue] = useState(props.initialValue);
    const first = useRef(true);
    /**
     * - Triggers when value changes
     * - If it reaches timeout, executes callback parameter function
     * - If value is changed before that, cleanup the previous
     * effect occurs and calls new effect, clearing previous
     * timeout
     */
    useEffect(() => {
        props.setPage(0);

        if (first.current) {
            first.current = false;
            return;
        }
        const debounce = setTimeout(() => {
            props.callback(value);
        }, props.timeoutDelay || 1000);
        return () => clearTimeout(debounce);
    }, [value]);

    return [value, setValue];
};
