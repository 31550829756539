import { connect } from 'react-redux';
import actions from '../../actions';
import RegistrarSignUp from '../components/RegistrarSignUpScreen';

const mapStateToProps = (state) => ({
    signUpStatus: state.registrar.registrarSignUpStatus,
    signUpEmailSendTo: state.registrar.signUpEmailSendTo,
});

const mapDispatchToProps = (dispatch) => ({
    signUp: (firstName, lastName, username, email, password, repeatPassword) =>
        dispatch(
            actions.registrar.registrarSignUp(
                firstName,
                lastName,
                username,
                email,
                password,
                repeatPassword,
            ),
        ),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrarSignUp);
