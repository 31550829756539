import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import '../OSTransactionsScreen/OSTransactionsSreen.scss';
import './AuditorTransactionsScreen.scss';
// todo cambiar scss y agregar el de este componente
import SearchBar from '../../../common/components/SearchBar';
import Button from '../../../common/components/Button';
import { I18n } from 'react-redux-i18n';
import Loader from '../../../common/components/Loader';
import ReactTable from 'react-table';
import TablePagination from '../../../common/components/TablePagination';
import { parseDate } from '../../../utils';
import ReactTooltip from 'react-tooltip';
import SelectMultiple from '../../../common/components/SelectMultiple';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const auditStates = [
    { value: 'P', label: 'auditPending' },
    { value: 'R', label: 'auditRejected' },
    { value: 'A', label: 'auditApproved' },
    { value: 'C', label: 'auditConsumptionRejected' },
    { value: 'B', label: 'cleanedEvidence' },
    { value: 'E', label: 'endSuccessfulTreatment' },
    { value: 'F', label: 'endTreatmentProgression' },
    { value: '#', label: 'noAuditor' },
];

const defaultStatusSelected = [
    { id: 'P', checked: true },
    { id: 'R', checked: true },
    { id: 'A', checked: false },
    { id: 'C', checked: true },
    { id: 'B', checked: true },
    { id: 'E', checked: true },
    { id: 'F', checked: true },
];

const AuditorTransactionsScreen = (props) => {
    const [textToSearch, setTextToSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState(defaultStatusSelected);
    const [transactions, setTransactions] = useState([]);
    const history = useHistory();

    useEffect(() => {
        props.getAuditorTransactions();
    }, []);

    useEffect(() => {
        const filters = statusFilter.filter((x) => x.checked) ?? [];
        if (filters.length > 0) {
            setTransactions(
                props.auditorTransactions.filter((x) => filters.some((y) => y.id === x.estado)),
            );
        }
        // eslint-disable-next-line
    }, [props.auditorTransactions]);

    useEffect(() => {
        const filters = statusFilter.filter((x) => x.checked) ?? [];
        if (filters.length > 0) {
            setTransactions(
                props.auditorTransactions.filter((x) => filters.some((y) => y.id === x.estado)),
            );
        }
        // eslint-disable-next-line
    }, [statusFilter]);

    const renderTableFooter = (paginationProps) => (
        <div className='transaction-table-footer'>
            <TablePagination
                {...paginationProps}
                total={transactions?.length}
                totalPages={transactions?.length / 10}
                pagedMessageTranslation='transaction.table.pagedMessage'
                pagedMessageEmptyTranslation='transaction.table.pagedMessageEmpty'
            />
        </div>
    );

    const handleStatusFilterChange = (statusId) => {
        const otherStatus = statusFilter.filter((status) => status.id !== statusId);
        const selectedStatus = statusFilter.filter((status) => status.id === statusId)[0];
        setStatusFilter([...otherStatus, { id: statusId, checked: !selectedStatus?.checked }]);
    };

    const infoModal = () => {
        return (
            <>
                <h5>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{
                            color: '#008e99',
                            fontSize: '2.25rem',
                            margin: '0 1.75rem',
                        }}
                    />
                    {I18n.t('transaction.table.status.definition')}
                </h5>
                <hr />
                <ul className='list approved'>
                    <li>
                        <h5>{I18n.t('transaction.table.status.auditPending')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.auditApproved')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.auditRejected')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.auditConsumptionRejected')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.cleanedEvidence')}</h5>
                    </li>
                </ul>
            </>
        );
    };

    const formatHeader = (text, id, infoIcon = false, modal) => {
        return (
            <div className='nexus-header-arrows space-between'>
                <h5>
                    {text}&nbsp;
                    {infoIcon && <FontAwesomeIcon data-tip data-for={id} icon={faInfoCircle} />}
                </h5>
                <ReactTooltip
                    id={id}
                    place='bottom'
                    type='light'
                    effect='solid'
                    className='info-modal'
                >
                    {modal}
                </ReactTooltip>
                <div className='arrows'>
                    <span>▲</span>
                    <span>▼</span>
                </div>
            </div>
        );
    };

    const DetailsCol = (patientRow) => {
        return (
            <button
                className='detail-button'
                onClick={() => {
                    // dispatch current transaction / patient
                    props.getPatientAuditor(patientRow._original.id);
                    props.setAuditorPatientDetail(patientRow);
                    history.push(`/auditIncident`);
                }}
            >
                <span className='text transactions-table-row details'>Ver detalles</span>
            </button>
        );
    };

    const getTableColumns = () => {
        // here could be some logic to show certain columns depending on user role.
        return [
            {
                id: 'numero', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transaction.table.headers.number')}</h5>,
                accessor: (d) => d.id, // Custom value accessors!
                Cell: (props) => (
                    <div className='numbers'>
                        {props.row.estado === 'P' && (
                            <span className='red-dot-number'>{'\u2022'}</span>
                        )}
                        <span className='text transactions-table-row'>{props.value}</span>
                    </div>
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 100,
            },
            {
                id: 'obra_social', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(I18n.t('transaction.table.headers.os'), 'header_obra_social'),
                accessor: (d) => d.obra_social.razon_social, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'paciente', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(I18n.t('transaction.table.headers.patientId'), 'header_patient'),
                accessor: (d) => d.paciente?.id, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 150,
            },
            {
                id: 'nombre', // Required because our accessor is not a string
                Header: () => formatHeader(I18n.t('transaction.table.headers.name'), 'header_name'),
                accessor: (d) => d?.paciente?.name, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header space-between',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'dni', // Required because our accessor is not a string
                Header: () => formatHeader(I18n.t('transaction.table.headers.dni'), 'header_dni'),
                accessor: (d) => d?.paciente?.dni, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 150,
            },
            {
                id: 'fecha', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(I18n.t('rocheTransaction.table.headers.date'), 'header_date'),
                accessor: (d) => d.updated_at, // Custom value accessors!
                Cell: (props) => (
                    <span className='text transactions-table-row'>
                        {parseDate(new Date(props.value))}
                    </span>
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'incidente', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(I18n.t('transaction.table.headers.incident'), 'header_incident'),
                accessor: (d) => d.descripcion, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'estado', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(
                        I18n.t('transaction.table.headers.status'),
                        'header_status',
                        true,
                        infoModal(),
                    ),
                accessor: (d) => d.estado, // Custom value accessors!
                //Cell: props => <span className='text transactions-table-row'>{props.value}</span>,
                Cell: (props) => (
                    <span
                        className={`transactions-table-row ${
                            auditStates.find((state) => state.value === props.value)?.label
                        }`}
                    >
                        {I18n.t(
                            `transaction.table.status.${
                                auditStates.find((state) => state.value === props.value)?.label
                            }`,
                        )}
                    </span>
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'detalle', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transaction.table.headers.detail')}</h5>,
                Cell: (props) => DetailsCol(props.row),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 150,
            },
        ];
    };

    return (
        <div className='patients-screen'>
            {/*<div className={"os-transactions-screen"}>*/}
            <div className='header'>
                <span className='title'>{I18n.t('transaction.auditorTitle')}</span>
                <SearchBar
                    textToSearch={textToSearch}
                    onSearch={(text) => {
                        setTextToSearch(text);
                        props.searchTransactions(text);
                    }}
                />
                <SelectMultiple
                    values={statusFilter}
                    onChange={handleStatusFilterChange}
                    className='rounded'
                    placeholder={I18n.t('transaction.table.headers.status')}
                    options={[
                        { value: 'P', label: I18n.t('transaction.table.status.auditPending') },
                        { value: 'A', label: I18n.t('transaction.table.status.auditApproved') },
                        { value: 'R', label: I18n.t('transaction.table.status.auditRejected') },
                        {
                            value: 'C',
                            label: I18n.t('transaction.table.status.auditConsumptionRejected'),
                        },
                        { value: 'B', label: I18n.t('transaction.table.status.cleanedEvidence') },
                        {
                            value: 'E',
                            label: I18n.t('transaction.table.status.endSuccessfulTreatment'),
                        },
                        {
                            value: 'F',
                            label: I18n.t('transaction.table.status.endTreatmentProgression'),
                        },
                    ]}
                />
                <Button
                    type='button'
                    className='primary small download-btn'
                    text={I18n.t('transaction.download')}
                    icon={<i className='icon-cloud-download' />}
                    onClick={() => {
                        props.downloadAuditorTransactions();
                    }}
                />
            </div>
            <div className='table-container'>
                {/* <div className={"transaction-table-container"}> */}
                {props.loading ? (
                    <>
                        <div className='header' />
                        <div className='transaction-table-container'>
                            <Loader />
                        </div>
                    </>
                ) : (
                    <ReactTable
                        //className={(props.transactions.length > 0 ? "nexus-table"  : "nexus-table empty")}
                        className='nexus-table'
                        PaginationComponent={(paginationProps) =>
                            renderTableFooter(paginationProps)
                        }
                        minRows={0}
                        pageSize={10}
                        data={transactions}
                        noDataText={I18n.t('transaction.table.noRows')}
                        onSortedChange={() => {
                            // props.updateFilterValues({ordering: (newSorted[0].desc ? "-" : "") + column.id});
                            // props.getTransactions();
                        }}
                        // todo check if this prop is necessary
                        getTdProps={() => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    if (handleOriginal) {
                                        handleOriginal();
                                    }
                                },
                            };
                        }}
                        columns={getTableColumns()}
                        defaultSorted={[{ id: 'dates', desc: true }]}
                    />
                )}
            </div>
        </div>
    );
};

export default AuditorTransactionsScreen;
