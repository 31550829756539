import React, { useState, useEffect } from 'react';
import './PatientScreen.scss';
import 'react-table/react-table.css';
import './Table.scss';
import { I18n } from 'react-redux-i18n';
import Button from '../../../common/components/Button';
import Loader from '../../../common/components/Loader';
import ReactTable from 'react-table';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import SearchBar from '../../../common/components/SearchBar';
import TablePagination from '../../../common/components/TablePagination';
import Checkbox from '../../../common/components/Checkbox';
import { IndStatusModal } from '../../../indication/components/IndicationsScreen/IndicationsScreen';
import { UserTypes } from '../../../constants';

const PatientScreen = (props) => {
    const [searchText, setSearchText] = useState('');
    const [statusFilter, setStatusFilter] = useState(['active', 'inactive']);
    const history = useHistory();
    const { searchPatients } = props;
    const [showState, setShowState] = useState(false);

    useEffect(() => {
        props.getPatients();
    }, []);

    useEffect(() => {
        if (props.editPatientStatus.error) {
            setShowState(true);
        } else {
            setShowState(false);
        }
    }, [props.editPatientStatus]);

    useEffect(() => {
        props.updateFilterValues({ status: statusFilter });
    }, [statusFilter]);

    const isCheckboxSelected = (checkboxId) => {
        const filtered = statusFilter.filter((status) => status === checkboxId);
        return filtered.length === 1;
    };

    const handleCheckboxChange = (checkboxId, checked) => {
        const filtered = statusFilter.filter((status) => status === checkboxId);
        if (checked && filtered.length === 0) setStatusFilter([...statusFilter, `${checkboxId}`]);
        else if (!checked && filtered.length === 1)
            setStatusFilter(statusFilter.filter((status) => status !== checkboxId));
    };

    const renderRowButtons = (rowData) => (
        <div className='action-buttons-container'>
            <a
                data-tip={
                    props.userType != UserTypes.DRUGSTORE
                        ? I18n.t('patient.table.buttonTooltips.editInformation')
                        : I18n.t('patient.table.buttonTooltips.seeInformation')
                }
                data-for='actionButtonTooltip'
            >
                <i
                    className={props.userType != UserTypes.DRUGSTORE ? 'icon-pencil' : 'icon-eye'}
                    onClick={() => {
                        props.loadPatientData(rowData);
                        history.push('patients/edit');
                    }}
                />
            </a>
            <ReactTooltip
                id='actionButtonTooltip'
                backgroundColor='#008e99'
                effect='solid'
                className='action-button-tooltip'
            />
            {props.userType != UserTypes.DRUGSTORE && (
                <>
                    <a
                        data-tip={I18n.t(
                            `patient.table.buttonTooltips.${
                                rowData.status === 'active' ? 'deactivate' : 'activate'
                            }`,
                        )}
                        data-for='actionButtonTooltip'
                    >
                        <i
                            className='icon-ban'
                            onClick={() => {
                                if (rowData.status === 'active') props.disablePatient(rowData);
                                else props.enablePatient(rowData);
                            }}
                        />
                    </a>
                    <ReactTooltip
                        id='actionButtonTooltip'
                        backgroundColor='#008e99'
                        effect='solid'
                        className='action-button-tooltip'
                    />
                </>
            )}
        </div>
    );

    const getTableColumns = () => {
        // here could be some logic to show certain columns depending on user role.
        return [
            {
                id: 'idp', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('patient.table.headers.id')}</h5>,
                accessor: (d) => d.id, // Custom value accessors!
                Cell: (props) => (
                    <div className='numbers'>
                        {props.row._original.contracts.some(
                            (x) => x.evidencias_notificar || x.dosis_notificar >= 1,
                        ) &&
                            props.row.status !== 'inactive' && (
                                <span className='red-dot-number'>{'\u2022'}</span>
                            )}
                        <span className='text transactions-table-row'>{props.value}</span>
                    </div>
                ),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'dni', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('patient.table.headers.dni')}</h5>,
                accessor: (d) => d.dni, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'name', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('patient.table.headers.name')}</h5>,
                accessor: (d) => d.name, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'affiliate', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('patient.table.headers.affiliate')}</h5>,
                accessor: (d) => d.affiliate, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'contract', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('patient.table.headers.contracts')}</h5>,
                accessor: (d) => d.contracts.map((contract) => contract.descripcion).join(', '), // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            {
                id: 'status', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('patient.table.headers.status')}</h5>,
                accessor: (d) => d.status, // Custom value accessors!
                Cell: (props) => (
                    <span className={`text status ${props.value.toString().toLowerCase()}`}>
                        {I18n.t(`patient.table.status.${props.value}`)}
                    </span>
                ),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
            },
            props.userType.type != UserTypes.DRUGSTORE && {
                id: 'id', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('patient.table.headers.action')}</h5>,
                accessor: (d) => d.buttons, // Custom value accessors!
                Cell: (props) => renderRowButtons(props.original),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell',
                style: { whiteSpace: 'unset' },
                width: 150,
            },
        ];
    };

    return props.loading ? (
        <div className='patients-screen'>
            <div className='header' />
            <div className='table-container'>
                <Loader />
            </div>
        </div>
    ) : (
        <div className='patients-screen'>
            {/* <NewPatientModal show={newPatientModalIsOpen} close={() => setOpenNewPatientModal(false)}/>
                <EditPatientModal show={editPatientModalIsOpen} close={() => setOpenEditPatientModal(false)}/> */}
            <div className='header'>
                <span className='title'>{I18n.t('patient.title')}</span>
                <SearchBar
                    textToSearch={searchText}
                    onSearch={(text) => {
                        setSearchText(text);
                        searchPatients(text);
                    }}
                />
                <div className='status-checkboxes'>
                    <Checkbox
                        id='active'
                        checked={isCheckboxSelected('active')}
                        onChange={handleCheckboxChange}
                        label={I18n.t('patient.statusFilter.active')}
                    />
                    <Checkbox
                        id='inactive'
                        checked={isCheckboxSelected('inactive')}
                        onChange={handleCheckboxChange}
                        label={I18n.t('patient.statusFilter.inactive')}
                    />
                </div>
                {props.userType != UserTypes.DRUGSTORE && (
                    <div className='search-button'>
                        <Button
                            type='button'
                            className='primary small'
                            onClick={() => {
                                //setOpenNewPatientModal(true)
                                props.loadPatientData({});
                                history.push('patients/edit');
                            }}
                            text={I18n.t('patient.createNew')}
                        />
                    </div>
                )}
            </div>
            <div className='table-container'>
                <ReactTable
                    className={props.patients.length > 0 ? 'nexus-table' : 'nexus-table empty'}
                    PaginationComponent={(paginationProps) => (
                        <TablePagination
                            {...paginationProps}
                            total={props.total}
                            totalPages={props.totalPages}
                            pagedMessageTranslation='patient.table.pagedMessage'
                            pagedMessageEmptyTranslation='patient.table.pagedMessageEmpty'
                        />
                    )}
                    minRows={0}
                    pageSize={10}
                    data={props.patients}
                    noDataText={I18n.t('patient.table.noRows')}
                    // todo check if this prop is necessary
                    getTdProps={(state, rowInfo /*column, instance*/) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                                props.selectPatient(props.patients[rowInfo.index]);
                            },
                        };
                    }}
                    columns={getTableColumns()}
                />
            </div>
            <IndStatusModal
                setShow={setShowState}
                show={showState}
                status={props.editPatientStatus}
            />
        </div>
    );
};

export default PatientScreen;
