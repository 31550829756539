import React, { useState, useEffect } from 'react';
import './RegistrarSignUpScreen.scss';
import NexusLogo from '../../../assets/logos/nexus-white-small.png';
import RegistrarSignUpForm from '../RegistrarSignUpForm/RegistrarSignUpForm';
import { useHistory } from 'react-router-dom';
import Header from '../../../assets/images/registrar-sign-up-header.png';
import { I18n } from 'react-redux-i18n';

const RegistrarSignUpScreen = (props) => {
    const [accountCreatedSuccessful, setAccountCreatedSuccessful] = useState();
    const [email, setEmail] = useState();

    const history = useHistory();

    useEffect(() => {
        if (props.signUpStatus.success) {
            setAccountCreatedSuccessful(true);
            setEmail(props.signUpEmailSendTo);
        }
    }, [props.signUpStatus, props.signUpEmailSendTo]);

    if (accountCreatedSuccessful) {
        return (
            <div className='account-created-successful-screen'>
                <img alt='nexus logo' src={NexusLogo} />
                <div className='activation-instructions-card'>
                    <i className='icon-check' />
                    <label className='title'>
                        {I18n.t('registrarSignUp.accountCreatedSuccessful.title')}
                    </label>
                    <div className='paragraph-container'>
                        <label className='paragraph'>
                            {I18n.t('registrarSignUp.accountCreatedSuccessful.paragraph1', {
                                email,
                            })}
                        </label>
                        <label className='paragraph last'>
                            {I18n.t('registrarSignUp.accountCreatedSuccessful.paragraph2')}
                        </label>
                    </div>
                </div>
                <label className='back-to-top' onClick={() => history.push('/')}>
                    {I18n.t('registrarSignUp.accountCreatedSuccessful.backToTop')}
                </label>
            </div>
        );
    }

    return (
        <div className='registrar-sign-up-screen'>
            <div className='nexus-header'>
                <img alt='nexus logo' src={Header} className='background-header' />
                <img alt='nexus logo' src={NexusLogo} className='header-logo' />
            </div>
            <RegistrarSignUpForm signUp={props.signUp} signUpStatus={props.signUpStatus} />
        </div>
    );
};

export default RegistrarSignUpScreen;
