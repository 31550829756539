import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import Button from '../../../common/components/Button';
import { useHistory } from 'react-router-dom';
import * as bip39 from 'bip39';
import LineSeparatorWithGreenIndicator from '../../../assets/images/line-separator-with-green-indicator.png';
import { UserTypes } from '../../../constants';
import Input from '../../../common/components/Input';
import { decryptString } from '../../../utils/encryption';
import { KeyManager } from 'jasper-roche-crypto';
import { userHasNoOldPrivateKey } from '../../../utils/maps';
// note: as the style is almost exactly as the createPrivateKeyWizard it use the same .scss

// Steps
const STEP_1 = 'STEP_1';
const STEP_2_A = 'STEP_2_A';
const STEP_2_A_PK = 'STEP_2_A_PK';
const STEP_2_B = 'STEP_2_B';

const ImportPrivateKeyWizard = (props) => {
    const [currentStep, setCurrentStep] = useState(STEP_2_A);
    const [wordInputs, setWordInputs] = useState([]);
    const [mnemonicCorrect, setMnemonicCorrect] = useState(false);
    const [privateKey, setPrivateKey] = useState();
    const [mnemonic, setMnemonic] = useState('');
    const [pkInput, setPKInput] = useState();

    const history = useHistory();

    useEffect(() => {
        // load the wordInputs
        let emptyInputs = [];
        for (let i = 0; i < 12; i++) {
            emptyInputs = [...emptyInputs, { index: i, value: '' }];
        }
        setWordInputs(emptyInputs);
    }, []);

    useEffect(() => {
        if (props.validatePrivateKeyStatus.success) {
            setMnemonicCorrect(true);
            nextStep();
        } else if (props.validatePrivateKeyStatus.error) {
            setMnemonicCorrect(false);
            nextStep();
        }
    }, [props.validatePrivateKeyStatus]);

    const handleInputChange = (index, value) => {
        const wordInputsModify = wordInputs.map((input) => {
            if (input.index === index) return { index, value };
            return input;
        });
        setWordInputs(wordInputsModify);
    };

    const getCurrentStep = () => {
        if (currentStep === STEP_1) return 1;
        if (currentStep === STEP_2_A || currentStep === STEP_2_A_PK || currentStep === STEP_2_B)
            return 2;
    };

    const stepFinish = (step) => {
        if (step === 1 && currentStep !== STEP_1) return true;
        if (step === 2 && currentStep === STEP_2_B) return true;
        return false;
    };

    const nextStep = () => {
        if (currentStep === STEP_2_A || currentStep === STEP_2_A_PK) setCurrentStep(STEP_2_B);
    };

    const previousStep = () => {
        if (currentStep === STEP_2_B) setCurrentStep(STEP_2_A);
    };

    const verify = () => {
        // order the words by index
        let wordInputsOrdered = wordInputs.sort((a, b) => {
            if (a.index < b.index) return -1;
            if (a.index > b.index) return 1;
            return 0;
        });
        const mnemonic = wordInputsOrdered.map((input) => input.value).join(' ');

        // check if mnemonic is valid
        bip39.setDefaultWordlist('spanish');
        const validMnemonic = bip39.validateMnemonic(mnemonic);

        if (validMnemonic) {
            const keyManager = new KeyManager(props.username, mnemonic);
            if (props.userType === UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY) {
                setMnemonicCorrect(true);
                setPrivateKey(keyManager.deriveDataKey());
                setMnemonic(keyManager.mnemonic);
                nextStep();
            } else {
                // If Necessary we could move this logic to a new route for migration
                if (userHasNoOldPrivateKey(props.userType)) {
                    props.validateOldPrivateKey(bip39.mnemonicToEntropy(mnemonic));
                    setPrivateKey(bip39.mnemonicToEntropy(mnemonic));
                    setMnemonic(mnemonic);
                } else {
                    props.validatePrivateKey(keyManager.deriveDataKey(), keyManager);
                    setPrivateKey(keyManager.deriveDataKey());
                    setMnemonic(keyManager.mnemonic);
                }
            }
        } else {
            setMnemonicCorrect(false);
            nextStep();
        }
    };

    const disableVerifyButton = () => {
        const emptyInputs = wordInputs.filter((input) => input.value === '');
        return emptyInputs.length !== 0;
    };

    // Due to the user type (without private key) the default redirect will be to the profile page.
    const returnToProfile = () => {
        history.push('/');
    };

    const handleSendPrivateKeyByEmailButtonClick = () => {
        setCurrentStep(STEP_2_A_PK);
        props.sendPrivateKeyByEmail();
    };

    // TODO create keyManager for this case
    const verifyPKInput = (username) => {
        // If Necessary we could move this logic to a new route for migration
        if (userHasNoOldPrivateKey(props.userType)) {
            try {
                const pkEntropy = decryptString(atob(pkInput), props.encKey);
                bip39.setDefaultWordlist('spanish');
                const mnemonic = bip39.entropyToMnemonic(pkEntropy);
                const validMnemonic = bip39.validateMnemonic(mnemonic);
                if (validMnemonic) {
                    setCurrentStep(STEP_2_B);
                    setMnemonicCorrect(true);
                    setPrivateKey(pkEntropy);
                } else {
                    setMnemonicCorrect(false);
                    nextStep();
                }
            } catch (e) {
                setMnemonicCorrect(false);
                nextStep();
            }
        } else {
            try {
                const mnemonic = decryptString(pkInput, props.encKey);
                const validMnemonic = bip39.validateMnemonic(mnemonic);
                if (validMnemonic) {
                    const keyManager = new KeyManager(username, mnemonic);
                    setCurrentStep(STEP_2_B);
                    setMnemonicCorrect(true);
                    setMnemonic(keyManager.mnemonic);
                    setPrivateKey(keyManager.deriveDataKey());
                } else {
                    setMnemonicCorrect(false);
                    nextStep();
                }
            } catch (e) {
                setMnemonicCorrect(false);
                nextStep();
            }
        }
    };

    return (
        <div className='create-private-key-wizard'>
            <div className='steps-header'>
                <div className={`step ${getCurrentStep() === 1 && 'selected'}`}>
                    {stepFinish(1) ? (
                        <i className='icon-check' />
                    ) : (
                        <span className='step-number'>1</span>
                    )}
                    <span className='step-name'>
                        {I18n.t('profile.createPrivateKeyWizard.stepsName.init')}
                    </span>
                </div>
                <hr className='step-separator' />
                <div className={`step ${getCurrentStep() === 2 && 'selected'}`}>
                    {stepFinish(2) ? (
                        <i className='icon-check' />
                    ) : (
                        <span className='step-number'>2</span>
                    )}
                    <span className='step-name'>
                        {I18n.t('profile.createPrivateKeyWizard.stepsName.key')}
                    </span>
                </div>
                <hr className='step-separator' />
                <div className={`step ${getCurrentStep() === 3 && 'selected'}`}>
                    {stepFinish(3) ? (
                        <i className='icon-check' />
                    ) : (
                        <span className='step-number'>3</span>
                    )}
                    <span className='step-name'>
                        {I18n.t('profile.createPrivateKeyWizard.stepsName.end')}
                    </span>
                </div>
            </div>
            <div className='step-card'>
                {currentStep === STEP_2_A && (
                    <div className='second-step-a'>
                        <span className='title'>
                            {I18n.t('profile.importPrivateKeyWizard.secondStepA.title')}
                        </span>
                        <img
                            className='line-separator'
                            alt='line separator'
                            src={LineSeparatorWithGreenIndicator}
                        />
                        <div className='words-container'>
                            {wordInputs.map((input) => (
                                <input
                                    value={input.value}
                                    key={input.index}
                                    onChange={(e) => handleInputChange(input.index, e.target.value)}
                                />
                            ))}
                        </div>
                        <hr />
                        <div className='buttons-row'>
                            <Button
                                className='primary with-gradient'
                                onClick={returnToProfile}
                                text={I18n.t('profile.importPrivateKeyWizard.secondStepA.goBack')}
                            />
                            <Button
                                className='primary with-gradient'
                                onClick={verify}
                                disabled={disableVerifyButton()}
                                text={I18n.t('profile.importPrivateKeyWizard.secondStepA.button')}
                            />
                        </div>
                        {!userHasNoOldPrivateKey(props.userType) && (
                            <div className='buttons-row'>
                                <a
                                    className='primary with-gradient'
                                    onClick={handleSendPrivateKeyByEmailButtonClick}
                                >
                                    Enviar clave privada a {props.pkEmail}
                                </a>
                            </div>
                        )}
                    </div>
                )}
                {currentStep === STEP_2_A_PK && (
                    <div className='second-step-a'>
                        <span className='title'>
                            {I18n.t('profile.importPrivateKeyWizard.secondStepAPK.title')}
                        </span>
                        <img
                            className='line-separator'
                            alt='line separator'
                            src={LineSeparatorWithGreenIndicator}
                        />
                        <Input
                            className='white'
                            value={pkInput}
                            handleChange={setPKInput}
                            icon={<i className='icon-key' />}
                            placeholder={I18n.t(
                                'profile.importPrivateKeyWizard.secondStepAPK.inputPlaceholder',
                            )}
                        />
                        <div className='buttons-row'>
                            <Button
                                className='primary with-gradient'
                                onClick={() => setCurrentStep(STEP_2_A)}
                                text={I18n.t('profile.importPrivateKeyWizard.secondStepAPK.goBack')}
                            />
                            <Button
                                className='primary with-gradient'
                                onClick={() => verifyPKInput(props.username)}
                                disabled={!pkInput}
                                text={I18n.t('profile.importPrivateKeyWizard.secondStepAPK.button')}
                            />
                        </div>
                    </div>
                )}
                {currentStep === STEP_2_B && mnemonicCorrect && (
                    <div className='second-step-c'>
                        <i className='icon-check green' />
                        <span className='result-text'>
                            {I18n.t('profile.importPrivateKeyWizard.secondStepB.successful.title')}
                        </span>
                        <hr />
                        <Button
                            className='primary with-gradient'
                            text={I18n.t(
                                'profile.importPrivateKeyWizard.secondStepB.successful.button',
                            )}
                            onClick={() => {
                                if (userHasNoOldPrivateKey(props.userType)) {
                                    props.saveOldPrivateKey(privateKey, mnemonic);
                                } else {
                                    props.savePrivateKey(privateKey, mnemonic);
                                }
                                props.userType === UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY
                                    ? history.push('/rocheTransactions')
                                    : history.push('/profile');
                            }}
                        />
                    </div>
                )}
                {currentStep === STEP_2_B && !mnemonicCorrect && (
                    <div className='second-step-c'>
                        <i className='icon-exclamation red' />
                        <span className='result-text'>
                            {I18n.t('profile.importPrivateKeyWizard.secondStepB.error.title')}
                        </span>
                        <hr />
                        <Button
                            className='primary with-gradient'
                            onClick={previousStep}
                            text={I18n.t('profile.importPrivateKeyWizard.secondStepB.error.button')}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImportPrivateKeyWizard;
