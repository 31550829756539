import React, { Component } from 'react';
import './Loader.scss';
import { ThreeDots } from 'react-loader-spinner';

class Loader extends Component {
    render() {
        return (
            <div className='loader'>
                <ThreeDots />
            </div>
        );
    }
}

Loader.defaultProps = {};
Loader.propTypes = {};

export default Loader;
