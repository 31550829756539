import actions, {
    GET_CONTRACTS,
    GET_CONTRACT,
    PUT_CONTRACT,
    POST_CONTRACT,
    RECTIFY_CONTRACT,
    GET_AUDITOR_GROUPS,
    GET_FAMILY_PROD,
    GET_CONTRACT_OS,
    GET_CONTRACT_TYPES,
} from './contract.actions';
import services from './contract.services';

const contractMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        switch (action.type) {
            case GET_CONTRACTS:
                services
                    .getContracts()
                    .then((res) => {
                        dispatch(actions.getContractsResponse(res));
                    })
                    .catch((err) => {
                        dispatch(actions.getContractsError(err));
                    });
                break;
            case GET_CONTRACT:
                services
                    .getContract(action.id)
                    .then((res) => {
                        dispatch(actions.getContractResponse(res));
                    })
                    .catch((err) => {
                        dispatch(actions.getContractError(err));
                    });
                break;
            case GET_CONTRACT_OS:
                services
                    .getContractOs(action.id, getState().session.os_id)
                    .then((res) => {
                        dispatch(actions.getContractOsResponse(res));
                        if (action.successCallback) action.successCallback(res[0]);
                    })
                    .catch((err) => {
                        dispatch(actions.getContractOsError(err));
                    });
                break;
            case PUT_CONTRACT:
                services
                    .putContract(action.ind)
                    .then((res) => {
                        dispatch(actions.putContractResponse(res));
                        // dispatch( actions.rectifyContract(action.ind,res) );
                    })
                    .catch((err) => {
                        dispatch(actions.putContractError(err));
                    });
                break;
            case POST_CONTRACT:
                if (action.ind?.contrato_version?.length === 0) {
                    dispatch(actions.postContractError('El contrato debe poseer una versión'));
                    break;
                }
                if (
                    action.ind?.contrato_version?.some((x) => x.contrato_v_beneficio === undefined)
                ) {
                    dispatch(
                        actions.postContractError('El contrato debe poseer al menos un beneficio'),
                    );
                    break;
                }
                services
                    .postContract(action.ind)
                    .then((res) => {
                        dispatch(actions.postContractResponse(res));
                        // dispatch( actions.rectifyContract(action.ind,res) );
                    })
                    .catch((err) => {
                        dispatch(actions.postContractError(err));
                    });
                break;
            case RECTIFY_CONTRACT:
                // FIXME - ON POST/PUT CONTRACT : Evidences w/ id get cleaned ( as back fails )
                // They get rectified on put, They could be rectified per request, TBS
                /*const cont = action.cont
            const res = action.res

            const rectified_contract = {
                ...res,
                contrato_version: res.contrato_version?.map( ver =>({
                    ...ver,
                    contrato_v_evidencia: ver.contrato_v_evidencia
                }))??[]
            }

            services.putContract( rectified_contract )
            .then( res => {
                dispatch( actions.putContractResponse(res) );
            }).catch( err => {
                dispatch( actions.putContractError(err) );
            })*/
                break;
            case GET_AUDITOR_GROUPS:
                services
                    .getAuditorGroups()
                    .then((res) => {
                        dispatch(actions.getAuditorGroupsResponse(res));
                    })
                    .catch((err) => {
                        dispatch(actions.getAuditorGroupsError(err));
                    });
                break;
            case GET_CONTRACT_TYPES:
                services
                    .getContractTypes()
                    .then((res) => {
                        dispatch(actions.getContractTypesResponse(res));
                    })
                    .catch((err) => {
                        dispatch(actions.getContractTypesError(err));
                    });
                break;
            case GET_FAMILY_PROD:
                if (
                    !action?.prod ||
                    (!action.force && !getState().contract.prodFamilys[action.prod])
                )
                    break;

                services
                    .getFamilyProducts(action.prod)
                    .then((res) => {
                        dispatch(actions.getFamilyProductsResponse({ [action.prod]: res }));
                    })
                    .catch((err) => {
                        dispatch(actions.getFamilyProductsError(err));
                    });
                break;
            default:
                break;
        }
    };
export default contractMiddleware;
