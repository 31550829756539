import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Redirect, Route } from 'react-router-dom';
import actions from '../../actions';
import { UserTypes } from '../../constants';
import { openTermsAndConditions } from '../../utils';
import TermsAndConditionsModal from './TermsAndConditionsModal';

const LoginRequiredRoute = ({ component, ...rest }) => {
    if (!rest.loggedIn) {
        rest.saveRedirectRoute(rest.location.pathname);
    }

    if (rest.loggedIn) {
        return (
            <Route
                {...rest}
                render={(props) =>
                    !rest.hasAcceptedTermsAndConditions &&
                    rest.userType !== UserTypes.USER &&
                    rest.userType !== UserTypes.ROCHE_USER &&
                    rest.userType !== UserTypes.DRUGSTORE_WITHOUT_PUBLIC_KEY &&
                    rest.userType !== UserTypes.DRUGSTORE ? (
                        <TermsAndConditionsModal
                            show={true}
                            title={I18n.t('termsAndConditionsModal.title')}
                            titleChk={I18n.t('termsAndConditionsModal.acceptThe')}
                            titleRedirect={I18n.t('termsAndConditionsModal.termsAndConditions')}
                            redirect={openTermsAndConditions}
                            onSubmit={rest.acceptTermsAndConditions}
                            loading={rest.acceptTermsAndConditionsStatus.loading}
                        />
                    ) : (
                        component(props)
                    )
                }
            />
        );
    } else {
        return <Redirect to='/' />;
    }
};

export default connect(
    (state) => ({
        loggedIn: state.session.isLoggedIn,
        hasAcceptedTermsAndConditions: state.profile.user?.hasAcceptedTermsAndConditions,
        userType: state.profile.user?.type,
        acceptTermsAndConditionsStatus: state.common.acceptTermsAndConditionsStatus,
    }),
    (dispatch) => ({
        saveRedirectRoute: (location) => dispatch(actions.session.saveRedirectRoute(location)),
        acceptTermsAndConditions: () => dispatch(actions.common.acceptTermsAndConditions()),
    }),
)(LoginRequiredRoute);
