export const copyToClipboard = async (text) => await navigator.clipboard.writeText(text);
export const pasteFromClipboard = async (handlePaste) => {
    if (navigator.clipboard && navigator.clipboard.readText) {
        navigator.clipboard
            .readText()
            .then((text) => {
                handlePaste(text);
            })
            .catch((err) => {
                console.error('Failed to read clipboard contents: ', err);
            });
    } else {
        navigator.permissions
            .query({ name: 'clipboard-read' })
            .then((result) => {
                if (result.state === 'granted' || result.state === 'prompt') {
                    navigator.clipboard
                        .readText()
                        .then((text) => {
                            handlePaste(text);
                        })
                        .catch((err) => {
                            console.error('Failed to read clipboard contents: ', err);
                        });
                }
            })
            .catch((err) => {
                console.error('Failed to request clipboard read permission: ', err);
            });
    }
};
