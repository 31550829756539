import React, { useState, useEffect } from 'react';
import './RegistrarSignUpForm.scss';
import Input from '../../../common/components/Input';
import { I18n } from 'react-redux-i18n';
import Button from '../../../common/components/Button';
import ErrorBox from '../../../common/components/ErrorBox';
import { validators } from '../../../utils';

const RegistrarSignUpForm = (props) => {
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [user, setUser] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);

    const [errorMessage, setErrorMessage] = useState();
    const [errorField, setErrorField] = useState();

    useEffect(() => {
        if (props.signUpStatus.error) {
            setErrorMessage(props.signUpStatus.errorMessage);
        }
    }, [props.signUpStatus]);

    const validate = () => {
        if (!name) {
            setErrorField('name');
            setErrorMessage(I18n.t('registrarSignUp.signUpForm.validationErrors.nameRequired'));
            return false;
        } else if (!lastName) {
            setErrorField('lastName');
            setErrorMessage(I18n.t('registrarSignUp.signUpForm.validationErrors.lastNameRequired'));
            return false;
        } else if (!user) {
            setErrorField('user');
            setErrorMessage(I18n.t('registrarSignUp.signUpForm.validationErrors.userRequired'));
            return false;
        } else if (!email || !validators.validEmail(email)) {
            setErrorField('email');
            setErrorMessage(
                !email
                    ? I18n.t('registrarSignUp.signUpForm.validationErrors.emailRequired')
                    : I18n.t('registrarSignUp.signUpForm.validationErrors.emailInvalid'),
            );
            return false;
        } else if (!password) {
            setErrorField('password');
            setErrorMessage(I18n.t('registrarSignUp.signUpForm.validationErrors.passwordRequired'));
            return false;
        } else if (!repeatPassword) {
            setErrorField('repeatPassword');
            setErrorMessage(
                I18n.t('registrarSignUp.signUpForm.validationErrors.repeatPasswordRequired'),
            );
            return false;
        } else if (password !== repeatPassword) {
            setErrorField('password');
            setErrorField('repeatPassword');
            setErrorMessage(
                I18n.t('registrarSignUp.signUpForm.validationErrors.passwordsDontMatch'),
            );
            return false;
        }
        setErrorField('');
        setErrorMessage(undefined);
        return true;
    };

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (validate()) props.signUp(name, lastName, user, email, password, repeatPassword);
    };

    return (
        <form className='registrar-sign-up-form' onSubmit={onSubmit} noValidate>
            <div className='fix-height'>
                <ErrorBox show={errorMessage !== undefined} errorMessage={errorMessage || ''} />
            </div>
            <span className='title'>{I18n.t('registrarSignUp.signUpForm.title')}</span>

            <div className='inputs-row'>
                <Input
                    type='text'
                    value={name}
                    icon={<i className='icon-user' />}
                    handleChange={setName}
                    placeholder={I18n.t('registrarSignUp.signUpForm.inputPlaceholders.name')}
                    error={errorField === 'name'}
                    pillStyle
                />
                <Input
                    type='text'
                    value={lastName}
                    icon={<i className='icon-user' />}
                    handleChange={setLastName}
                    placeholder={I18n.t('registrarSignUp.signUpForm.inputPlaceholders.lastName')}
                    error={errorField === 'lastName'}
                    pillStyle
                />
            </div>
            <div className='inputs-row'>
                <Input
                    type='text'
                    value={user}
                    icon={<i className='icon-user' />}
                    handleChange={setUser}
                    placeholder={I18n.t('registrarSignUp.signUpForm.inputPlaceholders.user')}
                    error={errorField === 'user'}
                    pillStyle
                />
                <Input
                    type='email'
                    value={email}
                    icon={<i className='icon-envelope' />}
                    handleChange={setEmail}
                    placeholder={I18n.t('registrarSignUp.signUpForm.inputPlaceholders.email')}
                    error={errorField === 'email'}
                    pillStyle
                />
            </div>
            <div className='inputs-row'>
                <Input
                    password
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    icon={<i className='icon-lock' />}
                    showPassword={() => setShowPassword(!showPassword)}
                    handleChange={setPassword}
                    placeholder={I18n.t('registrarSignUp.signUpForm.inputPlaceholders.password')}
                    error={errorField === 'password'}
                    pillStyle
                />
                <Input
                    password
                    type={showRepeatPassword ? 'text' : 'password'}
                    value={repeatPassword}
                    icon={<i className='icon-lock' />}
                    showPassword={() => setShowRepeatPassword(!showRepeatPassword)}
                    handleChange={setRepeatPassword}
                    placeholder={I18n.t(
                        'registrarSignUp.signUpForm.inputPlaceholders.repeatPassword',
                    )}
                    error={errorField === 'repeatPassword'}
                    pillStyle
                />
            </div>

            <Button
                className='primary with-gradient'
                type='submit'
                loading={props.signUpStatus?.loading}
                text={I18n.t('registrarSignUp.signUpForm.button')}
            />
        </form>
    );
};

export default RegistrarSignUpForm;
