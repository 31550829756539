import { createDiffieHellman } from 'diffie-hellman/browser';
import CryptoJs from 'crypto-js';
import { Cipher } from 'jasper-roche-crypto';

// Default IV for AES encryption
const DEFAULT_IV = CryptoJs.enc.Hex.parse('00000000000000000000000000000000');

// Prime and generator
const PRIME_STRING = '775f2812cef29cd1f9f7654a2ad09d83';
const GENERATOR_STRING = '02';

/*
 * Data and functions for testing - START
 * */

// Alice keys
const ALICE_PUBLIC_KEY_STRING = '52ad64ed5b968744bbc0b23c4fb452fe';
const ALICE_PRIVATE_KEY_STRING = '7a8cd23f0787889aeb63cc34fc506bf6';

// Bob keys
const BOB_PUBLIC_KEY_STRING = '74fc94ffe1b515ca1c4308dc2ec1c9f3';
const BOB_PRIVATE_KEY_STRING = '05e7274862ad58cb28d53965adcddd87';

export const diffieHellmanExample_1 = () => {
    const alice = createDiffieHellman(128);
    alice.generateKeys();

    const bob = createDiffieHellman(alice.getPrime(), alice.getGenerator());
    bob.generateKeys();

    // const aliceShareSecret = alice.computeSecret(bob.getPublicKey());
    // const bobShareSecret = bob.computeSecret(alice.getPublicKey());

    // const areEquals = aliceShareSecret.toString('hex') === bobShareSecret.toString('hex');
};

export const createTestKeys = () => {
    const alice = createDiffieHellman(PRIME_STRING, 'hex', GENERATOR_STRING, 'hex');
    alice.generateKeys();

    const bob = createDiffieHellman(PRIME_STRING, 'hex', GENERATOR_STRING, 'hex');
    bob.generateKeys();
};

export const diffieHellmanExample_2 = () => {
    const alice = createDiffieHellman(PRIME_STRING, 'hex', GENERATOR_STRING, 'hex');
    alice.setPublicKey(ALICE_PUBLIC_KEY_STRING, 'hex');
    alice.setPrivateKey(ALICE_PRIVATE_KEY_STRING, 'hex');

    const bob = createDiffieHellman(PRIME_STRING, 'hex', GENERATOR_STRING, 'hex');
    bob.setPublicKey(BOB_PUBLIC_KEY_STRING, 'hex');
    bob.setPrivateKey(BOB_PRIVATE_KEY_STRING, 'hex');

    // const bobPublic = parsePublicKey(bob.getPublicKey('hex'));

    // const aliceShareSecret = alice.computeSecret(bobPublic);
    // const bobShareSecret = bob.computeSecret(alice.getPublicKey());

    // const areEquals = aliceShareSecret.toString('hex') === bobShareSecret.toString('hex');
};

/*
 * Data and functions for testing - END
 * */

/*
 * Encrypt the given string with the given key. It will produce the same encrypted text for the same inputs.
 * */
export const encryptString = (plainText, key) => {
    // Is important to parse the key to hex
    const key_hex = CryptoJs.enc.Hex.parse(key);
    // The IV is set manually to avoid random salts and different results with the same inputs
    return CryptoJs.AES.encrypt(plainText, key_hex, { iv: DEFAULT_IV }).toString();
};

/*
 * Decrypt the given encrypted text with the given key.
 * */
export const decryptString = (encryptedText, key) => {
    try {
        // Is important to parse the key to hex
        const key_hex = CryptoJs.enc.Hex.parse(key);
        // The IV is set manually to avoid random salts and different results with the same inputs
        // Is important to set the Utf8 on the to string
        return CryptoJs.AES.decrypt(encryptedText, key_hex, { iv: DEFAULT_IV }).toString(
            CryptoJs.enc.Utf8,
        );
    } catch (error) {
        return '';
    }
};

/*
 * This method generates a private and a public key.
 * */
export const generateKeys = () => {
    // With this prime and generator the strength is 128 bits
    // todo check if prime and generator must be hard-code
    const diffieHellman = createDiffieHellman(PRIME_STRING, 'hex', GENERATOR_STRING, 'hex');
    diffieHellman.generateKeys();

    return {
        public: diffieHellman.getPublicKey('hex'),
        private: diffieHellman.getPrivateKey('hex'),
    };
};

export const generateCse = () => {
    const diffieHellman = createDiffieHellman(PRIME_STRING, 'hex', GENERATOR_STRING, 'hex');
    diffieHellman.generateKeys();
    return diffieHellman.getPrivateKey('hex');
};

/*
 * This method parse an string public key to a Uint8Array(16) format (that is the one the library use)
 * */
const parsePublicKey = (publicKeyString) => {
    const diffieHellman = createDiffieHellman(PRIME_STRING, 'hex', GENERATOR_STRING, 'hex');
    diffieHellman.setPublicKey(publicKeyString, 'hex');
    return diffieHellman.getPublicKey();
};

/*
 * This method computes the shared secret key and returns it as string
 * */
export const getSharedSecret = (myPrivateKey, myPublicKey, otherPublicKey) => {
    const diffieHellman = createDiffieHellman(PRIME_STRING, 'hex', GENERATOR_STRING, 'hex');
    diffieHellman.setPrivateKey(myPrivateKey, 'hex');
    diffieHellman.setPublicKey(myPublicKey, 'hex');
    return diffieHellman.computeSecret(parsePublicKey(otherPublicKey)).toString('hex');
};

/*
 * This method computes the public key of a given private key.
 * */
export const getPublicKeyFromPrivate = (privateKey) => {
    // Create a diffie-hellman
    const diffieHellman = createDiffieHellman(PRIME_STRING, 'hex', GENERATOR_STRING, 'hex');
    // set the private key
    diffieHellman.setPrivateKey(privateKey, 'hex');
    // generates the keys (the library will NOT generate another private because this was set on the step above)
    diffieHellman.generateKeys();
    return diffieHellman.getPublicKey('hex');
};

export const decryptEntity = (entity, myPrivateKey, csee, normalizer) => {
    const cipher = new Cipher();
    try {
        const cse = cipher.decrypt(myPrivateKey, csee);
        return normalizer(entity, cse);
    } catch (e) {
        return normalizer(entity);
    }
};
