import { connect } from 'react-redux';
import AuditIncident from '../components/AuditIncident';
import actions from '../../actions';
import service from '../../patient/patient.services';

const mapStateToProps = (state) => ({
    patientDetail: state.transaction.auditorPatientDetail,
    auditGroupData: state.profile.user.audit_group,
    transactionStatus: state.transaction.postAuditorTransactionStatus,
    userType: state.profile.user.type,
    privateKey: state.profile.user.privateKey,
    publicKey: state.profile.user.audit_group.pubkey,
});

const mapDispatchToProps = (dispatch) => ({
    setContractSelected: (contract) => dispatch(actions.contract.setContractSelected(contract)),
    getProgramedDose: (contract) => dispatch(actions.patient.getProgramedDose(contract)),
    setBreadcrumbs: (bcrms) => dispatch(actions.common.setBreadcrumbs(bcrms)),
    postAuditorTransactions: (body, id) =>
        dispatch(actions.transaction.postAuditorTransactions(body, id)),
    getFileToS3: (url) => service.getFileToS3(url),
    getAuditorProgramedDose: (contract) =>
        dispatch(actions.patient.getAuditorProgramedDose(contract)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditIncident);
