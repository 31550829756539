import React, { useEffect, useState } from 'react';
import './RegistrationScreen.scss';
import Input from '../../../common/components/Input';
import { I18n } from 'react-redux-i18n';
import Button from '../../../common/components/Button';
import ProductCard from '../ProductCard/ProductCard';
import { ConfirmationModal } from '../../../common/components/Modal';
import ErrorBox from '../../../common/components/ErrorBox';
import { parseVnDate } from '../../../utils/parser';
import Checkbox from '../../../common/components/Checkbox/Checkbox';
import { UserTypes } from '../../../constants';
import ProgramedDoses from '../../containers/ProgramedDoses';
import Loader from '../../../common/components/Loader';
import { todayStr } from '../../../utils/date';

export const hc_doses = [
    { ciclo: 1, dia: 1, fecha_estimada: '2020-01-01', total_mg: 111, fecha_registro: '2020-01-01' },
    { ciclo: 2, dia: 7, fecha_estimada: '2020-01-01', total_mg: 840, fecha_registro: '2020-01-01' },
    {
        ciclo: 3,
        dia: 14,
        fecha_estimada: '2020-01-01',
        total_mg: 420,
        fecha_registro: '2020-01-01',
    },
    {
        ciclo: 4,
        dia: 21,
        fecha_estimada: '2020-01-01',
        total_mg: 390,
        fecha_registro: '2020-01-01',
    },
    {
        ciclo: 5,
        dia: 42,
        fecha_estimada: '2020-01-01',
        total_mg: 144,
        fecha_registro: '2020-01-01',
    },
    {
        ciclo: 6,
        dia: 84,
        fecha_estimada: '2020-01-01',
        total_mg: 234,
        fecha_registro: '2020-01-01',
    },
    {
        ciclo: 7,
        dia: 101,
        fecha_estimada: '2020-01-01',
        total_mg: 444,
        fecha_registro: '2020-01-01',
    },
    {
        ciclo: 8,
        dia: 101,
        fecha_estimada: '2020-01-01',
        total_mg: 444,
        fecha_registro: '2020-01-01',
    },
    {
        ciclo: 9,
        dia: 101,
        fecha_estimada: '2020-01-01',
        total_mg: 444,
        fecha_registro: '2020-01-01',
    },
    {
        ciclo: 10,
        dia: 101,
        fecha_estimada: '2020-01-01',
        total_mg: 444,
        fecha_registro: '2020-01-01',
    },
    {
        ciclo: 11,
        dia: 101,
        fecha_estimada: '2020-01-01',
        total_mg: 444,
        fecha_registro: '2020-01-01',
    },
];

const RegistrationScreen = (props) => {
    const [dni, setDni] = useState('');
    const [products, setProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [dosesAux, setDosesAux] = useState([]);

    const [firstProductLoaded, setFirstProductLoaded] = useState(false);

    const [showScanCard, setShowScanCard] = useState(false);

    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [confirmSaveOpen, setConfirmSaveOpen] = useState(false);
    const [indexToDelete, setIndexToDelete] = useState();

    const [isUsingKeyboard, setIsUsingKeyboard] = useState(false);
    const [forceFocusOnProductGtin, setForceFocusOnProductGtin] = useState(false);

    const [validationError, setValidationError] = useState(undefined);

    const [assignCycle, setAssignCycle] = useState(props.userType !== UserTypes.REGISTER_USER);
    const [cycle, setCycle] = useState(1);
    const [isSurContract, setIsSurContract] = useState(false);

    const [selectedPatient, setPatient] = useState();

    useEffect(() => {
        props.clearProgrammedDoses();
        setDosesAux([]);
        props.getPatients();
    }, []);

    useEffect(() => {
        const _n = (x) => parseInt(x).toString();
        if (dni && props.patients) {
            const dniWithoutPoints = dni.replace(/[^0-9]/g, '');
            if (dniWithoutPoints.length === 8) {
                setPatient(
                    props.patients.find((patient) => {
                        return _n(patient.dni) === _n(dniWithoutPoints);
                    }),
                );
            } else if (selectedPatient) setPatient(undefined);
        }
    }, [dni, props.patients]);

    useEffect(() => {
        if (props.validationStatus.error) {
            setValidationError(props.validationStatus.errorMessage);
        }
    }, [props.validationStatus]);

    useEffect(() => {
        setForceFocusOnProductGtin(true);
    }, [isUsingKeyboard]);

    useEffect(() => {
        setAllProducts(props.allProducts);
    }, [props.allProducts]);

    useEffect(() => {
        setDosesAux(JSON.parse(JSON.stringify(props.programedDoses)));
    }, [props.programedDoses]);

    const submitAProduct = (product) => {
        /*
         * Is to avoid submit the product when the date is invalid, but try to submit if the error is other
         * like (trace already register error)
         * */
        if (!firstProductLoaded) {
            setFirstProductLoaded(true);
            setAllProducts(allProducts.filter((x) => x.contractId === product.product.contractId));
            setIsSurContract(product.product.tipo_contrato === 'S');
        }

        if (validationError !== I18n.t('registration.errors.invalidDate') && selectedPatient) {
            const actionProduct = {
                ...product,
                trace: `${product.gtin}${product.sn}*${product.ven}${product.lot}`,
            };
            props.validateProduct(selectedPatient?.osId, selectedPatient?.id, actionProduct, () => {
                setProducts([...products, product]);
                if (!firstProductLoaded && product.product.tipo_contrato !== 'S') {
                    props.getProgramedDoses(product.product.contractId);
                }
            });
        }
    };

    const deleteProduct = () => {
        setConfirmDeleteOpen(false);
        setProducts(products.filter((_, index) => index !== indexToDelete));
        setIndexToDelete(undefined);
        setForceFocusOnProductGtin(true);
    };

    const editProduct = (product, index) => {
        setProducts(
            products.map((p, i) =>
                i === index ? { ...p, ...product, edited: true } : { ...p, edited: false },
            ),
        );
        if (!isSurContract) {
            setDosesAux({
                dosis: dosesAux.dosis.map((dose) => {
                    if (dose._sn?.includes(product.sn)) {
                        // elimina el producto del ciclo y recalcula el monto de dosis si es necesario
                        if (dose._sn?.length <= 1) {
                            const doseAux = props.programedDoses.dosis.find(
                                (x) => x.ciclo === dose.ciclo,
                            );

                            return { ...doseAux };
                        } else {
                            const dosisReal =
                                dose.dosis_real - parseFloat(product.product.cantidad);
                            return {
                                ...dose,
                                dosis_real: dosisReal,
                                _sn: dose._sn.filter((x) => x !== product.sn),
                            };
                        }
                    }
                    // agrega el producto al ciclo y calcula el monto de dosis
                    if (product.cycle === dose.ciclo) {
                        const dosisReal = dose.dosis_real + parseFloat(product.product.cantidad);
                        return {
                            ...dose,
                            dosis_real: dosisReal,
                            fecha_registracion: todayStr,
                            _sn: dose._sn?.length > 0 ? dose._sn.concat(product.sn) : [product.sn],
                        };
                    } else {
                        return {
                            ...dose,
                        };
                    }
                }),
            });
        }
    };

    React.useEffect(() => {}, [assignCycle, allProducts, props.productsByOs]);

    const resetForceFocusOnProductGtin = () => setForceFocusOnProductGtin(false);

    const showConfirmDeleteModal = (productIndex) => {
        setConfirmDeleteOpen(true);
        setIndexToDelete(productIndex);
    };

    const showConfirmSaveModal = () => {
        setConfirmSaveOpen(true);
    };

    const validateVn = (vn) => {
        if (!vn) setValidationError(undefined);
        else {
            try {
                const vnDate = parseVnDate(vn, !isUsingKeyboard);
                const today = new Date();
                if (isNaN(vnDate.getTime()) || vnDate.getTime() < today.getTime()) {
                    setValidationError(I18n.t('registration.errors.invalidDate'));
                    return false;
                } else {
                    setValidationError(undefined);
                    return true;
                }
            } catch (e) {
                setValidationError(I18n.t('registration.errors.invalidDate'));
                return false;
            }
        }
    };

    const clearData = () => {
        setDni('');
        setProducts([]);
        setIsUsingKeyboard(false);
        setShowScanCard(false);
    };

    const clearValidationError = () => setValidationError(undefined);

    const confirmSave = () => {
        if (selectedPatient) {
            const traces = [
                ...products.map((p) => ({ ...p, trace: `${p.gtin}${p.sn}*${p.ven}${p.lot}` })),
            ];
            props.registerProducts(selectedPatient?.osId, selectedPatient?.id, traces, () =>
                setConfirmSaveOpen(false),
            );
            clearData();
            setFirstProductLoaded(false);
        }
    };

    const getProductDescription = (currentProductGtin) => {
        return props.productsByOs.find((obj) => {
            if (obj?.codigo_ean === currentProductGtin) return obj?.denominacion;
        })?.denominacion;
    };

    return (
        <div className='registration-screen'>
            <div className='registration-header'>
                <span className='title'>{I18n.t('registration.title')}</span>
                {props.userType === UserTypes.REGISTER_USER && (
                    <Button
                        type='button'
                        onClick={props.downloadTransactions}
                        text={I18n.t('registration.downloadHistory')}
                        className='primary'
                        icon={<i className='icon-cloud-download' />}
                        loading={props.downloadTransactionsStatus.loading}
                    />
                )}
            </div>
            <div className='validation-card'>
                <div className='field-container'>
                    <div className='column'>
                        <Input
                            label={I18n.t('registration.inputLabels.dni')}
                            value={dni}
                            handleChange={setDni}
                            mask='99.999.999'
                            disabled={showScanCard}
                        />
                    </div>
                    <div className='column'>
                        <Input
                            label={I18n.t('registration.inputLabels.name')}
                            value={selectedPatient?.name}
                            disabled={true}
                            placeholder={I18n.t('registration.inputLabels.placeholder.name')}
                        />
                    </div>
                    <div className='column'>
                        <Input
                            label={I18n.t('registration.inputLabels.os')}
                            value={selectedPatient?.os}
                            disabled={true}
                            placeholder={I18n.t('registration.inputLabels.placeholder.os')}
                        />
                    </div>
                </div>
                <Button
                    className='primary small'
                    text={I18n.t('registration.continue')}
                    onClick={() => {
                        props.clearProgrammedDoses();
                        setDosesAux([]);
                        props.getAllProductsById(selectedPatient?.id);
                        setShowScanCard(true);
                    }}
                    disabled={!selectedPatient}
                />
            </div>
            {showScanCard && props.getAllProductsStatus.loading ? (
                <Loader />
            ) : (
                showScanCard && (
                    <div className='scan-wrapper'>
                        <div className='scan-header'>
                            {props.userType === UserTypes.REGISTER_USER ? (
                                <div className='selected' onClick={() => setAssignCycle(false)}>
                                    {I18n.t('registration.header.reg')}
                                </div>
                            ) : (
                                <div className='selected' onClick={() => setAssignCycle(true)}>
                                    {I18n.t('registration.header.reg_as')}
                                </div>
                            )}
                        </div>
                        <div className='scan-card'>
                            {assignCycle && (
                                <>
                                    <ProgramedDoses
                                        doses={dosesAux?.dosis}
                                        products={products}
                                        id={props.productsForPatient[0]?.id}
                                        cycle={cycle}
                                        setCycle={setCycle}
                                        emptyPage={true}
                                    />
                                    <br />
                                </>
                            )}

                            <span className='title'>{I18n.t('registration.scanTheProductQr')}</span>
                            <hr />
                            <div className='fix-height'>
                                <ErrorBox
                                    show={validationError !== undefined}
                                    errorMessage={validationError ? validationError : ''}
                                />
                            </div>
                            <div className='checkboxes-row'>
                                <Checkbox
                                    id='isHandgun'
                                    checked={!isUsingKeyboard}
                                    onChange={() => setIsUsingKeyboard(false)}
                                    label={I18n.t('registration.scanner')}
                                />
                                <Checkbox
                                    id='isKeyboard'
                                    checked={isUsingKeyboard}
                                    onChange={() => setIsUsingKeyboard(true)}
                                    label={I18n.t('registration.keyboard')}
                                />
                            </div>
                            <div className='product-card-container'>
                                {/* this is the first product card and should always be render */}
                                <ProductCard
                                    submit={submitAProduct}
                                    isUsingKeyboard={isUsingKeyboard}
                                    delete={() => {}}
                                    id={-1}
                                    validateVn={validateVn}
                                    clearValidationError={clearValidationError}
                                    forceGtinFocus={forceFocusOnProductGtin}
                                    resetForceFocus={resetForceFocusOnProductGtin}
                                    productsByOs={assignCycle ? allProducts : props.productsByOs}
                                />
                                {products.map((product, index) => (
                                    <ProductCard
                                        key={index}
                                        submit={() => {}}
                                        delete={showConfirmDeleteModal}
                                        id={index}
                                        product={product}
                                        isUsingKeyboard={false}
                                        editProduct={(p) => editProduct(p, index)}
                                        cycle={assignCycle ? cycle : 0}
                                        productDescription={getProductDescription(
                                            product.gtin.substring(2, product.gtin.length),
                                        )}
                                        isSurContract={isSurContract}
                                    />
                                ))}
                            </div>
                            <Button
                                type='button'
                                onClick={showConfirmSaveModal}
                                text={I18n.t('common.save')}
                                className='primary'
                                disabled={products.length === 0}
                                loading={false}
                            />
                        </div>
                    </div>
                )
            )}
            {/* Modals */}
            <ConfirmationModal
                show={confirmDeleteOpen}
                icon={<i className='icon-exclamation red' />}
                title={I18n.t('registration.deleteModalTitle')}
                noBody={true}
                cancel={() => setConfirmDeleteOpen(false)}
                submit={deleteProduct}
            />

            <ConfirmationModal
                show={confirmSaveOpen}
                icon={<i className='icon-check green' />}
                title={I18n.t('registration.confirmSaveModalTitle')}
                cancel={() => setConfirmSaveOpen(false)}
                submit={confirmSave}
                loading={props.registerProductsStatus.loading}
            >
                <ErrorBox
                    show={props.registerProductsStatus.error}
                    errorMessage={props.registerProductsStatus.errorMessage}
                />
            </ConfirmationModal>
        </div>
    );
};

export default RegistrationScreen;
