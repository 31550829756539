import actions from '../../src/actions';
import { services } from './common.services';
import contractServices from '../contract/contract.services';
import { GET_ALL_OS, GET_OS_CONTRACTS, GET_OS_CONTRACT } from './common.actions';
const commonMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        switch (action.type) {
            case GET_OS_CONTRACTS:
                services
                    .getOSContracts(getState().session.os_id)
                    .then((response) => {
                        // note: this route is paginated, but for our use, pagination is not needed.
                        dispatch(actions.common.getOSContractsResponse(response));
                        /*
                    // Get all contracts by ID, this gives extra info
                    if( action.extra ){
                        let promises = []
                        for( const c of response.results ){
                            promises.push(
                                contractServices.getContract( c.id )
                            )
                        }
                        Promise.all( promises ).then( res => {
                            const newContracts = response.results.map( (c) => (
                                {...c, ...res?.find( r => r.id === c.id )??{}}
                            ))
                            dispatch(actions.common.getOSContractsResponse(newContracts))
                        })
                    }
                    */
                    })
                    .catch((error) => {
                        dispatch(actions.common.getOSContractsError(error));
                    });
                break;
            case GET_OS_CONTRACT:
                contractServices
                    .getContract(action.id)
                    .then((response) => {
                        dispatch(actions.common.getOSContractResponse(response));
                    })
                    .catch((error) => {
                        dispatch(actions.common.getOSContractError(error));
                    });
                break;
            case GET_ALL_OS:
                services
                    .getOsList()
                    .then((response) => {
                        dispatch(actions.common.getOsListResponse(response));
                    })
                    .catch((error) => {
                        dispatch(actions.common.getOsListError(error));
                    });
                break;
            default:
        }
    };

export default commonMiddleware;
