import {
    CLEAR_SELECTED_FMI_PATIENT,
    GET_AUDITOR_OPTIONS,
    GET_DRUGSTORE_OPTIONS,
    GET_ACCESS,
    GET_ACCESS_ERROR,
    GET_ACCESS_RESPONSE,
    GET_FMI_OPTIONS,
    GET_PATIENT,
    GET_PATIENT_ERROR,
    GET_PATIENT_RESPONSE,
    GET_MEDIC_OPTIONS,
    GET_OPERATOR_OPTIONS,
    GET_OS_OPTIONS,
    GET_PATIENT_OPTIONS,
    GET_ROLE_OPTIONS_ERROR,
    GET_ROLE_OPTIONS_RESPONSE,
    RESET_UPDATE_STATUS,
    DELETE_PATIENT_ACCESS,
    ADD_PATIENT_ACCESS,
    ADD_PATIENT_ACCESS_RESPONSE,
    ADD_PATIENT_ACCESS_ERROR,
    DELETE_PATIENT_ACCESS_RESPONSE,
    DELETE_PATIENT_ACCESS_ERROR,
} from './roleAccess.actions';

const initialState = {
    selectedRoleOptions: [],
    filteredPatients: [],
    access: [],
    selectedId: null,
    updatePatientStatus: { error: false, loading: false, success: false, errors: {} },
    getAccessStatus: { error: false, loading: false, success: false, errorMessage: '' },
    getOptionsStatus: { error: false, loading: false, success: false, isOperator: false },
    addPatientAccessStatus: { error: false, loading: false, success: false },
    deletePatientAccessStatus: { error: false, loading: false, success: false },
};

const fmiReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PATIENT:
            return {
                ...state,
                getSelectedPatientStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
                updatePatientStatus: { error: false, loading: false, success: false, errors: {} },
            };
        case GET_PATIENT_RESPONSE:
            return {
                ...state,
                getSelectedPatientStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
                updatePatientStatus: { error: false, loading: false, success: false, errors: {} },
                selectedPatient: action.response,
                samplesVersion: action.response.estudios[0]?.muestras,
            };

        case GET_PATIENT_ERROR:
            return {
                ...state,
                getSelectedPatientStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };

        case RESET_UPDATE_STATUS: {
            return {
                ...state,
                updatePatientStatus: { error: false, loading: false, success: false, errors: {} },
                // access: [],
            };
        }
        case GET_ACCESS: {
            return {
                ...state,
                getAccessStatus: { error: false, loading: true, success: false, errorMessage: '' },
            };
        }
        case GET_ACCESS_RESPONSE: {
            return {
                ...state,
                getAccessStatus: { error: false, loading: false, success: true, errorMessage: '' },
                access: action.response,
            };
        }
        case GET_ACCESS_ERROR: {
            return {
                ...state,
                getAccessStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
                access: [],
            };
        }
        case GET_FMI_OPTIONS:
        case GET_OS_OPTIONS:
        case GET_MEDIC_OPTIONS:
        case GET_PATIENT_OPTIONS:
        case GET_AUDITOR_OPTIONS:
        case GET_DRUGSTORE_OPTIONS:
            return {
                ...state,
                getOptionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    isOperator: false,
                },
            };
        case GET_OPERATOR_OPTIONS:
            return {
                ...state,
                getOptionsStatus: { error: false, loading: true, success: false, isOperator: true },
            };
        case GET_ROLE_OPTIONS_RESPONSE: {
            return {
                ...state,
                getOptionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    isOperator: state.getOptionsStatus.isOperator,
                },
                selectedRoleOptions: action.response.filter((r) => !!r.pb),
            };
        }
        case GET_ROLE_OPTIONS_ERROR: {
            return {
                ...state,
                getOptionsStatus: { error: true, loading: false, success: false },
                selectedRoleOptions: [],
            };
        }
        case CLEAR_SELECTED_FMI_PATIENT:
            return {
                ...state,
                selectedPatient: {
                    accesos: [],
                    activo: true,
                    contactado: false,
                    contratos: [],
                    contactos: [],
                    estudios: [],
                    fmi: true,
                    historial_peso: [],
                },
                samplesVersion: {},
                getSelectedPatientStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case ADD_PATIENT_ACCESS:
            return {
                ...state,
                addPatientAccessStatus: { error: false, loading: true, success: false },
            };
        case ADD_PATIENT_ACCESS_RESPONSE:
            return {
                ...state,
                addPatientAccessStatus: { error: false, loading: false, success: true },
            };
        case ADD_PATIENT_ACCESS_ERROR:
            return {
                ...state,
                addPatientAccessStatus: { error: true, loading: false, success: false },
            };
        case DELETE_PATIENT_ACCESS:
            return {
                ...state,
                deletePatientAccessStatus: { error: false, loading: true, success: false },
            };
        case DELETE_PATIENT_ACCESS_RESPONSE:
            return {
                ...state,
                deletePatientAccessStatus: { error: false, loading: false, success: true },
            };
        case DELETE_PATIENT_ACCESS_ERROR:
            return {
                ...state,
                deletePatientAccessStatus: { error: true, loading: false, success: false },
            };
        default:
            return {
                ...state,
                updatePatientStatus: { error: false, loading: false, success: false, errors: {} },
                // getAccessStatus: { error: false, loading: false, success: false, errorMessage: "" },
                // access: [],
            };
    }
};

export default fmiReducer;
