import React, { useState, useEffect } from 'react';
import './SharedRisk.scss';
// todo cambiar scss y agregar el de este componente
import SearchBar from '../../../common/components/SearchBar';
import { I18n } from 'react-redux-i18n';
import Loader from '../../../common/components/Loader';
import ReactTable from 'react-table';
import TablePagination from '../../../common/components/TablePagination';
import ReactTooltip from 'react-tooltip';
import SelectMultiple from '../../../common/components/SelectMultiple';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { DisplayComment } from './Modals';
import { states, defaultStatusSelected } from './sharedRiskconstants';
import { ConfirmationModalWithText } from '../../../common/components/Modal/Modal';

const SharedRiskScreen = (props) => {
    const [textToSearch, setTextToSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState(defaultStatusSelected);
    const [transactions, setTransactions] = useState([]);
    const [errorMsg, setErrorMsg] = useState(null);

    const [show, setShow] = useState(false);
    const [original, setOriginal] = useState('');
    const [comment, setComment] = useState(original.comentario_roche);

    useEffect(() => {
        props.getSharedRiskTransactions();
    }, []);

    useEffect(() => {
        setErrorMsg(props.updateSharedRiskTransactionStatus?.errorMessage);
    }, [props.updateSharedRiskTransactionStatus?.errorMessage]);

    useEffect(() => {
        const filters = statusFilter.filter((x) => x.checked);
        if (filters.length > 0) {
            setTransactions(
                props.sharedRiskTransactions?.filter((x) => filters.some((y) => y.id === x.estado)),
            );
        }
        // eslint-disable-next-line
    }, [props.sharedRiskTransactions, statusFilter]);

    const renderTableFooter = (paginationProps) => (
        <div className='transaction-table-footer'>
            <TablePagination
                {...paginationProps}
                total={transactions?.length}
                totalPages={transactions?.length / 10}
                pagedMessageTranslation='transaction.table.pagedMessage'
                pagedMessageEmptyTranslation='transaction.table.pagedMessageEmpty'
            />
        </div>
    );

    const handleStatusFilterChange = (statusId) => {
        setStatusFilter(
            statusFilter.map((status) => {
                if (status.id === statusId) {
                    return { ...status, checked: !status.checked };
                }
                return status;
            }),
        );
    };

    const infoModal = () => {
        return (
            <>
                <h5>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        style={{
                            color: '#008e99',
                            fontSize: '2.25rem',
                            margin: '0 1.75rem',
                        }}
                    />
                    {I18n.t('transaction.table.status.definition')}
                </h5>
                <hr />
                <ul className='list approved'>
                    <li>
                        <h5>{I18n.t('transaction.table.status.Pending')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.Approved')}</h5>
                    </li>
                    <li>
                        <h5>{I18n.t('transaction.table.status.Rejected')}</h5>
                    </li>
                </ul>
            </>
        );
    };

    const formatHeader = (text, infoIcon = false, modal) => {
        return (
            <div className='nexus-header-arrows space-between'>
                <h5>
                    {text}&nbsp;
                    {infoIcon && <FontAwesomeIcon data-tip data-for={text} icon={faInfoCircle} />}
                </h5>
                <ReactTooltip
                    id={text}
                    place='bottom'
                    type='light'
                    effect='solid'
                    className='info-modal'
                >
                    {modal}
                </ReactTooltip>
                <div className='arrows'>
                    <span>▲</span>
                    <span>▼</span>
                </div>
            </div>
        );
    };

    const getTableColumns = () => {
        // here could be some logic to show certain columns depending on user role.
        return [
            {
                id: 'numero', // Required because our accessor is not a string
                Header: () => formatHeader(I18n.t('transaction.table.headers.number')),
                accessor: (d) => d.id, // Custom value accessors!
                Cell: (props) => (
                    <div className='numbers'>
                        {props.row.estado === 'P' && (
                            <span className='red-dot-number'>{'\u2022'}</span>
                        )}
                        <span className='text transactions-table-row'>{props.value}</span>
                    </div>
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 150,
            },
            {
                id: 'paciente', // Required because our accessor is not a string
                Header: () => formatHeader(I18n.t('transaction.table.headers.patientId')),
                accessor: (d) => d.paciente_id, // Custom value accessors!
                Cell: (props) => (
                    <div className='numbers'>
                        {props.row.estado === 'P' && (
                            <span className='red-dot-number'>{'\u2022'}</span>
                        )}
                        <span className='text transactions-table-row'>{props.value}</span>
                    </div>
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 200,
            },
            {
                id: 'amount', // Required because our accessor is not a string
                Header: () => formatHeader(I18n.t('transaction.table.headers.amount')),
                accessor: (d) => d.importe_calculado, // Custom value accessors!
                Cell: (props) => <span className='text transactions-table-row'>{props.value}</span>,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                resizable: true,
            },
            {
                id: 'comment', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transaction.table.headers.auditorComment')}</h5>,
                accessor: (d) => d.comentario_auditor, // Custom value accessors!
                Cell: (props) => <DisplayComment comment={props.value} />,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                resizable: true,
            },
            {
                id: 'status', // Required because our accessor is not a string
                Header: () =>
                    formatHeader(I18n.t('transaction.table.headers.status'), true, infoModal()),
                accessor: (d) => d.estado, // Custom value accessors!
                //Cell: props => <span className='text transactions-table-row'>{props.value}</span>,
                Cell: (props) => (
                    <span
                        className={`transactions-table-row ${
                            states.find((state) => state.value === props.value)?.label
                        }`}
                    >
                        {I18n.t(
                            `transaction.table.status.${
                                states.find((state) => state.value === props.value)?.label
                            }`,
                        )}
                    </span>
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                resizable: true,
            },
            {
                id: 'revisar', // Required because our accessor is not a string
                Header: () => <h5>{I18n.t('transaction.table.headers.review')}</h5>,
                Cell: (props) => (
                    <button
                        className='detail-button'
                        onClick={() => {
                            return setOriginal(props.row._original), setShow(true);
                        }}
                        disabled={props.row._original.estado !== 'P'}
                    >
                        <span className='text transactions-table-row details'>
                            {I18n.t('transaction.table.headers.review')}
                        </span>
                    </button>
                ),
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                resizable: true,
            },
        ];
    };

    return (
        <div className='patients-screen'>
            {/*<div className={"os-transactions-screen"}>*/}
            <div className='header-container'>
                <div className='header'>
                    <span className='title'>{I18n.t('transaction.sharedRiskTitle')}</span>
                    <SearchBar
                        textToSearch={textToSearch}
                        onSearch={(text) => {
                            setTextToSearch(text);
                            props.searchTransactions(text);
                        }}
                    />
                    <SelectMultiple
                        values={statusFilter}
                        onChange={handleStatusFilterChange}
                        className='rounded'
                        placeholder={I18n.t('transaction.table.headers.status')}
                        options={[
                            { value: 'P', label: I18n.t('transaction.table.status.pending') },
                            { value: 'A', label: I18n.t('transaction.table.status.approved') },
                            { value: 'R', label: I18n.t('transaction.table.status.rejected') },
                        ]}
                    />
                </div>
                <div>
                    {errorMsg && (
                        <>
                            <div className='error-box show'>
                                <i className='icon-exclamation' />
                                <div className='error-msj'>{errorMsg}</div>
                            </div>
                            <br />
                        </>
                    )}
                </div>
            </div>

            <div className='table-container'>
                {/* <div className={"transaction-table-container"}> */}
                {props.loading ? (
                    <>
                        <div className='header' />
                        <div className='transaction-table-container'>
                            <Loader />
                        </div>
                    </>
                ) : (
                    <ReactTable
                        //className={(props.transactions.length > 0 ? "nexus-table"  : "nexus-table empty")}
                        className='nexus-table'
                        PaginationComponent={(paginationProps) =>
                            renderTableFooter(paginationProps)
                        }
                        minRows={0}
                        pageSize={10}
                        data={transactions}
                        noDataText={I18n.t('transaction.table.noRows')}
                        onSortedChange={() => {
                            // props.updateFilterValues({ordering: (newSorted[0].desc ? "-" : "") + column.id});
                            // props.getTransactions();
                        }}
                        // todo check if this prop is necessary
                        getTdProps={() => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    if (handleOriginal) {
                                        handleOriginal();
                                    }
                                },
                            };
                        }}
                        columns={getTableColumns()}
                        defaultSorted={[{ id: 'dates', desc: true }]}
                    />
                )}
            </div>
            {original.estado === 'P' && (
                <ConfirmationModalWithText
                    show={show}
                    close={() => {
                        setShow(false);
                    }}
                    comment={comment}
                    setComment={(ev) => {
                        setComment(ev);
                    }}
                    cancelText={I18n.t('auditor.incident.deny')}
                    cancel={() => {
                        props.updateSharedRiskTransactions(
                            {
                                estado: 'R',
                                comentario_roche: comment,
                            },
                            original.id,
                        );
                        setShow(false);
                    }}
                    submit={() => {
                        props.updateSharedRiskTransactions(
                            {
                                estado: 'A',
                                comentario_roche: comment,
                            },
                            original.id,
                        );
                        setShow(false);
                    }}
                    noBody={true}
                ></ConfirmationModalWithText>
            )}
        </div>
    );
};

export default SharedRiskScreen;
