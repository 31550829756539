import { connect } from 'react-redux';
import PatientsScreen from '../components/PatientsScreen/';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    patients: state.patient.filteredResults,
    total: state.patient.filteredResults.length,
    totalPages: Math.ceil(state.patient.filteredResults.length / 10),
    currentPage: state.patient.current,

    loading: state.patient.getPatientsStatus.loading,
    editPatientStatus: state.patient.editPatientStatus,

    userType: state.profile.user.type,
});

const mapDispatchToProps = (dispatch) => ({
    getPatients: () => dispatch(actions.patient.getPatients()),

    updateFilterValues: (filter) => dispatch(actions.patient.updateFilterValues(filter)),
    clearFilters: () => dispatch(actions.patient.clearFilters()),

    updatePage: (pageNumber) => dispatch(actions.patient.updatePage(pageNumber)),
    updatePageSize: (pageSize) => dispatch(actions.patient.updatePageSize(pageSize)),

    disablePatient: (patient) =>
        dispatch(actions.patient.editPatient(patient.id, { ...patient, status: 'inactive' })),
    enablePatient: (patient) =>
        dispatch(actions.patient.editPatient(patient.id, { ...patient, status: 'active' })),
    selectPatient: () => {},

    // this action set patient details in reducer
    loadPatientData: (patient) => dispatch(actions.patient.setPatientDetails(patient)),
    searchPatients: (search) => dispatch(actions.patient.searchPatients(search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientsScreen);
