import './SendNex.scss';
import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import Button from '../../../common/components/Button';
import IconAdd from '../../../common/components/Icon/Add';
import Input from '../../../common/components/Input';
import ErrorBox from '../../../common/components/ErrorBox';
import SuccessBox from '../../../common/components/SuccesBox/SuccessBox';
import { close, shareIcon } from './constants.js';
import { useEffect } from 'react';
import WalletScreenWrapper from '../common/WalletScreen/WalletScreenWrapper';
import ModalAddresseeContainer from '../../containers/ModalAddresseeContainer';
import { useHistory } from 'react-router-dom';
import LinkAccess from '../../../common/components/LinkAccess/LinkAccess';
import { linkAction } from './constants';
import { walletExplorer } from '../../../utils/http';
import CurrencyInputBox from './CurrencyInputBox/CurrencyInputBox';

const SendNexScreen = ({
    directionSelection,
    setContact,
    addressValidationStatus,
    saveSendNex,
    transactionValidationStatus,
    transactionHash,
    getWalletDetails,
    balanceNex,
    username,
}) => {
    const history = useHistory();
    //Address
    const [address, setAddress] = useState(directionSelection);

    //Recipient
    const [showRecipient, setShowRecipient] = useState(false);
    const [recipientName, setRecipientName] = useState();

    //Transfer of info
    const [showTransfer, setShowTransfer] = useState(false);
    const [infoTransfer, setInfoTransfer] = useState('');
    const [detailsLink, setDetailsLink] = useState('');
    //Modal information addressee
    const [showAddressee, setShowAddressee] = useState(false);
    const [AddressValidation] = useState(false);
    const [chkSchedule, setChkSchedule] = useState(true); //modo de ejemplo

    //Show Btn
    const [, setShowBtn] = useState(true); //modo de ejemplo
    const [showAddContact, setShowAddContact] = useState(false); //modo de ejemplo

    useEffect(() => {
        if (Object.keys(address).length === 0) history.push('shippingAddress');
        getWalletDetails(username);
    }, []);

    useEffect(() => {
        setAddress(directionSelection); // obtener address
    }, [directionSelection]);

    useEffect(() => {
        setShowRecipient(true);
        setRecipientName(
            addressValidationStatus.error
                ? I18n.t('wallet.targetTypes.withoutName')
                : addressValidationStatus.success &&
                  !directionSelection.is_contact &&
                  !directionSelection?.nombre
                ? I18n.t('wallet.targetTypes.withoutName')
                : I18n.t('wallet.targetTypes.withName') + directionSelection?.nombre,
        ); //seteo informacion del destinatario
    }, [addressValidationStatus]);

    useEffect(() => {
        if (
            (transactionValidationStatus.success &&
                !directionSelection.is_contact &&
                (addressValidationStatus.success || addressValidationStatus.error)) ||
            (transactionValidationStatus.success &&
                addressValidationStatus.error &&
                directionSelection.is_contact === undefined)
        )
            setShowAddContact(true);
        if (
            (transactionValidationStatus.success || transactionValidationStatus.error) &&
            transactionHash
        )
            setDetailsLink(`${walletExplorer}/transaction/${transactionHash}`);
        setInfoTransfer(
            transactionValidationStatus.error
                ? transactionValidationStatus.errorMessage ??
                      I18n.t('wallet.transaction.message.error')
                : I18n.t('wallet.transaction.message.success'),
        );
    }, [transactionValidationStatus]);

    useEffect(() => {
        if (
            transactionValidationStatus.loading ||
            transactionValidationStatus.success ||
            transactionValidationStatus.error
        )
            setShowBtn(true);
        else if (
            address?.import <= 0 ||
            address.import === '' ||
            address.import === undefined ||
            address?.import > balanceNex.balance
        )
            setShowBtn(true);
        else setShowBtn(false);
    }, [address, transactionValidationStatus, balanceNex]);

    const handleClick = () => {
        setShowRecipient(true);
        saveSendNex({ ...address, amount: address.amount });
        setRecipientName(
            addressValidationStatus.error
                ? I18n.t('wallet.targetTypes.withoutName')
                : I18n.t('wallet.targetTypes.withName') + directionSelection?.nombre,
        );
        setShowAddressee(true);
    };

    const handleClickSend = () => {
        setShowAddressee(false);

        setShowTransfer(true);
    };

    const handleAddContact = () => {
        setContact({
            nombre: directionSelection.nombre,
            address: directionSelection.address,
        });
        history.push('contact');
    };
    return (
        <WalletScreenWrapper
            titleCard={I18n.t('wallet.sendNex.title')}
            to='shippingAddress'
            classBody='card-sendNex'
        >
            <div className='container-sendNex'>
                <div className='container-icon'>
                    <IconAdd
                        width={shareIcon.size}
                        height={shareIcon.size}
                        color={shareIcon.color}
                        className='background-icon-shared'
                    />
                </div>
                {/* Notificacion sobre la dirección */}
                <div className='wapper-notification'>
                    <SuccessBox
                        text={recipientName}
                        show={showRecipient}
                        className={
                            addressValidationStatus.error
                                ? 'notification-wallet-warning'
                                : 'notification-wallet-success'
                        }
                    />
                </div>
                <div className='input-code'>
                    <Input
                        className='border'
                        value={address?.address}
                        pillStyle='wallet'
                        handleChange={(v) => setAddress({ ...address, code: v })}
                        disabled={false}
                    />
                </div>
                <div className='input-import'>
                    <CurrencyInputBox
                        onChange={(e) => setAddress({ ...address, amount: e })}
                        typeCurrency={I18n.t('wallet.sendNex.typeCurrency')}
                        editable={
                            (addressValidationStatus.error ||
                                transactionValidationStatus.loading ||
                                transactionValidationStatus.success ||
                                transactionValidationStatus.error) ??
                            true
                        }
                    />
                </div>
                {showTransfer && !transactionValidationStatus.loading && (
                    <div className='wapper-notification-transaction'>
                        <ErrorBox
                            show={showTransfer}
                            className={`title-error ${
                                transactionValidationStatus.success
                                    ? 'notification-wallet-success'
                                    : transactionValidationStatus.error
                                    ? 'notification-wallet-error'
                                    : 'notification-wallet-loading'
                            }`}
                            errorMessage={infoTransfer}
                            href={detailsLink}
                            linkMessage={I18n.t('wallet.transaction.message.viewDetail')}
                            icon='false'
                        />
                    </div>
                )}
                <div>
                    {!showAddressee && !AddressValidation && !showAddContact ? (
                        <>
                            <Button
                                type='button'
                                className='primary with-gradient wallet'
                                text={I18n.t('wallet.contacts.shipmentDirection.send')}
                                onClick={handleClick}
                                loading={transactionValidationStatus.loading ?? ''}
                                disabled={
                                    !address?.amount ||
                                    transactionValidationStatus.success ||
                                    transactionValidationStatus.error
                                }
                            />
                        </>
                    ) : (
                        !transactionValidationStatus.error &&
                        showAddContact && (
                            <>
                                <div className='container-linkAccess'>
                                    <LinkAccess
                                        className='linkAccess'
                                        titleStyle='title-linkAccess'
                                        title={I18n.t('wallet.sendNex.addContact')}
                                        icon={
                                            <IconAdd
                                                width={linkAction.size}
                                                height={linkAction.size}
                                                color={linkAction.color}
                                            />
                                        }
                                        handleClick={() => handleAddContact()}
                                    />
                                </div>
                            </>
                        )
                    )}

                    {AddressValidation && (
                        <div className='button-wrapper-error padding-right'>
                            <Button
                                type='button'
                                className='decline'
                                classText='btn-text text-cancel'
                                text={I18n.t('wallet.transaction.button.cancel')}
                            />
                            <Button
                                type='button'
                                className='continue'
                                classText='btn-text text-newTry'
                                text={I18n.t('wallet.transaction.button.newTry')}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className='input-wrapper-sendNex'>
                <ModalAddresseeContainer
                    show={showAddressee}
                    closeModal={() => setShowAddressee(false)}
                    chkHandleChange={() => setChkSchedule(!chkSchedule)}
                    handleClickSend={handleClickSend}
                    address={address}
                    iconClose={close}
                />
            </div>
        </WalletScreenWrapper>
    );
};
export default SendNexScreen;
