import { connect } from 'react-redux';
import RegistrationScreen from '../components/RegistrationScreen/';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    osList: state.common.osList,
    validationStatus: state.registration.validationStatus,
    registerProductsStatus: state.registration.registerProductsStatus,
    downloadTransactionsStatus: state.registration.downloadTransactionsStatus,
    productsByOs: state.common.productsByOs,
    userType: state.profile.user.type,
    patients: state.patient.basicPatients,

    // for os user
    osId: state.session?.os_id,
    osName: state.session?.os_name,
    osPublicKey: state.session?.os_publicKey,
    programmedDosesStatus: state.patient.programmedDosesStatus,
    programedDoses: state.patient.programedDoses,
    productsForPatient: state.registration.productsForPatient,
    allProducts: state.registration.allProducts,
    getProductsStatus: state.registration.getProductsStatus,
    getAllProductsStatus: state.registration.getAllProductsStatus,
});

const mapDispatchToProps = (dispatch) => ({
    getPatients: () => dispatch(actions.patient.getBasicPatients()),
    validateProduct: (os, patientDni, trace, successCallback) =>
        dispatch(actions.registration.validateProduct(os, patientDni, trace, successCallback)),
    registerProducts: (os, patientDni, traces, successCallback) =>
        dispatch(actions.registration.registerProducts(os, patientDni, traces, successCallback)),
    downloadTransactions: () => dispatch(actions.registration.downloadRegisterTransactions()),
    getProgramedDose: (contract) => dispatch(actions.patient.getProgramedDose(contract)),
    getAllProductsById: (id) => dispatch(actions.registration.getAllProductsById(id)),
    getProgramedDoses: (contract) => dispatch(actions.patient.getProgramedDose(contract)),
    clearProgrammedDoses: () => dispatch(actions.patient.clearProgrammedDoses()),
    editProgrammedDoses: (doses) => dispatch(actions.patient.editProgrammedDoses(doses)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationScreen);
