const ESCAPED_CHARACTERS = ['=', '+', '-', '@'];

export const escapeChar = (s?: any) => {
    if (!s) return '';
    let result = s.toString();
    ESCAPED_CHARACTERS.forEach((c) => {
        result = result.replaceAll(c, `'${c}`);
    });
    return result;
};
