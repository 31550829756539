import React from 'react';
import { I18n } from 'react-redux-i18n';
import './AuditComments.scss';

export enum AUDIT_COMMENT_STATE_LABEL {
    AUDIT_REJECTED = 'auditRejected',
    CLEANED_EVIDENCE = 'cleanedEvidence',
    AUDIT_APPROVED = 'auditApproved',
    AUDIT_CONSUMPTION_REJECTED = 'auditConsumptionRejected',
    AUDIT_REQUIRED = 'auditRequired',
    AUDIT_PENDING = 'auditPending',
}

interface AuditHistoryItem {
    comentario_enc: string;
    estado: string;
    id: number;
    usuario: string;
    usuario_auditor: number | null;
    usuario_drogueria: number | null;
    usuario_os: number | null;
}

export const RejectedTypeComments = ({
    stateLabel,
    data,
}: {
    stateLabel: AUDIT_COMMENT_STATE_LABEL;
    data: AuditHistoryItem[];
}) => {
    const comments = data.filter((audit) => !!audit.comentario_enc);
    const rejectedOrCleaned =
        stateLabel === AUDIT_COMMENT_STATE_LABEL.AUDIT_REJECTED ||
        stateLabel === AUDIT_COMMENT_STATE_LABEL.CLEANED_EVIDENCE;
    return (
        <div className={`audit-comments-container${rejectedOrCleaned ? ' error-box' : ''}`}>
            {rejectedOrCleaned && (
                <p className='title red-box'>
                    {stateLabel === 'auditRejected'
                        ? I18n.t('transaction.detailModal.commentSection.audit_rejected')
                        : I18n.t('transaction.detailModal.commentSection.cleanedEvidence')}
                </p>
            )}
            <div className='comments-container'>
                {comments.map((audit, i) => (
                    <div className='comment-item' key={`audit-comment-${i}`}>
                        <p>{audit.usuario}:</p>
                        <p className='text'>{audit.comentario_enc || '-'}</p>
                    </div>
                ))}
                {comments.length === 0 && <p className='empty-text'>Sin comentarios</p>}
            </div>
        </div>
    );
};

export const PendingTypeComments = ({ data }: { data: AuditHistoryItem[] }) => {
    const comments = data.filter((audit) => !!audit.comentario_enc);
    return (
        <div className='audit-comments-container'>
            <div className='comments-container'>
                {comments.map((audit, i) => (
                    <div className='comment-item' key={`audit-comment-${i}`}>
                        <p>{audit.usuario}:</p>
                        <p className='text'>{audit.comentario_enc || '-'}</p>
                    </div>
                ))}
                {comments.length === 0 && <p className='empty-text'>Sin comentarios</p>}
            </div>
        </div>
    );
};
