import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import './BreadCrumbs.scss';

const BreadCrumbs = ({ breadcrumbs, links, extraLinks }) => {
    const _links = [...(links ?? breadcrumbs ?? []), ...(extraLinks ?? [])];

    return (
        <div className='bread-crum'>
            {_links?.map(({ link, label, lastWill }, i) => {
                const key = `breadcrums-link-${i}`;
                if (i === 0) {
                    return (
                        <Link
                            key={key}
                            to={link}
                            style={{ textDecoration: 'none' }}
                            onClick={() => lastWill && lastWill()}
                        >
                            {`< ${label}/ `}
                        </Link>
                    );
                }

                if (i === _links.length - 1) {
                    return <b key={key}>{` ${label}`}</b>;
                }

                return (
                    <Link
                        key={key}
                        to={link}
                        style={{ textDecoration: 'none' }}
                        onClick={() => lastWill && lastWill()}
                    >
                        {` ${label}/ `}
                    </Link>
                );
            })}
        </div>
    );
};

const mapStateToProps = (state) => ({
    breadcrumbs: state.common.breadcrumbs,
});
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumbs);
