import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import React from 'react';
import PropTypes from 'prop-types';
import './BackLink.scss';

const BackLink = (props) => {
    return (
        <Link className={`patient-details-breadcrumbs ${props.className}`} to={props.to}>
            {props.title ?? I18n.t('common.goBack')}
        </Link>
    );
};

BackLink.propTypes = {
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
};
export default BackLink;
