import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './routes';
import store from './store';
import { ConnectedRouter } from 'connected-react-router';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import translations from './i18n/translations';
import './styles/font.scss';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

function startApp() {
    const onStart = () => {
        syncTranslationWithStore(store.store);
        store.store.dispatch(loadTranslations(translations));
        store.store.dispatch(setLocale('es'));
    };

    ReactDOM.render(
        <QueryClientProvider client={new QueryClient()}>
            <PersistGate
                loading={<div>Loading</div>}
                persistor={store.persistor}
                onBeforeLift={onStart}
            >
                <Provider store={store.store}>
                    <ConnectedRouter history={store.history}>
                        <BrowserRouter>
                            <Routes />
                        </BrowserRouter>
                    </ConnectedRouter>
                </Provider>
            </PersistGate>
        </QueryClientProvider>,
        document.getElementById('root'),
    );
}

startApp();
