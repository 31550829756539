import PropTypes from 'prop-types';
import React from 'react';

const ClipBoard = ({ width, height, color }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 19 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <g clipPath='url(#clip0_201_366)'>
                <path
                    d='M5.70001 11.2002H12.35'
                    stroke={color}
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M5.70001 15.2002H9.86101'
                    stroke={color}
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M7.60001 5H11.4C13.3 5 13.3 4 13.3 3C13.3 1 12.35 1 11.4 1H7.60001C6.65001 1 5.70001 1 5.70001 3C5.70001 5 6.65001 5 7.60001 5Z'
                    stroke={color}
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M13.3 3.02002C16.4635 3.20002 18.05 4.43002 18.05 9.00002V15C18.05 19 17.1 21 12.35 21H6.65001C1.90001 21 0.950012 19 0.950012 15V9.00002C0.950012 4.44002 2.53651 3.20002 5.70001 3.02002'
                    stroke={color}
                    strokeWidth='1.5'
                    strokeMiterlimit='10'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_201_366'>
                    <rect width='19' height='22' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
ClipBoard.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
};
export default ClipBoard;
