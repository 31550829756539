import React from 'react';
import ReactTable, { Column } from 'react-table';
import TablePagination from '../TablePagination';
import { ThreeDots } from 'react-loader-spinner';
import './Table.scss';

interface TableProps<DataType> {
    data: DataType[];
    columns: Column<DataType>[];
    pagination?: PaginationProps;
    noDataText?: string;
    ref?: React.Ref<HTMLDivElement>;
    loading?: boolean;
    sortable?: boolean;
}

export interface PaginationProps {
    page: number;
    total: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    pagedMessageTranslation: string;
    pagedMessageEmptyTranslation: string;
}

const Table = <DataType,>(props: TableProps<DataType>) => {
    return (
        <ReactTable
            className={props.data.length > 0 ? 'nexus-table' : 'nexus-table empty'}
            PaginationComponent={
                props.pagination
                    ? (paginationProps) => (
                          <TablePagination
                              {...paginationProps}
                              page={props.pagination?.page}
                              total={props.pagination?.total}
                              totalPages={props.pagination?.totalPages}
                              onPageChange={props.pagination?.onPageChange}
                              pagedMessageTranslation={props.pagination?.pagedMessageTranslation}
                              pagedMessageEmptyTranslation={
                                  props.pagination?.pagedMessageEmptyTranslation
                              }
                          />
                      )
                    : undefined
            }
            showPagination={!!props.pagination}
            data={props.data}
            columns={props.columns.map((column) => ({
                ...column,
                className: column.className ? column.className : 'nexus-cell center',
                headerClassName: column.headerClassName
                    ? column.headerClassName
                    : 'nexus-header center',
                resizable: false,
                style: { whiteSpace: 'unset' },
                sortable: false,
            }))}
            pageSize={10}
            minRows={0}
            noDataText={props.noDataText}
            LoadingComponent={(props) => (
                <div className={`-loading ${props.loading ? '-active custom-table-loader' : ''}`}>
                    <ThreeDots />
                </div>
            )}
            loading={props.loading}
            sortable={props.sortable}
        />
    );
};

export default Table;
