import React, { useState, useEffect } from 'react';
import './RecoverPasswordScreen.scss';
import nexus_logo from '../../../assets/logos/nexus-white-small.png';
import { I18n } from 'react-redux-i18n';
import Button from '../../../common/components/Button';
import Input from '../../../common/components/Input';
import { useHistory } from 'react-router-dom';
import { validators } from '../../../utils';

const RecoverPasswordScreen = (props) => {
    const [email, setEmail] = useState('');
    const [instructionsSentSuccessfully, setInstructionsSentSuccessfully] = useState(false);
    const [errorField, setErrorField] = useState();
    const [errorMessage, setErrorMessage] = useState();

    let history = useHistory();

    useEffect(() => {
        if (props.restorePasswordStatus.success) {
            setInstructionsSentSuccessfully(true);
        }
    }, [props.restorePasswordStatus]);

    useEffect(() => {
        if (props.restorePasswordStatus.error)
            setErrorMessage(props.restorePasswordStatus.errorMessage);
    }, [props.restorePasswordStatus.error]);

    const validate = () => {
        if (!email) {
            setErrorField('email');
            setErrorMessage(I18n.t('recoverPassword.errors.enterAnEmail'));
            return false;
        } else if (!validators.validEmail(email)) {
            setErrorField('email');
            setErrorMessage(I18n.t('recoverPassword.errors.enterAValidEmail'));
        } else {
            setErrorField(undefined);
            setErrorMessage(undefined);
            return true;
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (validate()) props.restorePassword(email);
    };

    return (
        <div className='recover-password-screen'>
            <img alt='Nexus logo' src={nexus_logo} />
            <div className='card'>
                {!instructionsSentSuccessfully ? (
                    <form className='form' onSubmit={onSubmit} noValidate>
                        <label className='title'>
                            {I18n.t('recoverPassword.forgotYourPassword')}
                        </label>
                        <div className={`error-container ${errorMessage ? 'show' : ''}`}>
                            <i className='icon-exclamation' />
                            <div className='error-msj'>{errorMessage}</div>
                        </div>
                        <Input
                            type='email'
                            value={email}
                            icon={<i className='icon-envelope' />}
                            handleChange={setEmail}
                            placeholder={I18n.t('recoverPassword.enterYourEmail')}
                            error={errorField === 'email'}
                            pillStyle
                        />
                        <Button
                            type='submit'
                            text={I18n.t('recoverPassword.recoverPassword')}
                            loading={props.restorePasswordStatus.loading}
                        />
                    </form>
                ) : (
                    <div className='recover-instructions'>
                        <i className='icon-check' />
                        <label className='title'>
                            {I18n.t('recoverPassword.instructionsSent')}
                        </label>
                        <div className='paragraph-container'>
                            <label className='paragraph'>
                                {I18n.t('recoverPassword.instructionsParagraph1', { email: email })}
                            </label>
                            <label className='paragraph last'>
                                {I18n.t('recoverPassword.instructionsParagraph2')}
                            </label>
                        </div>
                    </div>
                )}
            </div>
            <label
                className='back-to-top'
                onClick={() => {
                    history.push('/');
                    props.resetUi();
                }}
            >
                {I18n.t('recoverPassword.backToTop')}
            </label>
        </div>
    );
};

export default RecoverPasswordScreen;
