import React from 'react';
import NexBalance from '../common/NexBalance/NexBalance';
import WalletIcon from '../common/WalletIcon/WalletIcon';
import KeyContainer from '../common/KeyContainer/KeyContainer';
import Button from '../../../common/components/Button/Button';
import { I18n } from 'react-redux-i18n';
import './WalletHome.scss';
import { useHistory } from 'react-router-dom';
import { check, copyKeyBtn } from '../common/PasteAddress/constants';

const WalletHeader = ({ wallet }) => {
    const history = useHistory();

    return (
        <div className='wallet-header-container'>
            <div className='wallet-info-container'>
                <div className='wallet-icon-box-container'>
                    <WalletIcon />
                </div>
                <div className='wallet-balance-container'>
                    <span className='wallet-balance-title'>
                        {I18n.t('wallet.walletHeader.totalBalance')}
                    </span>
                    <NexBalance balance={wallet.balance} style={{ fontSize: '3em' }} />
                    <KeyContainer
                        walletKey={wallet.address}
                        iconStyleCopy={{ ...copyKeyBtn, color: '#f3f3f3' }}
                        iconStyleCopyCheck={{ ...check, color: '#f3f3f3' }}
                    />
                </div>
            </div>
            <div className='send-button-container'>
                <Button
                    text={I18n.t('wallet.walletHeader.sendButton')}
                    className='white bold small-wide'
                    onClick={() => history.push('/wallet/shippingAddress')}
                />
            </div>
        </div>
    );
};
export default WalletHeader;
