import { connect } from 'react-redux';
import CreatePrivateKeyScreen from '../components/CreatePrivateKeyScreen/CreatePrivateKeyScreen';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    saveKeysStatus: state.profile.saveKeysStatus,
    username: state.profile.user.username, // TODO replace user with username. Verify route first
});
const mapDispatchToProps = (dispatch) => ({
    saveKeys: (keys, address, mnemonic) =>
        dispatch(actions.profile.saveKeys(keys, address, mnemonic)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePrivateKeyScreen);
