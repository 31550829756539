import React, { useEffect, useState } from 'react';
import TitleBar from '../../../../common/components/TitleBar/TitleBar';
import './TransactionList.scss';
import TransactionCard from './TransactionCard';
import Button from '../../../../common/components/Button/Button';
import { I18n } from 'react-redux-i18n';
import WalletCard from '../../common/ListCard/WalletCard';
import Loader from '../../../../common/components/Loader';
import { success } from '../../../../utils/models';

const TransactionsList = ({ transactions, address, getRecentTransactions, recentStatus }) => {
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        getRecentTransactions();
    }, [address]);

    return (
        <div className='transactions-list-container'>
            <div style={{ borderBottom: '2px solid #f3f3f3', margin: '0 0.75% 3vh 0.75%' }}>
                <div className='title-bar-container'>
                    <TitleBar
                        title={I18n.t('wallet.transactionsList.header')}
                        backgroundUnderline='light-green'
                        titleClass='wallet-title'
                    />
                </div>
            </div>
            <div className='transactions-list-container'>
                {recentStatus.loading ? (
                    <div className='loader-container'>
                        <Loader />
                    </div>
                ) : success && transactions?.length > 0 ? (
                    transactions
                        .slice(0, offset + 3)
                        .map((t, i) => (
                            <TransactionCard key={`transaction-card-${i}`} transaction={t} />
                        ))
                ) : (
                    <WalletCard className='no-transaction'>
                        <span className='no-transactions-text'>
                            {I18n.t('wallet.transactionsList.noTransactions')}
                        </span>
                    </WalletCard>
                )}
            </div>

            {transactions?.length > 3 && (
                <div className='view-more-button-container'>
                    <Button
                        text={I18n.t('wallet.transactionsList.seeMore')}
                        className=' primary with-gradient small'
                        onClick={() => setOffset(offset + 3)}
                        disabled={offset + 3 >= transactions.length}
                    />
                </div>
            )}
        </div>
    );
};
export default TransactionsList;
