import { connect } from 'react-redux';
import NexMonitorScreen from '../components/NexMonitorScreen/';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    transactions: state.transaction.nexMonitorFilteredResults,
    total: state.transaction.nexMonitorFilteredResults.length,
    totalPages: Math.ceil(state.transaction.nexMonitorFilteredResults.length / 10),
    currentPage: state.transaction.nexMonitorCurrent,

    allTransactions: state.transaction.nexMonitorResults,
    filteredTransactionsBySearch: state.transaction.nexMonitorFilteredResultsBySearch,

    loading: state.transaction.getNexMonitorTransactionsStatus.loading,
    downloadStatus: state.transaction.downloadNexMonitorTransactionsStatus,

    sendDataToSAPStatus: state.transaction.sendDataToSAPStatus,
    sendDataToSAPResults: state.transaction.lastSendDataToSAPResults,

    userIsPayer: state.profile.user.groups?.includes('pagadores'),
    userType: state.profile.user.type,
});

const mapDispatchToProps = (dispatch) => ({
    getTransactions: (filter) => dispatch(actions.transaction.getNexMonitorTransactions(filter)),

    updateFilterValues: (filter) =>
        dispatch(actions.transaction.updateNexMonitorFilterValues(filter)),

    updatePage: (pageNumber) => dispatch(actions.transaction.updateNexMonitorPage(pageNumber)),
    updatePageSize: (pageSize) => dispatch(actions.transaction.updateNexMonitorPageSize(pageSize)),

    searchTransactions: (search) =>
        dispatch(actions.transaction.searchNexMonitorTransactions(search)),

    updateFilteredTransactionsByDateRange: (newResults) =>
        dispatch(actions.transaction.updateNexMonitorFilteredTransactionsByDateRange(newResults)),
    clearDateRangeSelection: (textToSearch) =>
        dispatch(actions.transaction.clearNexMonitorDateRangeSelection(textToSearch)),

    sendDataToSAP: (transactions) => dispatch(actions.transaction.sendDataToSAP(transactions)),

    downloadTransactions: () => dispatch(actions.transaction.downloadNexMonitorTransactions()),

    clearUI: () => dispatch(actions.transaction.clearUI()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NexMonitorScreen);
