import React from 'react';
import './RegistrarInstitutionCard.scss';
import LineSeparator from '../../../assets/images/line-separator-with-green-indicator.png';
import { I18n } from 'react-redux-i18n';
import Input from '../../../common/components/Input';

const RegistrarInstitutionCard = (props) => {
    const { institution, branch } = props;

    return (
        <div className='registrar-institution-card'>
            <span className='title'>{I18n.t('registrarProfile.institutionCard.title')}</span>
            <img alt='line separator' src={LineSeparator} />
            <div className='inputs-container'>
                <div className='inputs-row'>
                    <div className='input-wrapper'>
                        <Input
                            className='white'
                            value={institution?.name}
                            label={I18n.t('registrarProfile.institutionCard.name')}
                            handleChange={() => {}}
                            disabled
                            icon={<i className='icon-user' />}
                        />
                    </div>
                    <div className='input-wrapper'>
                        <Input
                            className='white'
                            value={institution?.cuit}
                            label={I18n.t('registrarProfile.institutionCard.cuit')}
                            handleChange={() => {}}
                            disabled
                            icon={<i className='icon-user' />}
                        />
                    </div>
                    <div className='input-wrapper'>
                        <Input
                            className='white'
                            value={I18n.t(
                                `registrarNewAccount.yourAccount.institutionOptions.${institution.type}`,
                            )}
                            label={I18n.t('registrarProfile.institutionCard.typeOfInstitution')}
                            handleChange={() => {}}
                            disabled
                            icon={<i className='icon-location-pin' />}
                        />
                    </div>
                </div>
                <div className='inputs-row'>
                    <div className='input-wrapper'>
                        <Input
                            className='white'
                            value={branch?.phone}
                            label={I18n.t('registrarProfile.institutionCard.phone')}
                            handleChange={() => {}}
                            disabled
                            icon={<i className='icon-phone' />}
                        />
                    </div>
                    <div className='input-wrapper'>
                        <Input
                            className='white'
                            value={branch?.address}
                            label={I18n.t('registrarProfile.institutionCard.branchAddress')}
                            handleChange={() => {}}
                            disabled
                            icon={<i className='icon-location-pin' />}
                        />
                    </div>
                    {/* empty div for styling purpose */}
                    <div className='input-wrapper' />
                </div>
            </div>
        </div>
    );
};

export default RegistrarInstitutionCard;
