import React from 'react';
import './TransactionsErrorModal.scss';
import { I18n } from 'react-redux-i18n';
import { SimpleModal } from '../../../common/components/Modal';
import PropTypes from 'prop-types';

const TransactionsErrorModal = (props) => {
    return (
        <SimpleModal show={props.show}>
            <div className='transactions-error-modal'>
                <div className='header'>
                    {props.isAValidationError ? (
                        <span className='title'>
                            {I18n.t('transaction.errorModal.validation.title')}
                        </span>
                    ) : props.isAPaymentError ? (
                        <span className='title'>{I18n.t('rocheTransaction.errorModal.title')}</span>
                    ) : props.isASendDataToSAPError ? (
                        <span className='title'>{I18n.t('nexMonitor.errorModal.title')}</span>
                    ) : props.isAGrantAddressesError ? (
                        <span className='title'>{I18n.t('grantAddresses.errorModal.title')}</span>
                    ) : (
                        <span className='title'>
                            {I18n.t('transaction.errorModal.rejection.title')}
                        </span>
                    )}
                    <i className='icon-close' onClick={props.close} />
                </div>
                <hr />
                {props.isAValidationError ? (
                    <span className='subtitle'>
                        {I18n.t(
                            'transaction.errorModal.validation.transactionsValidatedSuccessfully',
                            { successTotal: props.successfulTotal },
                        )}
                    </span>
                ) : props.isAPaymentError ? (
                    <span className='subtitle'>
                        {I18n.t('rocheTransaction.errorModal.transactionsPayedSuccessfully', {
                            successTotal: props.successfulTotal,
                        })}
                    </span>
                ) : props.isASendDataToSAPError ? (
                    <span className='subtitle'>
                        {I18n.t('nexMonitor.errorModal.transactionsSentToSAPSuccessfully', {
                            successTotal: props.successfulTotal,
                        })}
                    </span>
                ) : props.isAGrantAddressesError ? (
                    <span className='subtitle'>
                        {I18n.t('grantAddresses.errorModal.addressesGrantedSuccessfully', {
                            successTotal: props.successfulTotal,
                        })}
                    </span>
                ) : (
                    <span className='subtitle'>
                        {I18n.t(
                            'transaction.errorModal.rejection.transactionsRejectedSuccessfully',
                            { successTotal: props.successfulTotal },
                        )}
                    </span>
                )}
                <hr />
                {props.isAValidationError ? (
                    <span className='subtitle'>
                        {I18n.t('transaction.errorModal.validation.thisErrorHaveAppeared', {
                            errorTotal: props.errors.length,
                        })}
                    </span>
                ) : props.isAPaymentError ? (
                    <span className='subtitle'>
                        {I18n.t('rocheTransaction.errorModal.thisErrorHaveAppeared', {
                            errorTotal: props.errors.length,
                        })}
                    </span>
                ) : props.isASendDataToSAPError ? (
                    <span className='subtitle'>
                        {I18n.t('nexMonitor.errorModal.thisErrorHaveAppeared', {
                            errorTotal: props.errors.length,
                        })}
                    </span>
                ) : props.isAGrantAddressesError ? (
                    <span className='subtitle'>
                        {I18n.t('grantAddresses.errorModal.thisErrorHaveAppeared', {
                            errorTotal: props.errors.length,
                        })}
                    </span>
                ) : (
                    <span className='subtitle'>
                        {I18n.t('transaction.errorModal.rejection.thisErrorHaveAppeared', {
                            errorTotal: props.errors.length,
                        })}
                    </span>
                )}
                <div className='errors-list'>
                    {props.errors.map((error, index) => (
                        <div className='error-row' key={index}>
                            <i className='icon-exclamation' />
                            <span className='error-detail'>{error}</span>
                        </div>
                    ))}
                </div>
            </div>
        </SimpleModal>
    );
};

TransactionsErrorModal.propTypes = {
    show: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    close: PropTypes.func.isRequired,
    successfulTotal: PropTypes.number.isRequired,
    isAValidationError: PropTypes.bool.isRequired,
    isAPaymentError: PropTypes.bool,
    isASendDataToSAPError: PropTypes.bool,
    isAGrantAddressesError: PropTypes.bool,
};

export default TransactionsErrorModal;
