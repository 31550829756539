import React, { useEffect, useRef, useState } from 'react';
import './CustomSelect.scss';
import PropTypes from 'prop-types';

const CustomSelect = (props) => {
    const node = useRef();

    const [selectIsOpen, setSelectIsOpen] = useState(false);

    const [_options, set_options] = useState();
    useEffect(() => {
        set_options(props.options);
    }, [props.options]);
    const [_search, set_search] = useState('');

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => document.removeEventListener('mousedown', handleClick);
    }, []);

    useEffect(() => {
        if (props.searchable && _search.length > 0) {
            const filteredOptions = props.options.filter((option) =>
                option.label.toLowerCase().includes(_search.toLowerCase()),
            );
            set_options(filteredOptions);
        } else {
            set_options(props.options);
        }
    }, [_search]);

    const handleClick = (e) => {
        if (node.current.contains(e.target)) return;
        setSelectIsOpen(false);
    };

    const select = (value) => {
        props.onSelect(value);
        setSelectIsOpen(false);
    };

    const getLabel = (value) => {
        return props.options.filter((option) => option.value === value)[0]?.label;
    };

    return (
        <div
            ref={node}
            tabIndex={0}
            className='custom-select-container'
            onFocus={() => setSelectIsOpen(true)}
        >
            {props.overwrite && props.children ? (
                <div onClick={() => !props.disabled && setSelectIsOpen(!selectIsOpen)}>
                    {props.children}
                </div>
            ) : (
                <div
                    className={`custom-select ${props.className || ''} ${props.error && 'error'} ${
                        props.disabled && 'disabled'
                    }`}
                    onClick={() => !props.disabled && setSelectIsOpen(!selectIsOpen)}
                >
                    {props.icon === 'timer' ? <a className='customer-icon'></a> : props.icon}
                    {props.value ? (
                        <span className='custom-select-value'>{getLabel(props.value)}</span>
                    ) : (
                        <span className='custom-select-placeholder'>{props.placeholder}</span>
                    )}
                    <i className='icon-arrow-down custom-select-icon' />
                </div>
            )}
            {!props.disabled && (
                <div
                    className={`custom-select-options-container ${selectIsOpen && 'show'} ${
                        props.className || ''
                    }`}
                >
                    {selectIsOpen && props.searchable && (
                        <div className='custom-select-searchterm'>
                            <input
                                value={_search}
                                onChange={(v) => set_search(v.target.value)}
                                placeholder='Filtrar'
                            />
                        </div>
                    )}
                    {_options?.map((option, index) => (
                        <div
                            key={index}
                            className='custom-select-option'
                            onClick={() => select(option.value)}
                        >
                            <span className='custom-select-option-label'>{option.label}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

CustomSelect.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    icon: PropTypes.string,
    className: PropTypes.string,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    searchable: PropTypes.bool,
};

CustomSelect.defaultProps = {
    autoFocus: false,
};

export default CustomSelect;
