import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import React from 'react';

const TableHeader = ({ text, infoIcon = false, modal }) => {
    return (
        <div className='nexus-header-arrows space-between' style={{ justifyContent: 'center' }}>
            <h5>
                {text}&nbsp;
                {infoIcon && <FontAwesomeIcon data-tip data-for={text} icon={faInfoCircle} />}
            </h5>
            <ReactTooltip
                id={text}
                place='bottom'
                type='light'
                effect='solid'
                className='info-modal'
            >
                {modal}
            </ReactTooltip>
            <div className='arrows' style={{ marginLeft: '1rem' }}>
                <span>▲</span>
                <span>▼</span>
            </div>
        </div>
    );
};
export default TableHeader;
