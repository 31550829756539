import { connect } from 'react-redux';
import OSAuditTransactionsScreen from '../components/OSAuditTransactionsScreen';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    transactions: state.transaction.filteredOsAuditTransactions,
    transactionStatus: state.transaction.getOsAuditTransactionsStatus,
    osID: state.session.os_id,
});

const mapDispatchToProps = (dispatch) => ({
    getAuditTransactions: (id) => dispatch(actions.transaction.getOsAuditTransactions(id)),
    searchTransactions: (search) => dispatch(actions.transaction.searchOsAuditTransactions(search)),
    postAuditorTransactions: (body, id) =>
        dispatch(actions.transaction.postAuditorTransactions(body, id)),
    setAuditorPatientDetail: (patient) =>
        dispatch(actions.transaction.setAuditorPatientDetail(patient)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OSAuditTransactionsScreen);
