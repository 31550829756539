import React from 'react';

const CustomSVGIcon = ({ width, height, color, className, strokeWidth, show }) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        x='0px'
        y='0px'
        width={width}
        height={height}
        viewBox='0,0,256,256'
        className={`${className} ${show ? 'show' : ''}`}
    >
        <g
            fill={color}
            fillRule='nonzero'
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap='butt'
            strokeLinejoin='miter'
            strokeMiterlimit='10'
            strokeDasharray=''
            strokeDashoffset='0'
            fontFamily='none'
            fontWeight='none'
            fontSize='none'
            textAnchor='none'
            style={{ mixBlendMode: 'normal' }}
        >
            <g transform='scale(5.12,5.12)'>
                <path d='M25,2c-12.69047,0 -23,10.30953 -23,23c0,12.69047 10.30953,23 23,23c12.69047,0 23,-10.30953 23,-23c0,-12.69047 -10.30953,-23 -23,-23zM25,4c11.60953,0 21,9.39047 21,21c0,11.60953 -9.39047,21 -21,21c-11.60953,0 -21,-9.39047 -21,-21c0,-11.60953 9.39047,-21 21,-21zM34.98828,14.98828c-0.3299,0.0065 -0.63536,0.17531 -0.81641,0.45117l-10.20117,15.03711l-7.29102,-6.76562c-0.26069,-0.25084 -0.63652,-0.34135 -0.98281,-0.23667c-0.3463,0.10468 -0.60907,0.38821 -0.68715,0.74145c-0.07809,0.35324 0.04068,0.72112 0.31059,0.96201l8.99609,8.34766l11.51172,-16.96484c0.2153,-0.3085 0.23926,-0.71173 0.06201,-1.04356c-0.17725,-0.33183 -0.52573,-0.53612 -0.90186,-0.5287z'></path>
            </g>
        </g>
    </svg>
);

export default CustomSVGIcon;
