import AccessTable from '../Components/ContactsTableScreen/AccessTable/AccessTable';
import { connect } from 'react-redux';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    patientId: state.patient.editionDetails?.id,
    access: state.roleAccess.access,
    total: state.patient.editionDetails.access?.length,
    totalPages: Math.ceil(state.patient.editionDetails.access?.length / 10),
    updatePatientStatus: state.patient.editPatientStatus,
    owner: state.patient.editionDetails.owner,
    editPatientStatus: state.patient.editPatientStatus,
    getAccessStatus: state.roleAccess.getAccessStatus,
});

const mapDispatchToProps = (dispatch) => ({
    getAccess: (id) => dispatch(actions.roleAccess.getAccess(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccessTable);
