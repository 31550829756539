import { services } from '../storage';
//import CryptoJS from "crypto-js"; may be necessary
const PRIVATE_KEY = 'PRIVATE_KEY';

export default {
    savePrivateByUsername: (privateKey, username) => {
        return services.insertData(`${username}_${PRIVATE_KEY}`, privateKey);
    },
    getPrivateByUsername: (username) => {
        return services.retrieveData(`${username}_${PRIVATE_KEY}`);
    },
    getMnemonicFromKeystore: (username) => {
        return services.retrieveData(`keystore_${username}`);
    },
};
