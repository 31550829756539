import { UserTypes, UserTypesGroups } from '../constants';

export const UserGroupMap = {
    [UserTypes.OS_USER]: UserTypesGroups.OS_USER_GROUP,
    [UserTypes.OS_USER_WITHOUT_PRIVATE_KEY]: UserTypesGroups.OS_USER_GROUP,
    [UserTypes.OS_USER_WITHOUT_OLD_PRIVATE_KEY]: UserTypesGroups.OS_USER_GROUP,
    [UserTypes.OS_USER_WITHOUT_PUBLIC_KEY]: UserTypesGroups.OS_USER_GROUP,
    [UserTypes.AUDITOR]: UserTypesGroups.AUDIT_USER_GROUP,
    [UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY]: UserTypesGroups.AUDIT_USER_GROUP,
    [UserTypes.AUDITOR_WITHOUT_OLD_PRIVATE_KEY]: UserTypesGroups.AUDIT_USER_GROUP,
    [UserTypes.AUDITOR_WITHOUT_PUBLIC_KEY]: UserTypesGroups.AUDIT_USER_GROUP,
    [UserTypes.DRUGSTORE]: UserTypesGroups.DRUGSTORE_USER_GROUP,
    [UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY]: UserTypesGroups.DRUGSTORE_USER_GROUP,
    [UserTypes.DRUGSTORE_WITHOUT_PUBLIC_KEY]: UserTypesGroups.DRUGSTORE_USER_GROUP,
};

export const userTypeHasNoPublicKey = (type) => {
    switch (type) {
        case UserTypes.OS_USER_WITHOUT_PUBLIC_KEY:
        case UserTypes.AUDITOR_WITHOUT_PUBLIC_KEY:
        case UserTypes.DRUGSTORE_WITHOUT_PUBLIC_KEY:
            return true;
        default:
            return false;
    }
};

export const userTypeHasNoPrivateKey = (type) => {
    switch (type) {
        case UserTypes.OS_USER_WITHOUT_PRIVATE_KEY:
        case UserTypes.OS_USER_WITHOUT_OLD_PRIVATE_KEY:
        case UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY:
        case UserTypes.AUDITOR_WITHOUT_OLD_PRIVATE_KEY:
        case UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY:
            return true;
        default:
            return false;
    }
};

export const userHasNoOldPrivateKey = (type) => {
    switch (type) {
        case UserTypes.OS_USER_WITHOUT_OLD_PRIVATE_KEY:
        case UserTypes.AUDITOR_WITHOUT_OLD_PRIVATE_KEY:
            return true;
        default:
            return false;
    }
};

export const changeToSavedKeysTypeMap = {
    [UserTypes.OS_USER_WITHOUT_PUBLIC_KEY]: UserTypes.OS_USER,
    [UserTypes.OS_USER_WITHOUT_PRIVATE_KEY]: UserTypes.OS_USER,
    [UserTypes.OS_USER_WITHOUT_OLD_PRIVATE_KEY]: UserTypes.OS_USER,
    [UserTypes.AUDITOR_WITHOUT_PUBLIC_KEY]: UserTypes.AUDITOR,
    [UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY]: UserTypes.AUDITOR,
    [UserTypes.AUDITOR_WITHOUT_OLD_PRIVATE_KEY]: UserTypes.AUDITOR,
    [UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY]: UserTypes.DRUGSTORE,
    [UserTypes.DRUGSTORE_WITHOUT_PUBLIC_KEY]: UserTypes.DRUGSTORE,
};
