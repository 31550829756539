import React, { useState } from 'react';
import { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import InputBox from '../../../../common/components/InputBox/InputBox';
import { formatValueToESCurrency } from './utils';

const CurrencyInputBox = ({ value, onChange, typeCurrency, editable, className }) => {
    const [amount, setAmount] = useState(value ?? 0);

    useEffect(() => {
        if (onChange) onChange(amount);
    }, [amount]);

    useEffect(() => {
        setAmount(value);
    }, [value]);

    return (
        <>
            <InputBox
                titleValue={I18n.t('wallet.sendNex.import')}
                typeCurrency={I18n.t('wallet.sendNex.typeCurrency')}
                value={amount}
                onChange={(e) => setAmount(formatValueToESCurrency(e))}
                editable={editable}
                className={className}
            >
                {typeCurrency}
            </InputBox>
        </>
    );
};
export default CurrencyInputBox;
