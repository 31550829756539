import { connect } from 'react-redux';
import ContractList from '../components/ContractList/ContractList';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    contracts: state.contract.filteredResults,
    total: state.contract.filteredResults.length,
    currentPage: state.contract.current,

    loading: state.contract.getContractsStatus.loading,

    osList: state.common.osList,
    indications: state.indication.results,
});

const mapDispatchToProps = (dispatch) => ({
    getContracts: () => dispatch(actions.contract.getContracts()),
    getContract: (id) => dispatch(actions.contract.getContract(id)),
    setContractSelected: (contract) => dispatch(actions.contract.setContractSelected(contract)),

    updatePage: (pageNumber) => dispatch(actions.contract.updatePage(pageNumber)),
    updatePageSize: (pageSize) => dispatch(actions.contract.updatePageSize(pageSize)),

    getOsList: () => dispatch(actions.common.getOsList()),
    getIndications: () => dispatch(actions.indication.getIndications()),

    searchContracts: (textToSearch) => dispatch(actions.contract.searchContracts(textToSearch)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractList);
