import * as React from 'react';
import PropTypes from 'prop-types';
import './User.scss';

const IconUser = (props) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='background-user'
    >
        <path
            d='M8.595 10.297a2.107 2.107 0 1 0 0-4.214 2.107 2.107 0 0 0 0 4.214zM12.488 15.15c0-1.747-1.74-3.173-3.893-3.173-2.152 0-3.892 1.418-3.892 3.173'
            stroke='#057A83'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M15.75 9.375A7.122 7.122 0 0 1 8.625 16.5 7.122 7.122 0 0 1 1.5 9.375 7.122 7.122 0 0 1 8.625 2.25c.982 0 1.92.195 2.775.555-.098.3-.15.615-.15.945 0 .563.158 1.095.435 1.545.15.255.345.487.57.682a2.94 2.94 0 0 0 2.932.615c.36.855.563 1.8.563 2.783z'
            stroke='#057A83'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M17.25 3.75c0 .24-.03.472-.09.697a2.8 2.8 0 0 1-.345.848 2.924 2.924 0 0 1-1.628 1.297 2.94 2.94 0 0 1-2.932-.615 2.763 2.763 0 0 1-.57-.682 2.94 2.94 0 0 1-.435-1.545 2.959 2.959 0 0 1 .848-2.092A2.996 2.996 0 0 1 14.25.75c.885 0 1.688.383 2.227.998.48.532.773 1.237.773 2.002zM15.367 3.735h-2.235M14.25 2.64v2.243'
            stroke='#057A83'
            strokeWidth='1.5'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

IconUser.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
};

export default IconUser;
