import {
    ADD_CONTACT,
    ADD_CONTACT_ERROR,
    ADD_CONTACT_RESPONSE,
    ADD_WHITELISTERS,
    ADD_WHITELISTERS_ERROR,
    ADD_WHITELISTERS_RESPONSE,
    CLEAR_ADDRESS,
    DELETE_CONTACT,
    DELETE_CONTACT_ERROR,
    DELETE_CONTACT_RESPONSE,
    EDIT_CONTACT,
    EDIT_CONTACT_ERROR,
    EDIT_CONTACT_RESPONSE,
    GET_ADDRESS_VALIDATION,
    GET_ADDRESS_VALIDATION_ERROR,
    GET_ADDRESS_VALIDATION_RESPONSE,
    GET_BALANCE_NEX_MONETARY_BASE_RESPONSE,
    GET_CONTACT_RESPONSE,
    GET_EMIT_MINT,
    GET_EMIT_MINT_ERROR,
    GET_EMIT_MINT_RESPONSE,
    GET_PAYMENT_REGISTER,
    GET_PAYMENT_REGISTER_RESPONSE,
    GET_RECENT_TRANSACTIONS,
    GET_RECENT_TRANSACTIONS_ERROR,
    GET_RECENT_TRANSACTIONS_RESPONSE,
    GET_REMOVE_BURN,
    GET_REMOVE_BURN_ERROR,
    GET_REMOVE_BURN_RESPONSE,
    GET_TOTAL_PUBLISHED,
    GET_TOTAL_PUBLISHED_ERROR,
    GET_TOTAL_PUBLISHED_RESPONSE,
    GET_W3MM,
    GET_W3MM_ERROR,
    GET_W3MM_RESPONSE,
    GET_WALLET_ADDRESS,
    GET_WALLET_ADDRESS_ERROR,
    GET_WALLET_ADDRESS_RESPONSE,
    GET_WALLET_CONTACTS,
    GET_WALLET_CONTACTS_ERROR,
    GET_WALLET_CONTACTS_RESPONSE,
    GET_WALLET_DETAILS_RESPONSE,
    GET_WHITELISTERS,
    GET_WHITELISTERS_ERROR,
    GET_WHITELISTERS_RESPONSE,
    POST_SEND_NEX,
    POST_SEND_NEX_ERROR,
    POST_SEND_NEX_RESPONSE,
    REMOVE_WHITELISTERS,
    REMOVE_WHITELISTERS_ERROR,
    REMOVE_WHITELISTERS_RESPONSE,
    SAVE_SEND_NEX,
    SEARCH_CONTACTS,
    SET_CONTACT,
    SET_CONTACT_ID,
} from './wallet.actions';

const initialState = {
    data: {
        balance: 0,
        address: '',
    },
    selectedContact: {},
    selectedContactId: '',
    recentTransactions: [],
    transactionHash: '',
    contacts: [],
    address: {},
    userAddress: '',
    funskey: '',
    directionSelection: {},
    getContactsStatus: { success: false, loading: false, error: false },
    addressValidationStatus: { success: false, loading: false, error: true, message: '' },
    transactionValidationStatus: { success: false, loading: false, error: false, message: '' },
    getAddressValidationStatus: { success: false, loading: false, error: false, message: '' },
    deleteContactStatus: { success: false, loading: false, error: false },
    getRecentTransactionsStatus: { success: false, loading: false, error: false },
    results: {},
    totalPushed: '',
    totalPushedStatus: { success: false, loading: false, error: false },
    EmitMintRemoveBurnStatus: { success: false, loading: false, error: false },
    addressWhiteListers: [],
    addressWhiteListersStatus: { success: false, loading: false, error: false },
    addRemoveWhiteListersStatus: { success: false, loading: false, error: false },
    getW3mmStatus: { success: false, loading: false, error: false },
};

const filterContacts = (results, listContacts, searchText) => {
    const contracts = listContacts.length > 0 ? listContacts : results;
    if (searchText.trim() === '') return results;
    else
        return contracts.filter((contract) => {
            const text = searchText.toLowerCase();
            return (
                contract.address.toLowerCase().includes(text) ||
                contract.nombre.toLowerCase().includes(text)
            );
        });
};

const walletReducer = (state = initialState, action) => {
    const formatError = (error, defaultMessage) => {
        let message = defaultMessage;
        error &&
            error.map((item) => {
                message = message + ' ' + item;
            });
        return message;
    };
    const formatName = (contact) => ({
        ...contact,
        nombre: contact?.nombre ?? contact?.descr,
    });

    const setContacts = (contacts) => {
        return contacts.map((contact) => ({
            ...contact,
            is_contact: contact.is_contact ?? true,
        }));
    };
    switch (action.type) {
        case GET_WALLET_CONTACTS:
        case GET_WALLET_CONTACTS_ERROR:
        case GET_WALLET_CONTACTS_RESPONSE:
            return {
                ...state,
                getContactsStatus: {
                    loading: action.type === GET_WALLET_CONTACTS,
                    error: action.type === GET_WALLET_CONTACTS_ERROR,
                    success: action.type === GET_WALLET_CONTACTS_RESPONSE,
                },
                results:
                    action.type === GET_WALLET_CONTACTS_RESPONSE
                        ? action.response?.sort((a, b) => (a.id < b.id ? 1 : -1))
                        : [],
                contacts:
                    action.type === GET_WALLET_CONTACTS_RESPONSE
                        ? setContacts(action.response)
                        : [],
            };
        case GET_WALLET_ADDRESS:
        case GET_WALLET_ADDRESS_ERROR:
        case GET_WALLET_ADDRESS_RESPONSE:
            return {
                ...state,
                address:
                    action.type === GET_WALLET_ADDRESS_RESPONSE
                        ? action.response?.sort((a, b) => (a.id < b.id ? 1 : -1))
                        : state.address,
            };
        case GET_WALLET_DETAILS_RESPONSE:
            return {
                ...state,
                data: { ...action.response },
            };
        case GET_RECENT_TRANSACTIONS:
        case GET_RECENT_TRANSACTIONS_RESPONSE:
        case GET_RECENT_TRANSACTIONS_ERROR:
            return {
                ...state,
                getRecentTransactionsStatus: {
                    loading: action.type === GET_RECENT_TRANSACTIONS,
                    success: action.type === GET_RECENT_TRANSACTIONS_RESPONSE,
                    error: action.type === GET_RECENT_TRANSACTIONS_ERROR,
                },
                recentTransactions:
                    action.type === GET_RECENT_TRANSACTIONS_RESPONSE
                        ? action.response
                        : state.recentTransactions,
            };
        case GET_ADDRESS_VALIDATION:
            return {
                ...state,
                directionSelection: { address: action.address },
                addressValidationStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case GET_ADDRESS_VALIDATION_RESPONSE:
            return {
                ...state,
                directionSelection: action.response,
                addressValidationStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case GET_ADDRESS_VALIDATION_ERROR:
            return {
                ...state,
                addressValidationStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action?.error?.data,
                },
            };
        case SAVE_SEND_NEX:
            return {
                ...state,
                directionSelection: action.body,
            };

        case POST_SEND_NEX:
            return {
                ...state,
                transactionValidationStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case POST_SEND_NEX_RESPONSE:
            return {
                ...state,
                transactionValidationStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
                transactionHash: action.response.transactionHash,
            };
        case POST_SEND_NEX_ERROR:
            return {
                ...state,
                transactionValidationStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action?.error?.message,
                },
                transactionHash: action.error?.receipt ? action.error.receipt.transactionHash : '',
            };

        case GET_PAYMENT_REGISTER:
        case GET_PAYMENT_REGISTER_RESPONSE:
            return {
                ...state,
                transactionValidationStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };

        case CLEAR_ADDRESS:
            return { ...initialState };

        case SET_CONTACT:
            return {
                ...state,
                selectedContact: action.contact,
            };

        case SET_CONTACT_ID:
            return {
                ...state,
                selectedContactId: action.contactId,
            };
        case GET_CONTACT_RESPONSE:
            return {
                ...state,
                selectedContact: formatName(action.response),
            };
        case ADD_CONTACT:
        case ADD_CONTACT_RESPONSE:
        case ADD_CONTACT_ERROR:
        case EDIT_CONTACT:
        case EDIT_CONTACT_RESPONSE:
        case EDIT_CONTACT_ERROR:
            return {
                ...state,
                addContactStatus: {
                    loading: action.type === ADD_CONTACT || action.type === EDIT_CONTACT,
                    success:
                        action.type === ADD_CONTACT_RESPONSE ||
                        action.type === EDIT_CONTACT_RESPONSE,
                    error: action.type === ADD_CONTACT_ERROR || action.type === EDIT_CONTACT_ERROR,
                    message: formatError(
                        action.error,
                        'Por favor completar los siguientes campos:  ',
                    ),
                },
            };
        case SEARCH_CONTACTS:
            return {
                ...state,
                contacts: filterContacts(state.results, state.contacts, action.search),
            };

        case DELETE_CONTACT:
        case DELETE_CONTACT_RESPONSE:
        case DELETE_CONTACT_ERROR:
            return {
                ...state,
                deleteContactStatus: {
                    loading: action.type === DELETE_CONTACT,
                    success: action.type === DELETE_CONTACT_RESPONSE,
                    error: action.type === DELETE_CONTACT_ERROR,
                },
            };
        case GET_BALANCE_NEX_MONETARY_BASE_RESPONSE:
            return {
                ...state,
                data: { ...action.response },
            };
        case GET_TOTAL_PUBLISHED:
        case GET_TOTAL_PUBLISHED_RESPONSE:
        case GET_TOTAL_PUBLISHED_ERROR:
            return {
                ...state,
                totalPushed: action.response,
                totalPushedStatus: {
                    loading: action.type === GET_TOTAL_PUBLISHED,
                    success: action.type === GET_TOTAL_PUBLISHED_RESPONSE,
                    error: action.type === GET_TOTAL_PUBLISHED_ERROR,
                },
            };
        case GET_EMIT_MINT:
        case GET_EMIT_MINT_RESPONSE:
        case GET_EMIT_MINT_ERROR:
        case GET_REMOVE_BURN:
        case GET_REMOVE_BURN_RESPONSE:
        case GET_REMOVE_BURN_ERROR:
            return {
                ...state,
                EmitMintRemoveBurnStatus: {
                    loading: action.type === GET_EMIT_MINT || action.type === GET_REMOVE_BURN,
                    success:
                        action.type === GET_EMIT_MINT_RESPONSE ||
                        action.type === GET_REMOVE_BURN_RESPONSE,
                    error:
                        action.type === GET_EMIT_MINT_ERROR ||
                        action.type === GET_REMOVE_BURN_ERROR,
                },
            };
        case GET_WHITELISTERS:
        case GET_WHITELISTERS_RESPONSE:
        case GET_WHITELISTERS_ERROR:
            return {
                ...state,
                addressWhiteListersStatus: {
                    loading: action.type === GET_WHITELISTERS,
                    success: action.type === GET_WHITELISTERS_RESPONSE,
                    error: action.type === GET_WHITELISTERS_ERROR,
                },
                addressWhiteListers:
                    action.type === GET_WHITELISTERS_RESPONSE ? action.response : [],
            };
        case ADD_WHITELISTERS:
        case ADD_WHITELISTERS_RESPONSE:
        case ADD_WHITELISTERS_ERROR:
        case REMOVE_WHITELISTERS:
        case REMOVE_WHITELISTERS_RESPONSE:
        case REMOVE_WHITELISTERS_ERROR:
            return {
                ...state,
                addRemoveWhiteListersStatus: {
                    loading:
                        action.type === ADD_WHITELISTERS || action.type === REMOVE_WHITELISTERS,
                    success:
                        action.type === ADD_WHITELISTERS_RESPONSE ||
                        action.type === REMOVE_WHITELISTERS_RESPONSE,
                    error:
                        action.type === ADD_WHITELISTERS_ERROR ||
                        action.type === REMOVE_WHITELISTERS_ERROR,
                },
            };
        case GET_W3MM:
        case GET_W3MM_RESPONSE:
        case GET_W3MM_ERROR:
            return {
                ...state,
                getW3mmStatus: {
                    loading: action.type === GET_W3MM,
                    success: action.type === GET_W3MM_RESPONSE,
                    error: action.type === GET_W3MM_ERROR,
                },
            };
        default:
            return {
                ...state,
                addContactStatus: { loading: false, success: false, error: false, message: '' },
                transactionValidationStatus: {
                    success: false,
                    loading: false,
                    error: false,
                    message: '',
                },
                deleteContactStatus: { success: false, loading: false, error: false },
                getRecentTransactionsStatus: { success: false, loading: false, error: false },
                getContactsStatus: { success: false, loading: false, error: false },
                addRemoveWhiteListersStatus: { success: false, loading: false, error: false },
            };
    }
};
export default walletReducer;
