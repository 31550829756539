import { I18n } from 'react-redux-i18n';

export const userTypes = [
    { value: 1, label: I18n.t('fmi.newContact.userTypes.fmi') },
    { value: 2, label: I18n.t('fmi.newContact.userTypes.os') },
    { value: 3, label: I18n.t('fmi.newContact.userTypes.operator') },
    { value: 4, label: I18n.t('fmi.newContact.userTypes.medic') },
    { value: 5, label: I18n.t('fmi.newContact.userTypes.patient') },
    { value: 6, label: I18n.t('fmi.newContact.userTypes.auditor') },
    { value: 7, label: I18n.t('fmi.newContact.userTypes.drugstore') },
];

export const accessRole = [
    // { value: 'P', label: 'Operador' },
    // { value: 'M', label: 'Medic' },
    // { value: 'A', label: 'Paciente' },
    { value: 'D', label: 'Drogueria' },
];
