export interface Bonification {
    links: Links;
    count: number;
    total_pages: number;
    current: number;
    results: BonificationResult[];
}

interface Links {
    next: number | null;
    previous: number | null;
    next_link: string | null;
    previous_link: string | null;
}

export interface BonificationResult {
    id: number;
    paciente: Patient;
    contrato: Contract;
    fechas: DateType[];
    registrado_por: RegistradoPor;
    estado: string;
    //TODO change to corresponding type
    errores: any[];
    os_address: string;
    obra_social: ObraSocial;
    updated_at: string;
    grupo_auditor_descr: string;
    ciclo: number;
    denominacion: string;
    observaciones: string | null;
    editable: boolean;
}

export interface Patient {
    id: number;
    csee: string | null;
    isowner: boolean;
    nombre_enc: string;
    dni_enc: string;
    afiliado_enc: string;
    obra_social_id: number;
    obra_social: string;
}

export interface Contract {
    id: number;
    obra_social_descr: string;
    indicacion_descr: string | null;
    version_actual: number;
    estado_version: string;
    tipo_contrato_descr: string;
    tipo_contrato_tipo: string;
    contrato_peso_req: boolean;
    habilitado: boolean;
    grupo_auditor_id: number;
    grupo_auditor_pubkey_data: string;
    descripcion: string;
    indicacion: number;
    obra_social: number;
    tipo_contrato: number;
}

export interface DateType {
    id: number;
    estado: string;
    created_at: string;
    last_modified_by: number;
}

interface RegistradoPor {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    tipo_usuario: string;
    groups: string[];
}

interface ObraSocial {
    id: number;
    razon_social: string;
    pago_directo: boolean;
    address: string;
}

export enum BONIFICATION_STATUS {
    DELIVERED = 'E',
    VALIDATED = 'V',
    REJECTED = 'R',
}
