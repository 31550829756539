import React from 'react';
import './TextArea.css';

const TextArea = ({ className, error, classNameLabel, label, ...props }) => {
    return (
        <div className={'TextArea ' + (error ? 'error ' : '') + className}>
            <div className={classNameLabel ?? 'txta-label'}>{label}</div>
            <textarea {...props}></textarea>
        </div>
    );
};

export default TextArea;
