import React, { useState } from 'react';
import './KeyContainer.scss';
import PropTypes from 'prop-types';
import { copyToClipboard } from '../../../../utils/clipboard';
import CopyKeyBtn from '../../../../common/components/Icon/CopyKeyBtn';
import IconCheck from '../../../../common/components/Icon/Check';

const KeyContainer = ({ walletKey, iconStyleCopy, iconStyleCopyCheck }) => {
    const [show, setShow] = useState(false);

    const handleCopyClick = () => {
        copyToClipboard(walletKey);
        setShow(true);

        setTimeout(() => {
            setShow(false);
        }, 2000);
    };
    return (
        <div className='wallet-key-container'>
            <span className='wallet-key'>{walletKey}</span>
            <a className='image-link'>
                {!show ? (
                    <CopyKeyBtn
                        width={iconStyleCopy?.width}
                        height={iconStyleCopy?.height}
                        color={iconStyleCopy?.color}
                        onClick={handleCopyClick}
                    />
                ) : (
                    <IconCheck
                        width={iconStyleCopyCheck.width}
                        height={iconStyleCopyCheck.height}
                        color={iconStyleCopyCheck.color}
                        strokeWidth={2}
                        show={show}
                        className='iconCheck'
                    />
                )}
            </a>
        </div>
    );
};
KeyContainer.propTypes = {
    walletKey: PropTypes.string.isRequired,
};
export default KeyContainer;
