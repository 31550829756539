import React, { useState, useEffect } from 'react';
import './ProductCard.scss';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import { parseGS1 } from '../../../utils'; // todo move the parseGS1 to the parser file
import { parseVnDateToYYMMDD, parseVnDateToDDMMYY } from '../../../utils/parser';
import InputMask from 'react-input-mask';
import Button from '../../../common/components/Button';
import ReactTooltip from 'react-tooltip';

const ProductCard = (props) => {
    const [description, setDescription] = useState('');
    const [gtin, setGtin] = useState('');
    const [sn, setSn] = useState('');
    const [ven, setVen] = useState('');
    const [lot, setLot] = useState('');

    let gtinInput;
    let snInput;
    let venInput;
    let lotInput;
    let scannerInputTimeout;
    let scannerInputBuffer = '';

    useEffect(() => {
        if (props.forceGtinFocus && !props.isUsingKeyboard) {
            gtinInput.focus();
            props.resetForceFocus();
        }
    }, [props.forceGtinFocus, props.isUsingKeyboard]);

    useEffect(() => {
        if (props.product) {
            setGtin(props.product?.gtin?.substring(2, props.product?.gtin.length));
            setSn(props.product?.sn?.substring(2, props.product?.sn.length));
            // Change format due to client request
            if (props.product?.ven) {
                const venInDDMMYYFormat = parseVnDateToDDMMYY(
                    props.product?.ven?.substring(2, props.product?.ven.length),
                );
                setVen(venInDDMMYYFormat);
            }
            if (props.product?.lot)
                setLot(props.product?.lot.substring(2, props.product?.lot.length));
            if (props.productDescription) setDescription(props.productDescription);
        }
    }, [props.product]);

    /*
     * This function will submit the product to been validated on the server.
     * Note: If a the product param is not passed is because the trace was enter manually.
     * */
    const submit = (e, product) => {
        e.preventDefault();
        e.stopPropagation();
        // if the trace was entered with the scanner
        if (product) {
            scannerInputBuffer = '';
            //todo: que si es un producto que no esta en el listado de posibles no agregarlo
            props.submit({
                id: props.id,
                gtin: product.gtin,
                sn: product.sn,
                ven: product.ven,
                lot: product.lot,
                product: props.productsByOs.find(
                    (x) => x.codigo_ean === (gtin.substring(0, 2) === '01' ? gtin.slice(2) : gtin),
                ),
            });
            clearFields();
        }
        // if the trace was entered manually
        else if (gtin !== '' && sn.length === 14) {
            /*
             * note that the ven date has spaces, it might contains the prefix "17", is also on DDMMYY format
             * and we need the YYMMDD format without spaces and with the "17" prefix. That why we do all this.
             * */
            const getVen = (ven) => {
                if (!ven) return '';

                let venWithOutSpaces = ven.replace(/ /g, '');
                let venWithOutPrefix =
                    venWithOutSpaces.substring(0, 2) === '17'
                        ? venWithOutSpaces.substring(2, venWithOutSpaces.length)
                        : venWithOutSpaces;

                return `17${parseVnDateToYYMMDD(venWithOutPrefix)}`;
            };

            const getLot = (lot) => {
                if (!lot) return '';
                else if (lot.substring(0, 2) === '10') return lot;
                return `10${lot}`;
            };

            // Add the prefixes if they weren't added
            props.submit({
                id: props.id,
                gtin: gtin.substring(0, 2) === '01' ? gtin : `01${gtin}`,
                sn: sn.substring(0, 2) === '21' ? sn : `21${sn}`,
                ven: getVen(ven),
                lot: getLot(lot),
                product: props.productsByOs?.find((x) => x.codigo_ean === gtin),
            });
            clearFields();
        }
    };

    /*
     * This function deletes the current product on component's father and clear the fields.
     * */
    const deleteProduct = () => {
        if (props.id !== -1) props.delete(props.id);
        // if the product id is -1, clear the inputs
        else {
            clearFields();
            props.clearValidationError();
            if (!props.isUsingKeyboard) gtinInput.focus();
        }
    };

    const clearFields = () => {
        setDescription('');
        setGtin('');
        setSn('');
        setVen('');
        setLot('');
    };

    const parseScannerText = (e, text) => {
        const product = parseGS1(text);

        // set the product on the state
        setGtin(product.gtin);
        setSn(product.sn);
        /*
         * The substring is made to show only the date and not the prefix (17) because the input can show
         * only 6 chars.
         * */
        let venWithOutPrefix = product.ven.substring(2, product.ven.length);
        setVen(venWithOutPrefix);
        setLot(product.lot);

        if (props.validateVn(venWithOutPrefix)) {
            /*
             * If the ven is a valid one, submit the product.
             * note: a product is submit to avoid problems due to state not updated yet
             * */
            submit(e, product);
        }
    };

    /*
     * GS1 format:
     * - gtin (starts with '01') --> 14 chars (if counting the prefix 16 chars)
     * - sn (starts with '21') --> up to 20 chars
     * - ven (starts with '17') --> 6 chars (YYMMDD) (if counting the prefix 8 chars)
     * - lot (starts with '10') --> up to 20 chars
     * */
    const onGtinChange = (e) => {
        // this check is done to detect if the QR scanner is use
        // console.log(`*********** Trace enter: ${e.target.value} *********** ${props.isUsingKeyboard}`);
        if (!props.isUsingKeyboard) {
            // if a QR scanner is use, we need to separate the inputs and submit after that.
            scannerInputBuffer += e.target.value;
            if (scannerInputTimeout) clearTimeout(scannerInputTimeout);
            scannerInputTimeout = setTimeout(() => {
                if (scannerInputBuffer.length >= 10) parseScannerText(e, scannerInputBuffer);
            }, 500);
        } else {
            if (e.target.value.length < 14) setGtin(e.target.value);
            else if (e.target.value.length === 14) {
                setGtin(e.target.value);
                snInput.focus();
            }
        }
    };

    /*
     * Serial number on change handler, limit the length and change focus when limit has reached
     * */
    const onSnChange = (e) => {
        if (e.target.value.length <= 20) setSn(e.target.value);
        else if (!props.isUsingKeyboard) venInput.focus();
    };

    const onVenChange = (e) => {
        const venWithOutSpaces = e.target.value.split(' ').join('');
        if (venWithOutSpaces.length === 0) setVen('');
        else setVen(e.target.value);

        if (venWithOutSpaces.length === 6) {
            lotInput.focus();
            props.validateVn(venWithOutSpaces);
        }
    };

    /*
     * Serial number on change handler, limit the length
     * */
    const onLotChange = (e) => {
        if (e.target.value.length <= 20) setLot(e.target.value);
    };

    const showVenAndLot = () => {
        if (props.isUsingKeyboard) return false;
        else if (props.product) return !(props.product.ven === '' && props.product.lot === '');
        else return true;
    };

    const disableAddProductButton = () => {
        return gtin === '' || sn.length !== 14;
    };

    const getProductsByOsNoDuplicates = () => {
        let productsByOsNoDuplicates = [];
        props.productsByOs.map((product) => {
            let canAdd = true;
            productsByOsNoDuplicates.map((productNoDuplicate) => {
                if (product?.denominacion === productNoDuplicate.denominacion) {
                    canAdd = false;
                }
            });
            if (canAdd) {
                productsByOsNoDuplicates[productsByOsNoDuplicates.length] = product;
            }
        });
        return productsByOsNoDuplicates;
    };

    return (
        <form
            className={'product-card ' + (props.product?.cycle > 0 ? 'active' : '')}
            onSubmit={(e) => submit(e)}
            onClick={() => {
                if (props.isSurContract) {
                    props.editProduct &&
                        props.editProduct({
                            ...props.product,
                        });
                } else {
                    props.editProduct &&
                        props.editProduct({
                            ...props.product,
                            cycle: props.cycle,
                        });
                }
            }}
        >
            <div className='labels'>
                {props.product && (
                    <span className='label'>{I18n.t('registration.productCard.description')}</span>
                )}
                <span className='label'>{I18n.t('registration.productCard.gtin')}</span>
                <span className='label'>{I18n.t('registration.productCard.sn')}</span>
                {showVenAndLot() ? (
                    <>
                        <span className='label'>{I18n.t('registration.productCard.ven')}</span>
                        <span className='label'>{I18n.t('registration.productCard.lot')}</span>
                    </>
                ) : (
                    <>
                        <div>&nbsp;</div>
                        {!props.product && <div>&nbsp;</div>}
                    </>
                )}
                {props.product?.cycle > 0 ? (
                    <span className='label primary'>
                        {I18n.t('registration.productCard.cycle')}:
                    </span>
                ) : (
                    ''
                )}
            </div>
            <div className='inputs'>
                {/* if the user is using the keyboard render a select of product instead*/}
                {props.product && description && (
                    <input
                        data-tip={description}
                        data-for={description}
                        className='input'
                        value={description}
                        disabled={true}
                    />
                )}
                {props.product && description && description.length > 17 && (
                    <ReactTooltip id={description} backgroundColor='#000000' effect='solid'>
                        <span>{description}</span>
                    </ReactTooltip>
                )}
                {!props.isUsingKeyboard ? (
                    <input
                        ref={(input) => (gtinInput = input)}
                        autoFocus={true}
                        className='input'
                        value={gtin}
                        onChange={onGtinChange}
                        disabled={props.product}
                    />
                ) : (
                    <select
                        className='select'
                        name='product-gtin'
                        onChange={onGtinChange}
                        value={gtin}
                    >
                        <option disabled='disabled' value=''>
                            {I18n.t('registration.productCard.selectAProduct')}
                        </option>
                        {getProductsByOsNoDuplicates().map((product) => (
                            <option value={product.codigo_ean} key={product.id}>
                                {product.denominacion}
                            </option>
                        ))}
                    </select>
                )}
                {props.isUsingKeyboard ? (
                    <input
                        ref={(input) => (snInput = input)}
                        className='input'
                        value={sn}
                        onChange={onSnChange}
                        disabled={props.product}
                        placeholder={I18n.t('registration.snInputPlaceHolder')}
                        type='text'
                        maxLength='14'
                        onInput={(e) => (e.target.value = e.target.value.match('[a-zA-Z0-9]*'))} // only numbers and letters
                    />
                ) : (
                    <input
                        ref={(input) => (snInput = input)}
                        className='input'
                        value={sn}
                        onChange={onSnChange}
                        disabled={props.product}
                    />
                )}
                {showVenAndLot() ? (
                    <>
                        <InputMask
                            mask='99 99 99'
                            maskChar={' '}
                            value={ven}
                            onChange={onVenChange}
                            // the product check on the onBlur is for avoid error when a disabled card triggers the onBlur event
                            onBlur={() =>
                                !props.product && props.validateVn(ven.split(' ').join(''))
                            }
                            disabled={props.product}
                        >
                            {(inputProps) => (
                                <input
                                    {...inputProps}
                                    placeholder='DD MM AA'
                                    ref={(input) => (venInput = input)}
                                    className='input'
                                />
                            )}
                        </InputMask>
                        <input
                            ref={(input) => (lotInput = input)}
                            className='input'
                            value={lot}
                            onChange={onLotChange}
                            disabled={props.product}
                        />
                    </>
                ) : (
                    <>
                        <div>&nbsp;</div>
                        {!props.product && <div>&nbsp;</div>}
                    </>
                )}
                {props.product?.cycle > 0 ? (
                    <input className='input' value={props.product?.cycle} disabled={true} />
                ) : (
                    ''
                )}
            </div>

            <button className='delete-product-button' type='submit'>
                <i className='icon-close' onClick={deleteProduct} />
            </button>

            {props.isUsingKeyboard && !props.product && (
                <Button
                    type='button'
                    onClick={(e) => submit(e)}
                    text={I18n.t('registration.add')}
                    className='primary small add-product-button'
                    disabled={disableAddProductButton()}
                    loading={false}
                />
            )}
        </form>
    );
};

ProductCard.propTypes = {
    submit: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    product: PropTypes.object,
    validateVn: PropTypes.func,
    isUsingKeyboard: PropTypes.bool,
    clearValidationError: PropTypes.func,
    resetForceFocus: PropTypes.func,
    productsByOs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            producto: {
                codigo_ean: PropTypes.string,
                denominacion: PropTypes.string,
            },
            contrato: PropTypes.number,
        }),
    ),
};

export default ProductCard;
