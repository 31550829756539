import {
    CLEAR_DATE_RANGE_SELECTION,
    CLEAR_NEX_MONITOR_DATE_RANGE_SELECTION,
    DOWNLOAD_AUDITOR_TRANSACTIONS,
    DOWNLOAD_GRANTING_MONITOR_ADDRESSES,
    DOWNLOAD_NEX_MONITOR_TRANSACTIONS,
    DOWNLOAD_OS_TRANSACTIONS,
    DOWNLOAD_ROCHE_TRANSACTIONS,
    GET_AUDITOR_TRANSACTIONS,
    GET_GRANTING_MONITOR_ADDRESSES,
    GET_NEX_MONITOR_TRANSACTIONS,
    GET_OS_AUDIT_TRANSACTIONS,
    GET_PATIENT_AUDITOR,
    GET_TRANSACTION_MONITOR_TRANSACTIONS,
    GET_TRANSACTIONS,
    GRANT_ADDRESSES,
    PAY,
    POST_AUDITOR_TRANSACTIONS,
    PUT_TRANSACTIONS_APLICATIONS,
    REJECT_TRANSACTIONS,
    SEND_DATA_TO_SAP,
    TRACK_PAYMENTS_ERRORS,
    UPDATE_GRANTING_MONITOR_PAGE,
    UPDATE_NEX_MONITOR_TRANSACTIONS_PAGE,
    UPDATE_TRANSACTIONS_PAGE,
    VALIDATE_PAYMENTS,
    VALIDATE_TRANSACTIONS,
    GET_SHARED_RISK_TRANSACTIONS,
    UPDATE_SHARED_RISK_TRANSACTIONS,
    GET_BALANCE_NEX,
    GET_WHITE_LISTED_ADDRESSES,
    SEND_NEX_MULTIPLE_NEX,
    GET_TRANSACTIONS_BY_FILTERS,
    MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS,
} from './transaction.actions';
import actions from '../actions';
import service from './transaction.services';
import { decryptString, decryptEntity, encryptString } from '../utils/encryption';
import { I18n } from 'react-redux-i18n';
import {
    parseDate,
    parseMonitorTransactionStatus,
    parseToCsvAndDownload,
    parseTransactionStatus,
    reverseTransactionStatus,
} from '../utils/';
import {
    UserTypes,
    RocheTransactionDownloadType,
    NexTransactionsOriginsAndDestinations,
} from '../constants';
import { Cipher, KeyManager } from 'jasper-roche-crypto';
import services from '../wallet/wallet.services';

const buildStatusFilter = (statusFilter, withoutPrefix) => {
    if (!statusFilter || statusFilter.filter((d) => d.id === 'all')[0]?.checked) return '';
    else {
        let allStatus = statusFilter.every((status) => status.checked === false);
        let prefix = '';
        if (withoutPrefix) {
            if (allStatus && statusFilter)
                //NOTE: If I don't select any param in 'estado', it should send '?estado='(only role roche)
                prefix = 'emptyValue';
        } else {
            prefix = '?estado='; // (only role OS/drugstore)
        }

        return `${prefix}${statusFilter
            .filter((d) => d.checked)
            .map((d) => reverseTransactionStatus(d.id))
            .join(',')}`;
    }
};

const buildStatusFilterMonitorTransaction = (statusFilter) => {
    if (!statusFilter || statusFilter.filter((d) => d.id === 'all')[0]?.checked) return '';
    else
        return `?estado=${statusFilter
            .filter((d) => d.checked)
            .map((d) => parseMonitorTransactionStatus(d.id))
            .join(',')}`;
};

const mapBalanceIntoAddress = (addresses, response) => {
    const addressesWithBalance = [];
    addresses.map((address) => {
        const whiteListedAddress = response.find(
            (whiteListedAddress) => whiteListedAddress[0] === address.address,
        );

        if (whiteListedAddress) {
            addressesWithBalance.push({
                ...address,
                nex: fromGweiToEth(whiteListedAddress[1]) ?? 0,
                jac: fromGweiToEth(whiteListedAddress[2]) ?? 0,
            });
        } else {
            addressesWithBalance.push({
                ...address,
                nex: 0,
                jac: 0,
            });
        }
    });
    return addressesWithBalance;
};

const mapTransacctionByFilters = (transaction, results) => ({
    count: transaction.count,
    current: transaction.current,
    total_pages: transaction.total_pages,
    results: results,
});

const transactionMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        const getKeysFromKeystoreWithPrivate = (username) => {
            const keyManager = new KeyManager(username);
            const privateKey = keyManager.deriveDataKey();
            const fundsKey = keyManager.deriveFundsKey();
            const publicKey = keyManager.getPublicKey(fundsKey);
            const address = keyManager.getAddress(publicKey);
            return { address, privateKey };
        };
        const getKeysFromKeystoreWithFunds = (username) => {
            const keyManager = new KeyManager(username);
            const fundsKey = keyManager.deriveFundsKey();
            const publicKey = keyManager.getPublicKey(fundsKey);
            const address = keyManager.getAddress(publicKey);
            return { address, fundsKey };
        };

        next(action);
        const statusFilter = buildStatusFilter(action.statusFilter);
        switch (action.type) {
            case GET_TRANSACTIONS:
                service
                    .getTransactions(buildStatusFilter(action.statusFilter))
                    .then((response) => {
                        let results;
                        // Map the transactions depending on the user type (OS or Roche)
                        if (
                            getState().profile.user.type === UserTypes.OS_USER ||
                            getState().profile.user.type === UserTypes.DRUGSTORE ||
                            getState().profile.user.type === UserTypes.DRUGSTORE_WITHOUT_PRIVATE_KEY
                        ) {
                            results = response.map((encryptedTransaction) => {
                                const keyManager = new KeyManager(getState().profile.user.username);
                                return decryptTransaction(
                                    encryptedTransaction,
                                    keyManager.deriveDataKey(),
                                    transactionNormalizer,
                                );
                            });
                        }
                        dispatch(actions.transaction.getTransactionsResponse({ results }));
                        if (action.search)
                            dispatch(actions.transaction.searchTransactions(action.search));
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.getTransactionsError(error));
                    });
                break;
            case GET_TRANSACTIONS_BY_FILTERS: //Only for role roche so far
                service
                    .getTransactionsFilter(
                        buildStatusFilter(action.statusFilter, true),
                        action.page === 1 || action.page === undefined ? '' : action.page,
                        action.search,
                        action.startDate,
                        action.endDate,
                        action.order,
                    )
                    .then((response) => {
                        let results;
                        if (
                            getState().profile.user.type === UserTypes.ROCHE_USER ||
                            getState().profile.user.type ===
                                UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY
                        )
                            results = mapRocheTransactions(response.results);
                        dispatch(
                            actions.transaction.getTransactionsByFiltersResponse(
                                mapTransacctionByFilters(response, results),
                            ),
                        );
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.getTransactionsByFiltersError(error));
                    });
                break;
            case PUT_TRANSACTIONS_APLICATIONS:
                service
                    .putTransactionsAplications(action.body)
                    .then((response) => {
                        dispatch(actions.transaction.putTransactionsAplicationsResponse(response));
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.putTransactionsAplicationsError(error));
                    });
                break;
            case GET_OS_AUDIT_TRANSACTIONS: {
                const { privateKey } = getKeysFromKeystoreWithPrivate(
                    getState().profile.user.username,
                );
                service
                    .getOsAuditTransactions()
                    .then((response) => {
                        response = response
                            .filter((transaction) => {
                                return transaction.paciente;
                            })
                            .map((transaction) => {
                                return decryptTransaction(
                                    transaction,
                                    privateKey,
                                    osRegisterNormalizer,
                                );
                            });
                        dispatch(actions.transaction.getOsAuditTransactionsResponse(response));
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.getOsAuditTransactionsError(error));
                    });
                break;
            }
            case GET_AUDITOR_TRANSACTIONS:
                service
                    .getAuditorTransactions()
                    .then((response) => {
                        response = response.map((transaction) => {
                            const { privateKey } = getKeysFromKeystoreWithPrivate(
                                getState().profile.user.username,
                            );
                            return decryptTransaction(
                                transaction,
                                privateKey,
                                osRegisterNormalizer,
                            );
                        });
                        dispatch(actions.transaction.getAuditorTransactionsResponse(response));
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.getAuditorTransactionsError(error));
                    });
                break;
            case POST_AUDITOR_TRANSACTIONS:
                service
                    .postAuditorTransactions(action.response, action.id)
                    .then((response) => {
                        dispatch(actions.transaction.postAuditorTransactionsResponse(response));
                        const os_id = getState().session.os_id;
                        os_id && dispatch(actions.transaction.getOsAuditTransactions());
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.postAuditorTransactionsError(error));
                    });
                break;
            case GET_SHARED_RISK_TRANSACTIONS:
                service
                    .getSharedRiskTransactions()
                    .then((response) => {
                        dispatch(actions.transaction.getSharedRiskTransactionsResponse(response));
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.getSharedRiskTransactionsError(error));
                    });
                break;
            case UPDATE_SHARED_RISK_TRANSACTIONS:
                service
                    .updateSharedRiskTransactions(action.response, action.id)
                    .then((response) => {
                        dispatch(
                            actions.transaction.updateSharedRiskTransactionsResponse(response),
                        );
                        dispatch(actions.transaction.getSharedRiskTransactions());
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.updateSharedRiskTransactionsError(error));
                    });
                break;
            case GET_NEX_MONITOR_TRANSACTIONS:
                service
                    .getNexMonitorTransactions(statusFilter)
                    .then((response) => {
                        const results = mapNexMonitorTransactions(response);
                        dispatch(
                            actions.transaction.getNexMonitorTransactionsResponse({ results }),
                        );
                        if (action.search)
                            dispatch(
                                actions.transaction.searchNexMonitorTransactions(action.search),
                            );
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.getNexMonitorTransactionsError(error));
                    });
                break;
            case GET_TRANSACTION_MONITOR_TRANSACTIONS:
                service
                    .getTransactionMonitorTransactions(
                        buildStatusFilterMonitorTransaction(action.statusFilter),
                    )
                    .then((response) => {
                        const results = mapTransactionMonitorTransactions(response);
                        dispatch(
                            actions.transaction.getTransactionMonitorTransactionsResponse({
                                results,
                            }),
                        );
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.getTransactionMonitorTransactionsError(error));
                    });
                break;
            case GET_GRANTING_MONITOR_ADDRESSES:
                service
                    .getGrantingMonitorAddresses(statusFilter)
                    .then(async (response) => {
                        const results = await mapGrantingMonitorAddresses(
                            response,
                            getState().profile.user.username,
                        );
                        dispatch(
                            actions.transaction.getGrantingMonitorAddressesResponse({ results }),
                        );
                        dispatch(actions.transaction.getWhiteListedAddresses());
                        if (action.search)
                            dispatch(
                                actions.transaction.searchGrantingMonitorAddresses(action.search),
                            );
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.getGrantingMonitorAddressesError(error));
                    });
                break;

            case GET_WHITE_LISTED_ADDRESSES:
                var { address: address1, fundsKey: fundsKey1 } = getKeysFromKeystoreWithFunds(
                    getState().profile.user.username,
                );
                service
                    .getWhiteListedAddresses(address1, fundsKey1)
                    .then((response) => {
                        dispatch(
                            actions.transaction.getWhiteListedAddressesResponse(
                                mapBalanceIntoAddress(
                                    getState().transaction.grantingMonitorResults,
                                    response,
                                ),
                            ),
                        );
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.getWhiteListedAddressesError(error));
                    });
                break;
            case UPDATE_TRANSACTIONS_PAGE:
                dispatch(actions.transaction.getTransactions());
                break;
            case UPDATE_NEX_MONITOR_TRANSACTIONS_PAGE:
                dispatch(actions.transaction.getNexMonitorTransactions());
                break;
            case UPDATE_GRANTING_MONITOR_PAGE:
                dispatch(actions.transaction.getGrantingMonitorAddresses());
                break;
            case VALIDATE_TRANSACTIONS:
                service
                    .validateTransactions(createValidateTransactionsBody(action.transactions))
                    .then((response) => {
                        // Map all the transactions
                        let mappedTransactions = response.map((encryptedTransaction) =>
                            decryptTransaction(
                                encryptedTransaction,
                                getState().profile.user.privateKey,
                                transactionNormalizer,
                            ),
                        );
                        // Check if there was an error in any transaction
                        let erroredTransactions = response.filter(
                            (encryptedTransaction) => encryptedTransaction.errores.length !== 0,
                        );

                        if (erroredTransactions.length === 0) {
                            // if ALL transaction were successful (none errors) dispatch a successful validation.
                            dispatch(
                                actions.transaction.validateTransactionsResponse({
                                    results: mappedTransactions,
                                }),
                            );
                        } else {
                            // If there is at least one error, dispatch an error validation
                            dispatch(
                                actions.transaction.validateTransactionsError({
                                    results: mappedTransactions,
                                }),
                            );
                        }
                    })
                    .catch((error) => {
                        const { detail } = error.data;
                        // todo check what has to be done here.
                        dispatch(
                            actions.transaction.validateTransactionsError({
                                results: [{ errors: [detail] }],
                            }),
                        );
                    });
                break;
            case SEND_NEX_MULTIPLE_NEX:
                var { address: address2, fundsKey: fundsKey2 } = getKeysFromKeystoreWithFunds(
                    getState().profile.user.username,
                );
                services.getTransactionCount(address2, fundsKey2).then((count) => {
                    let i = count - 1;

                    action.transactions.map((transaction) => {
                        if (transaction.directPayment) {
                            i = i + 1;

                            //pago directo
                            service
                                //.postSendNex(
                                .postMultipleSendsNex(
                                    transaction.osAddress,
                                    transaction.tokenAmount,
                                    address2,
                                    fundsKey2,
                                    i,
                                )
                                .then(() => {
                                    i = i + 1;
                                    services
                                        .postSendNexFrom(
                                            transaction.osAddress,
                                            address2,
                                            transaction.tokenAmount,
                                            fundsKey2,
                                            i,
                                        )
                                        .then((res) => {
                                            dispatch(
                                                actions.transaction.sendMultipleNexResponse({
                                                    ...res,
                                                    id: transaction.id,
                                                }),
                                            );
                                        })
                                        .catch((error) => {
                                            dispatch(
                                                actions.transaction.sendMultipleNexError({
                                                    ...error,
                                                    id: transaction.id,
                                                }),
                                            );
                                        });
                                })
                                .catch((error) => {
                                    dispatch(
                                        actions.transaction.sendMultipleNexError({
                                            ...error,
                                            id: transaction.id,
                                        }),
                                    );
                                });
                        } else {
                            i = i + 1;
                            service
                                .postMultipleSendsNex(
                                    transaction.osAddress,
                                    transaction.tokenAmount,
                                    address2,
                                    fundsKey2,
                                    i,
                                )
                                .then((res) => {
                                    dispatch(
                                        actions.transaction.sendMultipleNexResponse({
                                            ...res,
                                            id: transaction.id,
                                        }),
                                    );
                                })
                                .catch((error) => {
                                    dispatch(
                                        actions.transaction.sendMultipleNexError({
                                            ...error,
                                            id: transaction.id,
                                        }),
                                    );
                                });
                        }
                    });
                });
                break;
            case REJECT_TRANSACTIONS:
                service
                    .rejectTransactions(createRejectTransactionsBody(action.transactions))
                    .then((response) => {
                        // Map all the transactions
                        let mappedTransactions = response.map((encryptedTransaction) =>
                            decryptTransaction(
                                encryptedTransaction,
                                getState().profile.user.privateKey,
                                transactionNormalizer,
                            ),
                        );
                        // Check if there was an error in any transaction
                        let erroredTransactions = response.filter(
                            (encryptedTransaction) => encryptedTransaction.errores.length !== 0,
                        );

                        if (erroredTransactions.length === 0) {
                            // if ALL transaction were successful (none errors) dispatch a successful rejection.
                            dispatch(
                                actions.transaction.rejectTransactionsResponse({
                                    results: mappedTransactions,
                                }),
                            );
                        } else {
                            // If there is at least one error, dispatch an error rejection
                            dispatch(
                                actions.transaction.rejectTransactionsError({
                                    results: mappedTransactions,
                                }),
                            );
                        }
                    })
                    .catch(() => {
                        // todo check what has to be done here.
                        dispatch(
                            actions.transaction.rejectTransactionsError({
                                results: action.transactions,
                            }),
                        );
                    });
                break;
            case VALIDATE_PAYMENTS:
                service
                    .validatePayments(createValidatePaymentsBody(action.transactionsIds))
                    .then(() => {
                        dispatch(
                            actions.transaction.validatePaymentsResponse(action.transactionsIds),
                        );
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.validatePaymentsError({ error: error.data }));
                    });
                break;
            case PAY:
                service
                    .pay(action.transactions)
                    .then((response) => {
                        // Map all the transactions
                        let mappedTransactions = mapRocheTransactions(response);

                        // Check if there was an error in any transaction
                        let erroredTransactions = response.filter(
                            (transaction) => transaction.errores.length !== 0,
                        );

                        if (erroredTransactions.length === 0) {
                            // if ALL transaction were successful (none errors) dispatch a successful validation.
                            dispatch(
                                actions.transaction.payResponse({ results: mappedTransactions }),
                            );
                        } else {
                            // If there is at least one error, dispatch an error validation
                            dispatch(actions.transaction.payError({ results: mappedTransactions }));
                        }
                    })
                    .catch(() => {
                        // todo check what has to be done here.
                        dispatch(actions.transaction.payError({ results: action.transactions }));
                    });
                break;
            case TRACK_PAYMENTS_ERRORS:
                service
                    .trackPaymentsErrors(createTrackPaymentsErrorsBody(action.transactionsIds))
                    .then(() => {
                        dispatch(
                            actions.transaction.trackPaymentsErrorsResponse(
                                action.transactionsIds.map((error) => ({
                                    id: error,
                                    errors: [{ description: 'Error' }],
                                })),
                            ),
                        );
                    })
                    .catch((error) => {
                        dispatch(
                            actions.transaction.trackPaymentsErrorsError({ error: error.data }),
                        );
                    });
                break;
            case SEND_DATA_TO_SAP: {
                const serviceAction = action.isRetry
                    ? service.resendDataToSAP
                    : service.sendDataToSAP;
                serviceAction(createSendDataToSAPBody(action.transactions))
                    .then((response) => {
                        dispatch(
                            actions.transaction.sendDataToSAPResponse(response, action.isRetry),
                        );
                        if (action.isRetry) {
                            dispatch(actions.transaction.getTransactionMonitorTransactions());
                        }
                    })
                    .catch((error) => {
                        dispatch(
                            actions.transaction.sendDataToSAPError(
                                { error: error.data },
                                action.isRetry,
                            ),
                        );
                    });
                break;
            }

            case GRANT_ADDRESSES: {
                var { address: address3, fundsKey: fundsKey3 } = getKeysFromKeystoreWithFunds(
                    getState().profile.user.username,
                );

                let grantAddressError = false;
                const addresses = action.addresses;

                const processAddresses = async () => {
                    for (const a of addresses) {
                        if (a.directPayment) {
                            const keyManager = new KeyManager('pagodirecto', '', true);
                            const fundsKeyPD = keyManager.deriveFundsKey();
                            const publicKeyPD = keyManager.getPublicKey(fundsKeyPD);
                            const addressPD = keyManager.getAddress(publicKeyPD);
                            try {
                                await service.transferJAC(addressPD, fundsKey3, address3);
                                await service.grantDirectPayment(address3, addressPD, fundsKeyPD);
                                a.address = addressPD;
                                await service.addToWhitelisted(a.address, address3, fundsKey3);
                            } catch (error) {
                                grantAddressError = true;
                                dispatch(actions.transaction.grantAddressesError({ error }));
                                // grantAddressErrorMessage = '';
                            }
                        } else {
                            try {
                                await service.transferJAC(a.address, fundsKey3, address3);
                                await service.addressIsWhiteLister(address3, fundsKey3);
                                await service.addToWhitelisted(a.address, address3, fundsKey3);
                            } catch (error) {
                                grantAddressError = true;
                                dispatch(actions.transaction.grantAddressesError({ error }));
                                // grantAddressErrorMessage = '';
                            }
                        }
                    }
                    return grantAddressError;
                };

                processAddresses().then(() => {
                    if (!grantAddressError) {
                        service
                            .grantAddresses(createGrantAddressesBody(addresses))
                            .then(() => {
                                dispatch(actions.transaction.grantAddressesResponse());
                            })
                            .catch((error) => {
                                dispatch(actions.transaction.grantAddressesError({ error }));
                            });
                    }
                });
                break;
            }

            case DOWNLOAD_AUDITOR_TRANSACTIONS: {
                const keyManager = new KeyManager(getState().profile.user.username);
                const auditStates = [
                    { value: 'Q', label: 'auditRequired' },
                    { value: 'P', label: 'auditPending' },
                    { value: 'R', label: 'auditRejected' },
                    { value: 'A', label: 'auditApproved' },
                    { value: '#', label: 'noAuditor' },
                ];
                const auditorDownloadTransactionNormalizer = (transaction, cse) => ({
                    id: transaction.id,
                    os: transaction.obra_social.razon_social,
                    patient: transaction.paciente,
                    dni: cse ? decryptString(transaction.paciente.dni_enc, cse) : '******',
                    name: cse ? decryptString(transaction.paciente.nombre_enc, cse) : '******',
                    creationDt: transaction.created_at,
                    description: transaction.descripcion,
                    status: transaction.estado,
                });
                service
                    .getAuditorTransactions()
                    .then((response) => {
                        const data = response.map((t) => {
                            const { id, os, patient, dni, name, creationDt, description, status } =
                                decryptTransaction(
                                    t,
                                    keyManager.deriveDataKey(),
                                    auditorDownloadTransactionNormalizer,
                                );
                            return {
                                Número: id,
                                'Obra Social': os,
                                'ID Paciente': patient.id,
                                Nombre: dni,
                                DNI: name,
                                Fecha: parseDate(new Date(creationDt)),
                                Inicidente: description,
                                Estado: I18n.t(
                                    `transaction.table.status.${
                                        auditStates.filter((a) => a.value === status)[0].label
                                    }`,
                                ),
                            };
                        });
                        parseToCsvAndDownload(data, 'Historial de Transacciones.csv');
                        dispatch(actions.transaction.downloadAuditorTransactionsResponse());
                    })
                    .catch((error) =>
                        dispatch(actions.transaction.downloadAuditorTransactionsError(error)),
                    );
                break;
            }
            case DOWNLOAD_OS_TRANSACTIONS: {
                const osResults = getState().transaction.filteredResults;

                service
                    .getTransactions()
                    .then((response) => {
                        let decryptedTransactions = response.map(function (encryptedTransaction) {
                            if (osResults.some((e) => e.id === encryptedTransaction.id)) {
                                return decryptTransaction(
                                    encryptedTransaction,
                                    getState().profile.user.privateKey,
                                    transactionNormalizer,
                                );
                            }
                        });

                        decryptedTransactions = decryptedTransactions.filter((item) => item);

                        // Parse the result with the columns name that will be display on csv
                        const data = decryptedTransactions.map((t) => ({
                            ID: t.id,
                            'DNI Paciente': t.dni,
                            'Nombre Paciente': t.name,
                            Afiliado: t.affiliate,
                            Contratos: t.contract?.descripcion,
                            Fecha: parseDate(new Date(t.dates[t.dates.length - 1].created_at)),
                            Registrador: t.registrar,
                            'Orden de Pago': t.paymentOrder,
                            'Importe Token': t.tokenAmount,
                            Moneda: t.currency,
                            Estado: I18n.t(`transaction.table.status.${t.status}`),
                        }));

                        parseToCsvAndDownload(data, 'Historial de Transacciones.csv');
                        dispatch(actions.transaction.downloadOSrTransactionsResponse());
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.downloadOSTransactionsError(error));
                    });
                break;
            }
            case DOWNLOAD_ROCHE_TRANSACTIONS: {
                let getTransactionsRequest;
                let fileName;
                let data;

                if (action.transactionType === RocheTransactionDownloadType.EMITTED) {
                    getTransactionsRequest = service.getTransactionsEmitted;
                    fileName = 'Roche Historial de Transacciones Emitidas.csv';
                } else if (action.transactionType === RocheTransactionDownloadType.RECEIVED) {
                    getTransactionsRequest = service.getTransactionsReceived;
                    fileName = 'Roche Historial de Transacciones Recibidas.csv';
                } else {
                    fileName = 'Roche Historial de Transacciones.csv';
                    const keyManager = new KeyManager(getState().profile.user.username);
                    service
                        .getNotPaginatedTransactions(
                            buildStatusFilter(action.statusFilter, true),
                            action.page === 1 || action.page === undefined ? '' : action.page,
                            action.search,
                            action.startDate,
                            action.endDate,
                            action.order,
                        )
                        .then((encryptedTransactions) => {
                            const decryptedTransactions = encryptedTransactions.map(
                                (encryptedTransaction) => {
                                    const {
                                        id,
                                        patientId,
                                        os,
                                        dates,
                                        contract,
                                        registrar,
                                        product,
                                        paymentOrder,
                                        tokenAmount,
                                    } = decryptTransaction(
                                        encryptedTransaction,
                                        keyManager.deriveDataKey(),
                                        transactionNormalizer,
                                    );
                                    return {
                                        ID: id,
                                        'ID del Paciente': patientId,
                                        'Obra Social': os,
                                        Fecha: parseDate(
                                            new Date(dates[dates.length - 1].created_at),
                                        ),
                                        Contratos: contract?.descripcion,
                                        Registrador: registrar,
                                        Producto: product,
                                        'Orden de Pago': paymentOrder,
                                        'Importe Token': tokenAmount,
                                        Estado: I18n.t(`transaction.table.status.${status}`),
                                    };
                                },
                            );

                            if (decryptedTransactions) {
                                parseToCsvAndDownload(decryptedTransactions, fileName);
                                dispatch(actions.transaction.downloadRocheTransactionsResponse());
                            } else {
                                dispatch(
                                    actions.transaction.downloadRocheTransactionsError(
                                        'Error: Empty List',
                                    ),
                                );
                            }
                        })
                        .catch((error) =>
                            dispatch(actions.transaction.downloadRocheTransactionsError(error)),
                        );
                }
                if (action.transactionType !== RocheTransactionDownloadType.ALL) {
                    getTransactionsRequest()
                        .then((response) => {
                            const rocheTransactions = mapRocheTransactions(
                                response,
                                action.transactionType,
                            );
                            if (rocheTransactions) {
                                data = rocheTransactions.map((t) => ({
                                    ID: t.id,
                                    'Address Roche': t.rocheAddress,
                                    'Razón Social': t.os,
                                    Address: t.address,
                                    Fecha: parseDate(new Date(t.date)),
                                    'Importe Token': t.tokenAmount,
                                }));
                                parseToCsvAndDownload(data, fileName);
                                dispatch(actions.transaction.downloadRocheTransactionsResponse());
                            } else {
                                dispatch(
                                    actions.transaction.downloadRocheTransactionsError(
                                        'Error: Empty List',
                                    ),
                                );
                            }
                        })
                        .catch((error) => {
                            dispatch(actions.transaction.downloadRocheTransactionsError(error));
                        });
                }

                break;
            }
            case DOWNLOAD_NEX_MONITOR_TRANSACTIONS: {
                const nexMonitorResults = getState().transaction.nexMonitorFilteredResults;

                if (nexMonitorResults) {
                    let data;

                    // Parse the result with the columns name that will be display on csv
                    data = nexMonitorResults.map((t) => ({
                        ID: t.id,
                        'Importe Token': t.tokenAmount,
                        Origen: t.origin,
                        Destino: t.destination,
                        Fecha: parseDate(new Date(t.timeReceived)),
                        Estado: t.status,
                    }));

                    parseToCsvAndDownload(data, 'NEX Historial de Transacciones.csv');
                    dispatch(actions.transaction.downloadNexMonitorTransactionsResponse());
                } else {
                    dispatch(
                        actions.transaction.downloadNexMonitorTransactionsError(
                            'Error: Empty List',
                        ),
                    );
                }

                break;
            }
            case DOWNLOAD_GRANTING_MONITOR_ADDRESSES: {
                const grantingMonitorResults =
                    getState().transaction.grantingMonitorFilteredResults;
                if (grantingMonitorResults) {
                    let data = grantingMonitorResults.map((a) => ({
                        'Razón Social': a.businessName,
                        Descripción: a.description,
                        Address: a.address,
                        NEX: a.nex,
                        JAC: a.jac,
                    }));
                    parseToCsvAndDownload(data, 'Listado de Addresses.csv');
                    dispatch(actions.transaction.downloadGrantingMonitorAddressesResponse());
                } else {
                    dispatch(
                        actions.transaction.downloadGrantingMonitorAddressesError(
                            'Error: Empty List',
                        ),
                    );
                }
                break;
            }
            case CLEAR_DATE_RANGE_SELECTION:
                dispatch(actions.transaction.getTransactions({ search: action.textToSearch }));
                break;
            case CLEAR_NEX_MONITOR_DATE_RANGE_SELECTION:
                dispatch(
                    actions.transaction.getNexMonitorTransactions({ search: action.textToSearch }),
                );
                break;
            case GET_PATIENT_AUDITOR:
                service
                    .getPatientAuditor(action.id)
                    .then(async (response) => {
                        const { privateKey } = getKeysFromKeystoreWithPrivate(
                            getState().profile.user.username,
                        );
                        const decryptedResponse = decryptEntity(
                            response,
                            privateKey,
                            response.paciente.csee,
                            normalizePatientAuditor,
                        );
                        service
                            .getPatientEvidenceAuditor(
                                decryptedResponse.id,
                                decryptedResponse.paciente_contrato,
                            )
                            .then((ev) => {
                                decryptedResponse.contrato = {
                                    ...decryptedResponse.contrato,
                                    evidencia: ev,
                                };
                                dispatch(
                                    actions.transaction.getPatientAuditorResponse(
                                        decryptedResponse,
                                    ),
                                );
                            })
                            .catch((error) => {
                                dispatch(actions.transaction.getPatientAuditorError(error));
                            });
                    })
                    .catch((error) => {
                        dispatch(actions.transaction.getPatientAuditorError(error));
                    });
                break;

            case MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS: {
                const oldPrivateKey = getState().profile.user?.oldPrivateKey;
                const manager = new KeyManager(getState().profile.user.username);
                const cipher = new Cipher();
                service
                    .getOsAuditTransactions()
                    .then((data) => {
                        Promise.all(
                            data.map((transaction) => {
                                const cse = cipher.decrypt(
                                    manager.deriveDataKey(),
                                    transaction.paciente.csee,
                                );
                                return transaction.audit_historial.map((hist) => {
                                    const comment = decryptString(
                                        hist.comentario_enc,
                                        oldPrivateKey,
                                    );
                                    const encComment = encryptString(comment, cse);
                                    return service.updateAuditorTransactions(
                                        { comentario_enc: encComment },
                                        hist.id,
                                    );
                                });
                            }),
                        )
                            .then(() => {
                                dispatch(actions.patient.patientMigrationResponse());
                                dispatch(actions.profile.updateMigratedStatus());
                            })
                            .catch((e) => {
                                dispatch(actions.patient.patientMigrationError(e));
                            });
                    })
                    .catch((err) => {
                        console.error(err);
                    });
                break;
            }

            case GET_BALANCE_NEX:
                {
                    const { address, fundsKey } = getKeysFromKeystoreWithFunds(
                        getState().profile.user.username,
                    );
                    services
                        .getWalletBalance(address, fundsKey)
                        .then((data) => {
                            const balance = fromGweiToEth(data);
                            dispatch(
                                actions.getBalanceNexResponse({
                                    address: address,
                                    balance: balance,
                                }),
                            );
                        })
                        .catch((err) => {
                            dispatch(actions.getBalanceNexError(err));
                        });
                }
                break;
        }
    };

const createValidateTransactionsBody = (transactions) => {
    return transactions.map((t) => ({ id: t.id, paciente_id: t.patient.id }));
};

const createRejectTransactionsBody = (transactions) => {
    return transactions.map((t) => ({ id: t.id }));
};

const createValidatePaymentsBody = (transactionsIds) => {
    return transactionsIds.map((tId) => ({ id: parseFloat(tId) }));
};

const createTrackPaymentsErrorsBody = (transactionsIds) => {
    return transactionsIds.map((tId) => ({ id: parseFloat(tId) }));
};

const createSendDataToSAPBody = (transactionsIds) => {
    return transactionsIds.map((tId) => ({ id: tId }));
};

const createGrantAddressesBody = (addresses) => {
    let osIds = [];
    let drugstoreIds = [];
    let directPaymentIds = [];

    addresses.map((address) => {
        //Si tiene pago directo (address.directPayment===true) mandar al back id: address.osId
        // Si tiene algo el campo address.osId mandar al back id: address.id
        // Si tiene algo el campo address.drugstoreId mandar al back id: address.id
        if (address.directPayment) {
            directPaymentIds.push({ os_id: address.osId, address: address.address });
        } else if (address.osId) osIds.push({ id: address.id });
        else drugstoreIds.push({ id: address.id });
    });

    return {
        os_address_ids: osIds,
        drogueria_address_ids: drugstoreIds,
        pago_directo_ids: directPaymentIds,
    };
};

const normalizePatientAuditor = (response, cse) => {
    let { paciente } = response;
    const audit_historial = response.audit_historial?.map((x) => {
        x.comentario_enc = decryptString(x.comentario_enc, cse);
        return x;
    });
    const patient = {
        ...response.paciente,
        afiliado: cse ? decryptString(paciente.afiliado_enc, cse) : '******',
        dni: cse ? decryptString(paciente.dni_enc, cse) : '******',
        nombre: cse ? decryptString(paciente.nombre_enc, cse) : '******',
        fecha_nacimiento: cse ? decryptString(paciente.fecha_nacimiento_enc, cse) : '******',
        cse: cse,
    };
    return {
        ...response,
        audit_historial: audit_historial,
        paciente: patient,
    };
};

const osRegisterNormalizer = (transaction, cse) => ({
    ...transaction,
    ...registerNormalizer(transaction, cse),
    audit_historial: transaction.audit_historial?.map((x) => {
        x.comentario_enc = decryptString(x.comentario_enc, cse);
        return x;
    }),
});
const registerNormalizer = (transaction, cse) => ({
    ...transaction,
    paciente: {
        id: transaction?.paciente?.id || '****',
        affiliate: cse ? decryptString(transaction.paciente.afiliado_enc, cse) : '******',
        dni: cse ? decryptString(transaction.paciente.dni_enc, cse) : '******',
        name: cse ? decryptString(transaction.paciente.nombre_enc, cse) : '******',
        cse: cse,
    },
});

const transactionNormalizer = (transaction, cse) => ({
    patient: transaction.paciente,
    patientId: transaction.paciente.id,
    os: transaction.obra_social.razon_social,
    product: transaction.denominacion,
    aplication: transaction.aplicacion,
    denomination: transaction.denominacion,
    id: transaction.id,
    dni: cse ? decryptString(transaction.paciente.dni_enc, cse) : '******',
    name: cse ? decryptString(transaction.paciente.nombre_enc, cse) : '******',
    affiliate: cse ? decryptString(transaction.paciente.afiliado_enc, cse) : '******',
    contract: transaction.contrato || { descripcion: '******' },
    dates: transaction.fechas,
    registrar: transaction?.registrado_por?.username,
    tokenAmount: transaction.importe_tokens,
    currency: transaction.moneda,
    paymentOrder: transaction.orden_pago,
    status: parseTransactionStatus(transaction.estado),
    contractPatientId: transaction.pacientecontrato_id,
    errors: transaction?.errores?.map((error) => ({
        errorCode: error.codigo_error,
        description: error.descripcion,
    })),
    updated_at: transaction.updated_at,
});

const decryptTransaction = (encryptedTransaction, myPrivateKey, normalizer) => {
    const csee = encryptedTransaction?.paciente?.csee;
    return decryptEntity(encryptedTransaction, myPrivateKey, csee, normalizer);
};

const mapRocheTransactions = (transactions, transactionType) => {
    if (transactionType && transactionType !== RocheTransactionDownloadType.ALL) {
        return transactions.map((transaction) => ({
            id: transaction.txid,
            rocheAddress: transaction.address_roche,
            os: transaction.razon_social,
            address: transaction.address,
            date: transaction.time,
            tokenAmount: transaction.qty,
        }));
    } else {
        return transactions.map((transaction) => ({
            id: transaction.id,
            os: transaction.obra_social.razon_social,
            contract: transaction.contrato || { descripcion: '' },
            dates: transaction.fechas,
            registrar: transaction.registrado_por.username,
            paymentOrder: transaction.orden_pago,
            tokenAmount: transaction.importe_tokens,
            errors: transaction.errores.map((error) => ({
                errorCode: error.codigo_error,
                description: error.descripcion,
            })),
            status: parseTransactionStatus(transaction.estado),
            product: transaction.denominacion || '',
            patientId: transaction.paciente?.id,
            osAddress: transaction.os_address,
            directPayment: transaction.obra_social?.pago_directo,
            updated_at: transaction.updated_at,
            fecha_estado_actual: transaction.fecha_estado_actual,
        }));
    }
};

const mapNexMonitorTransactions = (transactions) => {
    return (transactions.results || transactions || []).map((transaction) => ({
        id: transaction.id,
        tokenAmount: transaction.importe_tokens,
        origin: getNexMonitorTransactionLocation(
            transaction.origen,
            transaction.os_address,
            transaction.drogueria_address,
            transaction.roche_address,
        ),
        destination: getNexMonitorTransactionLocation(
            transaction.destino,
            transaction.os_address,
            transaction.drogueria_address,
            transaction.roche_address,
        ),
        timeReceived: transaction.timereceived,
        status: I18n.t('nexMonitor.table.status.' + transaction.estado),
        statusAux: transaction.estado,
    }));
};

const mapTransactionMonitorTransactions = (transactions) => {
    return (transactions.results || transactions || []).map((transaction) => ({
        guid: transaction.id,
        txnId: (transaction.registro_consumo || transaction.txn_token)?.id,
        type: transaction.tipo,
        entity: transaction.razon_social,
        detail: transaction.detalle,
        status: transaction.intfc_status,
        creationDt: transaction.created_at,
    }));
};

const getNexMonitorTransactionLocation = (location, osAddress, drugstoreAddress, rocheAddress) => {
    switch (location) {
        case NexTransactionsOriginsAndDestinations.OS:
            return osAddress?.obra_social?.razon_social;
        case NexTransactionsOriginsAndDestinations.DRUGSTORE:
            return drugstoreAddress?.drogueria?.razon_social;
        case NexTransactionsOriginsAndDestinations.ROCHE:
            return rocheAddress?.descripcion;
        default:
            return 'U';
    }
};

const addressValidate = (addAddress, fundsKey) => {
    return services.addressValidation(addAddress, fundsKey);
};

const mapGrantingMonitorAddresses = async (addresses, userName) => {
    const keyManager = new KeyManager(userName);
    const fundsKey = keyManager.deriveFundsKey();

    const mappedAddresses = [...addresses.os_addresses, ...addresses.drogueria_addresses]
        .map((address, index) => ({
            id: address.id,
            osId: address?.os_id,
            drugstoreId: address?.drogueria_id,
            businessName: address.razon_social,
            description: address.descripcion,
            address: address.address,
            granted: address.granted,
            status: address.granted ? 'granted' : 'notGranted',
            directPayment: address?.pago_directo,
            index: index,
        }))
        .filter(
            (address) =>
                (!address.directPayment && addressValidate(address.address, fundsKey)) ||
                address.directPayment,
        );

    return mappedAddresses;
};

const fromGweiToEth = (gwei) => {
    return Math.round(gwei / 1000000000000000) / 1000;
};

export default transactionMiddleware;
