import React, { useEffect, useState } from 'react';
import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import './OSDashboard.scss';
import { connect } from 'react-redux';
import DateRangePicker from '../../common/components/DateRangePicker';
import { useOSDashboardQuery } from '../../queries';
import Loader from '../../common/components/Loader';
import { format, parse } from 'date-fns';
import actions from '../../actions';
import { I18n } from 'react-redux-i18n';
import MigrationModal from '../components/MigrationModal/MigrationModal';

const numberToCurrency = (number: number) => {
    const newn = new Intl.NumberFormat('es-AR', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(number);
    return newn;
};

interface ProductSavings {
    flia_producto: string;
    tokens: number;
    pacientes: number;
}

interface MonthlyTokensSavings {
    mes: string;
    tokens: number;
}

export interface DashboardData {
    desde: string;
    hasta: string;

    pacientes_totales: number;
    contratos: number;
    tokens_totales_recibidos: number;
    ahorro_por_producto: ProductSavings[];
    ahorro_por_mes: MonthlyTokensSavings[];
}

interface DashboardProps {
    migrationStatus: any;
    userType: any;
    osMigrated: any;
    osId: any;
    migratePatients: () => void;
    logout: () => void;
}

const OSDashboard = (props: DashboardProps) => {
    const [defaultStartDate, setDefaultStartDate] = useState<string | null>(null);
    const [defaultEndDate, setDefaultEndDate] = useState<string | null>(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const { data: dashboardData, isLoading } = useOSDashboardQuery({
        startDate,
        endDate,
        defaultStartDate,
        defaultEndDate,
    });

    useEffect(() => {
        if (dashboardData?.desde) setDefaultStartDate(dashboardData.desde);
        if (dashboardData?.hasta) setDefaultEndDate(dashboardData.hasta);
    }, [dashboardData]);

    const getCardsData = () => {
        return [
            {
                title: I18n.t('dashboard.os.cards.totalPatients'),
                value: numberToCurrency(dashboardData?.pacientes_totales || 0),
            },
            {
                title: I18n.t('dashboard.os.cards.contracts'),
                value: numberToCurrency(dashboardData?.contratos || 0),
            },
            {
                title: I18n.t('dashboard.os.cards.nexTotalReceived'),
                value: numberToCurrency(dashboardData?.tokens_totales_recibidos || 0),
            },
        ];
    };

    return (
        <div className='os-dashboard'>
            <MigrationModal
                migrationStatus={props.migrationStatus}
                migratePatients={props.migratePatients}
                userType={props.userType}
                osMigrated={props.osMigrated}
                logout={props.logout}
            />
            <div className='cards-container'>
                {getCardsData().map((cardData, i) => (
                    <div key={`dashboard-card-${i}`} className='dashboard-card'>
                        <h3 className='title'>{cardData.title}</h3>
                        {isLoading ? <Loader /> : <h1 className='value'>{cardData.value}</h1>}
                    </div>
                ))}
            </div>
            <div className='graphs-container'>
                <div className='filters'>
                    <DateRangePicker
                        allTransactions={[]}
                        textToSearch=''
                        startDate={setStartDate}
                        endDate={setEndDate}
                        updateFilteredTransactionsByDateRange={() => null}
                        clearDateRangeSelection={() => {
                            if (!dashboardData) return;
                            setStartDate(
                                format(
                                    parse(dashboardData?.desde, 'MMyyyy', new Date()),
                                    'dd/MM/yyyy',
                                ),
                            );
                            setEndDate(
                                format(
                                    parse(dashboardData.hasta, 'MMyyyy', new Date()),
                                    'dd/MM/yyyy',
                                ),
                            );
                        }}
                        searchText={() => null}
                        setPage={() => null}
                        showMonthYearPicker
                        receiveDates={
                            dashboardData
                                ? {
                                      startDate: parse(dashboardData.desde, 'MMyyyy', new Date()),
                                      endDate: parse(dashboardData.hasta, 'MMyyyy', new Date()),
                                  }
                                : undefined
                        }
                        notShowReset
                        startDateLabel={I18n.t('dashboard.os.filters.from')}
                        endDateLabel={I18n.t('dashboard.os.filters.to')}
                    />
                </div>
                <div className='bottom-row'>
                    <div className='graph-and-title'>
                        <h3>{I18n.t('dashboard.os.graphics.savingsPerProductTitle')}</h3>
                        <TreeMapGraphic
                            data={dashboardData?.ahorro_por_producto || []}
                            loading={isLoading}
                        />
                    </div>
                    <div className='graph-and-title'>
                        <h3>{I18n.t('dashboard.os.graphics.savingsMonthlyTitle')}</h3>
                        <BarsGraphic
                            data={dashboardData?.ahorro_por_mes || []}
                            loading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const TreeMapGraphic = ({ data, loading }: { data: ProductSavings[]; loading: boolean }) => {
    const totalNEX = data.reduce((acc, productSaving) => acc + productSaving.tokens, 0);

    const calculatePercentage = (partialValue: number, totalValue: number) => {
        return Math.floor((partialValue / totalValue) * 100);
    };

    const getColorRanges = () => {
        const maxNEX = Math.max(...data.map((productSaving) => productSaving.tokens));
        const step = Math.ceil(maxNEX / 7);

        return [
            { from: 0, to: step, color: '#008e99' },
            { from: step + 1, to: step * 2, color: '#00808a' },
            { from: step * 2 + 1, to: step * 3, color: '#00727a' },
            { from: step * 3 + 1, to: step * 4, color: '#00636b' },
            { from: step * 4 + 1, to: step * 5, color: '#00555c' },
            { from: step * 5 + 1, to: step * 6, color: '#00474d' },
            { from: step * 6 + 1, to: maxNEX, color: '#00393d' },
        ];
    };

    const getNPatientsFromData = (index: number) => data[index].pacientes;

    const options: ApexOptions = {
        chart: {
            toolbar: { show: false },
            type: 'treemap',
            animations: { speed: 250 },
        },
        tooltip: {
            x: {
                formatter: (val) => {
                    const xData = val as unknown as string[];
                    return xData.join(', ');
                },
            },
            y: {
                formatter: (val, opts) => {
                    return `${getNPatientsFromData(opts.dataPointIndex)} Pacientes`;
                },
            },
        },
        series: [
            {
                data: data.map((productSaving) => ({
                    x: [
                        ` ${productSaving.flia_producto}`,
                        ` ${numberToCurrency(productSaving.tokens)} NEX`,
                        ` ${calculatePercentage(productSaving.tokens, totalNEX)}%`,
                        ` ${productSaving.pacientes} Pacientes`,
                    ],
                    y: productSaving.tokens,
                })),
            },
        ],
    };

    return (
        <ReactApexChart
            className='treemap-graphic'
            options={{
                chart: options.chart,
                noData: { text: loading ? 'Cargando...' : 'No hay datos' },
                plotOptions: { treemap: { colorScale: { ranges: getColorRanges() } } },
                states: { hover: { filter: { type: 'darken' } } },
                tooltip: options.tooltip,
            }}
            height={300}
            type='treemap'
            series={options.series}
        />
    );
};

const BarsGraphic = ({ data, loading }: { data: MonthlyTokensSavings[]; loading: boolean }) => {
    const options: ApexOptions = {
        chart: {
            toolbar: { show: false },
            type: 'bar',
            animations: { speed: 250 },
        },
        noData: { text: loading ? 'Cargando...' : 'No hay datos' },
        plotOptions: {
            bar: {
                horizontal: false,
                dataLabels: { position: 'bottom' },
            },
        },
        tooltip: {
            y: {
                formatter: (val) => `${numberToCurrency(val)} NEX`,
                title: { formatter: () => '' },
            },
        },
        yaxis: { labels: { formatter: (value) => numberToCurrency(value) } },
        dataLabels: { enabled: false },
        series: [
            {
                data: data.map((monthlyTokensSaving) => ({
                    x: monthlyTokensSaving.mes,
                    y: monthlyTokensSaving.tokens,
                })),
                color: '#008E99',
            },
        ],
    };

    return (
        <ReactApexChart
            className='bar-graphic'
            options={{
                chart: options.chart,
                plotOptions: options.plotOptions,
                dataLabels: options.dataLabels,
                states: { hover: { filter: { type: 'darken' } } },
                tooltip: options.tooltip,
                yaxis: options.yaxis,
            }}
            height={300}
            type='bar'
            series={options.series}
        />
    );
};

const mapStateToProps = (state: any) => ({
    migrationStatus: state.patient.patientMigrationStatus,
    userType: state.profile.user.type,
    osMigrated: state.session.migrated,
    osId: state.session.os_id,
});

const mapDispatchToProps = (dispatch: any) => ({
    migratePatients: () => dispatch(actions.patient.patientMigration()),
    logout: () => dispatch(actions.session.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OSDashboard);
