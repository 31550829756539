import {
    GET_PATIENTS,
    GET_PATIENTS_ERROR,
    GET_PATIENTS_RESPONSE,
    PATIENT_MIGRATION,
    PATIENT_MIGRATION_RESPONSE,
    PATIENT_MIGRATION_ERROR,
    UPDATE_PATIENT_FILTER_VALUES,
    CLEAR_PATIENT_FILTERS,
    UPDATE_PATIENTS_PAGE,
    UPDATE_PATIENTS_PAGE_SIZE,
    CREATE_NEW_PATIENT,
    CREATE_NEW_PATIENT_ERROR,
    CREATE_NEW_PATIENT_RESPONSE,
    SET_PATIENT_DETAILS,
    CLEAR_PATIENT_DETAILS,
    EDIT_PATIENT,
    EDIT_PATIENT_ERROR,
    EDIT_PATIENT_RESPONSE,
    SEARCH_PATIENTS,
    GET_PROGRAMED_DOSE,
    GET_PROGRAMED_DOSE_ERROR,
    GET_PROGRAMED_DOSE_RESPONSE,
    CLEAR_PROGRAMMED_DOSES,
    EDIT_PROGRAMMED_DOSES,
    GET_AUDITOR_PROGRAMED_DOSE,
    GET_AUDITOR_PROGRAMED_DOSE_ERROR,
    GET_AUDITOR_PROGRAMED_DOSE_RESPONSE,
    GET_BASIC_PATIENTS_RESPONSE,
    GET_BASIC_PATIENTS_ERROR,
    GET_BASIC_PATIENTS,
    EDIT_PATIENT_WITH_ROLLBACK,
    EDIT_PATIENT_WITH_ROLLBACK_RESPONSE,
    EDIT_PATIENT_WITH_ROLLBACK_ERROR,
} from './patient.actions';
import { REHYDRATE } from 'redux-persist';
import { LOGOUT } from '../session/session.actions';
import {
    AND_OPERATOR,
    OR_OPERATOR,
    parseConditionalStatement,
} from '../utils/conditionalStatementParser';

const initialState = {
    results: [],
    filteredResults: [],
    count: 0, // table rows (all patients)
    current: 1, // actual page
    total_pages: 1,
    page_size: 10,
    customFilter: {
        ordering: '',
        search: '',
        status: '',
    },
    // Data used for patient edition
    editionDetails: {},
    programedDoses: [],
    // UI related
    getPatientsStatus: { error: false, loading: false, success: false, message: '' },
    editPatientStatus: { error: false, loading: false, success: false, message: '' },
    editPatientWithRollbackStatus: { error: false, loading: false, success: false, message: '' },
    programmedDosesStatus: { error: false, loading: false, success: false, message: '' },
    patientMigrationStatus: { error: false, inProgress: false, completed: false },
};

const filterPatient = (patient, search, filter) => {
    const searchArray = parseConditionalStatement(search.toString().toLowerCase());
    return iterateArray(patient, searchArray, filter);
};

const iterateArray = (patient, searchArray, filter) => {
    if (Array.isArray(searchArray)) {
        if (searchArray[1] === AND_OPERATOR) {
            return (
                iterateArray(patient, searchArray[0], filter) &&
                iterateArray(patient, searchArray[2], filter)
            );
        } else if (searchArray[1] === OR_OPERATOR) {
            return (
                iterateArray(patient, searchArray[0], filter) ||
                iterateArray(patient, searchArray[2], filter)
            );
        }
    } else if (searchArray !== undefined) {
        return filterTransactionAux(patient, searchArray.toString().trim(), filter);
    }
};

const filterTransactionAux = (patient, search, filter) =>
    (patient.dni.toLowerCase().indexOf(search) > -1 ||
        patient.name.toLowerCase().indexOf(search) > -1 ||
        patient.affiliate.toLowerCase().indexOf(search) > -1 ||
        patient.contracts?.some(
            (contract) => contract.descripcion?.toLowerCase().indexOf(search) > -1,
        )) &&
    applyFilter(patient, filter);

const applyFilter = (patient, filter) => {
    if (filter.status) return filter.status.includes(patient.status);
    return false;
};

const compare_id = (a, b) => {
    if (a.id > b.id) {
        return -1;
    }
    if (a.id < b.id) {
        return 1;
    }
    return 0;
};

const patientReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PATIENTS:
            return {
                ...state,
                getPatientsStatus: { error: false, loading: true, success: false, message: '' },
            };
        case GET_PATIENTS_RESPONSE: {
            const patientsWithNotifications =
                action.response?.results
                    ?.sort(compare_id)
                    ?.filter(
                        (pat) =>
                            pat.contracts?.some(
                                (x) => x.evidencias_notificar || x.dosis_notificar >= 1,
                            ) && pat.status !== 'inactive',
                    ) ?? [];
            const otherPatients =
                action.response?.results
                    ?.sort(compare_id)
                    ?.filter(
                        (pat) =>
                            !(
                                pat.contracts?.some(
                                    (x) => x.evidencias_notificar || x.dosis_notificar >= 1,
                                ) && pat.status !== 'inactive'
                            ),
                    ) ?? [];
            const patients = patientsWithNotifications?.concat(otherPatients);

            return {
                ...state,
                results: patients,
                filteredResults:
                    state.customFilter.status !== ''
                        ? patients.filter((patient) => applyFilter(patient, state.customFilter))
                        : patients,
                getPatientsStatus: { error: false, loading: false, success: true, message: '' },
            };
        }
        case PATIENT_MIGRATION:
            return {
                ...state,
                patientMigrationStatus: { completed: false, inProgress: true, error: false },
            };
        case PATIENT_MIGRATION_RESPONSE:
            return {
                ...state,
                patientMigrationStatus: { completed: true, inProgress: false, error: false },
            };
        case PATIENT_MIGRATION_ERROR:
            return {
                ...state,
                patientMigrationStatus: {
                    completed: false,
                    inProgress: false,
                    error: true,
                    errorMessage: action.error,
                },
            };
        case SEARCH_PATIENTS:
            return {
                ...state,
                filteredResults: action.search
                    ? state.results.filter((patient) =>
                          filterPatient(patient, action.search, state.customFilter),
                      )
                    : state.results.filter((patient) => applyFilter(patient, state.customFilter)),
            };
        case GET_PATIENTS_ERROR:
            return {
                ...state,
                getPatientsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    message: action.error,
                },
            };
        case UPDATE_PATIENT_FILTER_VALUES:
            return {
                ...state,
                customFilter: { ...state.customFilter, ...action.filter },
                filteredResults: state.results.filter((patient) =>
                    applyFilter(patient, action.filter),
                ),
            };
        case CLEAR_PATIENT_FILTERS:
            return { ...state, customFilter: initialState.customFilter };
        case UPDATE_PATIENTS_PAGE:
            return { ...state, current: action.pageNumber };
        case UPDATE_PATIENTS_PAGE_SIZE:
            return { ...state, page_size: action.page_size };
        case CREATE_NEW_PATIENT:
        case CREATE_NEW_PATIENT_RESPONSE:
        case CREATE_NEW_PATIENT_ERROR:
            return {
                ...state,
                editPatientStatus: {
                    success: action.type === CREATE_NEW_PATIENT_RESPONSE,
                    loading: action.type === CREATE_NEW_PATIENT,
                    error: action.type === CREATE_NEW_PATIENT_ERROR,
                    message: action.type === CREATE_NEW_PATIENT_ERROR && action.error,
                },
            };
        case EDIT_PATIENT:
            return {
                ...state,
                editPatientStatus: { error: false, loading: true, success: false, message: '' },
            };
        case EDIT_PATIENT_RESPONSE:
            return {
                ...state,
                editPatientStatus: { error: false, loading: false, success: true, message: '' },
            };
        case EDIT_PATIENT_ERROR:
            return {
                ...state,
                editPatientStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    message: action.error,
                },
            };
        case EDIT_PATIENT_WITH_ROLLBACK:
        case EDIT_PATIENT_WITH_ROLLBACK_ERROR:
        case EDIT_PATIENT_WITH_ROLLBACK_RESPONSE: {
            const isError = action.type === EDIT_PATIENT_WITH_ROLLBACK_ERROR;
            return {
                ...state,
                editPatientWithRollbackStatus: {
                    error: isError,
                    loading: action.type === EDIT_PATIENT_WITH_ROLLBACK,
                    success: action.type === EDIT_PATIENT_WITH_ROLLBACK_RESPONSE,
                    message: isError ? action.error : '',
                },
            };
        }
        case SET_PATIENT_DETAILS:
            if (action.update)
                return { ...state, editionDetails: { ...state.editionDetails, ...action.patient } };
            return { ...state, editionDetails: action.patient };
        case CLEAR_PATIENT_DETAILS:
            return {
                ...state,
                editionDetails: {},
                getPatientsStatus: initialState.getPatientsStatus,
                createPatientStatus: initialState.createPatientStatus,
                editPatientStatus: initialState.editPatientStatus,
            };
        case GET_PROGRAMED_DOSE:
        case GET_PROGRAMED_DOSE_ERROR:
        case GET_PROGRAMED_DOSE_RESPONSE:
            return {
                ...state,
                programmedDosesStatus: {
                    success: action.type === GET_PROGRAMED_DOSE_RESPONSE,
                    loading: action.type === GET_PROGRAMED_DOSE,
                    message: action.type === GET_PROGRAMED_DOSE_ERROR && action.error,
                    error: action.type === GET_PROGRAMED_DOSE_ERROR,
                },
                programedDoses: action.type === GET_PROGRAMED_DOSE_RESPONSE ? action.response : [],
            };
        case GET_AUDITOR_PROGRAMED_DOSE:
        case GET_AUDITOR_PROGRAMED_DOSE_ERROR:
        case GET_AUDITOR_PROGRAMED_DOSE_RESPONSE:
            return {
                ...state,
                programmedDosesStatus: {
                    success: action.type === GET_AUDITOR_PROGRAMED_DOSE_RESPONSE,
                    loading: action.type === GET_AUDITOR_PROGRAMED_DOSE,
                    message: action.type === GET_AUDITOR_PROGRAMED_DOSE_ERROR && action.error,
                    error: action.type === GET_AUDITOR_PROGRAMED_DOSE_ERROR,
                },
                programedDoses:
                    action.type === GET_AUDITOR_PROGRAMED_DOSE_RESPONSE ? action.response : [],
            };
        case GET_BASIC_PATIENTS:
        case GET_BASIC_PATIENTS_ERROR:
        case GET_BASIC_PATIENTS_RESPONSE:
            return {
                ...state,
                basicPatientsStatus: {
                    success: action.type === GET_BASIC_PATIENTS_RESPONSE,
                    loading: action.type === GET_BASIC_PATIENTS,
                    message: action.type === GET_BASIC_PATIENTS_ERROR && action.error,
                    error: action.type === GET_BASIC_PATIENTS_ERROR,
                },
                basicPatients: action.type === GET_BASIC_PATIENTS_RESPONSE ? action.response : [],
            };
        case CLEAR_PROGRAMMED_DOSES:
            return {
                ...state,
                programedDoses: initialState.programedDoses,
                programmedDosesStatus: initialState.programmedDosesStatus,
            };
        case EDIT_PROGRAMMED_DOSES:
            return {
                ...state,
                programedDoses: action.doses,
            };
        case LOGOUT:
            return { ...initialState };
        case REHYDRATE: {
            let incoming = action.payload;
            if (incoming) {
                return {
                    ...incoming.patient,
                    getPatientsStatus: initialState.getPatientsStatus,
                    createPatientStatus: initialState.createPatientStatus,
                    editPatientStatus: initialState.editPatientStatus,
                };
            } else {
                return state;
            }
        }
        default:
            return {
                ...state,
                editPatientStatus: { error: false, loading: false, success: false, message: '' },
                editPatientWithRollbackStatus: {
                    error: false,
                    loading: false,
                    success: false,
                    message: '',
                },
            };
    }
};

export default patientReducer;
