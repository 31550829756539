import React from 'react';
import './ImportPrivateKeyScreen.scss';
import ImportPrivateKeyWizard from '../ImportPrivateKeyWizard';

const ImportPrivateKeyScreen = (props) => {
    return (
        <div className='import-private-key-screen'>
            <ImportPrivateKeyWizard
                savePrivateKey={props.savePrivateKey}
                saveOldPrivateKey={props.saveOldPrivateKey}
                validatePrivateKey={props.validatePrivateKey}
                validateOldPrivateKey={props.validateOldPrivateKey}
                validatePrivateKeyStatus={props.validatePrivateKeyStatus}
                userType={props.userType}
                sendPrivateKeyByEmail={props.sendPrivateKeyByEmail}
                encKey={props.encKey}
                pkEmail={props.pkEmail}
                username={props.username}
            />
        </div>
    );
};

export default ImportPrivateKeyScreen;
