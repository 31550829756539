import { I18n } from 'react-redux-i18n';
import Input from '../../../common/components/Input/Input';
import Button from '../../../common/components/Button/Button';
import './AddNewScreen.scss';
import IconAdd from '../../../common/components/Icon/Add';
import RoundIconContainer from '../common/RoundIconContainer/RoundIconContainer';
import React, { useEffect, useState } from 'react';
import PasteAddressInput from '../common/PasteAddress/PasteAddressInput';
import { headIconParams, inputIconParams } from './constants';
import UserIcon from '../../../common/components/Icon/UserIcon';
import WalletScreenWrapper from '../common/WalletScreen/WalletScreenWrapper';
import { useHistory } from 'react-router-dom';
import { StatusModal } from '../../../common/components/Modal/Modal';

const AddContactScreen = (props) => {
    const [contact, setContact] = useState({});
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();

    useEffect(() => {
        props.contact && setContact(props.contact);
    }, [props.contact]);

    return (
        <>
            <WalletScreenWrapper
                to='shippingAddress'
                className='add-new-card'
                classHead='add-new-card-head'
                classBody='add-new-card-body'
                titleCard={I18n.t('wallet.addNew.title')}
            >
                <div className='add-new-card-icon-container'>
                    <RoundIconContainer color='light-green'>
                        <UserIcon
                            width={headIconParams.width}
                            height={headIconParams.height}
                            color1={headIconParams.color1}
                            color2={headIconParams.color2}
                        />
                    </RoundIconContainer>
                </div>

                <Input
                    label={I18n.t('wallet.addNew.name')}
                    className='add-new-input'
                    labelClassName='add-new-input-label'
                    inputClassname='add-new-pill'
                    labelIcon={
                        <IconAdd
                            width={inputIconParams.width}
                            height={inputIconParams.height}
                            color={inputIconParams.color}
                        />
                    }
                    value={contact.nombre}
                    handleChange={(e) => setContact({ ...contact, nombre: e })}
                />

                <Input
                    label={I18n.t('wallet.addNew.email')}
                    className='add-new-input'
                    labelClassName='add-new-input-label'
                    inputClassname='add-new-pill'
                    labelIcon={
                        <IconAdd
                            width={inputIconParams.width}
                            height={inputIconParams.height}
                            color={inputIconParams.color}
                        />
                    }
                    value={contact.email}
                    handleChange={(e) => setContact({ ...contact, email: e })}
                />

                <div style={{ width: '45%' }}>
                    <PasteAddressInput
                        label={I18n.t('wallet.addNew.address')}
                        labelClassName='add-new-input-label'
                        className='add-contact'
                        labelIcon={
                            <IconAdd
                                width={inputIconParams.width}
                                height={inputIconParams.height}
                                color={inputIconParams.color}
                            />
                        }
                        value={contact.address}
                        handleChange={(e) => setContact({ ...contact, address: e })}
                        address={contact.address}
                    />
                </div>

                <div className='add-new-card-buttons-container'>
                    <Button
                        style={{ marginRight: '10px' }}
                        className='small margin-right decline'
                        text={I18n.t('wallet.addNew.cancel')}
                        onClick={() => history.push('shippingAddress')}
                    />
                    <Button
                        className=' small primary with-gradient'
                        text={I18n.t('wallet.addNew.save')}
                        onClick={() => {
                            setShowModal(true);
                            contact.id ? props.editContact(contact) : props.addContact(contact);
                        }}
                    />
                </div>
            </WalletScreenWrapper>
            <StatusModal
                close={() => {
                    setShowModal(false);
                }}
                show={showModal}
                status={props.addContactStatus}
                successPathname='shippingAddress'
            />
        </>
    );
};
export default AddContactScreen;
