import Card from '../../../../common/components/Card/Card';
import React from 'react';
import './WalletScreenWrapper.scss';
import SuccessBox from '../../../../common/components/SuccesBox/SuccessBox';
import BackLink from '../../../../common/components/BackLink/BackLink';

const WalletScreenWrapper = ({
    to,
    titleCard,
    className,
    classHead,
    classBody,
    children,
    textNotification,
    showNotification,
    classnameNotication,
    titleBack,
}) => {
    return (
        <div className='wallet-screen-container'>
            <BackLink to={to} className='back-link' title={titleBack} />
            <Card
                className={`wallet-card-container ${className}`}
                classHead={classHead}
                classBody={classBody}
                title={titleCard}
            >
                {children}
            </Card>
            <div className='wrapper-notification'>
                <div className='container-notification'>
                    <SuccessBox
                        text={textNotification}
                        show={showNotification}
                        className={classnameNotication}
                    />
                </div>
            </div>
        </div>
    );
};

export default WalletScreenWrapper;
