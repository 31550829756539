import React, { useState } from 'react';
import { connect } from 'react-redux';
import './RocheMonitorScreen.scss';
import { I18n } from 'react-redux-i18n';
import SearchBar from '../../common/components/SearchBar';
import DateRangePicker from '../../common/components/DateRangePicker';
import SelectMultiple from '../../common/components/SelectMultiple';
import Button from '../../common/components/Button';
import Table from '../../common/components/Table';
import { PaginationProps } from '../../common/components/Table/Table';
import { MonitorTransaction, useRocheGetMonitorTransactions } from '../../queries';
import { parseDate, parseToCsvAndDownload } from '../../utils';
import { Column } from 'react-table';

const auditStates = [
    { value: 'P', label: 'auditPending' },
    { value: 'R', label: 'auditRejected' },
    { value: 'A', label: 'auditApproved' },
    { value: 'C', label: 'auditConsumptionRejected' },
    { value: 'B', label: 'cleanedEvidence' },
    { value: 'E', label: 'endSuccessfulTreatment' },
    { value: 'F', label: 'endTreatmentProgression' },
    { value: '#', label: 'noAuditor' },
];

interface StatusFilter {
    id: string;
    checked: boolean;
}

const defaultStatusSelected: StatusFilter[] = [
    { id: 'P', checked: true },
    { id: 'R', checked: true },
    { id: 'A', checked: false },
    { id: 'C', checked: true },
    { id: 'B', checked: true },
    { id: 'E', checked: true },
    { id: 'F', checked: true },
];

interface RocheMonitorScreenProps {
    downloadAuditorTransactions: () => void;
}

interface StateParams {
    statusFilter: StatusFilter[];
    searchText: string;
    startDate: string;
    endDate: string;
    order: string;
}

const initialStateParams: StateParams = {
    statusFilter: defaultStatusSelected,
    searchText: '',
    startDate: '',
    endDate: '',
    order: '',
};

const RocheMonitorScreen = (props: RocheMonitorScreenProps) => {
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState<string | null>(null);
    const [stateParams, setStateParams] = useState(initialStateParams);
    const [searchDate, setSearchDate] = useState('');

    const { data: transactions, isLoading } = useRocheGetMonitorTransactions();

    const filteredTransactions = (transactions || [])
        .filter((transaction) => {
            const includedStatusFilter = stateParams.statusFilter.some(
                (statusFilter) => statusFilter.id === transaction.estado,
            );
            let includedSearchText = true;
            if (stateParams.searchText) {
                const _s = (s: any) =>
                    s.toString().toLowerCase().includes(stateParams.searchText.toLowerCase());
                const includedSearchText_Id = _s(transaction.id);
                const includedSearchText__auditorGroup = _s(transaction.grupo_auditor_descr);
                const includedSearchText__os = _s(transaction.obra_social.razon_social);
                const includedSearchText__patientId = _s(transaction.paciente.id);
                const includedSearchText__productFamily = _s(transaction.flia_producto);
                const includedSearchText__incident = _s(transaction.descripcion);
                includedSearchText =
                    includedSearchText_Id ||
                    includedSearchText__auditorGroup ||
                    includedSearchText__os ||
                    includedSearchText__patientId ||
                    includedSearchText__productFamily ||
                    includedSearchText__incident;
            }
            const includedDateRange =
                (!stateParams.startDate ||
                    new Date(transaction.updated_at) >= new Date(stateParams.startDate)) &&
                (!stateParams.endDate ||
                    new Date(transaction.updated_at) <= new Date(stateParams.endDate));
            const includedStatusFilterChecked = stateParams.statusFilter
                .filter((statusFilter) => statusFilter.checked)
                .some((statusFilter) => statusFilter.id === transaction.estado);
            return (
                includedStatusFilter &&
                includedSearchText &&
                includedDateRange &&
                includedStatusFilterChecked
            );
        })
        .sort((t1, t2) => {
            if (!sort) return 0;
            const desc = sort[0] === '-';
            const sortValue = desc ? sort.slice(1) : sort;
            let result = 0;
            switch (sortValue) {
                case 'numero':
                    result = t1.id - t2.id;
                    break;
                case 'auditor-group':
                    if (!t1.grupo_auditor_descr || !t2.grupo_auditor_descr) return 0;
                    result = t1.grupo_auditor_descr.localeCompare(t2.grupo_auditor_descr);
                    break;
                case 'os':
                    result = t1.obra_social.razon_social.localeCompare(t2.obra_social.razon_social);
                    break;
                case 'patient-id':
                    result = t1.paciente.id - t2.paciente.id;
                    break;
                case 'product-family':
                    result = t1.flia_producto.localeCompare(t2.flia_producto);
                    break;
                case 'incident':
                    result = t1.descripcion.localeCompare(t2.descripcion);
                    break;
                case 'date':
                    result = new Date(t1.updated_at).getTime() - new Date(t2.updated_at).getTime();
                    break;
                case 'estado':
                    result = t1.estado.localeCompare(t2.estado);
                    break;
                default:
                    break;
            }
            if (desc) return -result;
            return result;
        });
    const paginatedTransactions = filteredTransactions.slice(page * 10, page * 10 + 10);

    const loading = isLoading;

    const pagination: PaginationProps = {
        page,
        total: filteredTransactions.length,
        totalPages: Math.floor(filteredTransactions.length / 10),
        onPageChange: setPage,
        pagedMessageTranslation: 'bonifications.table.pagedMessage',
        pagedMessageEmptyTranslation: 'bonifications.table.pagedMessageEmpty',
    };

    const handleStatusFilterChange = (statusId: string) => {
        const otherStatus = stateParams.statusFilter.filter((status) => status.id !== statusId);
        const selectedStatus = stateParams.statusFilter.filter(
            (status) => status.id === statusId,
        )[0];
        setStateParams({
            ...stateParams,
            statusFilter: [...otherStatus, { id: statusId, checked: !selectedStatus?.checked }],
        });
        setPage(0);
    };

    const handleSearchChange = (value: string) => {
        setStateParams({ ...stateParams, searchText: value });
    };

    const handleSort = (id: string) => {
        if (!sort) {
            setSort(id);
            return;
        }
        const isDesc = sort[0] === '-';
        const descCurrent = isDesc && sort.slice(1) === id;
        const ascCurrent = !isDesc && sort === id;
        if (ascCurrent) setSort(`-${id}`);
        else if (descCurrent) setSort(null);
        else setSort(id);
        setPage(0);
    };

    const handleDownload = () => {
        parseToCsvAndDownload(
            filteredTransactions.map((t) => {
                const foundState = auditStates.find((state) => state.value === t.estado)?.label;
                return {
                    numero: t.id,
                    grupo_auditor: t.grupo_auditor_descr,
                    obra_social: t.obra_social.razon_social,
                    paciente_id: t.paciente.id,
                    flia_producto: t.flia_producto,
                    fecha: t.updated_at,
                    incidente: t.descripcion,
                    estado: foundState ? I18n.t(`transaction.table.status.${foundState}`) : '',
                };
            }),
            'Monitor auditorías.csv',
            [
                'Nro',
                'Grupo Auditor',
                'Obra Social',
                'Id Paciente',
                'Flia de producto',
                'Fecha',
                'Incidente',
                'Estado',
            ],
        );
    };

    const formatHeader = (text: string, id: string) => {
        return (
            <div className='nexus-header-arrows space-between' onClick={() => handleSort(id)}>
                <h5>{text}</h5>
                {sort && (
                    <div className='arrows'>
                        {sort === id && <span>▲</span>}
                        {sort === `-${id}` && <span>▼</span>}
                    </div>
                )}
            </div>
        );
    };

    const getColumns = (): Column[] => [
        {
            id: 'numero',
            Header: () => formatHeader(I18n.t('rocheAuditorMonitor.table.header.number'), 'numero'),
            accessor: (d: MonitorTransaction) => d.id,
            Cell: (props: any) => (
                <div className='numbers'>
                    {props.row.estado === 'P' && <span className='red-dot-number'>{'\u2022'}</span>}
                    <span className='text transactions-table-row'>{props.value}</span>
                </div>
            ),
            headerClassName: 'nexus-header center',
            className: 'nexus-cell center',
            style: { whiteSpace: 'unset' },
            width: 100,
        },
        {
            id: 'auditor-group',
            Header: () =>
                formatHeader(
                    I18n.t('rocheAuditorMonitor.table.header.auditorGroup'),
                    'auditor-group',
                ),
            accessor: (d: MonitorTransaction) => d?.grupo_auditor_descr,
            Cell: (props: any) => (
                <span className='text transactions-table-row'>{props.value}</span>
            ),
            headerClassName: 'nexus-header space-between',
            className: 'nexus-cell center',
            style: { whiteSpace: 'unset' },
        },
        {
            id: 'os',
            Header: () => formatHeader(I18n.t('rocheAuditorMonitor.table.header.os'), 'os'),
            accessor: (d: MonitorTransaction) => d?.obra_social.razon_social,
            Cell: (props: any) => (
                <span className='text transactions-table-row'>{props.value}</span>
            ),
            headerClassName: 'nexus-header center',
            className: 'nexus-cell center',
            style: { whiteSpace: 'unset' },
            width: 150,
        },
        {
            id: 'patient-id',
            Header: () =>
                formatHeader(I18n.t('rocheAuditorMonitor.table.header.patientId'), 'patient-id'),
            accessor: (d: MonitorTransaction) => d.paciente.id,
            Cell: (props: any) => (
                <span className='text transactions-table-row'>{props.value}</span>
            ),
            headerClassName: 'nexus-header center',
            className: 'nexus-cell center',
            style: { whiteSpace: 'unset' },
        },
        {
            id: 'product-family',
            Header: () =>
                formatHeader(
                    I18n.t('rocheAuditorMonitor.table.header.productFamily'),
                    'product-family',
                ),
            accessor: (d: MonitorTransaction) => d.flia_producto,
            Cell: (props: any) => (
                <span className='text transactions-table-row'>{props.value}</span>
            ),
            headerClassName: 'nexus-header center',
            className: 'nexus-cell center',
            style: { whiteSpace: 'unset' },
        },
        {
            id: 'date',
            Header: () => formatHeader(I18n.t('rocheAuditorMonitor.table.header.date'), 'date'),
            accessor: (d: MonitorTransaction) => d.updated_at,
            Cell: (props: any) => (
                <span className='text transactions-table-row'>
                    {parseDate(new Date(props.value))}
                </span>
            ),
            headerClassName: 'nexus-header center',
            className: 'nexus-cell center',
            style: { whiteSpace: 'unset' },
        },
        {
            id: 'incident',
            Header: () =>
                formatHeader(I18n.t('rocheAuditorMonitor.table.header.incident'), 'incident'),
            accessor: (d: MonitorTransaction) => d.descripcion,
            headerClassName: 'nexus-header center',
            className: 'nexus-cell center',
            style: { whiteSpace: 'unset' },
        },
        {
            id: 'estado',
            Header: () => formatHeader(I18n.t('rocheAuditorMonitor.table.header.status'), 'estado'),
            accessor: (d: MonitorTransaction) => d.estado,
            Cell: (props: any) => (
                <span
                    className={`transactions-table-row ${
                        auditStates.find((state) => state.value === props.value)?.label
                    }`}
                >
                    {I18n.t(
                        `transaction.table.status.${
                            auditStates.find((state) => state.value === props.value)?.label
                        }`,
                    )}
                </span>
            ),
            headerClassName: 'nexus-header center',
            className: 'nexus-cell center',
            style: { whiteSpace: 'unset' },
            width: 200,
        },
    ];

    return (
        <div className='auditor-monitor-screen'>
            <div className='header'>
                <span className='title'>{I18n.t('rocheAuditorMonitor.title')}</span>
                <SearchBar textToSearch={stateParams.searchText} onSearch={handleSearchChange} />
                <DateRangePicker
                    textToSearch={searchDate}
                    allTransactions={filteredTransactions}
                    searchText={setSearchDate}
                    updateFilteredTransactionsByDateRange={() => {}}
                    clearDateRangeSelection={() => {
                        setStateParams({ ...stateParams, startDate: '', endDate: '' });
                        setPage(0);
                    }}
                    startDate={(value) => {
                        setStateParams({ ...stateParams, startDate: value });
                        setPage(0);
                    }}
                    endDate={(value) => {
                        setStateParams({ ...stateParams, endDate: value });
                        setPage(0);
                    }}
                    setPage={() => null}
                />
                <SelectMultiple
                    placeholder={I18n.t('bonifications.table.headers.status')}
                    values={stateParams.statusFilter}
                    className='rounded'
                    onChange={handleStatusFilterChange}
                    options={[
                        { value: 'P', label: I18n.t('transaction.table.status.auditPending') },
                        { value: 'A', label: I18n.t('transaction.table.status.auditApproved') },
                        { value: 'R', label: I18n.t('transaction.table.status.auditRejected') },
                        {
                            value: 'C',
                            label: I18n.t('transaction.table.status.auditConsumptionRejected'),
                        },
                        { value: 'B', label: I18n.t('transaction.table.status.cleanedEvidence') },
                        {
                            value: 'E',
                            label: I18n.t('transaction.table.status.endSuccessfulTreatment'),
                        },
                        {
                            value: 'F',
                            label: I18n.t('transaction.table.status.endTreatmentProgression'),
                        },
                    ]}
                />
                <Button
                    type='button'
                    className='primary icon-size download-button'
                    onClick={handleDownload}
                    loading={false}
                    icon={<i className='icon-cloud-download' />}
                />
            </div>
            <Table
                data={paginatedTransactions}
                columns={getColumns()}
                pagination={pagination}
                loading={loading}
                noDataText={I18n.t('bonifications.table.noRows')}
                sortable
            />
        </div>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RocheMonitorScreen);
