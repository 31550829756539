import React, { useEffect, useState } from 'react';
import Card from '../../../common/components/Card/Card';
import Input from '../../../common/components/Input/Input';
import CustomSelect from '../../../common/components/CustomSelect/CustomSelect';
import './Contract.scss';
import { EvidencesTable } from '../../../indication/components/IndicationsScreen/IndicationsScreen';
import BenefitsTable from './Bennefits';
import Checkbox from '../../../common/components/Checkbox';
import Button from '../../../common/components/Button';
import add_contract from '../../../assets/icons/plus_ic.svg';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { dateStr, getDate } from '../../../utils/date';
import { zeroPad } from '../../../utils/various';
import { connect } from 'react-redux';
import actions from '../../../actions';

const addContract = <img src={add_contract} alt='Add contract' />;

const riskSharingCalculationTypes = [
    { value: 'U', label: 'Formula del ultimo ciclo/rango' },
    { value: 'C', label: 'Formula de cada ciclo/rango' },
];
const operatorCalculateBen = [
    { value: 'S', label: 'Suma' },
    { value: 'M', label: 'Multiplicación' },
];

const Version = ({
    version,
    updVersion,
    indication,

    product_families,
    auditorGroups,
    showErrors,
    evidenceTypes,
    isSur,
    // lastVersion,
    typeContractSelected,
}) => {
    if (!version) return null;

    const {
        // version: c_version,
        estado_version,

        vigencia_desde,
        fin_incorp_pacientes,
        vigencia_hasta,
        max_pacientes,
        cant_pacientes_activos,
        estado, // what is this? estado_version?

        // frecuencia_control,
        tolerancia_periodo,
        tolerancia_dosis,

        // tipo_calculo_rc,
        grupo_auditor,
        compartir_nya, //
        compartir_dni, //
        compartir_afiliado, //
    } = version ?? {};

    const updVersionAtt = (obj) => updVersion({ ...version, ...obj });

    const updVerAttDate = (obj) => {
        Object.keys(obj).map((key) => {
            obj[key] = obj[key] ? dateStr(obj[key]) : undefined;
        });
        updVersionAtt(obj);
    };

    const addEvidence = () => {
        if (readOnly) return;
        const evidences = version.contrato_v_evidencia ?? [];
        const newEvidence = {
            _id:
                evidences?.reduce((prev, current) => (prev._id > current._id ? prev : current), {
                    _id: 0,
                })._id + 1,
            numero:
                evidences?.reduce(
                    (prev, current) => (prev.numero > current.numero ? prev : current),
                    { numero: 0 },
                ).numero + 1,
        };
        updVersionAtt({ contrato_v_evidencia: [...evidences, newEvidence] });
    };

    // Function que genera un id, del tipo string, random con muy pocas chances de repetirse
    const uniqueIdGenerator = () => ((Math.random() * 100) % 1).toString().slice(2);

    const addBenefits = (b) => {
        const benefits = version.contrato_v_beneficio ?? [];
        // get biggest aplicacion_hasta from benefits
        const bah =
            benefits?.reduce(
                (prev, current) =>
                    prev.aplicacion_hasta > current.aplicacion_hasta ? prev : current,
                { aplicacion_hasta: 0 },
            ).aplicacion_hasta + 1;

        const { aplicacion_hasta } = benefits[benefits?.length - 1] ?? { aplicacion_hasta: 0 };
        const prod = product_families
            ?.find((pf) => pf.id === b)
            ?.products?.map((p) => ({
                ...p,
                _id: uniqueIdGenerator(),
                producto: p.id,
                id: undefined,
                aplicacion_desde: parseInt(aplicacion_hasta) + 1,
                aplicacion_hasta: parseInt(aplicacion_hasta) + 2,
                familia_producto: b,
                groupId: `${bah}-${bah + 1}-${b}`,
            }));
        if (!prod) return;
        updVersionAtt({ contrato_v_beneficio: [...benefits, ...prod] });
    };

    const indicationFams = Array.from(
        new Set(indication?.indicacion_familiaprod?.map((pd) => pd.familia_producto)),
    );

    const selectableFams = product_families?.filter((pf) => indicationFams.includes(pf.id));

    const _estado = estado === 'B' ? 'B' : estado_version;
    const toggleEstado = () => {
        updVersionAtt({ estado: estado === 'B' ? estado_version : 'B' });
    };

    const readOnly = version.cant_txns > 0;

    return (
        <div className='body-version'>
            <div className='bv-dp dl-mwmc'>
                <div>
                    <label
                        className={
                            'input-label ' +
                            (showErrors && (vigencia_desde ? '' : 'error-next-datepicker'))
                        }
                    >
                        {I18n.t(
                            'contract.versionDetail.dateRangePicker.startDatePickerPlaceholder',
                        )}
                    </label>
                    <DatePicker
                        disabled={readOnly}
                        selected={vigencia_desde ? getDate(vigencia_desde) : undefined}
                        dateFormat='dd/MM/yyyy'
                        minDate={new Date()}
                        maxDate={
                            fin_incorp_pacientes || vigencia_hasta
                                ? Math.min(...[getDate(fin_incorp_pacientes)].filter((d) => d))
                                : undefined
                        }
                        onChange={(v) => {
                            updVerAttDate({
                                vigencia_desde: v,
                            });
                        }}
                        placeholderText={moment().format('DD/MM/YYYY')}
                        autoComplete='on'
                    />
                </div>
                <div>
                    <label
                        className={
                            'input-label ' +
                            (showErrors && (fin_incorp_pacientes ? '' : 'error-next-datepicker'))
                        }
                    >
                        {I18n.t(
                            'contract.versionDetail.dateRangePicker.endPatientDischargePlaceholder',
                        )}
                    </label>
                    <DatePicker
                        disabled={readOnly}
                        selected={fin_incorp_pacientes ? getDate(fin_incorp_pacientes) : undefined}
                        dateFormat='dd/MM/yyyy'
                        minDate={getDate(vigencia_desde)}
                        maxDate={getDate(vigencia_hasta)}
                        onChange={(v) =>
                            updVerAttDate({
                                fin_incorp_pacientes: v,
                            })
                        }
                        placeholderText={moment(getDate(vigencia_desde) || new Date()).format(
                            'DD/MM/YYYY',
                        )}
                        autoComplete='off'
                    ></DatePicker>
                </div>
                <div>
                    <label
                        className={
                            'input-label ' +
                            (showErrors && (vigencia_hasta ? '' : 'error-next-datepicker'))
                        }
                    >
                        {I18n.t(
                            'contract.versionDetail.dateRangePicker.endRegistrationPlaceholder',
                        )}
                    </label>
                    <DatePicker
                        disabled={readOnly}
                        selected={getDate(vigencia_hasta)}
                        dateFormat='dd/MM/yyyy'
                        minDate={getDate(fin_incorp_pacientes)}
                        onChange={(v) => updVerAttDate({ vigencia_hasta: v })}
                        // onChangeRaw={handleDateChangeRaw}
                        placeholderText={moment(getDate(vigencia_desde) || new Date()).format(
                            'DD/MM/YYYY',
                        )}
                        autoComplete='off'
                    />
                </div>
                <div className=''>
                    <Input
                        disabled={readOnly}
                        className='white ip-custom-mx'
                        value={max_pacientes}
                        type='number'
                        handleChange={(v) => updVersionAtt({ max_pacientes: v })}
                        label={I18n.t('contract.versionDetail.inputLabels.patientsMax')}
                        error={showErrors && !(max_pacientes > 0)}
                    />
                </div>
                <div className=''>
                    <Input
                        disabled
                        className='white ip-custom-mx'
                        value={cant_pacientes_activos}
                        type='number'
                        label={I18n.t('contract.versionDetail.inputLabels.activePatients')}
                        error={showErrors && cant_pacientes_activos < 0}
                    />
                </div>
                <div className='vl' />
                <div className='bv-status'>
                    <div className='label-status'>
                        {I18n.t('contract.versionDetail.inputLabels.status')}
                    </div>
                    <div className={'bv-btn-status status-' + _estado} onClick={toggleEstado}>
                        {I18n.t('contract.versionDetail.status.' + _estado ?? 'B')}
                    </div>
                </div>
            </div>
            <hr />
            <div className='bv-dp bv-dp-even'>
                <div className='vl'></div>
                {!isSur && (
                    <>
                        <div className='bv-display'>
                            <Input
                                disabled={readOnly}
                                className='white ip-custom'
                                value={tolerancia_periodo}
                                type='number'
                                handleChange={(v) => updVersionAtt({ tolerancia_periodo: v })}
                                label={
                                    <>
                                        <div>
                                            {I18n.t(
                                                'contract.versionDetail.inputLabels.controlPeriodTolerance1',
                                            )}
                                            <br />
                                            {I18n.t(
                                                'contract.versionDetail.inputLabels.controlPeriodTolerance2',
                                            )}
                                        </div>
                                        <br />
                                        <div className='sub-label'>
                                            {I18n.t(
                                                'contract.versionDetail.inputLabels.controlPeriodTolerance3',
                                            )}
                                        </div>
                                    </>
                                }
                            />
                        </div>
                        <div className='vl'></div>
                        <div className='bv-display'>
                            <Input
                                disabled={readOnly}
                                className='white ip-custom'
                                value={tolerancia_dosis}
                                type='number'
                                handleChange={(v) => updVersionAtt({ tolerancia_dosis: v })}
                                label={
                                    <>
                                        <div>
                                            {I18n.t(
                                                'contract.versionDetail.inputLabels.doseToleranceMg1',
                                            )}
                                            <br />
                                            {I18n.t(
                                                'contract.versionDetail.inputLabels.doseToleranceMg2',
                                            )}
                                        </div>
                                        <br />
                                        <div className='sub-label'>
                                            {I18n.t(
                                                'contract.versionDetail.inputLabels.doseToleranceMg3',
                                            )}
                                        </div>
                                    </>
                                }
                                // error={showErrors && (tolerancia_dosis == null || tolerancia_dosis > 100)}
                            />
                        </div>
                    </>
                )}
            </div>
            <br />
            {!isSur && (
                <Card
                    classHead='card-head-mod'
                    title={I18n.t('contract.versionDetail.audit.title')}
                >
                    <div className='card-row'>
                        <div className='cb-mt'>
                            <div key='input-label'>
                                <label className='input-label'>
                                    {I18n.t('contract.versionDetail.audit.group')}
                                </label>
                            </div>
                            <CustomSelect
                                disabled={readOnly}
                                options={
                                    auditorGroups
                                        ?.map((ag) => ({ value: ag.id, label: ag.razon_social }))
                                        ?.sort((a, b) =>
                                            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
                                        ) ?? []
                                }
                                value={grupo_auditor}
                                onSelect={(v) => updVersionAtt({ grupo_auditor: v })}
                                placeholder=''
                            />
                        </div>
                        <hr />
                        <div className='cb-mt'>
                            <label className='input-label il-label-aud'>
                                {I18n.t('contract.versionDetail.audit.shareinfo')}
                            </label>
                            <div className='il-chb'>
                                <Checkbox
                                    id='nameSurname'
                                    disabled={readOnly}
                                    label={I18n.t(
                                        'contract.versionDetail.audit.selectedFilter.nameSurname',
                                    )}
                                    checked={compartir_nya}
                                    onChange={() =>
                                        updVersionAtt({ compartir_nya: !compartir_nya })
                                    }
                                />
                                <Checkbox
                                    id='dni'
                                    label={I18n.t(
                                        'contract.versionDetail.audit.selectedFilter.dni',
                                    )}
                                    checked={compartir_dni}
                                    disabled={readOnly}
                                    onChange={() =>
                                        updVersionAtt({ compartir_dni: !compartir_dni })
                                    }
                                />
                                <Checkbox
                                    id='afiliateNumber'
                                    label={I18n.t(
                                        'contract.versionDetail.audit.selectedFilter.afiliateNumber',
                                    )}
                                    checked={compartir_afiliado}
                                    disabled={readOnly}
                                    onChange={() =>
                                        updVersionAtt({ compartir_afiliado: !compartir_afiliado })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </Card>
            )}
            <Card
                className='card-custom'
                classHead='card-head-mod'
                classBody='card-body-custom'
                title={I18n.t('contract.versionDetail.benefitCard.title')}
            ></Card>
            <BenefitsTable
                indication={indication}
                benefits={version.contrato_v_beneficio}
                setBenefits={(v) => updVersionAtt({ contrato_v_beneficio: v })}
                product_families={product_families}
                operatorCalculateBen={operatorCalculateBen}
                isSur={isSur}
                readOnly={readOnly}
                typeContractSelected={typeContractSelected}
            />
            <br />

            <div className='add-app'>
                <CustomSelect
                    options={
                        isSur
                            ? product_families?.map((pf) => ({
                                  value: pf.id,
                                  label: pf.denominacion,
                              })) ?? []
                            : selectableFams?.map((pf) => ({
                                  value: pf.id,
                                  label: pf.denominacion,
                              })) ?? []
                    }
                    value={undefined}
                    onSelect={(v) => addBenefits(v)}
                    placeholder=''
                    overwrite={true}
                    disabled={readOnly}
                    className='rounded i-screen csoc_mw mxh coptop'
                    searchable={true}
                >
                    {I18n.t('contract.versionDetail.benefitCard.addApplication')}
                </CustomSelect>
            </div>

            {typeContractSelected === 3 && (
                <>
                    <hr className='hr-add-pp' />

                    <div className='card-row'>
                        <div className='cb-mt'>
                            <div key='input-label'>
                                <label className='input-label'>
                                    {I18n.t(
                                        'contract.versionDetail.benefitCard.riskSharingCalculation',
                                    )}
                                </label>
                            </div>
                            <CustomSelect
                                options={riskSharingCalculationTypes.map((type) => ({
                                    value: type?.value,
                                    label: type?.label,
                                }))}
                                value={
                                    version.tipo_calculo_rc ??
                                    riskSharingCalculationTypes.filter(
                                        (type) => type.value === 'U',
                                    )[0].value
                                }
                                onSelect={(v) => {
                                    updVersionAtt({ tipo_calculo_rc: v });
                                }}
                                className='rounded i-screen mxh'
                                disabled={readOnly}
                            />
                        </div>
                    </div>
                </>
            )}

            <br />
            <br />
            <hr />
            {!isSur && evidenceTypes?.length > 0 && (
                <Card title='Evidencias'>
                    <br />
                    <EvidencesTable
                        evidences={version.contrato_v_evidencia}
                        setEvidences={(v) => {
                            updVersionAtt({ contrato_v_evidencia: v });
                        }}
                        showErrors={showErrors}
                        evidenceTypes={evidenceTypes}
                        readOnly={readOnly}
                    />
                    <br />
                    <div className='card-rlink' onClick={!readOnly ? addEvidence : ''}>
                        {I18n.t('indication.details.evidences.addNew')}
                    </div>
                    <br />
                </Card>
            )}
        </div>
    );
};

const VersionContainer = ({
    versions,
    updVersions,
    indication,

    selectedIndication,
    getIndications,

    product_families,
    auditorGroups,
    showErrors,
    getProdFamily,
    evidenceTypes,
    isSur,
    getEvidenceType,
    lastVersion,
    typeContractSelected,
}) => {
    const [currentVersion, setCurrentVersion] = useState(0);

    useEffect(() => {
        if (indication != undefined) getIndications(indication);
    }, [indication]);

    useEffect(() => {
        if (lastVersion && versions) {
            setCurrentVersion(versions.length - 1);
        }
    }, [versions?.length]);

    useEffect(() => {
        getProdFamily(true);
        getEvidenceType();
    }, []);

    const version = versions?.[currentVersion];
    const updVersion = (v) => {
        let _ver = versions?.map((_v, i) => (i === currentVersion ? v : _v));
        updVersions(_ver);
    };

    const addVersion = () => {
        if ((!selectedIndication?.indicacion_evidencia || !indication) && !isSur) {
            alert('Seleccione una indicacion');
            return;
        }

        const _id =
            versions?.reduce((prev, current) => (prev._id > current._id ? prev : current), {
                _id: 0,
            })._id + 1;
        const mut_version = version
            ? {
                  ...version,
                  id: undefined,
                  contrato_v_beneficio:
                      version?.contrato_v_beneficio?.map((b) => ({ ...b, id: undefined })) ?? [],
                  contrato_v_evidencia:
                      version?.contrato_v_evidencia?.map((e) => ({ ...e, id: undefined })) ?? [],
              }
            : {};
        const defaultData = {
            compartir_afiliado: true,
            compartir_dni: true,
            compartir_nya: true,
            estado: 'A',
            estado_version: 'A',
            cant_txns: 0,
            vigencia_desde: moment(new Date()).format('YYYY-MM-DD'),
            vigencia_hasta: '',
            fin_incorp_pacientes: '',
        };

        updVersions([
            ...versions,
            {
                contrato_v_beneficio: undefined,
                ...mut_version,
                id: undefined,
                contrato_v_evidencia:
                    selectedIndication?.indicacion_evidencia?.map((ev) => ({
                        ...ev,
                        _id: ev.id,
                        id: undefined,
                    })) ?? [],
                _id,
                ...defaultData,
                version:
                    typeof version !== 'undefined' && typeof version.version !== 'undefined'
                        ? 1 + version.version
                        : 1,
            },
        ]);
        setCurrentVersion(versions?.length);
    };

    const version_options =
        versions?.map((ver, i) => ({
            value: i,
            label: `${i} - ${ver.vigencia_desde} - ${I18n.t(
                'contract.versionDetail.status.' + ver.estado ?? 'B',
            )}`,
        })) ?? [];

    return (
        <div className='section-detail-contract'>
            <div className='header-version'>
                <div>
                    <CustomSelect
                        options={version_options}
                        value={currentVersion}
                        onSelect={(v) => setCurrentVersion(v)}
                        placeholder=''
                        overwrite={true}
                        className='csoc_mw'
                    >
                        {I18n.t('contract.versionDetail.title')}
                        <i className='icon-arrow-down hv-padd' />
                    </CustomSelect>
                </div>
                <div className='hv-nr-btn hv-padd'>
                    <div>
                        <i
                            className='icon-arrow-left hv-padd'
                            onClick={() => {
                                if (currentVersion <= 0) return;
                                setCurrentVersion(currentVersion - 1);
                            }}
                        />
                        {zeroPad(currentVersion + 1, 3)}
                        <i
                            className='icon-arrow-right hv-padd'
                            onClick={() => {
                                if (currentVersion >= versions?.length - 1) return;
                                setCurrentVersion(currentVersion + 1);
                            }}
                        />
                    </div>
                    <Button
                        type='button'
                        className='primary sm'
                        onClick={addVersion}
                        text={addContract}
                    />
                </div>
            </div>
            <Version
                version={version}
                updVersion={updVersion}
                indication={selectedIndication}
                product_families={product_families}
                auditorGroups={auditorGroups}
                showErrors={showErrors}
                evidenceTypes={evidenceTypes}
                isSur={isSur}
                typeContractSelected={typeContractSelected}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    product_families: state.indication.product_families,
    evidenceTypes: state.indication.evidenceTypes,
});

const mapDispatchToProps = (dispatch) => ({
    getProdFamily: (getProducts) => dispatch(actions.indication.getProdFamily(getProducts)),
    getEvidenceType: () => dispatch(actions.indication.getEvidenceType()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionContainer);
