import React from 'react';
import './ErrorBox.scss';
import PropTypes from 'prop-types';
import LinkAccess from '../LinkAccess/LinkAccess';

const ErrorBox = (props) => {
    const { show, errorMessage } = props;

    return (
        <div className={`error-box ${show && 'show'}  ${props.className}`}>
            {!props.icon && <i className='icon-exclamation' />}
            <div className='error-msj'>
                {errorMessage === 'generic' ? 'Ha ocurrido un error' : errorMessage}
            </div>
            {props.href && props.linkMessage && (
                <LinkAccess className='details-link' href={props.href} title={props.linkMessage} />
            )}
        </div>
    );
};

ErrorBox.propTypes = {
    show: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string.isRequired,
    href: PropTypes.string,
    linkMessage: PropTypes.string,
};

export default ErrorBox;
