export const clipboard = {
    height: '20',
    width: '20',
    color: '#057A83',
};

export const copyKeyBtn = {
    height: '19',
    width: '19',
    color: '#055f62',
};

export const check = {
    height: '18',
    width: '18',
    color: '#2cb095',
};
