import session from '../session/session.actions';
import common from '../common/common.actions';
import profile from '../profile/profile.actions';
import patient from '../patient/patient.actions';
import registration from '../registration/registration.actions';
import transaction from '../transaction/transaction.actions';
import registrar from '../registrar/registrar.actions';
import indication from '../indication/indication.actions';
import contract from '../contract/contract.actions';
import roleAccess from '../roleAccess/roleAccess.actions';
import wallet from '../wallet/wallet.actions';

export default {
    session,
    common,
    profile,
    patient,
    registration,
    transaction,
    registrar,
    indication,
    contract,
    roleAccess,
    wallet,
};
