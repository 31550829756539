import React, { useState } from 'react';
import { ConfirmationModal, SimpleModal } from '../../../common/components/Modal';
import TextArea from '../../../common/components/TextArea/TextArea';
import { I18n } from 'react-redux-i18n';

export const ReviewModal = ({ original, updateSharedRiskTransactions }) => {
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState(
        original.comentario_roche ? original.comentario_roche : 'Sin Comentario',
    );

    return (
        <>
            {original.estado === 'P' && (
                <>
                    <button
                        className='detail-button'
                        onClick={() => {
                            setShowModal(!showModal);
                        }}
                    >
                        <span className='text transactions-table-row details'>
                            {I18n.t('transaction.table.headers.review')}
                        </span>
                    </button>

                    <ConfirmationModal
                        show={showModal}
                        close={() => {
                            setShowModal(false);
                        }}
                        cancel={() => {
                            updateSharedRiskTransactions(
                                {
                                    estado: 'R',
                                    comentario_roche: comment ? comment : 'Sin Comentario',
                                },
                                original.id,
                            );
                        }}
                        submit={() => {
                            updateSharedRiskTransactions(
                                {
                                    estado: 'A',
                                    comentario_roche: comment ? comment : 'Sin Comentario',
                                },
                                original.id,
                            );
                        }}
                        title={I18n.t('transaction.table.headers.reviewEndContract')}
                        noBody={false}
                    >
                        <div className='comment-body'>
                            <span
                                className='comment'
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            >
                                <TextArea
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </span>
                        </div>
                    </ConfirmationModal>
                </>
            )}
        </>
    );
};

export const DisplayComment = ({ comment }) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <button
                className='detail-button'
                onClick={() => {
                    setShowModal(true);
                }}
            >
                <span className='text transactions-table-row details'>
                    {I18n.t('transaction.table.headers.viewDetails')}
                </span>
            </button>
            <SimpleModal
                show={showModal}
                close={() => {
                    setShowModal(!showModal);
                }}
            >
                <div className='header'>
                    <span className='title'>
                        {I18n.t('transaction.table.headers.auditorComment')}
                    </span>
                </div>
                <div className='comment-body'>
                    <div className='body'>
                        <span className='comment'>{comment}</span>
                    </div>
                </div>
                <div className='separator-line' />
            </SimpleModal>
        </>
    );
};
