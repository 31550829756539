import React, { useEffect, useRef } from 'react';
import './InputBox.scss';

const InputBox = (props) => {
    const editBoxRef = useRef(null);

    useEffect(() => {
        editBoxRef.current.focus();
    }, [editBoxRef]);

    useEffect(() => {
        handleChange(props.value);
        editBoxRef.current.style.width = `${props.value?.length || 0}ch`;
        const maxCharsWidth = Math.max(
            editBoxRef.current.scrollWidth,
            editBoxRef.current.clientWidth,
        );
        editBoxRef.current.style.width = `${maxCharsWidth + 5}px`;
    }, [props.value]);

    const handleChange = (value) => {
        if (value === 0 || value === undefined) return;
        props.onChange(value);
    };

    return (
        <>
            <div key='input-label'>
                {props.label && (
                    <label
                        className={`input-label ${props.required ? 'required' : ''} ${
                            props.pillStyle ? 'pill' : ''
                        }`}
                    >
                        {props.label}
                    </label>
                )}
            </div>
            <div className={`body-input-box ${props.className}`}>
                {props.titleValue && <div className='title-box'>{props.titleValue}</div>}
                <div className='amount'>
                    <input
                        ref={editBoxRef}
                        className='editBox'
                        value={props.value}
                        onChange={(e) => handleChange(e.target.value)}
                        disabled={props.editable}
                    />
                    <div className='wrapperChildren'>{props.children}</div>
                </div>
            </div>
        </>
    );
};
export default InputBox;
