export const REGISTRAR_SIGN_UP = 'REGISTRAR_SIGN_UP';
export const REGISTRAR_SIGN_UP_RESPONSE = 'REGISTRAR_SIGN_UP_RESPONSE';
export const REGISTRAR_SIGN_UP_ERROR = 'REGISTRAR_SIGN_UP_ERROR';

export const GET_INSTITUTION_BY_CUIT = 'GET_INSTITUTION_BY_CUIT';
export const GET_INSTITUTION_BY_CUIT_RESPONSE = 'GET_INSTITUTION_BY_CUIT_RESPONSE';
export const GET_INSTITUTION_BY_CUIT_ERROR = 'GET_INSTITUTION_BY_CUIT_ERROR';

export const CREATE_BRANCH = 'CREATE_BRANCH';
export const CREATE_BRANCH_RESPONSE = 'CREATE_BRANCH_RESPONSE';
export const CREATE_BRANCH_ERROR = 'CREATE_BRANCH_ERROR';

export const CREATE_INSTITUTION_AND_BRANCH = 'CREATE_INSTITUTION_AND_BRANCH';
export const CREATE_INSTITUTION_AND_BRANCH_RESPONSE = 'CREATE_INSTITUTION_AND_BRANCH_RESPONSE';
export const CREATE_INSTITUTION_AND_BRANCH_ERROR = 'CREATE_INSTITUTION_AND_BRANCH_ERROR';

export const CREATE_REGISTRAR = 'CREATE_REGISTRAR';
export const CREATE_REGISTRAR_RESPONSE = 'CREATE_REGISTRAR_RESPONSE';
export const CREATE_REGISTRAR_ERROR = 'CREATE_REGISTRAR_ERROR';

export const ACTIVATE_REGISTRAR_ACCOUNT = 'ACTIVATE_REGISTRAR_ACCOUNT';
export const ACTIVATE_REGISTRAR_ACCOUNT_RESPONSE = 'ACTIVATE_REGISTRAR_ACCOUNT_RESPONSE';
export const ACTIVATE_REGISTRAR_ACCOUNT_ERROR = 'ACTIVATE_REGISTRAR_ACCOUNT_ERROR';

export const SELECT_BRANCH = 'SELECT_BRANCH';
export const RESET_REGISTRAR_UI = 'RESET_REGISTRAR_UI';

const registrarActions = {
    registrarSignUp: (firstName, lastName, username, email, password, repeatPassword) => ({
        type: REGISTRAR_SIGN_UP,
        firstName,
        lastName,
        username,
        email,
        password,
        repeatPassword,
    }),
    registrarSignUpResponse: (response) => ({ type: REGISTRAR_SIGN_UP_RESPONSE, response }),
    registrarSignUpError: (error) => ({ type: REGISTRAR_SIGN_UP_ERROR, error }),

    getInstitutionByCuit: (cuit) => ({ type: GET_INSTITUTION_BY_CUIT, cuit }),
    getInstitutionByCuitResponse: (response) => ({
        type: GET_INSTITUTION_BY_CUIT_RESPONSE,
        response,
    }),
    getInstitutionByCuitError: (error) => ({ type: GET_INSTITUTION_BY_CUIT_ERROR, error }),

    createBranch: (branch, institutionId) => ({ type: CREATE_BRANCH, branch, institutionId }),
    createBranchResponse: (response) => ({ type: CREATE_BRANCH_RESPONSE, response }),
    createBranchError: (error) => ({ type: CREATE_BRANCH_ERROR, error }),

    createInstitutionAndBranch: (institution, branch) => ({
        type: CREATE_INSTITUTION_AND_BRANCH,
        institution,
        branch,
    }),
    createInstitutionAndBranchResponse: (response) => ({
        type: CREATE_INSTITUTION_AND_BRANCH_RESPONSE,
        response,
    }),
    createInstitutionAndBranchError: (error) => ({
        type: CREATE_INSTITUTION_AND_BRANCH_ERROR,
        error,
    }),

    createRegistrar: () => ({ type: CREATE_REGISTRAR }),
    createRegistrarResponse: (response) => ({ type: CREATE_REGISTRAR_RESPONSE, response }),
    createRegistrarError: (error) => ({ type: CREATE_REGISTRAR_ERROR, error }),

    activateRegistrarAccount: (uid, token) => ({ type: ACTIVATE_REGISTRAR_ACCOUNT, uid, token }),
    activateRegistrarAccountResponse: (response) => ({
        type: ACTIVATE_REGISTRAR_ACCOUNT_RESPONSE,
        response,
    }),
    activateRegistrarAccountError: (error) => ({ type: ACTIVATE_REGISTRAR_ACCOUNT_ERROR, error }),

    selectBranch: (branch) => ({ type: SELECT_BRANCH, branch }),
    resetRegistrarUi: () => ({ type: RESET_REGISTRAR_UI }),
};

export default registrarActions;
