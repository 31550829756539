import React, { Component } from 'react';
import './Input.scss';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

class Input extends Component {
    render() {
        // todo the pill style need to be moved to the className prop
        const className = `
			input-container 
			${this.props.disabled ? 'disabled' : ''} 
			${this.props.error ? 'error' : ''}
		 	${this.props.pillStyle ? 'pill' : ''}
		 	${this.props.className ? this.props.className : ''}
		 	`;

        return [
            <div key='nexus-input'>
                {this.props.labelIcon && this.props.label ? (
                    <div key='input-label' className={`input-label ${this.props.labelClassName}`}>
                        <div className='input-label' style={{ marginRight: '10px' }}>
                            {this.props.labelIcon}
                        </div>
                        {this.props.label && (
                            <label
                                className={`input-label ${this.props.required ? 'required' : ''} ${
                                    this.props.pillStyle ? 'pill' : ''
                                } `}
                            >
                                {this.props.label}
                            </label>
                        )}
                    </div>
                ) : (
                    <div key='input-label'>
                        {this.props.label && (
                            <label
                                className={`input-label ${this.props.required ? 'required' : ''} ${
                                    this.props.pillStyle ? 'pill' : ''
                                }`}
                            >
                                {this.props.label}
                            </label>
                        )}
                    </div>
                )}
                <div key='input' className={className}>
                    {this.props.icon}
                    {this.props.textarea ? (
                        <textarea
                            className='input'
                            autoComplete='new-password'
                            placeholder={this.props.placeholder}
                            value={this.props.value}
                            rows={5}
                            style={{ resize: 'none' }}
                            // this was change because now the components which will call this inputs, will be functional
                            // so there is no need to do handle change in this way (only pass the value).
                            // onChange={(e) => !this.props.disabled ? this.props.handleChange(this.props.field, e.target.value) : null}
                            onChange={(e) =>
                                !this.props.disabled
                                    ? this.props.handleChange(e.target.value)
                                    : null
                            }
                        />
                    ) : this.props.mask ? (
                        <InputMask
                            mask={this.props.mask}
                            value={this.props.value}
                            onChange={(e) =>
                                !this.props.disabled
                                    ? this.props.handleChange(e.target.value)
                                    : null
                            }
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && this.props.handleSubmit)
                                    this.props.handleSubmit();
                            }}
                        >
                            {(inputProps) => (
                                <input
                                    {...inputProps}
                                    className={`input ${this.props.pillStyle ? 'pill' : ''}`}
                                    autoComplete='new-password'
                                    placeholder={this.props.placeholder}
                                    type={this.props.type}
                                />
                            )}
                        </InputMask>
                    ) : (
                        <input
                            key='input-new-password'
                            className={`input ${this.props.pillStyle} ${this.props.inputClassname}`}
                            autoComplete='new-password'
                            placeholder={this.props.placeholder}
                            type={this.props.type}
                            value={this.props.value}
                            onChange={(e) =>
                                !this.props.disabled
                                    ? this.props.handleChange(e.target.value)
                                    : null
                            }
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && this.props.handleSubmit)
                                    this.props.handleSubmit();
                            }}
                            min={this.props.min}
                            max={this.props.max}
                            maxLength={this.props.maxLength}
                        />
                    )}
                    {this.props.password && (
                        <i className='icon-eye' onClick={this.props.showPassword} />
                    )}
                </div>
            </div>,
        ];
    }
}

Input.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    disabled: PropTypes.bool,
    icon: PropTypes.element,
    labelIcon: PropTypes.element,
    textarea: PropTypes.bool,
    password: PropTypes.bool,
    showPassword: PropTypes.func,
    error: PropTypes.bool,
    mask: PropTypes.string,
    // the 'pillStyle' is a prop that will change the style of the input, when pillStyle is true the input style
    // will be the same as the login inputs and the search input (rounded as a 'pill').
    pillStyle: PropTypes.bool,
    inputClassname: PropTypes.string,
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
};

Input.defaultPropType = {
    placeholder: '',
    type: 'text',
    min: '',
    max: '',
};

export default Input;
