import React from 'react';
import Button from '../../../../common/components/Button/Button';
import './NewAccess.scss';
import { I18n } from 'react-redux-i18n';

const NewAccessButton = ({ openModal, disabled, loading }) => {
    return (
        <>
            <div className='new-user-button-container'>
                <Button
                    onClick={openModal}
                    text={I18n.t('roleAccess.newAccess.addNew')}
                    className='new-user-button big'
                    disabled={disabled}
                    loading={loading}
                />
            </div>
        </>
    );
};
export default NewAccessButton;
