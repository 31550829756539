import { connect } from 'react-redux';
import actions from '../../actions';
import RegistrarNewAccount from '../components/RegistrarNewAccountScreen';

const mapStateToProps = (state) => ({
    userFirstName: state.profile.user.first_name,
    institution: state.registrar.institution,
    userType: state.profile.user.type,
    user: state.profile.user,
    /*
     * the difference between branch and branches is that 'branches' are all the branches in the institution, while
     * 'branch' is the selected branch (or the created one).
     * */
    branch: state.registrar.branch,
    branches: state.registrar.branches,
    getInstitutionStatus: state.registrar.getInstitutionByCuitStatus,
    createBranchStatus: state.registrar.createBranchStatus,
    createInstitutionAndBranchStatus: state.registrar.createInstitutionAndBranchStatus,
    createRegistrarStatus: state.registrar.createRegistrarStatus,
});

const mapDispatchToProps = (dispatch) => ({
    getInstitution: (cuit) => dispatch(actions.registrar.getInstitutionByCuit(cuit)),
    createBranch: (branch, institutionId) =>
        dispatch(actions.registrar.createBranch(branch, institutionId)),
    createInstitutionAndBranch: (institution, branch) =>
        dispatch(actions.registrar.createInstitutionAndBranch(institution, branch)),
    createRegistrar: () => dispatch(actions.registrar.createRegistrar()),
    selectBranch: (branch) => dispatch(actions.registrar.selectBranch(branch)),
    resetUi: () => dispatch(actions.registrar.resetRegistrarUi()),
    logout: () => dispatch(actions.session.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrarNewAccount);
