const NUMBERS_COMMA_REGEX = /[^0-9,]/g; // used to filter out every character but numbers and comma.

// given a integer string, return it with dots between every thousand
// example: 99999 -> 99.999
const addDotsBetweenThousands = (v) => v.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

/**
 * @description return number of commas of string
 * used to remove extra comma if typed
 */
const commas = (v) => {
    let result = 0;
    for (const chr of v) {
        if (chr === ',') result++;
    }
    return result;
};

const DECIMAL_LIMIT = 2;
const INTEGER_LIMIT = 10;
/**
 * receives a number as string and returns an array
 * with 3 strings: 1) integers. 2) comma or empty string. 3) decimals
 *
 * Note: ignores every integer or decimal if reached limit constant above.
 */
const getIntegerAndDecimalParts = (v) => {
    const integersArray = [];
    let comma = '';
    const decimalsArray = [];
    let decimals = false;
    for (const chr of v) {
        if (decimals) {
            if (decimalsArray.length < DECIMAL_LIMIT) decimalsArray.push(chr);
        } else {
            if (chr === ',') {
                comma = chr;
                decimals = true;
            } else {
                if (integersArray.length < INTEGER_LIMIT) integersArray.push(chr);
            }
        }
    }
    return [integersArray.join(''), comma, decimalsArray.join('')];
};

/**
 * Receives input string value and formats it to "x.xxx.xxx.xxx,xx" format
 */
export const formatValueToESCurrency = (value) => {
    // removes every invalid char
    let formatted = value.replace(NUMBERS_COMMA_REGEX, '');

    // counts number of comma and removes last char if has more than one
    const numberOfCommas = commas(formatted);
    if (numberOfCommas > 1) {
        formatted = formatted.substring(0, formatted.length - 1);
    }

    // if empty defaults to '0'
    if (formatted === '') return '0';

    // use aux function to limit integers and decimals
    const [integer, comma, decimal] = getIntegerAndDecimalParts(formatted);

    // remove left zeroes in integer part by parsing
    const parsedInteger = parseInt(integer);

    return `${addDotsBetweenThousands(parsedInteger.toString())}${comma}${decimal}`;
};
