import React from 'react';

const WalletNavBar = ({ color }) => (
    <svg
        width='35%'
        height='35%'
        viewBox='0 0 28 28'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M15.1667 11.375H8.16669C7.68835 11.375 7.29169 10.9783 7.29169 10.5C7.29169 10.0217 7.68835 9.625 8.16669 9.625H15.1667C15.645 9.625 16.0417 10.0217 16.0417 10.5C16.0417 10.9783 15.645 11.375 15.1667 11.375Z'
            fill={color ?? '#2CB095'}
        />
        <path
            d='M22.2132 17.2667C20.4515 17.2667 18.9582 15.9601 18.8182 14.2801C18.7249 13.3117 19.0749 12.3667 19.7749 11.6784C20.3582 11.0717 21.1865 10.7334 22.0615 10.7334H24.4999C25.6549 10.7684 26.5415 11.6784 26.5415 12.7984V15.2017C26.5415 16.3217 25.6549 17.2317 24.5349 17.2667H22.2132ZM24.4649 12.4834H22.0732C21.6649 12.4834 21.2915 12.6351 21.0232 12.9151C20.6849 13.2417 20.5215 13.6851 20.5682 14.1284C20.6265 14.8984 21.3732 15.5167 22.2132 15.5167H24.4999C24.6515 15.5167 24.7915 15.3767 24.7915 15.2017V12.7984C24.7915 12.6234 24.6515 12.4951 24.4649 12.4834Z'
            fill={color ?? '#2CB095'}
        />
        <path
            d='M18.6666 24.7918H8.16665C4.15331 24.7918 1.45831 22.0968 1.45831 18.0835V9.91683C1.45831 6.3235 3.67496 3.72184 7.11662 3.29017C7.43162 3.24351 7.79331 3.2085 8.16665 3.2085H18.6666C18.9466 3.2085 19.3083 3.22016 19.6816 3.2785C23.1233 3.67516 25.375 6.2885 25.375 9.91683V11.6085C25.375 12.0868 24.9783 12.4835 24.5 12.4835H22.0733C21.665 12.4835 21.2916 12.6352 21.0233 12.9152L21.0116 12.9268C20.685 13.2418 20.5333 13.6735 20.5683 14.1168C20.6266 14.8868 21.3733 15.5052 22.2133 15.5052H24.5C24.9783 15.5052 25.375 15.9018 25.375 16.3802V18.0718C25.375 22.0968 22.68 24.7918 18.6666 24.7918ZM8.16665 4.9585C7.88665 4.9585 7.6183 4.98182 7.34997 5.01682C4.7833 5.34348 3.20831 7.21016 3.20831 9.91683V18.0835C3.20831 21.0935 5.15665 23.0418 8.16665 23.0418H18.6666C21.6766 23.0418 23.625 21.0935 23.625 18.0835V17.2668H22.2133C20.4516 17.2668 18.9583 15.9602 18.8183 14.2802C18.725 13.3235 19.075 12.3668 19.775 11.6902C20.3816 11.0719 21.1983 10.7335 22.0733 10.7335H23.625V9.91683C23.625 7.18683 22.0266 5.30847 19.4366 5.00514C19.1566 4.95847 18.9116 4.9585 18.6666 4.9585H8.16665Z'
            fill={color ?? '#2CB095'}
        />
    </svg>
);
export default WalletNavBar;
