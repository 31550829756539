import { Blockchain, KeyManager } from 'jasper-roche-crypto';
import BigNumber from 'bignumber.js';
import { nex_abi } from '../wallet/containers/abi';
import settings from '../settings/settings';

export const loadWeb3 = (fundsKey) => new Blockchain(fundsKey).loadWeb3(settings.apiWallet);

export const maxGasForTokenSend = 600000;
export const Gwei = (1.0e9).toString();
export const gasPrice = new BigNumber(10).times(Gwei).toNumber();

export const newTokenContract = (fundsKey) => {
    let w3 = loadWeb3(fundsKey);
    return { w3: w3, tokenContract: new w3.eth.Contract(nex_abi, process.env.REACT_APP_TOKEN_NEX) };
};
export const getKeysFromKeystore = (username) => {
    const keyManager = new KeyManager(username);
    const fundsKey = keyManager.deriveFundsKey();
    const publicKey = keyManager.getPublicKey(fundsKey);
    const address = keyManager.getAddress(publicKey);
    return { address, fundsKey };
};
