import React, { useState, useEffect } from 'react';
import nexus_logo from '../../../assets/logos/nexus-white-small.png';
import { I18n } from 'react-redux-i18n';
import Input from '../../../common/components/Input';
import Button from '../../../common/components/Button';
import { useHistory } from 'react-router-dom';
import { validators } from '../../../utils';

const ResetPasswordScreen = (props) => {
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [passwordResetSuccessfully, setPasswordResetSuccessfully] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
    const [errorFields, setErrorFields] = useState([]);
    const [errorMessage, setErrorMessage] = useState();

    let history = useHistory();

    const { uid, token } = props.match.params;

    useEffect(() => {
        if (props.resetPasswordStatus.success) setPasswordResetSuccessfully(true);
    }, [props.resetPasswordStatus]);

    useEffect(() => {
        if (props.resetPasswordStatus.error)
            setErrorMessage(props.resetPasswordStatus.errorMessage);
    }, [props.resetPasswordStatus.error]);

    const validate = () => {
        if (!password) {
            setErrorFields(['password']);
            setErrorMessage(I18n.t('resetPassword.errors.enterAPassword'));
            return false;
        } else if (!passwordRepeat) {
            setErrorFields(['passwordRepeat']);
            setErrorMessage(I18n.t('resetPassword.errors.confirmThePassword'));
            return false;
        } else if (!validators.validateMinLength(password, 8)) {
            setErrorFields(['password']);
            setErrorMessage(I18n.t('resetPassword.errors.passwordMinLength'));
            return false;
        } else if (password !== passwordRepeat) {
            setErrorFields(['password', 'passwordRepeat']);
            setErrorMessage(I18n.t('resetPassword.errors.passwordMustMatch'));
            return false;
        } else {
            setErrorFields([]);
            setErrorMessage(undefined);
            return true;
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (validate()) props.resetPassword(uid, token, password, passwordRepeat);
    };

    return (
        <div className='recover-password-screen'>
            <img alt='Nexus logo' src={nexus_logo} />
            <div className='card'>
                {!passwordResetSuccessfully ? (
                    <form className='form' onSubmit={onSubmit} noValidate>
                        <label className='title'>{I18n.t('resetPassword.resetYourPassword')}</label>
                        <div className={`error-container ${errorMessage ? 'show' : ''}`}>
                            <i className='icon-exclamation' />
                            <div className='error-msj'>{errorMessage}</div>
                        </div>
                        <Input
                            password
                            type={showPassword ? 'text' : 'password'}
                            showPassword={() => setShowPassword(!showPassword)}
                            value={password}
                            icon={<i className='icon-lock' />}
                            handleChange={setPassword}
                            placeholder={I18n.t('resetPassword.enterYourNewPassword')}
                            error={errorFields.includes('password')}
                            pillStyle
                        />
                        <Input
                            password
                            type={showPasswordRepeat ? 'text' : 'password'}
                            showPassword={() => setShowPasswordRepeat(!showPasswordRepeat)}
                            value={passwordRepeat}
                            icon={<i className='icon-lock' />}
                            handleChange={setPasswordRepeat}
                            placeholder={I18n.t('resetPassword.confirmYourNewPassword')}
                            error={errorFields.includes('passwordRepeat')}
                            pillStyle
                        />
                        <Button
                            type='submit'
                            text={I18n.t('resetPassword.resetPassword')}
                            loading={props.resetPasswordStatus.loading}
                        />
                    </form>
                ) : (
                    <div className='recover-instructions'>
                        <i className='icon-check' />
                        <label className='title'>{I18n.t('resetPassword.passwordReset')}</label>
                        <div className='paragraph-container'>
                            <label className='paragraph'>
                                {I18n.t('resetPassword.yourPasswordHasBeenSuccessfullyReset')}
                            </label>
                        </div>
                    </div>
                )}
            </div>
            <label className='back-to-top' onClick={() => history.push('/')}>
                {I18n.t('resetPassword.backToTop')}
            </label>
        </div>
    );
};

export default ResetPasswordScreen;
