import Card from '../../../../common/components/Card/Card';
import React, { useEffect, useState } from 'react';
import closeModal from '../../../../assets/icons/vuesax_outline_close-circle.svg';
import { I18n } from 'react-redux-i18n';
import CustomSelect from '../../../../common/components/CustomSelect';
import SuccessBox from '../../../../common/components/SuccesBox/SuccessBox';
import Button from '../../../../common/components/Button/Button';
import './NewAccess.scss';
import OperatorSelect from '../../../../common/components/OperatorSelect/OperatoroSelect';
import ErrorBox from '../../../../common/components/ErrorBox';
import { accessRole } from './constants';

const NewAccessModal = ({
    show,
    close,
    getFmi,
    getOs,
    getOperator,
    getMedic,
    getPatients,
    getAuditor,
    getDrugstore,
    roleOptions,
    getOptionsStatus,
    userId,
    patient,
    // editPatient,
    editPatientStatus,
    resetUpdateStatus,
    getAccessStatus,
    patientAccess,
    updPatientWithNewAccess,
    onNewAccess,
}) => {
    const [role, setRole] = useState('D');
    const [access, setAccess] = useState('');
    const [options, setOptions] = useState([]);
    const [roleField, setRoleField] = useState('');
    const [showMessage, toggleMessage] = useState(false);
    const [showError, setShowError] = useState(false);

    const [optionsRole, setOptionsRole] = useState([]);

    useEffect(() => {
        setOptions([]);
        setAccess('');
        switch (role) {
            case 'F': {
                setRoleField('equipo_fmi');
                getFmi();
                break;
            }
            case 'O': {
                setRoleField('obra_social');

                getOs();
                break;
            }
            case 'P': {
                setRoleField('operador');

                getOperator();
                break;
            }
            case 'M': {
                setRoleField('medico');

                getMedic();
                break;
            }
            case 'A': {
                setRoleField('paciente');

                getPatients();
                break;
            }
            case 'U': {
                setRoleField('grupo_auditor');

                getAuditor();
                break;
            }
            case 'D': {
                setRoleField('drogueria');

                getDrugstore();
            }
        }
    }, [role]);

    useEffect(() => {
        roleOptions?.length > 0 && setAccess(roleOptions[0].value);
    }, [roleOptions]);

    useEffect(() => {
        parseOsOptionsPatientAcesss(patientAccess);
    }, [patientAccess]);

    const emptyModal = () => {
        toggleMessage(false);
        //This code comment because now we must leave for default role drogueria , maybe more later We can use this logic for more roles
        // setRole('');
        // setAccess('');
        // setRoleField('');
        // setOptions([]);
    };

    useEffect(() => {
        if (editPatientStatus.success || editPatientStatus.error) {
            editPatientStatus.success
                ? toggleMessage(true)
                : editPatientStatus.error
                ? setShowError(true)
                : getAccessStatus.success ?? setShowError(true);

            setTimeout(() => {
                emptyModal();
                close();
                resetUpdateStatus();
            }, 2000);
            parseOsOptionsPatientAcesss(patientAccess);
        }
    }, [editPatientStatus.success, editPatientStatus.error]);

    const generateAccess = () => {
        const newAccess = {
            patientId: patient.id,
            owner: userId === access,
            tipo_usuario: role,
            publicKey: roleOptions
                .filter((r) => r.value === access)
                .map((r) => r.pb)
                .join(''),
            [roleField]: access,
        };
        // updPatientWithNewAccess will be deprecated since new access endpoints
        updPatientWithNewAccess({ ...patient, accesos: [...patientAccess, newAccess] });
        onNewAccess(newAccess); // we will pass it through directly
    };

    const parseOsOptionsPatientAcesss = (updAccess) => {
        const hasDrogueria = updAccess.some((obj) => Object.hasOwn(obj, 'drogueria'));
        const filteredArray1 = hasDrogueria
            ? accessRole.filter((obj) => obj.value !== 'D')
            : accessRole;
        setOptionsRole(filteredArray1);
    };

    return (
        <>
            {show && (
                <div className='new-user-modal-background'>
                    <Card
                        className={`new-user-modal  ${role ? 'big' : 'small'}`}
                        classHead='modal-card-head'
                        classBody={`new-user-modal-children-container ${
                            showMessage && 'show-message'
                        }`}
                        detail={
                            <div className='close-modal-container' onClick={close}>
                                <img src={closeModal} alt='close-modal' />
                            </div>
                        }
                    >
                        <span className='new-user-header'>
                            {I18n.t('roleAccess.newAccess.addNew')}
                        </span>
                        <CustomSelect
                            value={role}
                            onSelect={(v) => {
                                setRole(v);
                            }}
                            options={optionsRole}
                            disabled={role === 'D'}
                            placeholder={I18n.t('roleAccess.newAccess.selectRole')}
                        ></CustomSelect>
                        {role &&
                            getOptionsStatus.success &&
                            (role === 'P' ? (
                                getOptionsStatus.isOperator && (
                                    <OperatorSelect
                                        operator={
                                            options?.find((op) =>
                                                op.sucursales.find(
                                                    (sucursal) =>
                                                        sucursal ===
                                                        op.sucursales?.find(
                                                            (suc) => suc.id === access,
                                                        ),
                                                ),
                                            )?.razon_social
                                        }
                                        operators={roleOptions}
                                        setOperator={(v) => setAccess(v)}
                                        value={access}
                                        className='rounded i-screen'
                                        hideLabels
                                    />
                                )
                            ) : (
                                <CustomSelect
                                    value={access}
                                    onSelect={(v) => setAccess(v)}
                                    options={roleOptions}
                                    placeholder='.'
                                />
                            ))}
                        <Button
                            text={I18n.t('roleAccess.newAccess.confirm')}
                            className='new-user-button small'
                            onClick={generateAccess}
                        />
                        <div className='wrapper-notification-modal'>
                            <SuccessBox
                                text={I18n.t('roleAccess.newAccess.successMessage')}
                                show={showMessage}
                            />
                            <ErrorBox
                                show={showError}
                                className='title-error notification-wallet-error'
                                errorMessage={editPatientStatus?.message}
                                icon='false'
                            />
                        </div>
                    </Card>
                </div>
            )}
        </>
    );
};
export default NewAccessModal;
