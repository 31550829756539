import { connect } from 'react-redux';
import actions from '../../actions';
import EditPatientScreen from '../components/EditPatientScreen';
import service from '../patient.services';

const mapStateToProps = (state) => ({
    patientDetails: state.patient.editionDetails,
    editPatientStatus: state.patient.editPatientStatus,
    editPatientWithRollbackStatus: state.patient.editPatientWithRollbackStatus,

    contracts: state.common.OSContracts,
    username: state.profile.user.username,
    userType: state.profile.user.type,
    osId: state.session.os_id,

    patients: state.patient.results,
});

const mapDispatchToProps = (dispatch) => ({
    loadPatientData: (patient) => dispatch(actions.patient.setPatientDetails(patient)),
    postPatient: (patient) => dispatch(actions.patient.createNewPatient(patient)),
    editPatientWithRollback: (previousPatientData, id, newPatientData, newAccesses) =>
        dispatch(
            actions.patient.editPatientWithRollback(
                previousPatientData,
                id,
                newPatientData,
                newAccesses,
            ),
        ),

    getOSContracts: (x) => dispatch(actions.common.getOSContracts(x)),
    setContractSelected: (contract) => dispatch(actions.contract.setContractSelected(contract)),
    getProgramedDose: (contract) => dispatch(actions.patient.getProgramedDose(contract)),
    getAuditorProgramedDose: (contract) =>
        dispatch(actions.patient.getAuditorProgramedDose(contract)),

    setBreadcrumbs: (bcrms) => dispatch(actions.common.setBreadcrumbs(bcrms)),

    getContractOs: (id, successCallback) =>
        dispatch(actions.contract.getContractOs(id, successCallback)),
    getFileToS3: (url) => service.getFileToS3(url),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPatientScreen);
