import { connect } from 'react-redux';
import IndicationsScreen from '../components/IndicationsScreen';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    selectedIndication: state.indication.selectedIndication,
    setIndicationStatus: state.indication.setIndicationStatus,
    evidenceTypes: state.indication.evidenceTypes,
    getEvidenceTypeStatus: state.indication.getEvidenceTypeStatus,
});

const mapDispatchToProps = (dispatch) => ({
    putIndication: (ind) => dispatch(actions.indication.putIndication(ind)),
    postIndication: (ind) => dispatch(actions.indication.postIndication(ind)),

    getProdFamily: () => dispatch(actions.indication.getProdFamily()),
    getEvidenceType: () => dispatch(actions.indication.getEvidenceType()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndicationsScreen);
