import React, { useEffect, useState, useRef } from 'react';
import './Contract.scss';
import { I18n } from 'react-redux-i18n';
import TempInput from '../../../common/components/TempInput/TempInput';

const benefitSort = (a, b) => {
    if (a.aplicacion_desde < b.aplicacion_desde) return -1;
    if (a.aplicacion_desde > b.aplicacion_desde) return 1;
    if (a.aplicacion_hasta < b.aplicacion_hasta) return -1;
    if (a.aplicacion_hasta > b.aplicacion_hasta) return 1;
    if (a.familia_producto < b.familia_producto) return -1;
    if (a.familia_producto > b.familia_producto) return 1;
    if (a.producto < b.producto) return -1;
    if (a.producto > b.producto) return 1;
    return 0;
};

const benefitDateTypes = [
    { value: 'A', label: 'Aplicación' },
    { value: 'U', label: 'Última aplicación' },
    { value: 'P', label: 'Primera aplicación' },
];

const benefitTypes = [
    { value: 'F', label: 'Fijo' },
    { value: 'V', label: '%PVP' },
    { value: 'S', label: '%PSL' },
];

const BenefitRow = ({
    product,
    products,
    benefit,
    updBenefit,
    isSur,
    operatorCalculateBen,
    typeContractSelected,
}) => {
    const [disableReferenceProduct, setDisableReferenceProduct] = useState(false);
    const [benefitType, setBenefitType] = useState('F');
    const _benefit = {
        ...benefit,
    };

    const setAtt = (obj) => {
        if (obj.tipo_beneficio) setBenefitType(obj.tipo_beneficio);

        if (obj?.tipo_beneficio === 'S' || obj?.tipo_beneficio === 'V') {
            let setProduct = { producto_ref: _benefit.producto, ...obj };
            return updBenefit({ ..._benefit, ...setProduct });
        }

        updBenefit({ ..._benefit, ...obj });
    };

    const edit = () => {
        updBenefit({ ...benefit, _modify: true, _editMode: true });
    };

    const submit = () => {
        if (benefit?.tipo_beneficio === 'F') {
            const { producto_ref, ...rest } = _benefit;
            updBenefit({ ...rest, _modify: false, _editMode: false });
        } else {
            updBenefit({ ..._benefit, _modify: false, _editMode: false });
        }
    };

    const formatBenefit = (type, benefit) => {
        if (!type || benefit === null || benefit === undefined || isNaN(benefit) || benefit < 0)
            return '';

        if (type === 'F') {
            const numberFormat2 = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });
            return numberFormat2.format(benefit);
        } else {
            if (benefit <= 100) {
                return benefit + ' %';
            } else {
                return '0 %';
            }
        }
    };

    React.useEffect(() => {
        if (_benefit?.tipo_beneficio === 'F') {
            setDisableReferenceProduct(true);
        } else if (_benefit?.tipo_beneficio === 'S' || _benefit?.tipo_beneficio === 'V') {
            setDisableReferenceProduct(false);
        }
    }, [_benefit]);

    return benefit._editMode ? (
        <>
            <td className={isSur ? 'width-20' : ''}>{product?.denominacion}</td>
            <td>
                <TempInput
                    options={benefitTypes}
                    className='csoc_mw'
                    type='select'
                    tempObj={_benefit}
                    att='tipo_beneficio'
                    setAtt={setAtt}
                />
            </td>
            <td>
                <TempInput
                    options={
                        disableReferenceProduct
                            ? []
                            : products?.sort((a, b) =>
                                  a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
                              )
                    }
                    className='csoc_mw mxh'
                    type='select'
                    tempObj={_benefit}
                    att='producto_ref'
                    setAtt={setAtt}
                    searchable={true}
                    disabled={disableReferenceProduct}
                />
            </td>
            <td>
                <TempInput
                    type={benefitType === 'F' ? 'uint' : 'uintlimithundred'}
                    tempObj={_benefit}
                    att='beneficio'
                    setAtt={setAtt}
                />
            </td>
            {typeContractSelected === 3 && (
                <>
                    <td>
                        <TempInput
                            options={benefitDateTypes}
                            type='select'
                            className='csoc_mw'
                            tempObj={_benefit}
                            att='tipo_fecha_ben'
                            setAtt={setAtt}
                            disabled={disableReferenceProduct}
                        />
                    </td>
                    <td>
                        <TempInput
                            options={operatorCalculateBen}
                            className='csoc_mw'
                            type='select'
                            tempObj={_benefit}
                            att='operador_calculo_ben'
                            setAtt={setAtt}
                            disabled={disableReferenceProduct}
                        />
                    </td>
                    <td>
                        <TempInput
                            tempObj={_benefit}
                            className='csoc_mw'
                            att='operando_calculo_ben'
                            setAtt={setAtt}
                            disabled={disableReferenceProduct}
                        />
                    </td>
                </>
            )}
            <td onClick={submit}>
                <i className='icon-note' />
            </td>
        </>
    ) : (
        <>
            <td>{product?.denominacion}</td>
            <td>{benefitTypes?.find((b) => b.value === benefit?.tipo_beneficio)?.label}</td>
            <td>
                {!disableReferenceProduct &&
                    products?.find((p) => p.id === benefit?.producto_ref)?.label}
            </td>
            <td>{formatBenefit(benefit?.tipo_beneficio, benefit?.beneficio)}</td>
            {typeContractSelected === 3 && (
                <>
                    <td>
                        {benefitDateTypes?.find((b) => b.value === benefit?.tipo_fecha_ben)?.label}
                    </td>
                    <td>
                        {
                            operatorCalculateBen?.find(
                                (c) => c.value === benefit?.operador_calculo_ben,
                            )?.label
                        }
                    </td>
                    <td>{benefit?.operando_calculo_ben}</td>
                </>
            )}
            <td onClick={edit}>
                <i className='icon-pencil' />
            </td>
        </>
    );
};

const BenefitGroup = ({
    benefits,
    updBenefit,
    updGroup,
    groupId,
    deleteGroup,
    product_families,
    operatorCalculateBen,
    products,
    isSur,
    typeContractSelected,
}) => {
    console.log('BENEFIT GROUP', {
        benefits,
        updBenefit,
        updGroup,
        groupId,
        deleteGroup,
        product_families,
        operatorCalculateBen,
        products,
        isSur,
        typeContractSelected,
    });
    const pLen = benefits?.length;
    const prodFam = product_families?.find((pf) => pf.id === benefits[0]?.familia_producto);

    const [dates, setDates] = useState({
        aplicacion_desde: benefits[0]?.aplicacion_desde,
        aplicacion_hasta: benefits[0]?.aplicacion_hasta,
    });
    const setDate = (obj) => setDates({ ...dates, ...obj });

    const firstModify = benefits?.find((b) => b._modify);

    const _mod = useRef(false);
    const _updBenefit = (benefit) => {
        const _firstModify = benefits?.find((b) => b._modify && b._id !== benefit._id);
        if (_firstModify !== undefined) {
            updBenefit(benefit);
        } else if (_mod.current) {
            updGroup(groupId, dates, [benefit]);
        } else {
            updBenefit(benefit);
            _mod.current = true;
        }
    };

    return (
        <>
            <tbody className='GreyTable-CustomBenefits'>
                {benefits?.map((benefit, i) => {
                    const prod = prodFam?.products?.find((p) => p.id == benefit.producto);

                    return (
                        <tr className='tbodyRest' key={benefit._id}>
                            {i === 0 && (
                                <>
                                    {!isSur && firstModify ? (
                                        <>
                                            <td rowSpan={pLen}>
                                                <TempInput
                                                    att='aplicacion_desde'
                                                    type='int'
                                                    tempObj={dates}
                                                    setAtt={setDate}
                                                />
                                            </td>
                                            <td rowSpan={pLen}>
                                                <TempInput
                                                    att='aplicacion_hasta'
                                                    type='int'
                                                    tempObj={dates}
                                                    setAtt={setDate}
                                                />
                                            </td>
                                        </>
                                    ) : (
                                        !isSur && (
                                            <>
                                                <td rowSpan={pLen}>{benefit?.aplicacion_desde}</td>
                                                <td rowSpan={pLen}>{benefit?.aplicacion_hasta}</td>
                                            </>
                                        )
                                    )}
                                    <td rowSpan={pLen} className={isSur ? 'width-20' : ''}>
                                        {prodFam?.denominacion}
                                    </td>
                                </>
                            )}

                            <BenefitRow
                                product={prod}
                                benefit={benefit}
                                updBenefit={_updBenefit}
                                products={products}
                                isSur={isSur}
                                operatorCalculateBen={operatorCalculateBen}
                                typeContractSelected={typeContractSelected}
                            />

                            {i === 0 && (
                                <>
                                    <td rowSpan={pLen}>
                                        <i
                                            onClick={() => deleteGroup(groupId)}
                                            className='icon-trash'
                                        />
                                    </td>
                                </>
                            )}
                        </tr>
                    );
                })}
            </tbody>
            <br />
        </>
    );
};

const BenefitsTable = ({
    indication,
    benefits,
    setBenefits,
    operatorCalculateBen,
    product_families,
    isSur,
    readOnly,
    typeContractSelected,
}) => {
    console.log('BENEFITS TABLE', {
        indication,
        benefits,
        setBenefits,
        operatorCalculateBen,
        product_families,
        isSur,
        readOnly,
        typeContractSelected,
    });
    const pFams = indication?.indicacion_familiaprod?.map((pd) => pd.familia_producto);
    const filteredFams = Array.from(new Set(pFams));
    const products = product_families?.reduce(
        (acc, pf) => [
            ...acc,
            ...(pf.products?.map((p) => ({
                ...p,
                value: p.id,
                label: p.familia + ' | ' + p.denominacion,
            })) ?? []),
        ],
        [],
    );

    const groups = benefits ? Array.from(new Set(benefits?.map((b) => b.groupId))) : undefined;

    useEffect(() => {
        // Check if all product families are loaded
        for (const fam of filteredFams) {
            if (product_families?.find((f) => f.id === fam) == undefined) return;
        }

        // If benefits already exist    <<< <<< <<<
        if (benefits != undefined) {
            // Make groups of existing benefits
            setBenefits(
                benefits?.map((b) => ({
                    ...b,
                    groupId:
                        b.aplicacion_desde + '-' + b.aplicacion_hasta + '-' + b.familia_producto,
                    _editMode: false,
                })),
            );
            return;
        }
        // If benefits don't exist      >>> >>> >>>

        // Get the indication benefits (families)
        const _benefits = indication?.indicacion_familiaprod;
        if (_benefits == undefined) return;

        // Expand the indication benefits ( families -> products of each family )
        let id_counter = 0;
        const newBenefits = _benefits?.reduce((acumulated, benefit) => {
            const addedBenefits =
                product_families
                    ?.find((f) => f.id === benefit.familia_producto)
                    ?.products?.map((prod) => ({
                        ...benefit,
                        ...prod,
                        id: undefined,
                        _id: id_counter++,
                        producto: prod.id,
                        groupId:
                            benefit.aplicacion_desde +
                            '-' +
                            benefit.aplicacion_hasta +
                            '-' +
                            benefit.familia_producto,
                        _editMode: false,
                    })) ?? [];
            return [...acumulated, ...addedBenefits];
        }, []);

        // Sort the benefits by date family and product
        newBenefits.sort(benefitSort);
        setBenefits(newBenefits);
    }, [product_families]);

    const deleteGroup = (groupId) => {
        if (readOnly) return;
        setBenefits(benefits?.filter((b) => b.groupId != groupId));
    };

    const updBenefit = (benefit) => {
        if (readOnly) return;
        const newBenefits = benefits?.map((b) => {
            if (b._id === benefit._id) {
                return benefit;
            }
            return b;
        });
    };

    const updGroup = (groupId, dates, _benefits) => {
        if (readOnly) return;
        const newBenefits = benefits?.map((b) => {
            if (b.groupId === groupId) {
                const _b = _benefits?.find((_b) => _b._id === b._id);
                return {
                    ...b,
                    ...(_b ?? {}),
                    ...dates,
                    groupId:
                        dates.aplicacion_desde +
                        '-' +
                        dates.aplicacion_hasta +
                        '-' +
                        b.familia_producto,
                };
            }
            return b;
        });
        newBenefits.sort(benefitSort);
        setBenefits(newBenefits);
    };

    return (
        <>
            <table className='GreyTable-CustomBenefits'>
                <thead>
                    <tr>
                        {!isSur && (
                            <th colSpan={2}>
                                {I18n.t(
                                    'indication.details.indication.durationTypes.' +
                                        indication?.tipo_duracion,
                                )}
                                <br />
                                {I18n.t('contract.versionDetail.benefitCard.header.sinceTo')}
                            </th>
                        )}
                        <th className={isSur ? 'width-20' : ''}>
                            {I18n.t('contract.versionDetail.benefitCard.header.productFamily')}
                        </th>
                        <th className={isSur ? 'width-20' : ''}>
                            {I18n.t('contract.versionDetail.benefitCard.header.presentation')}
                        </th>
                        <th>{I18n.t('contract.versionDetail.benefitCard.header.benefitType')}</th>
                        <th>
                            {I18n.t('contract.versionDetail.benefitCard.header.referenceProduct')}
                        </th>
                        <th>{I18n.t('contract.versionDetail.benefitCard.header.benefit')}</th>

                        {typeContractSelected === 3 && (
                            <>
                                <th>{I18n.t('contract.versionDetail.benefitCard.header.fech')} </th>
                                <th>
                                    {I18n.t('contract.versionDetail.benefitCard.header.calculator')}
                                </th>
                                <th>
                                    {I18n.t(
                                        'contract.versionDetail.benefitCard.header.calculation',
                                    )}
                                </th>
                            </>
                        )}
                        <th colSpan={2}>
                            {I18n.t('contract.versionDetail.benefitCard.header.action')}
                        </th>
                    </tr>
                </thead>
                {groups?.map((group, i) => (
                    <BenefitGroup
                        key={group}
                        benefits={benefits?.filter((b) => b.groupId === group)}
                        updBenefit={updBenefit}
                        deleteGroup={deleteGroup}
                        groupId={group}
                        product_families={product_families}
                        products={products}
                        operatorCalculateBen={operatorCalculateBen}
                        updGroup={updGroup}
                        isSur={isSur}
                        typeContractSelected={typeContractSelected}
                    />
                )) ?? 'No hay beneficios'}
            </table>
        </>
    );
};

export default BenefitsTable;
