export const UserTypes = {
    OS_USER: 'O',
    OS_USER_WITHOUT_PUBLIC_KEY: 'OS_USER_WITHOUT_PUBLIC_KEY',
    OS_USER_WITHOUT_PRIVATE_KEY: 'OS_USER_WITHOUT_PRIVATE_KEY',
    OS_USER_WITHOUT_OLD_PRIVATE_KEY: 'OS_USER_WITHOUT_OLD_PRIVATE_KEY',
    REGISTER_USER: 'R',
    REGISTER_USER_WITHOUT_PUBLIC_KEY: 'REGISTER_USER_WITHOUT_PUBLIC_KEY',
    REGISTER_USER_WITHOUT_PRIVATE_KEY: 'REGISTER_USER_WITHOUT_PRIVATE_KEY',
    USER: 'U', // Is the basic user created at registrar sign up,
    A_AUDITOR: 'A', // BACK Changed and now sends this type, not sure what it signifies
    AUDITOR_WITHOUT_PUBLIC_KEY: 'AUDITOR_WITHOUT_PUBLIC_KEY',
    AUDITOR_WITHOUT_PRIVATE_KEY: 'AUDITOR_WITHOUT_PRIVATE_KEY',
    AUDITOR_WITHOUT_OLD_PRIVATE_KEY: 'AUDITOR_WITHOUT_OLD_PRIVATE_KEY',
    AUDITOR: 'AUDITOR',
    ROCHE_USER: 'RO',
    ROCHE_USER_WITHOUT_PRIVATE_KEY: 'ROCHE_USER_WITHOUT_PRIVATE_KEY',
    DRUGSTORE: 'D',
    DRUGSTORE_WITHOUT_PUBLIC_KEY: 'D_WITHOUT_PUBLIC_KEY',
    DRUGSTORE_WITHOUT_PRIVATE_KEY: 'D_WITHOUT_PRIVATE_KEY',
    DRUGSTORE_WITHOUT_OLD_PRIVATE_KEY: 'D_WITHOUT_OLD_PRIVATE_KEY',
};

export const UserTypesGroups = {
    OS_USER_GROUP: 'OS_USER_GROUP',
    AUDIT_USER_GROUP: 'AUDIT_USER_GROUP',
    DRUGSTORE_USER_GROUP: 'DRUGSTORE_USER_GROUP',
};

export const TRACE_DEFAULT_LENGTH = 50;

export const TRANSACTION_STATUS = {
    PENDING: 'P',
    REJECTED: 'R',
    VALIDATED: 'V',
    VALIDATION_ERROR: 'E',
    PAID: 'G',
    POSTED: 'C',
    PROCESSING_PAYMENT: 'O',
    INTERFACE_ERROR_SAP: 'I',
};

export const MONITOR_TRANSACTION_STATUS = {
    RECEIVED: 'R',
    SENT: 'E',
    ERROR: 'F',
};

export const InstitutionTypes = {
    PHARMACY: 'F',
    INFUSION_CENTER: 'I',
    OTHERS: 'O',
    TAKE_CARE_OR_PATIENT: 'C',
};

export const RocheTransactionDownloadType = {
    ALL: 'ALL',
    EMITTED: 'EMITTED',
    RECEIVED: 'RECEIVED',
};

export const NexTransactionsOriginsAndDestinations = {
    OS: 'O',
    DRUGSTORE: 'D',
    ROCHE: 'R',
};

export const AssetsRefs = {
    NEX: '173752-301-9620',
};
