import React, { useEffect, useState } from 'react';
import TitleBar from '../../../../common/components/TitleBar/TitleBar';
import { I18n } from 'react-redux-i18n';
import GrantCard from './GrantCard';
import './GrantList.scss';
import Button from '../../../../common/components/Button/Button';
import PasteAddressInput from '../../common/PasteAddress/PasteAddressInput';
import Loader from '../../../../common/components/Loader';
import ErrorBox from '../../../../common/components/ErrorBox';
import LoadingOverlay from 'react-loading-overlay';

const GrantList = (props) => {
    const [show, setShow] = useState(false);
    const [grants, setGrants] = useState(props.grants);
    const [newGrant, setNewGrant] = useState('');

    const [action, setAction] = useState('');
    const [showNotifaction, setShowNotication] = useState(false);

    useEffect(() => {
        props.getWhitelisters();
    }, []);

    useEffect(() => {
        setGrants(props.grants);
    }, [props.grants, props.addressWhiteListersStatus]);

    useEffect(() => {
        setShow(props.addRemoveWhiteListersStatus.loading ?? false);
        setNewGrant(
            props.addRemoveWhiteListersStatus.success || props.addRemoveWhiteListersStatus.error
                ? ''
                : newGrant,
        );
        setShowNotication(
            props.addRemoveWhiteListersStatus.success || props.addRemoveWhiteListersStatus.error
                ? true
                : false,
        );
    }, [props.addRemoveWhiteListersStatus]);

    useEffect(() => {
        if (showNotifaction) {
            setTimeout(() => {
                setShowNotication(false); //look for a better solution to put show => false
            }, 2500);
        }
    }, [showNotifaction]);

    return (
        <div className='grant-container'>
            <div style={{ borderBottom: '2px solid #f3f3f3', width: '100%', margin: '1vh 0.75%' }}>
                <div className='title-bar-container' style={{ padding: '0' }}>
                    <TitleBar
                        title={I18n.t('wallet.monetaryBase.whoCanGrant')}
                        backgroundUnderline='light-green fixed'
                        titleClass='wallet-title'
                    />
                </div>
            </div>
            <div className='grant-list-container'>
                {grants.length > 0 ? (
                    grants?.map((grant, i) => (
                        <GrantCard
                            key={`grant-card-${i}`}
                            grant={grant}
                            deleteGrant={() => (
                                props.removeWhitelisters(grant), setAction('delete')
                            )}
                        />
                    ))
                ) : (
                    <div className='wrapper-loader'>
                        {' '}
                        <Loader />
                    </div>
                )}
            </div>
            <div className='add-address-container'>
                <PasteAddressInput
                    value={newGrant}
                    className='base'
                    handleChange={(text) => setNewGrant(text)}
                    address={newGrant}
                />

                {props.addRemoveWhiteListersStatus !== undefined && (
                    <div className='wrapper-container'>
                        <ErrorBox
                            show={showNotifaction}
                            className={`title-error ${
                                props.addRemoveWhiteListersStatus?.success
                                    ? 'notification-wallet-success'
                                    : props.addRemoveWhiteListersStatus.error
                                    ? 'notification-wallet-error'
                                    : ''
                            }`}
                            errorMessage={
                                props.addRemoveWhiteListersStatus.error
                                    ? I18n.t(
                                          'wallet.transaction.message.addRemoveWhiteListers.error',
                                      )
                                    : props.addRemoveWhiteListersStatus.success
                                    ? I18n.t(
                                          'wallet.transaction.message.addRemoveWhiteListers.success',
                                      )
                                    : ''
                            }
                            icon='false'
                        />
                    </div>
                )}
                <div style={{ marginBottom: '0.5%' }}>
                    <Button
                        className='primary with-gradient wide'
                        text={I18n.t('wallet.monetaryBase.add')}
                        onClick={() => (props.addWhitelisters(newGrant), setAction('add'))}
                        loading={props.addRemoveWhiteListersStatus.loading && action !== 'delete'}
                        disabled={!newGrant}
                    />
                </div>
            </div>
            {show && (
                <div className='fullscreen-overlay'>
                    <LoadingOverlay
                        active={show}
                        spinner
                        text={I18n.t('wallet.monetaryBase.sendMetaMask')}
                    />
                </div>
            )}
        </div>
    );
};
export default GrantList;
