import React from 'react';
import './NoPhotoModal.scss';
import Button from '../../../common/components/Button';
import { SimpleModal } from '../../../common/components/Modal';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import NoPhotoIcon from '../../../assets/icons/no-photo.png';

const NoPhotoModal = (props) => {
    return (
        <SimpleModal show={props.show}>
            <div className='no-photo-modal'>
                <div className='header'>
                    <img alt='no photo' src={NoPhotoIcon} />
                    <span className='title'>{I18n.t('profile.noPhotoModal.title')}</span>
                </div>
                <div className='body'>
                    <p>
                        {I18n.t('profile.noPhotoModal.bodyPart1')}{' '}
                        <b>{I18n.t('profile.noPhotoModal.boldWord1')} </b>
                        {I18n.t('profile.noPhotoModal.bodyPart2')}{' '}
                        <b>{I18n.t('profile.noPhotoModal.boldWord2')}</b>.
                    </p>
                </div>
                <div className='footer'>
                    <Button
                        className='primary'
                        text={I18n.t('profile.noPhotoModal.button')}
                        onClick={props.onClick}
                    />
                </div>
            </div>
        </SimpleModal>
    );
};

NoPhotoModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default NoPhotoModal;
