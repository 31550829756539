import { connect } from 'react-redux';
import actions from '../../actions';
import RegistrarAccountActivationScreen from '../components/RegistrarAccountActivationScreen';

const mapStateToProps = (state) => ({
    activationStatus: state.registrar.activateRegistrarAccountStatus,
});

const mapDispatchToProps = (dispatch) => ({
    activateAccount: (uid, token) =>
        dispatch(actions.registrar.activateRegistrarAccount(uid, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrarAccountActivationScreen);
