import { connect } from 'react-redux';
import actions from '../../actions';
import CycleCell from '../components/OSTransactionsScreen/CycleCell';

const mapStateToProps = (state) => ({
    programedDoses: state.patient.programedDoses,
});

const mapDispatchToProps = (dispatch) => ({
    getProgramedDoses: (contract) => dispatch(actions.patient.getProgramedDose(contract)),
    clearProgrammedDoses: () => dispatch(actions.patient.clearProgrammedDoses()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CycleCell);
