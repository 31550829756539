export const GET_INDICATIONS = 'GET_INDICATIONS';
export const GET_INDICATIONS_RESPONSE = 'GET_INDICATIONS_RESPONSE';
export const GET_INDICATIONS_ERROR = 'GET_INDICATIONS_ERROR';

export const SET_INDICATION_SELECTED = 'SET_INDICATION_SELECTED';

export const PUT_INDICATION = 'PUT_INDICATION';
export const PUT_INDICATION_RESPONSE = 'PUT_INDICATION_RESPONSE';
export const PUT_INDICATION_ERROR = 'PUT_INDICATION_ERROR';

export const POST_INDICATION = 'POST_INDICATION';
export const POST_INDICATION_RESPONSE = 'POST_INDICATION_RESPONSE';
export const POST_INDICATION_ERROR = 'POST_INDICATION_ERROR';

export const UPDATE_INDICATIONS_PAGE = 'UPDATE_INDICATIONS_PAGE';
export const UPDATE_INDICATIONS_PAGE_SIZE = 'UPDATE_INDICATIONS_PAGE_SIZE';

export const GET_PRODFAMILY = 'GET_PRODFAMILY';
export const GET_PRODFAMILY_RESPONSE = 'GET_PRODFAMILY_RESPONSE';
export const GET_PRODFAMILY_ERROR = 'GET_PRODFAMILY_ERROR';

export const GET_FAMILY_PRODUCTS = 'GET_FAMILY_PRODUCTS';
export const GET_FAMILY_PRODUCTS_RESPONSE = 'GET_FAMILY_PRODUCTS_RESPONSE';
export const GET_FAMILY_PRODUCTS_ERROR = 'GET_FAMILY_PRODUCTS_ERROR';

export const GET_EVIDENCE_TYPE = 'GET_EVIDENCE_TYPE';
export const GET_EVIDENCE_TYPE_RESPONSE = 'GET_EVIDENCE_TYPE_RESPONSE';
export const GET_EVIDENCE_TYPE_ERROR = 'GET_EVIDENCE_TYPE_ERROR';

export const SEARCH_INDICATIONS = 'SEARCH_INDICATIONS';

export default {
    getIndications: (ind) => ({ type: GET_INDICATIONS, ind }),
    getIndicationsResponse: (response, ind) => ({ type: GET_INDICATIONS_RESPONSE, response, ind }),
    getIndicationsError: (error, ind) => ({ type: GET_INDICATIONS_ERROR, error, ind }),

    setIndicationSelected: (body) => ({ type: SET_INDICATION_SELECTED, body }),

    putIndication: (ind) => ({ type: PUT_INDICATION, ind }),
    putIndicationResponse: (response) => ({ type: PUT_INDICATION_RESPONSE, response }),
    putIndicationError: (error) => ({ type: PUT_INDICATION_ERROR, error }),

    postIndication: (ind) => ({ type: POST_INDICATION, ind }),
    postIndicationResponse: (response) => ({ type: POST_INDICATION_RESPONSE, response }),
    postIndicationError: (error) => ({ type: POST_INDICATION_ERROR, error }),

    updatePage: (pageNumber) => ({ type: UPDATE_INDICATIONS_PAGE, pageNumber }),
    updatePageSize: (pageSize) => ({ type: UPDATE_INDICATIONS_PAGE_SIZE, pageSize }),

    getProdFamily: (getProducts) => ({ type: GET_PRODFAMILY, getProducts }),
    getProdFamilyResponse: (response) => ({ type: GET_PRODFAMILY_RESPONSE, response }),
    getProdFamilyError: (error) => ({ type: GET_PRODFAMILY_ERROR, error }),

    getFamilyProducts: (fam) => ({ type: GET_FAMILY_PRODUCTS, fam }),
    getFamilyProductsResponse: (response, fam) => ({
        type: GET_FAMILY_PRODUCTS_RESPONSE,
        response,
        fam,
    }),
    getFamilyProductsError: (error) => ({ type: GET_FAMILY_PRODUCTS_ERROR, error }),

    getEvidenceType: () => ({ type: GET_EVIDENCE_TYPE }),
    getEvidenceTypeResponse: (response) => ({ type: GET_EVIDENCE_TYPE_RESPONSE, response }),
    getEvidenceTypeError: (error) => ({ type: GET_EVIDENCE_TYPE_ERROR, error }),

    searchIndications: (search) => ({ type: SEARCH_INDICATIONS, search }),
};
