import React from 'react';
import './SuccessAccountCreationModal.scss';
import { SimpleModal } from '../../../common/components/Modal';
import { I18n } from 'react-redux-i18n';
import Button from '../../../common/components/Button';
import PropTypes from 'prop-types';

const SuccessAccountCreationModal = (props) => {
    return (
        <div className='success-account-creation-modal'>
            <SimpleModal show={props.show}>
                <i className='icon-check' />
                <span className='title'>
                    {props.newInstitutionAndBranchCreated &&
                        !props.newBranchCreated &&
                        I18n.t(
                            'registrarNewAccount.successAccountCreationModal.branchAndInstitutionCreated',
                        )}
                    {props.newBranchCreated &&
                        !props.newInstitutionAndBranchCreated &&
                        I18n.t('registrarNewAccount.successAccountCreationModal.branchCrated')}
                    {!props.newInstitutionAndBranchCreated &&
                        !props.newBranchCreated &&
                        I18n.t('registrarNewAccount.successAccountCreationModal.branchSelected')}
                </span>
                <Button
                    className='primary small'
                    text={I18n.t('registrarNewAccount.successAccountCreationModal.button')}
                    loading={props.createRegistrarStatus.loading}
                    onClick={props.onClick}
                />
            </SimpleModal>
        </div>
    );
};

SuccessAccountCreationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    newBranchCreated: PropTypes.bool.isRequired,
    newInstitutionAndBranchCreated: PropTypes.bool.isRequired,
    createRegistrarStatus: PropTypes.object.isRequired,
};

export default SuccessAccountCreationModal;
