import React from 'react';
import './RoundIconContainer.scss';
const RoundIconContainer = (props) => {
    return (
        <div className={`round-icon-container  ${props.color} ${props.className}`}>
            {props.children}
        </div>
    );
};
export default RoundIconContainer;
