import PropTypes from 'prop-types';
import React from 'react';

const CopyKeyBtn = ({ width, height, color, onClick }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox='0 0 19 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            onClick={onClick}
        >
            <path
                d='M9.025 18.01H6.017C2.54 18.01.99 16.46.99 12.983V9.975c0-3.476 1.551-5.027 5.027-5.027h2.375c.324 0 .593.269.593.593 0 .325-.269.594-.593.594H6.017c-2.834 0-3.84 1.006-3.84 3.84v3.008c0 2.834 1.006 3.84 3.84 3.84h3.008c2.834 0 3.84-1.006 3.84-3.84v-2.375c0-.324.269-.594.593-.594.325 0 .594.27.594.594v2.375c0 3.476-1.552 5.027-5.027 5.027z'
                fill={color}
            />
            <path
                d='M13.458 11.202h-2.533c-2.225 0-3.127-.902-3.127-3.127V5.542c0-.238.142-.46.364-.546a.599.599 0 0 1 .65.126l5.066 5.067a.596.596 0 0 1-.42 1.013zM8.985 6.975v1.1c0 1.576.365 1.94 1.94 1.94h1.1l-3.04-3.04zM12.35 2.177H9.183a.598.598 0 0 1-.593-.594c0-.324.269-.593.593-.593h3.167c.325 0 .594.269.594.593 0 .325-.27.594-.594.594z'
                fill={color}
            />
            <path
                d='M5.542 4.552a.598.598 0 0 1-.594-.593A2.97 2.97 0 0 1 7.917.988H9.99c.324 0 .594.27.594.594 0 .325-.27.594-.594.594H7.917c-.982 0-1.782.8-1.782 1.781 0 .325-.269.594-.593.594zM15.192 14.052a.598.598 0 0 1-.594-.593c0-.325.27-.594.594-.594.903 0 1.63-.736 1.63-1.631v-4.9c0-.325.27-.594.595-.594.324 0 .593.269.593.593v4.9a2.825 2.825 0 0 1-2.818 2.82z'
                fill={color}
            />
            <path
                d='M17.417 6.927h-2.375c-2.106 0-2.97-.863-2.97-2.969V1.583c0-.237.143-.459.365-.546a.599.599 0 0 1 .65.127l4.75 4.75a.596.596 0 0 1-.42 1.013zm-4.157-3.91v.941c0 1.449.333 1.782 1.782 1.782h.942L13.26 3.016z'
                fill={color}
            />
        </svg>
    );
};
CopyKeyBtn.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
};
export default CopyKeyBtn;
