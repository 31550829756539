import React from 'react';
import './SuccessBox.scss';

const SuccessBox = ({ text, show, className }) => {
    return (
        <>
            {show && (
                <div className={`success-box ${className}`}>
                    <div className='success-box-text'> {text}</div>
                </div>
            )}
        </>
    );
};
export default SuccessBox;
