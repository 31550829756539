import React from 'react';
import './LinkAccess.scss';
import PropTypes from 'prop-types';

const LinkAccess = ({ className, titleStyle, icon, title, handleClick, style, href }) => {
    return (
        <a
            className={className ?? 'container-link'}
            onClick={handleClick}
            style={style}
            href={href}
        >
            <div className='link-icon'>{icon}</div>
            <span className={titleStyle ?? 'link-access-text'}>{title}</span>
        </a>
    );
};

LinkAccess.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.element,
    title: PropTypes.string,
};

export default LinkAccess;
