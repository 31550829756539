import * as React from 'react';
import PropTypes from 'prop-types';

const IconCopy = (props) => (
    <svg width={19} height={19} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            d='M9.025 18.01H6.017C2.54 18.01.99 16.46.99 12.985v-3.01c0-3.475 1.55-5.027 5.027-5.027h2.375a.6.6 0 0 1 .594.594c0 .325-.27.594-.594.594H6.017c-2.834 0-3.84 1.005-3.84 3.84v3.008c0 2.834 1.006 3.84 3.84 3.84h3.008c2.834 0 3.84-1.006 3.84-3.84v-2.375c0-.325.269-.594.594-.594.324 0 .593.27.593.594v2.375c0 3.475-1.551 5.027-5.027 5.027z'
            fill={props.color}
        />
        <path
            d='M13.458 11.202h-2.533c-2.225 0-3.127-.902-3.127-3.127V5.542c0-.238.142-.46.364-.546a.599.599 0 0 1 .65.126l5.066 5.067a.596.596 0 0 1-.42 1.013zM8.985 6.975v1.1c0 1.576.365 1.94 1.94 1.94h1.1l-3.04-3.04zm3.365-4.798H9.183a.598.598 0 0 1-.594-.594c0-.325.27-.594.594-.594h3.167a.6.6 0 0 1 .594.594c0 .325-.27.594-.594.594z'
            fill={props.color}
        />
        <path
            d='M5.542 4.552a.598.598 0 0 1-.594-.594A2.97 2.97 0 0 1 7.916.989h2.075c.324 0 .593.27.593.594a.598.598 0 0 1-.593.594H7.917c-.982 0-1.782.8-1.782 1.781a.598.598 0 0 1-.593.594zm9.65 9.5a.598.598 0 0 1-.594-.594c0-.325.27-.594.594-.594.902 0 1.63-.736 1.63-1.63V6.332c0-.325.27-.594.595-.594.324 0 .593.27.593.594v4.9a2.825 2.825 0 0 1-2.818 2.819z'
            fill={props.color}
        />
        <path
            d='M17.416 6.928h-2.375c-2.105 0-2.968-.863-2.968-2.97V1.585c0-.238.142-.46.364-.546a.599.599 0 0 1 .65.126l4.75 4.75a.596.596 0 0 1 .126.65.587.587 0 0 1-.547.364zM13.26 3.017v.942c0 1.449.333 1.781 1.781 1.781h.943L13.26 3.017z'
            fill={props.color}
        />
    </svg>
);
IconCopy.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
};

export default IconCopy;
