import {
    GET_INDICATIONS,
    GET_INDICATIONS_RESPONSE,
    GET_INDICATIONS_ERROR,
    SET_INDICATION_SELECTED,
    PUT_INDICATION,
    PUT_INDICATION_RESPONSE,
    PUT_INDICATION_ERROR,
    POST_INDICATION,
    POST_INDICATION_RESPONSE,
    POST_INDICATION_ERROR,
    GET_PRODFAMILY,
    GET_PRODFAMILY_RESPONSE,
    GET_PRODFAMILY_ERROR,
    GET_FAMILY_PRODUCTS,
    GET_FAMILY_PRODUCTS_RESPONSE,
    GET_FAMILY_PRODUCTS_ERROR,
    SEARCH_INDICATIONS,
    GET_EVIDENCE_TYPE,
    GET_EVIDENCE_TYPE_ERROR,
    GET_EVIDENCE_TYPE_RESPONSE,
} from './indication.actions';

const initialState = {
    results: [],
    filteredResults: [],
    count: 0, // table rows (all indications)
    current: 1, // actual page
    total_pages: 1,
    page_size: 10, // TODO - is harcoded in implementation
    customFilter: {
        ordering: '',
        search: '',
        status: '',
    },

    // UI related
    getIndicationsStatus: { success: false, loading: false, error: false, message: '' },
    setIndicationStatus: { success: false, loading: false, error: false, message: '' },
    getEvidenceTypeStatus: { success: false, loading: false, error: false, message: '' },

    evidenceTypes: [],
    // Indication Details
    selectedIndication: {},

    // Treatments
    product_families: [],
};

const filterIndications = (indications, searchText) => {
    return indications.filter((indication) => {
        return indication.titulo.concat(indication.descripcion).toLowerCase().includes(searchText);
    });
};

const indicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_INDICATIONS:
        case GET_INDICATIONS_ERROR:
        case GET_INDICATIONS_RESPONSE:
            if (action.ind != undefined)
                return {
                    // SINGLE INDICATION
                    ...state,
                    selectedIndication:
                        action.type == GET_INDICATIONS_RESPONSE ? action.response : {},
                    getIndicationsStatus: {
                        success: action.type == GET_INDICATIONS_RESPONSE,
                        loading: action.type == GET_INDICATIONS,
                        error: action.type == GET_INDICATIONS_ERROR && action.err,
                    },
                };
            return {
                // MULTIPLE INDICATIONS
                ...state,
                results:
                    action.type == GET_INDICATIONS_RESPONSE
                        ? action.response?.sort((a, b) => (a.id < b.id ? 1 : -1))
                        : [],
                filteredResults:
                    action.type == GET_INDICATIONS_RESPONSE
                        ? action.response?.sort((a, b) => (a.id < b.id ? 1 : -1))
                        : [],
                getIndicationsStatus: {
                    success: action.type == GET_INDICATIONS_RESPONSE,
                    loading: action.type == GET_INDICATIONS,
                    error: action.type == GET_INDICATIONS_ERROR && action.err,
                },
            };
        case SET_INDICATION_SELECTED:
            return {
                ...state,
                selectedIndication: action.body,
            };
        case PUT_INDICATION:
        case PUT_INDICATION_ERROR:
        case PUT_INDICATION_RESPONSE:
            return {
                ...state,
                selectedIndication:
                    action.type === PUT_INDICATION_RESPONSE
                        ? action.response
                        : state.selectedIndication,
                setIndicationStatus: {
                    success: action.type == PUT_INDICATION_RESPONSE,
                    loading: action.type == PUT_INDICATION,
                    error: action.type == PUT_INDICATION_ERROR,
                    message:
                        action.type == PUT_INDICATION_ERROR &&
                        (action?.error?.data ? Object.values(action?.error?.data)[0][0] : 'error'),
                },
            };
        case POST_INDICATION:
        case POST_INDICATION_ERROR:
        case POST_INDICATION_RESPONSE:
            return {
                ...state,
                selectedIndication:
                    action.type === POST_INDICATION_RESPONSE
                        ? action.response
                        : state.selectedIndication,
                setIndicationStatus: {
                    success: action.type == POST_INDICATION_RESPONSE,
                    loading: action.type == POST_INDICATION,
                    error: action.type == POST_INDICATION_ERROR,
                    message:
                        action.type == POST_INDICATION_ERROR &&
                        (action?.error?.data ? Object.values(action?.error?.data)[0][0] : 'error'),
                },
            };
        case GET_PRODFAMILY:
        case GET_PRODFAMILY_ERROR:
        case GET_PRODFAMILY_RESPONSE:
            return {
                ...state,
                product_families: action.type == GET_PRODFAMILY_RESPONSE ? action.response : [],
            };
        case GET_FAMILY_PRODUCTS:
        case GET_FAMILY_PRODUCTS_ERROR:
        case GET_FAMILY_PRODUCTS_RESPONSE:
            return {
                ...state,
                product_families:
                    action.type == GET_FAMILY_PRODUCTS_RESPONSE
                        ? state.product_families.map((fam) => {
                              if (fam.id == action.fam) fam.products = action.response;
                              return fam;
                          })
                        : state.product_families,
            };
        case GET_EVIDENCE_TYPE:
        case GET_EVIDENCE_TYPE_ERROR:
        case GET_EVIDENCE_TYPE_RESPONSE:
            return {
                ...state,
                evidenceTypes: action.type == GET_EVIDENCE_TYPE_RESPONSE ? action.response : [],
                getEvidenceTypeStatus: {
                    success: action.type == GET_EVIDENCE_TYPE_RESPONSE,
                    loading: action.type == GET_EVIDENCE_TYPE,
                    error: action.type == GET_EVIDENCE_TYPE_ERROR && action.err,
                },
            };
        case SEARCH_INDICATIONS:
            return {
                ...state,
                filteredResults: filterIndications(state.results, action.search),
            };
        default:
            return state;
    }
};

export default indicationReducer;
