import React, { useEffect, useState } from 'react';
import Card from '../../../common/components/Card/Card';
import CustomSelect from '../../../common/components/CustomSelect';
import SelectMultiple from '../../../common/components/SelectMultiple';
import Input from '../../../common/components/Input';
import TextArea from '../../../common/components/TextArea/TextArea';
import Button from '../../../common/components/Button';
import { SimpleModal } from '../../../common/components/Modal';
import Loader from '../../../common/components/Loader';
import { I18n } from 'react-redux-i18n';
import { useHistory, useLocation } from 'react-router-dom';
import TreatmentTable from '../Table/TreatmentTable';
import TempInput from '../../../common/components/TempInput/TempInput';
import './Indications.scss';
import '../Table/table.css';

/* -------------------- INDEPENDENT COMPONENTS START ---------- */
const DetailedLabel = ({ label, detail }) => {
    return (
        <div className='detailedLabel'>
            <div className='dlabel-text'>{label}</div>
            <div className='detail'>{detail}</div>
        </div>
    );
};
/* -------------------- INDEPENDENT COMPONENTS END ---------- */

export const IndStatusModal = ({ show, setShow, status, setShowErrors }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    return (
        <SimpleModal show={show} className='IndStatusModal'>
            {show?.message ? (
                <div>{show.message}</div>
            ) : (
                <>
                    {status?.loading && <Loader />}
                    {status?.error && (
                        <div>
                            {' '}
                            {status?.message
                                ? status?.message.toString()
                                : I18n.t('errors.generic')}{' '}
                        </div>
                    )}
                    {status?.success && <div> {I18n.t('success.load')} </div>}
                </>
            )}
            <br />
            <Button
                type='button'
                className='primary small'
                text='Ok'
                onClick={() => {
                    setShow(false);
                    setShowErrors && setShowErrors(status?.error);
                    if (status?.success && pathname === '/contracts/detail') {
                        history.push('/contracts/list');
                    } else if (status?.success && pathname === '/indications/details') {
                        history.push('/indications/list');
                    } else if (
                        status?.success &&
                        (pathname === '/patients/edit' || pathname === '/patients/evidence')
                    ) {
                        history.replace('/patients');
                    }
                }}
            />
        </SimpleModal>
    );
};

const EvidenceRow = ({
    evidence,
    modifyEvidence,
    deleteEvidence,
    types,
    showErrors,
    readOnly,
    ...props
}) => {
    const [modify, setModify] = useState(!evidence.tipo_evidencia);
    const [tempEvidence, setTempEvidence] = [evidence, modifyEvidence];

    const setAtt = (att) => setTempEvidence({ ...tempEvidence, ...att });
    const submit = () => {
        modifyEvidence(tempEvidence);
        setModify(false);
    };

    const protectedEv = tempEvidence.protected;

    return modify ? (
        <tr {...props}>
            <td>
                {' '}
                <TempInput
                    att='numero'
                    type='uint'
                    tempObj={tempEvidence}
                    setAtt={setAtt}
                    submit={submit}
                    error={showErrors && tempEvidence?.numero === undefined}
                />
            </td>
            <td>
                {' '}
                <TempInput
                    att='tipo_evidencia'
                    type='select'
                    tempObj={tempEvidence}
                    setAtt={setAtt}
                    submit={submit}
                    options={types}
                    error={showErrors && !tempEvidence?.tipo_evidencia}
                    className='csoc_mw'
                />
            </td>
            <td>
                <TempInput
                    att='descripcion'
                    type='text'
                    tempObj={tempEvidence}
                    setAtt={setAtt}
                    submit={submit}
                    error={showErrors && !tempEvidence?.descripcion}
                />
            </td>
            <td>
                <TempInput
                    att='aplicacion'
                    type='uint'
                    tempObj={tempEvidence}
                    setAtt={setAtt}
                    submit={submit}
                />
            </td>
            <td>
                <div className='action-container'>
                    <i
                        className={'icon-note ' + (protectedEv ? 'disabled' : '')}
                        onClick={protectedEv || submit}
                    />
                    <div className='action-pipe' />
                    <i
                        className={'icon-trash ' + (protectedEv ? 'disabled' : '')}
                        onClick={() => protectedEv || deleteEvidence(evidence)}
                    />
                </div>
            </td>
        </tr>
    ) : (
        <tr {...props}>
            <td className={showErrors && tempEvidence?.numero === undefined ? 'error-td' : ''}>
                {evidence.numero}
            </td>
            <td className={showErrors && !tempEvidence?.tipo_evidencia ? 'error-td' : ''}>
                {types?.find((e) => e.value === evidence.tipo_evidencia)?.label ?? '-'}
            </td>
            <td className={showErrors && !tempEvidence?.descripcion ? 'error-td' : ''}>
                <DetailedLabel label={evidence.descripcion} detail={evidence.descripcion} />
            </td>
            <td>{evidence.aplicacion}</td>
            <td>
                <div className='action-container'>
                    <i
                        className={'icon-pencil ' + (protectedEv ? 'disabled' : '')}
                        onClick={() => !readOnly && !protectedEv && setModify(true)}
                    />
                    <div className='action-pipe' />
                    <i
                        className={'icon-trash ' + (protectedEv ? 'disabled' : '')}
                        onClick={() => !readOnly && !protectedEv && deleteEvidence(evidence)}
                    />
                </div>
            </td>
        </tr>
    );
};

export const EvidencesTable = ({
    evidences,
    setEvidences,

    showErrors,
    evidenceTypes,
    readOnly,
}) => {
    const types = evidenceTypes
        ?.map((v) => ({ value: v.id, label: v.descripcion }))
        ?.sort((a, b) => (a.label > b.label ? 1 : -1));

    const [descFilter, setDescFilter] = useState(
        evidenceTypes?.map((n) => ({ id: n.id, checked: true })),
    );
    const handleDescFilterChange = (statusId) => {
        const otherStatus = descFilter?.filter((status) => status.id !== statusId);
        const selectedStatus = descFilter?.filter((status) => status.id === statusId)[0];
        setDescFilter([...otherStatus, { id: statusId, checked: !selectedStatus?.checked }]);
    };

    const modifyEvidence = (evidence) => {
        setEvidences(evidences?.map((t) => (t._id == evidence._id ? evidence : t)));
    };

    const deleteEvidence = (evidence) => {
        setEvidences(evidences?.filter((t) => t._id != evidence._id));
    };

    return (
        <table className='GreenTable EvidencesTable'>
            <thead>
                <tr>
                    <th>{I18n.t('indication.details.evidences.table.number')}</th>
                    <th>
                        <SelectMultiple
                            placeholder={I18n.t('indication.details.evidences.table.description')}
                            values={descFilter}
                            onChange={handleDescFilterChange}
                            options={types}
                        />
                    </th>
                    <th>{I18n.t('indication.details.evidences.table.detail')}</th>
                    <th>{I18n.t('indication.details.evidences.table.cycle')}</th>
                    <th>{I18n.t('indication.details.evidences.table.action')}</th>
                </tr>
            </thead>
            <tbody>
                {evidences
                    ?.filter(
                        (e) =>
                            !e.tipo_evidencia ||
                            descFilter
                                ?.filter((s) => s.checked)
                                ?.map((s) => s.id)
                                ?.includes(e.tipo_evidencia),
                    )
                    ?.sort((a, b) => (a.aplicacion > b.aplicacion ? 1 : -1))
                    ?.map((evidence) => {
                        return (
                            <EvidenceRow
                                evidence={evidence}
                                key={evidence._id}
                                modifyEvidence={modifyEvidence}
                                deleteEvidence={deleteEvidence}
                                showErrors={showErrors}
                                types={types}
                                readOnly={readOnly}
                            />
                        );
                    })}
            </tbody>
        </table>
    );
};

const IndicationDetailsCard = ({
    indication,
    setIndication,

    showErrors,
}) => {
    const [readOnly] = useState(indication.tiene_contratos);
    const treatmentTypes = ['C', 'F', 'P'];
    const durationTypes = ['A', 'C', 'T']; // TODO - missing options ? not shown in design

    return (
        <Card
            className='side-padding-m ind-det-card'
            title={I18n.t('indication.details.indication.title')}
        >
            <br />
            <div className='card-row' style={{ marginBottom: '20px' }}>
                <div>
                    <Input
                        disabled={readOnly}
                        className='white'
                        value={indication?.titulo}
                        handleChange={(e) => setIndication({ ...indication, titulo: e })}
                        label={I18n.t('indication.details.indication.indications')}
                        error={showErrors && !indication?.titulo}
                        maxLength='50'
                    />
                </div>
                <div>
                    <div key='input-label'>
                        <label className='input-label'>
                            {I18n.t('indication.details.indication.type')}
                        </label>
                    </div>
                    <CustomSelect
                        disabled={readOnly}
                        options={treatmentTypes
                            ?.map((k) => ({
                                value: k,
                                label: I18n.t('indication.details.indication.treatmentTypes.' + k),
                            }))
                            ?.sort()}
                        placeholder='-'
                        value={indication?.tipo_tratamiento}
                        onSelect={(e) => setIndication({ ...indication, tipo_tratamiento: e })}
                        className='rounded i-screen'
                        error={showErrors && !indication?.tipo_tratamiento}
                        // placeholder="" error={false} disabled={false} icon={<i className={"icon-location-pin"}/>}
                    />
                </div>
                <div>
                    <div key='input-label'>
                        <label className='input-label'>
                            {I18n.t('indication.details.indication.duration')}
                        </label>
                    </div>

                    <div className='card-subrow'>
                        <div className='w50'>
                            <Input
                                disabled={readOnly}
                                className='white'
                                type='number'
                                value={indication?.duracion}
                                error={showErrors && !indication?.duracion}
                                handleChange={(e) => setIndication({ ...indication, duracion: e })}
                            />
                        </div>
                        <div>
                            <CustomSelect
                                disabled={readOnly}
                                options={durationTypes?.map((k) => ({
                                    value: k,
                                    label: I18n.t(
                                        'indication.details.indication.durationTypes.' + k,
                                    ),
                                }))}
                                value={indication?.tipo_duracion}
                                onSelect={(e) => setIndication({ ...indication, tipo_duracion: e })}
                                placeholder='-'
                                className='rounded i-screen'
                                error={
                                    showErrors &&
                                    (indication?.tipo_duracion?.length === 0 ||
                                        !indication.tipo_duracion)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='card-row'>
                <TextArea
                    disabled={readOnly}
                    label={I18n.t('indication.details.indication.description')}
                    value={indication?.descripcion}
                    onChange={(e) => {
                        setIndication({ ...indication, descripcion: e.target.value });
                    }}
                    error={showErrors && !indication?.descripcion}
                />
            </div>
        </Card>
    );
};

const IndicationsScreen = ({
    selectedIndication,
    putIndication,
    postIndication,
    setIndicationStatus,
    evidenceTypes,
    getEvidenceType,
}) => {
    const [indication, setIndication] = useState({
        ...selectedIndication,
        indicacion_evidencia: selectedIndication?.indicacion_evidencia?.map(
            (ev) => ({ ...ev, _id: ev.id } ?? []),
        ),
        indicacion_familiaprod: selectedIndication?.indicacion_familiaprod
            ?.map((pd) => ({ ...pd, _id: pd.id } ?? []))
            ?.sort((a, b) => a.aplicacion_desde - b.aplicacion_desde),
    });
    const [showState, setShowState] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {
        getEvidenceType();
    }, []);

    // useEffect( ()=>{
    //     setIndication({ ...selectedIndication,
    //         indicacion_evidencia: selectedIndication.indicacion_evidencia?.map( ev => ({ ...ev, _id:ev.id })??[] ),
    //         indicacion_familiaprod: selectedIndication.indicacion_familiaprod?.map( pd => ({ ...pd, _id:pd.id })??[] ),
    //     })
    // },[selectedIndication] )
    // NOTE: leaving this here in case in case the refactor doesnt work correctly

    const setEvidencias = (evs) => {
        setIndication({ ...indication, indicacion_evidencia: evs });
    };
    const setProductos = (pds) => {
        setIndication({ ...indication, indicacion_familiaprod: pds });
    };

    const addProduct = () => {
        const treatments = indication.indicacion_familiaprod ?? [];
        const lastTreatment = treatments[treatments?.length - 1] ?? {};
        const calcularDia = () => {
            if (lastTreatment.frecuencia === 0) {
                return '-';
            } else {
                if (treatments?.length > 1) {
                    const prevCicloHasta = treatments[treatments?.length - 2].aplicacion_hasta;
                    const actualCicloHasta = lastTreatment.aplicacion_hasta;
                    return (
                        (actualCicloHasta - prevCicloHasta) * lastTreatment.frecuencia +
                        lastTreatment.desde_dia
                    );
                }
                return lastTreatment.aplicacion_hasta * lastTreatment.frecuencia + 1 || '-';
            }
        };
        const newTreatment = {
            _id: treatments?.reduce((max, t) => Math.max(max, t._id ?? 0), 0) + 1,
            aplicacion_desde: lastTreatment.aplicacion_hasta + 1,
            aplicacion_hasta:
                2 * lastTreatment.aplicacion_hasta + 1 - lastTreatment.aplicacion_desde,
            familia_producto: lastTreatment.familia_producto,
            desde_dia: calcularDia(),
            _edit: false,
        };
        setProductos([...treatments, newTreatment]);
    };

    const addEvidence = () => {
        const evidences = indication.indicacion_evidencia ?? [];
        const newEvidence = {
            _id:
                evidences?.reduce((prev, current) => (prev._id > current._id ? prev : current), {
                    _id: 0,
                })._id + 1,
            numero:
                evidences?.reduce(
                    (prev, current) => (prev.numero > current.numero ? prev : current),
                    { numero: 0 },
                ).numero + 1,
        };
        setEvidencias([...evidences, newEvidence]);
    };

    //sort evidence by aplicacion_desde and aplicacion_hasta
    const sortProducts = (a, b) => {
        if (a.aplicacion_desde < b.aplicacion_desde) return -1;
        if (a.aplicacion_desde > b.aplicacion_desde) return 1;
        if (a.aplicacion_hasta < b.aplicacion_hasta) return -1;
        if (a.aplicacion_hasta > b.aplicacion_hasta) return 1;
        return 0;
    };

    const submitIndication = (type) => {
        const sortedProducts = indication.indicacion_familiaprod?.sort(sortProducts) ?? [];

        let dateCounter = 0; // last date
        let dateErrors = [];

        const maxDoseError = 'Error: La dosificacion no puede ser mayor a la dosis maxima';

        for (const product of sortedProducts) {
            // product.aplicacion_desde <= dateCounter || // superposition
            // product.aplicacion_desde > dateCounter+1 || // gap
            // product.aplicacion_hasta < product.aplicacion_desde // invalid range (negative)
            const errRange = `[${product.aplicacion_desde}, ${product.aplicacion_hasta}]`;

            if (product.aplicacion_desde <= dateCounter)
                dateErrors.push(`Fechas superpuestas ${errRange}`);

            if (product.aplicacion_desde > dateCounter + 1) dateErrors.push(`Hueco ${errRange}`);

            if (product.aplicacion_hasta < product.aplicacion_desde)
                dateErrors.push(`Rango Invalido ${errRange}`);

            if (
                product.aplicacion_desde > indication.duracion ||
                product.aplicacion_hasta > indication.duracion
            )
                dateErrors.push(`Fuera de Rango ${errRange}`);

            if (
                (product.tipo_dosis === 'P' && parseFloat(product.dosis_maxima) <= dateCounter) ||
                product.dosis_maxima === ''
            )
                dateErrors.push(`La dosis máxima debe ser mayor a cero`);

            dateCounter = product.aplicacion_hasta;
        }
        if (dateErrors?.length > 0) {
            type === 'post' && postIndication({});
            type === 'put' && putIndication({});
            if (dateErrors.length === 1 && dateErrors[0] === maxDoseError) {
                setShowState({ message: dateErrors[0] });
            } else {
                setShowState({
                    message: 'Error en esquema de tratamiento.\n' + dateErrors.join('\n'),
                });
            }
            return;
        }

        // Validacion de tipo de dosis.
        let tipoDosisError = false;
        if (indication.indicacion_familiaprod && indication?.indicacion_familiaprod?.length > 0) {
            indication.indicacion_familiaprod.forEach((pr) => {
                if (!pr.tipo_dosis) {
                    tipoDosisError = true;
                }
            });
        }
        if (tipoDosisError) {
            type === 'post' && postIndication({});
            type === 'put' && putIndication({});
            setShowState({ message: 'Falta tipo de dosis.\n' });
            return;
        }

        if (indication.indicacion_evidencia?.some((x) => !x.tipo_evidencia)) {
            type === 'post' && postIndication({});
            type === 'put' && putIndication({});
            setShowState({ message: 'Error evidencia sin tipo de descripción.\n' });
            return;
        }

        if (indication.indicacion_evidencia?.some((x) => !x.descripcion)) {
            type === 'post' && postIndication({});
            type === 'put' && putIndication({});
            setShowState({ message: 'Error evidencia sin descripción.\n' });
            return;
        }

        const search = indication.indicacion_evidencia?.reduce((acc, ind) => {
            acc[ind.aplicacion] = ++acc[ind.aplicacion] || 0;
            return acc;
        }, {});

        const duplicates = indication.indicacion_evidencia?.filter((ind) => {
            return search[ind.aplicacion];
        });

        if (duplicates?.length > 0) {
            type === 'post' && postIndication({});
            type === 'put' && putIndication({});
            setShowState({ message: 'Error evidencias con mismo ciclo.\n' });
            return;
        }

        if (indication.indicacion_evidencia?.some((x) => x.aplicacion > indication.duracion)) {
            type === 'post' && postIndication({});
            type === 'put' && putIndication({});
            setShowState({ message: 'Error evidencia con ciclo fuera de rango.\n' });
            return;
        }

        if (!indication.duracion) {
            type === 'post' && postIndication({});
            type === 'put' && putIndication({});
            setShowState({ message: 'Error en duracion.\n' });
            return;
        }

        // Validacion de tipo de duracion
        if (indication?.tipo_duracion?.length > 0 || indication.tipo_duracion) {
            const newIndication = {
                ...indication,
                indicacion_familiaprod: indication.indicacion_familiaprod ?? [],
                indicacion_evidencia: indication.indicacion_evidencia ?? [],
            };
            type === 'post' && postIndication(newIndication);
            type === 'put' && putIndication(newIndication);
        } else {
            // Si la duracion no llega, hago un pedido al endpoint que falle
            type === 'post' && postIndication({});
            type === 'put' && putIndication({});
            setShowState({ message: 'Error en tipo de duracion.\n' });
            return;
        }

        setShowState({ message: undefined });
        setShowErrors(true);
    };

    return (
        <div className='i-container'>
            <IndicationDetailsCard
                indication={indication}
                setIndication={setIndication}
                showErrors={showErrors}
            />
            <Card title={I18n.t('indication.details.products.title')}>
                <br />
                <div className='card-label'>{I18n.t('indication.details.products.subtitle')}</div>
                <br />
                <TreatmentTable
                    treatments={indication?.indicacion_familiaprod}
                    setTreatments={setProductos}
                    showErrors={showErrors}
                    durationType={indication?.tipo_duracion}
                    readOnly={indication.tiene_contratos}
                />
                <br />
                <div className='card-rlink' onClick={!indication.tiene_contratos ? addProduct : ''}>
                    {I18n.t('indication.details.products.addNew')}
                </div>
                <br />
            </Card>
            <Card title={I18n.t('indication.details.evidences.title')}>
                <br />
                <div
                    className='card-rlink'
                    onClick={!indication.tiene_contratos ? addEvidence : ''}
                >
                    {I18n.t('indication.details.evidences.addNew')}
                </div>
                <br />
                <br />
                <EvidencesTable
                    evidences={indication?.indicacion_evidencia}
                    setEvidences={setEvidencias}
                    showErrors={showErrors}
                    evidenceTypes={evidenceTypes}
                    readOnly={indication?.tiene_contratos}
                />
                <br />
            </Card>

            {indication?.id ? (
                <Button
                    type='button'
                    className='primary small f-right'
                    text={I18n.t('contract.buttons.save')}
                    onClick={() => submitIndication('put')}
                    disabled={indication?.tiene_contratos}
                />
            ) : (
                <Button
                    type='button'
                    className='primary small f-right'
                    text={I18n.t('contract.buttons.new')}
                    onClick={() => submitIndication('post')}
                />
            )}
            <IndStatusModal
                status={setIndicationStatus}
                show={showState}
                setShow={setShowState}
                setShowErrors={setShowErrors}
            />
        </div>
    );
};

export default IndicationsScreen;
