import React from 'react';
import './Checkbox.scss';
import PropTypes from 'prop-types';

const Checkbox = (props) => {
    return (
        <div className={'checkbox-container ' + (props.className ?? '')}>
            {(!props.className || !props.className.includes('chk-label-right')) && props.label ? (
                <>
                    <span className={props.className ?? 'checkbox-label'}>{props.label}</span>
                    <input
                        type='checkbox'
                        checked={props.checked}
                        className={`regular-checkbox ${props.disabled && 'disabled'}`}
                        disabled={props.disabled}
                        id={props.id}
                        name={props.id}
                        onChange={(e) => props.onChange(props.id, e.target.checked)}
                    />
                </>
            ) : (
                <>
                    <input
                        type='checkbox'
                        id={props.id}
                        checked={props.checked}
                        className={`regular-checkbox ${props.disabled && 'disabled'}`}
                        disabled={props.disabled}
                        name={props.id}
                        onChange={(e) => props.onChange(props.id, e.target.checked)}
                    />
                    <span className={props.className ?? 'checkbox-label'}>{props.label}</span>
                </>
            )}
        </div>
    );
};

Checkbox.propTypes = {
    id: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
};

export default Checkbox;
