import React from 'react';
import WalletCard from '../../common/ListCard/WalletCard';
import IconDelete from '../../../../common/components/Icon/Delete';
import './GrantList.scss';
const GrantCard = ({ grant, deleteGrant }) => {
    return (
        <div className='grant-card-container'>
            <WalletCard className='grant'>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <span className='card-key-text'>{grant}</span>
                    <div style={{ cursor: 'pointer' }} onClick={() => deleteGrant(grant)}>
                        <IconDelete width='20' color='#011415' />
                    </div>
                </div>
            </WalletCard>
        </div>
    );
};
export default GrantCard;
