import React, { useState, useEffect } from 'react';
import './RegistrarInstitutionForm.scss';
import { I18n } from 'react-redux-i18n';
import CustomSelect from '../../../common/components/CustomSelect';
import Input from '../../../common/components/Input';
import Button from '../../../common/components/Button';
import ErrorBox from '../../../common/components/ErrorBox';
import { InstitutionTypes } from '../../../constants';

const RegistrarInstitutionForm = (props) => {
    const [institutionType, setInstitutionType] = useState();
    const [institutionName, setInstitutionName] = useState('');
    const [cuit, setCuit] = useState('');
    const [branchAddress, setBranchAddress] = useState('');
    const [branchPhone, setBranchPhone] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [errorField, setErrorField] = useState();

    const { showBranchInput, disableInstitutionInputs, disableBranchInputs, branchSelected } =
        props;

    useEffect(() => {
        if (props.cuitNonExistent) {
            setErrorMessage(
                I18n.t('registrarNewAccount.yourAccount.validationErrors.cuitNonExistent'),
            );
        }
    }, [props.cuitNonExistent]);

    const validate = () => {
        if (!showBranchInput) {
            if (!institutionType) {
                setErrorField('institutionType');
                setErrorMessage(
                    I18n.t(
                        'registrarNewAccount.yourAccount.validationErrors.institutionTypeIsRequired',
                    ),
                );
                return false;
            }
            if (!institutionName || institutionName === '') {
                setErrorField('institutionName');
                setErrorMessage(
                    I18n.t(
                        'registrarNewAccount.yourAccount.validationErrors.institutionNameIsRequired',
                    ),
                );
                return false;
            }
            if (!cuit || cuit === '' || cuit === '__-________-_') {
                setErrorField('cuit');
                setErrorMessage(
                    I18n.t('registrarNewAccount.yourAccount.validationErrors.cuitIsRequired'),
                );
                return false;
            }
            setErrorField('');
            setErrorMessage('');
            return true;
        } else if (!disableBranchInputs) {
            if (!branchAddress || branchAddress === '') {
                setErrorField('branchAddress');
                setErrorMessage(
                    I18n.t('registrarNewAccount.yourAccount.validationErrors.addressIsRequired'),
                );
                return false;
            }
            if (!branchPhone || branchPhone === '') {
                setErrorField('branchPhone');
                setErrorMessage(
                    I18n.t('registrarNewAccount.yourAccount.validationErrors.phoneIsRequired'),
                );
                return false;
            }
            setErrorField('');
            setErrorMessage('');
            return true;
        }

        setErrorField('');
        setErrorMessage('');
        return true;
    };

    // it take out the "_" and the "-" chars given by the input mask
    const parseCuit = (cuit) => cuit.split('_').join('').split('-').join('');

    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (validate()) {
            // If branch inputs are visible and enabled and the cuit doesn't exists, submit the institution AND the branch
            if (showBranchInput && !disableBranchInputs && props.cuitNonExistent) {
                const institution = {
                    name: institutionName,
                    cuit: parseCuit(cuit),
                    type: institutionType,
                };
                const branch = {
                    address: branchAddress,
                    phone: branchPhone,
                };
                props.submitInstitutionAndBranch(institution, branch);
            }
            // If the institutions inputs are not disabled, we are tying to submit only the institution
            else if (!disableInstitutionInputs) props.submitInstitution(parseCuit(cuit));
            // If we are showing the branch inputs and they are not disabled, we  are trying to submit the only the branch
            else if (showBranchInput && !disableBranchInputs) {
                const branch = {
                    address: branchAddress,
                    phone: branchPhone,
                };
                props.submitBranch(branch);
            }
            // Otherwise we have all saved and the next step is to create the register user
            else props.submitPreloadedInstitutionAndBranch();
        }
    };

    const institutionTypesOptions = [
        {
            value: InstitutionTypes.TAKE_CARE_OR_PATIENT,
            label: I18n.t('registrarNewAccount.yourAccount.institutionOptions.takeCareOrPatient'),
        },
        {
            value: InstitutionTypes.PHARMACY,
            label: I18n.t('registrarNewAccount.yourAccount.institutionOptions.pharmacy'),
        },
        {
            value: InstitutionTypes.INFUSION_CENTER,
            label: I18n.t('registrarNewAccount.yourAccount.institutionOptions.infusionCenters'),
        },
        {
            value: InstitutionTypes.OTHERS,
            label: I18n.t('registrarNewAccount.yourAccount.institutionOptions.others'),
        },
    ];

    return (
        <form className='registrar-institution-form' onSubmit={onSubmit}>
            <ErrorBox show={errorMessage !== ''} errorMessage={errorMessage} />

            {/* title and subtitle */}
            <span className='title'>{props.title}</span>
            {props.subtitle && <span className='subtitle'>{props.subtitle}</span>}

            {/* form */}
            <CustomSelect
                placeholder={I18n.t('registrarNewAccount.yourAccount.typeOfInstitution')}
                onSelect={setInstitutionType}
                className='rounded'
                error={errorField === 'institutionType'}
                icon={<i className='icon-location-pin' />}
                value={institutionType}
                disabled={disableInstitutionInputs}
                options={institutionTypesOptions}
            />
            <Input
                type='text'
                value={institutionName}
                icon={<i className='icon-user' />}
                handleChange={setInstitutionName}
                error={errorField === 'institutionName'}
                pillStyle
                disabled={disableInstitutionInputs}
                placeholder={I18n.t('registrarNewAccount.yourAccount.institutionName')}
            />
            <Input
                type='text'
                value={cuit}
                icon={<i className='icon-user' />}
                handleChange={setCuit}
                error={errorField === 'cuit'}
                pillStyle
                disabled={disableInstitutionInputs}
                placeholder={I18n.t('registrarNewAccount.yourAccount.cuit')}
                mask='99-99999999-9'
            />

            {showBranchInput && <hr />}
            {showBranchInput && (
                <Input
                    type='text'
                    value={branchSelected?.address || branchAddress}
                    icon={<i className='icon-location-pin' />}
                    handleChange={setBranchAddress}
                    error={errorField === 'branchAddress'}
                    pillStyle
                    disabled={disableBranchInputs}
                    placeholder={I18n.t('registrarNewAccount.yourAccount.address')}
                />
            )}
            {showBranchInput && (
                <Input
                    type='text'
                    value={branchSelected?.phone || branchPhone}
                    icon={<i className='icon-phone' />}
                    handleChange={setBranchPhone}
                    error={errorField === 'branchPhone'}
                    pillStyle
                    disabled={disableBranchInputs}
                    placeholder={I18n.t('registrarNewAccount.yourAccount.phone')}
                />
            )}

            <Button
                className='primary with-gradient'
                type='submit'
                disabled={false}
                text={I18n.t('registrarNewAccount.yourAccount.button')}
                loading={
                    props.getInstitutionStatus.loading ||
                    props.createInstitutionAndBranchStatus.loading
                }
            />
            <label className='log-out-text' onClick={props.logout}>
                {I18n.t('registrarNewAccount.yourAccount.logout')}
            </label>
        </form>
    );
};

export default RegistrarInstitutionForm;
