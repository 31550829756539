import React from 'react';
import './CreatePrivateKeyScreen.scss';
import CreatePrivateKeyWizard from '../CreatePrivateKeyWizard';

const CreatePrivateKeyScreen = (props) => {
    return (
        <div className='create-private-key-screen'>
            <CreatePrivateKeyWizard
                saveKeys={props.saveKeys}
                saveKeysStatus={props.saveKeysStatus}
                username={props.username}
            />
        </div>
    );
};

export default CreatePrivateKeyScreen;
