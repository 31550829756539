import React, { useState, useEffect } from 'react';
import './RegistrarNewAccountScreen.scss';
import NexusLogo from '../../../assets/logos/nexus-color-small.png';
import RightColumnBackground from '../../../assets/images/registrar-new-acount-background.png';
import RegistrarInstitutionForm from '../RegistrarInstitutionForm';
import SelectBranchModal from '../SelectBranchModal';
import { I18n } from 'react-redux-i18n';
import SuccessAccountCreationModal from '../SuccessAccountCreationModal';
import { useHistory } from 'react-router-dom';
import { UserTypes } from '../../../constants';

const RegistrarNewAccountScreen = (props) => {
    const [showBranchSelectionModal, setShowBranchSelectionModal] = useState(false);
    const [showBranchInputs, setShowBranchInputs] = useState(false);
    const [disableBranchInputs, setDisableBranchInputs] = useState(false);
    const [showSuccessAccountCreationModal, setShowAccountSuccessCreationModal] = useState(false);
    const [cuitNonExistent, setCuitNonExistent] = useState(false);
    const [branches, setBranches] = useState([]);

    const history = useHistory();

    useEffect(() => {
        // if the get institution request was success and there is no institution, means that the cuit is non existent
        if (props.getInstitutionStatus.success) {
            if (!props.institution) {
                setCuitNonExistent(true);
                setShowBranchInputs(true);
            } else {
                setShowBranchSelectionModal(true);
                setBranches(props.branches);
            }
        }
    }, [props.getInstitutionStatus, props.institution, props.branches]);

    useEffect(() => {
        if (props.createInstitutionAndBranchStatus.success || props.createBranchStatus.success) {
            setShowAccountSuccessCreationModal(true);
        }
    }, [props.createInstitutionAndBranchStatus, props.createBranchStatus]);

    useEffect(() => {
        const isARegisterUser =
            props.userType === UserTypes.REGISTER_USER_WITHOUT_PRIVATE_KEY ||
            props.userType === UserTypes.REGISTER_USER_WITHOUT_PUBLIC_KEY;

        // If user type change correctly, redirect the user to the registrarProfile screen
        if (isARegisterUser) {
            history.push('registrarProfile');
        }
    }, [props.userType]);

    useEffect(() => {
        if (props.user.audit_group) {
            history.push('/auditorTransactions');
        }
    }, []);

    const renderTitle = () => {
        if (!showBranchInputs)
            return I18n.t('registrarNewAccount.yourAccount.welcomeYou', {
                name: props.userFirstName,
            });
        else if (disableBranchInputs || cuitNonExistent)
            return I18n.t('registrarNewAccount.yourAccount.yourBranch');
        else return I18n.t('registrarNewAccount.yourAccount.newBranch');
    };

    const renderSubtitle = () => {
        if (!showBranchInputs) return I18n.t('registrarNewAccount.yourAccount.subtitle');
        return undefined;
    };

    const onBranchSelect = (branch) => {
        props.selectBranch(branch);
        //setBranchSelected(branch);
        setShowBranchSelectionModal(false);
        setShowBranchInputs(true);
        setDisableBranchInputs(true);
    };

    const onNewBranch = () => {
        setShowBranchSelectionModal(false);
        setShowBranchInputs(true);
        setDisableBranchInputs(false);
    };

    const onInstitutionSubmit = (cuit) => {
        props.getInstitution(cuit);
    };

    const onBranchSubmit = (branch) => {
        props.createBranch(branch, props.institution.id);
    };

    const onInstitutionAndBranchSubmit = (institution, branch) => {
        props.createInstitutionAndBranch(institution, branch);
    };

    // This function will be call when the user 'submit' an already created institution and branch.
    const onPreloadedInstitutionAndBranchSubmit = () => {
        setShowAccountSuccessCreationModal(true);
    };

    return (
        <div className='registrar-new-account-screen'>
            <div className='main-column'>
                <div className='header'>
                    <img alt='nexus logo' src={NexusLogo} />
                </div>
                <RegistrarInstitutionForm
                    title={renderTitle()}
                    subtitle={renderSubtitle()}
                    showBranchInput={showBranchInputs}
                    disableInstitutionInputs={showBranchInputs}
                    disableBranchInputs={disableBranchInputs}
                    branchSelected={props.branch}
                    submitInstitution={onInstitutionSubmit}
                    submitBranch={onBranchSubmit}
                    submitInstitutionAndBranch={onInstitutionAndBranchSubmit}
                    submitPreloadedInstitutionAndBranch={onPreloadedInstitutionAndBranchSubmit}
                    cuitNonExistent={cuitNonExistent}
                    getInstitutionStatus={props.getInstitutionStatus}
                    createInstitutionAndBranchStatus={props.createInstitutionAndBranchStatus}
                    logout={props.logout}
                />
            </div>
            <div className='main-column right'>
                <img
                    alt='new account'
                    src={RightColumnBackground}
                    className='new-account-background'
                />
            </div>
            <SelectBranchModal
                show={showBranchSelectionModal}
                branches={branches}
                newBranch={onNewBranch}
                selectBranch={onBranchSelect}
            />
            {/* If the branch inputs are not disable means that the user has created a new branch */}
            <SuccessAccountCreationModal
                show={showSuccessAccountCreationModal}
                onClick={props.createRegistrar}
                newBranchCreated={!disableBranchInputs && !cuitNonExistent}
                newInstitutionAndBranchCreated={!disableBranchInputs && cuitNonExistent}
                createRegistrarStatus={props.createRegistrarStatus}
            />
        </div>
    );
};

export default RegistrarNewAccountScreen;
