import React from 'react';
import './TransactionsSuccessModal.scss';
import { I18n } from 'react-redux-i18n';
import Button from '../../../common/components/Button';
import { SimpleModal } from '../../../common/components/Modal';
import PropType from 'prop-types';
import Loader from '../../../common/components/Loader';

const TransactionsSuccessModal = (props) => {
    return (
        <SimpleModal show={props.show}>
            <div className='transactions-success-modal'>
                {props?.status?.loading ? (
                    <div className='loader-container'>
                        <Loader />
                    </div>
                ) : (
                    <>
                        <i className='icon-check' />
                        <span className='modal-body-text'>{props.text}</span>
                        <Button
                            className='primary'
                            text={I18n.t('transaction.successfulModal.button')}
                            onClick={props.onClick}
                        />
                    </>
                )}
            </div>
        </SimpleModal>
    );
};

TransactionsSuccessModal.propTypes = {
    show: PropType.bool.isRequired,
    onClick: PropType.func.isRequired,
    text: PropType.string.isRequired,
};

export default TransactionsSuccessModal;
