import { post, get, webApi } from '../utils/http';
export const services = {
    login: (username, password) =>
        post(
            webApi + 'auth/jwt/create/',
            { username: username, password: password },
            { noAuth: true },
        ),
    restorePassword: (email) =>
        post(webApi + 'auth/users/reset_password/', { email }, { noAuth: true }),
    resetPassword: (uid, token, password, passwordRepeat) =>
        post(
            webApi + 'auth/users/reset_password_confirm/',
            { uid, token, new_password: password, re_new_password: passwordRepeat },
            { noAuth: true },
        ),
    getUser: () => get(webApi + 'api/usuario/me/'),
    getNotifications: () => get(webApi + 'api/menunotifications/'),
};
