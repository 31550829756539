import React, { useState, useEffect, useRef } from 'react';
import './SelectMultiple.scss';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';

const SelectMultiple = (props) => {
    const node = useRef();

    const [selectIsOpen, setSelectIsOpen] = useState(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => document.removeEventListener('mousedown', handleClick);
    }, []);

    const handleClick = (e) => {
        if (node.current.contains(e.target)) return;
        setSelectIsOpen(false);
    };

    const isChecked = (id) => {
        const value = props.values.filter((value) => value.id === id)[0];
        if (!value) return false;
        return value.checked;
    };

    return (
        <div ref={node} className='select-multiple-container'>
            <div
                className={`select-multiple ${props.className || ''}`}
                onClick={() => setSelectIsOpen(!selectIsOpen)}
            >
                <span className='select-multiple-placeholder'>{props.placeholder}</span>
                <i className='icon-arrow-down' />
            </div>
            <div className={`select-multiple-options-container ${selectIsOpen && 'show'}`}>
                {props.options?.map((option) => (
                    <div
                        key={option.value}
                        className='select-multiple-option'
                        onClick={() => props.onChange(option.value)}
                    >
                        <Checkbox
                            id={option.value.toString()}
                            checked={isChecked(option.value)}
                            onChange={() => {}}
                        />
                        <span className='select-multiple-option-label'>{option.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

SelectMultiple.propTypes = {
    values: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            checked: PropTypes.bool.isRequired,
        }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    className: PropTypes.string,
};

export default SelectMultiple;
