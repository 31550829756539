import { connect } from 'react-redux';
import WalletHomeScreen from '../../components/walletHome/WalletHomeScreen';
import actions from '../../../actions';

const mapStateToProps = (state) => ({
    wallet: state.wallet.data,
    username: state.profile.user.username,
});

const mapDispatchToProps = (dispatch) => ({
    getWalletDetails: () => dispatch(actions.wallet.getWalletDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletHomeScreen);
