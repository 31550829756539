export const services = {
    insertData: (key, value) => {
        try {
            localStorage.setItem(key, value);
            return Promise.resolve();
        } catch (e) {
            return Promise.reject('Error when inserting data.');
        }
    },
    retrieveData: (key) => {
        try {
            const data = localStorage.getItem(key);
            return Promise.resolve(data);
        } catch (e) {
            return Promise.reject('Error when retrieving data.');
        }
    },
    deleteData: (key) => {
        try {
            localStorage.removeItem(key);
            return Promise.resolve();
        } catch (e) {
            return Promise.reject('Error when deleting data.');
        }
    },
    clearData: () => {
        return localStorage.clear();
    },
    containsData: async (key) => (await localStorage.getItem(key)) != null,
};
