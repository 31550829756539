export const inputIconParams = {
    height: '20',
    width: '20',
    color: '#057A83',
};

export const headIconParams = {
    width: '60%',
    height: '60%',
    color1: '#2CB095',
    color2: '#008E99',
};
