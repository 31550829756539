import React from 'react';
import './ProfileCard.scss';
import NexusWhiteSmallLogo from '../../../assets/logos/nexus-white-small.png';
import { I18n } from 'react-redux-i18n';

const ProfileCard = (props) => {
    const { user } = props;

    return (
        <div className='profile-card'>
            <div className='profile-card-header'>
                <img alt='Nexus logo' src={NexusWhiteSmallLogo} />
                <span className='os-name'>{props.osName}</span>
            </div>
            <div className='profile-card-body'>
                <span className='user-name'>
                    {user.first_name} {user.last_name}
                </span>
                <div className='icon-and-text-row'>
                    {user.email && <i className='icon-envelope' />}
                    <span className='text-value'>{user.email}</span>
                </div>
                <div className='icon-and-text-row'>
                    {user.phone && <i className='icon-phone' />}
                    <span className='text-value'>{user.phone}</span>
                </div>
            </div>
            <hr />
            <div className='profile-card-footer'>
                <i className='icon-key' />
                <span onClick={props.showChangePasswordModal}>
                    {I18n.t('profile.card.changePassword')}
                </span>
            </div>
        </div>
    );
};

export default ProfileCard;
