import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { useHistory } from 'react-router-dom';
import Button from '../../../common/components/Button';
import CardRows from '../../../common/components/CardRows';
import SearchBar from '../../../common/components/SearchBar';
import TitleBar from '../../../common/components/TitleBar/TitleBar';
import './ShippingAddress.scss';
import { linkAction } from './constants';
import IconAdd from '../../../common/components/Icon/Add';
import WalletScreenWrapper from '../common/WalletScreen/WalletScreenWrapper';
import LinkAccess from '../../../common/components/LinkAccess/LinkAccess';
import { ConfirmationModal, StatusModal } from '../../../common/components/Modal/Modal';
import { copyToClipboard } from '../../../utils/clipboard';
import Loader from '../../../common/components/Loader';

const ShippingAddressScreen = ({
    userContacts,
    contactId,
    setContact,
    setContactId,
    deleteContact,
    getContacts,
    deleteContactStatus,
    getAddressValidation,
    saveSendNex,
    getAddressValidationResponse,
    searchContacts,
    loading,
}) => {
    const history = useHistory();

    const [search, setSearch] = useState('');
    const [notificationClipboard, setNotificationClipboard] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    useEffect(() => {
        getContacts();
    }, []);

    useEffect(() => {
        if (notificationClipboard) setTimeout(() => setNotificationClipboard(false), 1200);
    }, [notificationClipboard]);

    const handleClick = async (action, contact) => {
        switch (action) {
            case 'copy':
                await copyToClipboard(contact.address);
                break;
            case 'edit':
                setContact(contact);
                history.push('contact');
                break;
            case 'delete':
                setContactId(contact.id);
                setShowConfirmationModal(true);
                break;
            default:
                break;
        }
    };

    const pasteAddress = () => {
        if (navigator.clipboard.readText) {
            navigator.clipboard.readText().then((text) => (setSearch(text), searchContacts(text)));
        } else {
            setNotificationClipboard(true);
        }
    };

    const redirectAction = (addAddress) => {
        saveSendNex(addAddress);
        getAddressValidationResponse(addAddress);
        history.push('sendNex');
    };

    return (
        <>
            <WalletScreenWrapper
                titleCard={I18n.t('wallet.contacts.shipmentDirection.title')}
                to='/wallet'
                classBody='wallet'
                className='container-shippingAddress'
                textNotification={I18n.t('wallet.errorCliboard')}
                showNotification={notificationClipboard}
                classnameNotication='notification-wallet-warning maxContainer-notification'
            >
                <div className='container-userUnknown'>
                    <div className='container-search-field'>
                        <SearchBar
                            className='search-bar-wallet'
                            placeholder={I18n.t('wallet.contacts.shipmentDirection.search')}
                            textToSearch={search}
                            onSearch={(text) => {
                                setSearch(text);
                                searchContacts(text);
                            }}
                            iconStyle='icon-color'
                            pillStyle='true'
                        />
                    </div>
                    <div className='container-btn'>
                        <div className='btn-address'>
                            <Button
                                type='button'
                                className='primary hollow wallet download-button'
                                text={I18n.t('wallet.contacts.shipmentDirection.paste')}
                                onClick={pasteAddress}
                            />
                            <Button
                                type='button'
                                className='primary with-gradient download-button'
                                text={I18n.t('wallet.contacts.shipmentDirection.send')}
                                onClick={() => (
                                    getAddressValidation(search), history.push('sendNex')
                                )}
                                disabled={!search}
                            />
                        </div>
                    </div>
                </div>
                <TitleBar
                    classNameContainer='container-line'
                    titleContainer='container-titleBar'
                    titleClass='title'
                    title={I18n.t('wallet.contacts.shipmentDirection.titleListContacts')}
                    backgroundUnderline='light-green'
                >
                    <div className='body-linkAccess'>
                        <div className='container-line-titleBar'>
                            <LinkAccess
                                className='container-linkAccess'
                                titleStyle='title-linkAccess'
                                title={I18n.t('wallet.contacts.shipmentDirection.addContact')}
                                icon={
                                    <IconAdd
                                        width={linkAction.size}
                                        height={linkAction.size}
                                        color={linkAction.color}
                                    />
                                }
                                handleClick={() => {
                                    history.push('contact');
                                    setContact({});
                                }}
                            />
                        </div>
                    </div>
                </TitleBar>
                {loading ? (
                    <div className='loader-container'>
                        <Loader />
                    </div>
                ) : (
                    <div className='container-card-row'>
                        {userContacts.map((contact) => (
                            <div key={contact.id}>
                                <CardRows
                                    contact={contact}
                                    onClick={handleClick}
                                    redirect={redirectAction}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </WalletScreenWrapper>
            <ConfirmationModal
                cancel={() => setShowConfirmationModal(false)}
                show={showConfirmationModal}
                noBody
                submit={() => {
                    setShowModal(true);
                    deleteContact(contactId);
                    setShowConfirmationModal(false);
                }}
                title={I18n.t('wallet.contacts.shipmentDirection.deleteContactConfirmation')}
                titleClassname='center-title'
            />

            {showModal && (
                <StatusModal
                    close={() => {
                        setShowModal(false);
                        getContacts();
                    }}
                    show={showModal}
                    status={deleteContactStatus}
                    successMessage={I18n.t(
                        'wallet.contacts.shipmentDirection.deleteContactSuccess',
                    )}
                />
            )}
        </>
    );
};
export default ShippingAddressScreen;
