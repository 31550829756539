import React from 'react';
import CustomSelect from '../CustomSelect';
import Input from '../Input';
import DatePicker from 'react-datepicker';
import FileInput from './FileInput';
import './TempInput.scss';

import { getDate, dateStr } from '../../../utils/date';

const TempInput = ({
    tempObj,
    setAtt,
    att,
    type,
    submit,
    className,
    options,
    privateKey,
    publicKey,
    id,
    getFileToUploadUrl,
    patientDetail,
    auditGroupData,
    userType,
    maxDate,
    patientCse,
    ...props
}) => {
    const _type =
        type === 'int' ||
        type === 'uint' ||
        type === 'uint-test' ||
        type === 'float' ||
        type === 'ufloat'
            ? 'number'
            : type;
    if (type === 'select')
        return (
            <div className={className}>
                <CustomSelect
                    options={options}
                    value={tempObj[att]}
                    onSelect={(e) => setAtt({ [att]: e })}
                    placeholder='-'
                    className={'rounded i-screen is-small ' + className}
                    // placeholder="" error={false} disabled={false} icon={<i className={"icon-location-pin"}/>}
                    {...props}
                />
            </div>
        );

    if (type === 'date')
        return (
            <div className={'temp-input ti-date ' + className}>
                <DatePicker
                    selected={getDate(tempObj[att])}
                    dateFormat='dd/MM/yyyy'
                    onChange={(v) => setAtt({ [att]: dateStr(v) })}
                    //onChangeRaw={handleDateChangeRaw}
                    //placeholderText={moment().format('DD/MM/YYYY')}
                    autoComplete='off'
                    {...props}
                    className={className}
                    maxDate={maxDate}
                />
            </div>
        );

    if (type === 'file')
        return (
            <FileInput
                tempObj={tempObj}
                att={att}
                setAtt={setAtt}
                className={className}
                privateKey={privateKey}
                publicKey={publicKey}
                id={id}
                getFileToUploadUrl={getFileToUploadUrl}
                patientDetail={patientDetail}
                auditGroupData={auditGroupData}
                userType={userType}
                patientCse={patientCse}
                {...props}
            />
        );

    if (_type === 'number')
        return (
            <Input
                className={'temp-input white is-small ' + className}
                type={_type}
                value={tempObj[att]?.toString()}
                placeholder='-'
                handleChange={(e) => {
                    type === 'int'
                        ? setAtt({ [att]: parseInt(e) })
                        : type === 'uint'
                        ? e >= 0 && setAtt({ [att]: parseInt(e) })
                        : // porcentaje es entre 0 y 100
                        type === 'uintlimithundred'
                        ? e >= 0 && e <= 100 && setAtt({ [att]: parseInt(e) })
                        : type === 'float'
                        ? setAtt({ [att]: e })
                        : type === 'ufloat'
                        ? e >= 0 && setAtt({ [att]: e })
                        : setAtt({ [att]: e });
                }}
                handleSubmit={submit}
                {...props}
            />
        );

    return (
        <Input
            className={'temp-input white is-small ' + className}
            type={type}
            value={tempObj[att]}
            placeholder='-'
            handleChange={(e) => {
                if (type === 'int') setAtt({ [att]: parseInt(e) });
                // floats are handled by type number
                else setAtt({ [att]: e });
            }}
            handleSubmit={submit}
            {...props}
        />
    );
};

export default TempInput;
