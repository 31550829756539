import React, { useState, useEffect, useRef } from 'react';
import './MultipleDownloadButton.scss';
import PropTypes from 'prop-types';

const MultipleDownloadButton = (props) => {
    const node = useRef();

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => document.removeEventListener('mousedown', handleClick);
    }, []);

    const handleClick = (e) => {
        if (node.current.contains(e.target)) return;
        setIsOpen(false);
    };

    return (
        <div ref={node} className='multiple-download-button-container'>
            <div
                className={`multiple-download-button ${props.className || ''}`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <i className='icon-cloud-download big' />
                <i className='icon-arrow-down' />
            </div>
            <div className={`multiple-download-button-options-container ${isOpen && 'show'}`}>
                {props.options.map((option, index) => (
                    <span
                        key={index}
                        className='download-option'
                        onClick={() => {
                            props.handleClick(option.value);
                            setIsOpen(false);
                        }}
                    >
                        {option.label}
                    </span>
                ))}
            </div>
        </div>
    );
};

MultipleDownloadButton.propTypes = {
    options: PropTypes.array.isRequired,
    handleClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default MultipleDownloadButton;
