import { connect } from 'react-redux';
import MonetaryBaseScreen from '../../components/monetaryBase/MonetaryBaseScreen';
import actions from '../../../actions';

const mapStateToProps = (state) => ({
    wallet: state.wallet.data,
    totalPushed: state.wallet.totalPushed,
    totalPushedStatus: state.wallet.totalPushedStatus,
    mintBurnStatus: state.wallet.EmitMintRemoveBurnStatus,
    getW3mmErrormessage: state.wallet.getW3mmStatus,
});

const mapDispatchToProps = (dispatch) => ({
    getW3mm: () => dispatch(actions.wallet.getW3mm()),
    getBalanceNexMonetaryBase: (address) =>
        dispatch(actions.wallet.getBalanceNexMonetaryBase(address)),
    getUserAddress: () => dispatch(actions.wallet.getUserAddress()),
    getTotalPublished: () => dispatch(actions.wallet.getTotalPublished()),

    getEmitMint: (mint) => dispatch(actions.wallet.getEmitMint(mint)),
    getRemoveBurn: (burn) => dispatch(actions.wallet.getRemoveBurn(burn)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MonetaryBaseScreen);
