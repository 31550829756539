import React from 'react';
import './ScreenQuickAccessCard.scss';
import Button from '../Button';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

const ScreenQuickAccessCard = (props) => {
    const history = useHistory();
    return (
        <div className='screen-quick-access-card'>
            <span className='title'>{props.title}</span>
            <div className='screen-image-container'>
                <Button
                    className='primary with-gradient'
                    text={I18n.t('dashboard.screenQuickAccessCard.button')}
                    onClick={() => props.redirectPath && history.push(props.redirectPath)}
                />
                <img alt='quick access background' src={props.imageSrc} />
            </div>
        </div>
    );
};

ScreenQuickAccessCard.propTypes = {
    title: PropTypes.string.isRequired,
    imageSrc: PropTypes.element,
    redirectPath: PropTypes.string,
};

export default withRouter(ScreenQuickAccessCard);
