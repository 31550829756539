import React from 'react';
import './TablePagination.scss';
import { I18n } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import firstIcon from '../../../assets/icons/list-first.svg';
import previousIcon from '../../../assets/icons/list-previous.svg';
import nextIcon from '../../../assets/icons/list-next.svg';
import lastIcon from '../../../assets/icons/list-last.svg';

const TablePagination = (props) => {
    const {
        page,
        pageSize,
        total,
        totalPages,
        onPageChange,
        pagedMessageTranslation,
        pagedMessageEmptyTranslation,
    } = props;

    return (
        <div className='paged-drawer'>
            <div>
                <span>
                    {total > 0
                        ? I18n.t(`${pagedMessageTranslation}`, {
                              pageStart: parseInt(page * pageSize) + 1,
                              pageEnd:
                                  (page + 1) * pageSize > total ? total : (page + 1) * pageSize,
                              total: total,
                          })
                        : I18n.t(`${pagedMessageEmptyTranslation}`)}
                </span>
            </div>
            <div>
                <button onClick={() => (page !== 0 ? onPageChange(0) : null)}>
                    <img alt='Primero' height='50px' src={firstIcon} />
                </button>
                <button onClick={() => (page !== 0 ? onPageChange(page - 1) : null)}>
                    <img alt='Anterior' height='50px' src={previousIcon} />
                </button>
                <button onClick={() => (totalPages !== page + 1 ? onPageChange(page + 1) : null)}>
                    <img alt='Siguiente' height='50px' src={nextIcon} />
                </button>
                <button
                    onClick={() => (totalPages !== page + 1 ? onPageChange(totalPages - 1) : null)}
                >
                    <img alt='Ultimo' height='50px' src={lastIcon} />
                </button>
            </div>
        </div>
    );
};

TablePagination.propTypes = {
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    pagedMessageTranslation: PropTypes.string.isRequired,
    pagedMessageEmptyTranslation: PropTypes.string.isRequired,
};

export default TablePagination;
