import React from 'react';
import './CardRows.scss';
import PropTypes from 'prop-types';
import IconCopy from '../Icon/Copy';
import IconEdit from '../Icon/Edit';
import IconDelete from '../Icon/Delete';
import IconUser from '../Icon/User/User';
import WalletCard from '../../../wallet/components/common/ListCard/WalletCard';
import {
    listContact,
    copyAction,
    editAction,
    deleteAction,
} from '../../../wallet/components/ShippingAddress/constants';

const CardRows = ({ onClick, contact, redirect }) => {
    return (
        <WalletCard className='contacts'>
            <div className='title-card' onClick={() => redirect(contact)}>
                <div className='item-card-row'>
                    <IconUser
                        width={listContact.size}
                        height={listContact.size}
                        color={listContact.color}
                    />
                </div>
                <div className='item-card-row title'>{contact.nombre}</div>
                <div className='item-card-row title'> |</div>
                <div className='item-card-row title'>{contact.address}</div>
            </div>
            <div className='icon-card-action'>
                <div className='item-icon' onClick={() => onClick('copy', contact)}>
                    <IconCopy
                        width={copyAction.size}
                        height={copyAction.size}
                        color={copyAction.color}
                    />
                </div>
                <div className='item-icon' onClick={() => onClick('edit', contact)}>
                    <IconEdit
                        width={editAction.size}
                        height={editAction.size}
                        color={editAction.color}
                    />
                </div>
                <div className='item-icon' onClick={() => onClick('delete', contact)}>
                    <IconDelete
                        width={deleteAction.size}
                        height={deleteAction.size}
                        color={deleteAction.color}
                    />
                </div>
            </div>
        </WalletCard>
    );
};

CardRows.propTypes = {
    onClick: PropTypes.func.isRequired,
    contact: PropTypes.object.isRequired,
};

export default CardRows;
