export const GET_CONTRACTS = 'GET_CONTRACTS';
export const GET_CONTRACTS_RESPONSE = 'GET_CONTRACTS_RESPONSE';
export const GET_CONTRACTS_ERROR = 'GET_CONTRACTS_ERROR';

export const GET_CONTRACT = 'GET_CONTRACT';
export const GET_CONTRACT_RESPONSE = 'GET_CONTRACT_RESPONSE';
export const GET_CONTRACT_ERROR = 'GET_CONTRACTS_ERROR';

export const GET_CONTRACT_OS = 'GET_CONTRACT_OS';
export const GET_CONTRACT_OS_RESPONSE = 'GET_CONTRACT_OS_RESPONSE';
export const GET_CONTRACT_OS_ERROR = 'GET_CONTRACT_OS_ERROR';

export const SET_CONTRACT_SELECTED = 'SET_CONTRACT_SELECTED';

export const PUT_CONTRACT = 'PUT_CONTRACT';
export const PUT_CONTRACT_RESPONSE = 'PUT_CONTRACT_RESPONSE';
export const PUT_CONTRACT_ERROR = 'PUT_CONTRACT_ERROR';

export const POST_CONTRACT = 'POST_CONTRACT';
export const POST_CONTRACT_RESPONSE = 'POST_CONTRACT_RESPONSE';
export const POST_CONTRACT_ERROR = 'POST_CONTRACT_ERROR';

export const RECTIFY_CONTRACT = 'RECTIFY_CONTRACT';

export const UPDATE_CONTRACTS_PAGE = 'UPDATE_CONTRACTS_PAGE';
export const UPDATE_CONTRACTS_PAGE_SIZE = 'UPDATE_CONTRACTS_PAGE_SIZE';

export const GET_AUDITOR_GROUPS = 'GET_AUDITOR_GROUPS';
export const GET_AUDITOR_GROUPS_RESPONSE = 'GET_AUDITOR_GROUPS_RESPONSE';
export const GET_AUDITOR_GROUPS_ERROR = 'GET_AUDITOR_GROUPS_ERROR';

export const GET_FAMILY_PROD = 'GET_FAMILY_PROD';
export const GET_FAMILY_PROD_RESPONSE = 'GET_FAMILY_PROD_RESPONSE';
export const GET_FAMILY_PROD_ERROR = 'GET_FAMILY_PROD_ERROR';

export const GET_CONTRACT_TYPES = 'GET_CONTRACT_TYPES';
export const GET_CONTRACT_TYPES_RESPONSE = 'GET_CONTRACT_TYPES_RESPONSE';
export const GET_CONTRACT_TYPES_ERROR = 'GET_CONTRACT_TYPES_ERROR';

export const SEARCH_CONTRACTS = 'SEARCH_CONTRACTS';
export const CLEAN_CONTRACT_TYPES = 'CLEAN_CONTRACT_TYPES';

export default {
    getContracts: () => ({ type: GET_CONTRACTS }),
    getContractsResponse: (response) => ({ type: GET_CONTRACTS_RESPONSE, response }),
    getContractsError: (error) => ({ type: GET_CONTRACTS_ERROR, error }),

    getContract: (id, successCallback) => ({ type: GET_CONTRACT, id, successCallback }),
    getContractResponse: (response) => ({ type: GET_CONTRACT_RESPONSE, response }),
    getContractError: (error) => ({ type: GET_CONTRACT_ERROR, error }),

    getContractOs: (id, successCallback) => ({ type: GET_CONTRACT_OS, id, successCallback }),
    getContractOsResponse: (response) => ({ type: GET_CONTRACT_OS_RESPONSE, response }),
    getContractOsError: (error) => ({ type: GET_CONTRACT_OS_ERROR, error }),

    setContractSelected: (body) => ({ type: SET_CONTRACT_SELECTED, body }),

    putContract: (ind) => ({ type: PUT_CONTRACT, ind }),
    putContractResponse: (response) => ({ type: PUT_CONTRACT_RESPONSE, response }),
    putContractError: (error) => ({ type: PUT_CONTRACT_ERROR, error }),

    postContract: (ind) => ({ type: POST_CONTRACT, ind }),
    postContractResponse: (response) => ({ type: POST_CONTRACT_RESPONSE, response }),
    postContractError: (error) => ({ type: POST_CONTRACT_ERROR, error }),

    rectifyContract: (cont, res) => ({ type: RECTIFY_CONTRACT, cont, res }),

    updatePage: (pageNumber) => ({ type: UPDATE_CONTRACTS_PAGE, pageNumber }),
    updatePageSize: (pageSize) => ({ type: UPDATE_CONTRACTS_PAGE_SIZE, pageSize }),

    getAuditorGroups: () => ({ type: GET_AUDITOR_GROUPS }),
    getAuditorGroupsResponse: (response) => ({ type: GET_AUDITOR_GROUPS_RESPONSE, response }),
    getAuditorGroupsError: (error) => ({ type: GET_AUDITOR_GROUPS_ERROR, error }),

    getFamilyProducts: (prod, force = false) => ({ type: GET_FAMILY_PROD, prod, force }),
    getFamilyProductsResponse: (response) => ({ type: GET_FAMILY_PROD_RESPONSE, response }),
    getFamilyProductsError: (error) => ({ type: GET_FAMILY_PROD_ERROR, error }),

    getContractTypes: () => ({ type: GET_CONTRACT_TYPES }),
    getContractTypesResponse: (response) => ({ type: GET_CONTRACT_TYPES_RESPONSE, response }),
    getContractTypesError: (error) => ({ type: GET_CONTRACT_TYPES_ERROR, error }),

    searchContracts: (search) => ({ type: SEARCH_CONTRACTS, search }),
    cleanContractTypes: () => ({ type: CLEAN_CONTRACT_TYPES }),
};
