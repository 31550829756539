import LinkAccess from '../../../../common/components/LinkAccess/LinkAccess';
import { I18n } from 'react-redux-i18n';
import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../common/components/Input';
import './PasteAddressInput.scss';
import ClipBoard from '../../../../common/components/Icon/ClipBoard';
import { clipboard } from './constants';

const PasteAddressInput = ({ label, labelIcon, address, handleChange, className, ...props }) => {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const handlePaste = (event) => {
        if (navigator.clipboard && navigator.clipboard.readText) {
            navigator.clipboard
                .readText()
                .then((text) => {
                    handleChange(text);
                })
                .catch((err) => {
                    console.error('Failed to read clipboard contents: ', err);
                });
        } else if (event.clipboardData && event.clipboardData.getData) {
            const text = event.clipboardData.getData('text/plain');
            handleChange(text);
        } else {
            navigator.permissions
                .query({ name: 'clipboard-read' })
                .then((result) => {
                    if (result.state === 'granted' || result.state === 'prompt') {
                        navigator.clipboard
                            .readText()
                            .then((text) => {
                                handleChange(text);
                            })
                            .catch((err) => {
                                console.error('Failed to read clipboard contents: ', err);
                            });
                    }
                })
                .catch((err) => {
                    console.error('Failed to request clipboard read permission: ', err);
                });
        }
    };

    return (
        <div className='input-paste-container'>
            <Input
                {...props}
                className='paste-input'
                inputClassname={`add-new-pill ${!isFirefox && 'with-icon'}`}
                label={label}
                labelClassName='add-new-input-label'
                labelIcon={labelIcon && labelIcon}
                value={address}
                handleChange={handleChange}
            />

            {!isFirefox && (
                <div className={`link-access-container ${className}`}>
                    <LinkAccess
                        title={I18n.t('wallet.monetaryBase.pasteAddress')}
                        className={`paste-address `}
                        handleClick={handlePaste}
                        icon={
                            <ClipBoard
                                width={clipboard.width}
                                height={clipboard.height}
                                color={clipboard.color}
                            />
                        }
                    />
                </div>
            )}
        </div>
    );
};

PasteAddressInput.propTypes = {
    handleChange: PropTypes.func.isRequired,
    address: PropTypes.string.isRequired,
};
export default PasteAddressInput;
