import { connect } from 'react-redux';
import actions from '../../actions';
import SharedRiskScreen from '../components/SharedRisk/SharedRiskScreen';

const mapStateToProps = (state) => ({
    sharedRiskTransactions: state.transaction.filteredSharedRiskTransactions,
    sharedRiskTransactionsStatus: state.transaction.sharedRiskTransactionsStatus,
    updateSharedRiskTransactionStatus: state.transaction.updateSharedRiskTransactionStatus,
});

const mapDispatchToProps = (dispatch) => ({
    getSharedRiskTransactions: () => dispatch(actions.transaction.getSharedRiskTransactions()),
    updateSharedRiskTransactions: (body, id) =>
        dispatch(actions.transaction.updateSharedRiskTransactions(body, id)),
    searchTransactions: (text) => dispatch(actions.transaction.searchSharedRiskTransactions(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SharedRiskScreen);
