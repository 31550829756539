import React, { useEffect } from 'react';
import './IndicationsList.scss';
import 'react-table/react-table.css'; // Default table styles
import { I18n } from 'react-redux-i18n';
import Button from '../../../common/components/Button';
import { Link, useHistory } from 'react-router-dom';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import SearchBar from '../../../common/components/SearchBar';
import TablePagination from '../../../common/components/TablePagination';

const IndicationsList = ({ indications, getIndications, setIndicationSelected, ...props }) => {
    const [textToSearch, setTextToSearch] = React.useState('');

    useEffect(() => {
        getIndications();
    }, []);

    const history = useHistory();

    const renderRowButtons = (props) => (
        <div className='action-buttons-container'>
            <a
                data-tip={I18n.t('indication.table.buttonTooltips.edit')}
                data-for='actionButtonTooltip'
            >
                <i
                    className='icon-pencil'
                    onClick={() => {
                        setIndicationSelected(props);
                        history.push('details');
                    }}
                />
            </a>
            <ReactTooltip
                id='actionButtonTooltip'
                backgroundColor='#008e99'
                effect='solid'
                className='action-button-tooltip'
            />
        </div>
    );

    const getTableColumns = () => {
        const commonData = {
            Cell: (props) => <span className='text'>{props.value}</span>,
            resizable: false,
            headerClassName: 'nexus-header center',
            className: 'nexus-cell center',
            style: { whiteSpace: 'unset' },
        };

        return [
            {
                ...commonData,
                id: '_id', // id: 'id' gets hidden
                Header: () => <h5>{I18n.t('indication.table.headers.id')}</h5>,
                accessor: (d) => d.id,
            },
            {
                ...commonData,
                id: 'name',
                Header: () => <h5>{I18n.t('indication.table.headers.name')}</h5>,
                accessor: (d) => d.titulo,
            },
            {
                ...commonData,
                id: 'type',
                Header: () => <h5>{I18n.t('indication.table.headers.type')}</h5>,
                accessor: (d) =>
                    I18n.t('indication.details.indication.treatmentTypes.' + d.tipo_tratamiento),
            },
            {
                ...commonData,
                id: 'desc',
                Header: () => <h5>{I18n.t('indication.table.headers.desc')}</h5>,
                accessor: (d) => d.descripcion,
            },
            {
                ...commonData,
                id: 'duration',
                Header: () => <h5>{I18n.t('indication.table.headers.duration')}</h5>,
                accessor: (d) => d.duracion,
            },
            {
                ...commonData,
                id: 'actions',
                Header: () => <h5>{I18n.t('indication.table.headers.action')}</h5>,
                accessor: (d) => d.buttons,
                Cell: (props) => renderRowButtons(props.original),
                width: 150,
            },
        ];
    };

    return (
        // props.loading
        //     ?
        //     <div className="indications-screen">
        //         <div className={"header"}/>
        //         <div className="table-container">
        //             <Loader />
        //         </div>
        //     </div>
        //     :
        <div className='indications-screen'>
            <div className='header'>
                <span className='title'>{I18n.t('indication.title')}</span>
                <SearchBar
                    textToSearch={textToSearch}
                    onSearch={(text) => {
                        setTextToSearch(text);
                        props.searchIndications(text);
                    }}
                />
                <div className='search-button'>
                    <Link to='details' style={{ textDecoration: 'none' }}>
                        <Button
                            type='button'
                            className='primary small'
                            onClick={() => {
                                setIndicationSelected({});
                            }}
                            text={I18n.t('indication.createNew')}
                        />
                    </Link>
                </div>
            </div>
            <div className='table-container'>
                <ReactTable
                    className={indications?.length > 0 ? 'nexus-table' : 'nexus-table empty'}
                    PaginationComponent={(paginationProps) => (
                        <TablePagination
                            {...paginationProps}
                            total={props.total}
                            totalPages={props.total / 10 /* TODO - MUTABLE PAGE SIZE */}
                            pagedMessageTranslation='indication.table.pagedMessage'
                            pagedMessageEmptyTranslation='indication.table.pagedMessageEmpty'
                        />
                    )}
                    minRows={0}
                    pageSize={10}
                    data={indications}
                    noDataText={I18n.t('indication.table.noRows')}
                    // todo check if this prop is necessary
                    getTdProps={() => {
                        return {
                            onClick: () => {},
                        };
                    }}
                    columns={getTableColumns()}
                />
            </div>
        </div>
    );
};

export default IndicationsList;
