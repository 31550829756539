import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import actions from '../../../actions';
import TempInput from '../../../common/components/TempInput/TempInput';
import '../IndicationsScreen/Indications.scss';
import './table.css';

const TreatmentRow = ({
    treatment,
    modifyTreatment,
    deleteTreatment,
    opt_familia_producto,

    showErrors,
    prevTreatment,
    nextTreatment,
    readOnly,
    ...props
}) => {
    const [modify, setModify] = useState(!treatment.familia_producto || treatment._edit);
    const tempTreatment = treatment;
    const setTempTreatment = (obj) => {
        const _obj = { ...tempTreatment, ...obj };
        modifyTreatment({
            ..._obj,
            ...(_obj.aplicacion_desde === _obj.aplicacion_hasta
                ? {
                      frecuencia: 0,
                  }
                : {}),
            ...(_obj.tipo_dosis !== 'P'
                ? {
                      dosis_maxima: 0,
                  }
                : {}),
            _edit: false,
        });
    };

    const setAtt = (att) => setTempTreatment({ ...tempTreatment, ...att });
    const submit = () => setModify(false);

    const opt_tipo_dosis = ['F', 'P'].map(
        // TODO - missing options ? not shown in design
        (v) => ({ value: v, label: I18n.t('indication.details.products.doseType.' + v) }),
    );

    const formatDosis = (value) => {
        if (treatment.tipo_dosis === 'P') {
            return `${value} mg/kg`;
        } else if (treatment.tipo_dosis === 'F') {
            return `${value} mg`;
        } else {
            return '-';
        }
    };

    const dateErrors =
        // !treatment.aplicacion_desde ||
        // !treatment.aplicacion_hasta ||
        treatment.aplicacion_desde > treatment.aplicacion_hasta || // invalid date
        (prevTreatment && treatment.aplicacion_desde <= prevTreatment.aplicacion_hasta) || // superposition with previous
        (nextTreatment && treatment.aplicacion_hasta >= nextTreatment.aplicacion_desde) || // superposition with next
        (prevTreatment && treatment.aplicacion_desde > prevTreatment.aplicacion_hasta + 1) || // gap between previous
        (nextTreatment && treatment.aplicacion_hasta < nextTreatment.aplicacion_desde - 1); // gap between next

    return modify ? (
        <tr {...props}>
            <td>
                {' '}
                <TempInput
                    att='aplicacion_desde'
                    type='uint'
                    min={1}
                    error={
                        (showErrors && tempTreatment?.aplicacion_desde === undefined) || dateErrors
                    }
                    tempObj={tempTreatment}
                    setAtt={setAtt}
                    submit={submit}
                />
            </td>
            <td>
                {' '}
                <TempInput
                    att='aplicacion_hasta'
                    type='uint'
                    min={1}
                    error={
                        (showErrors && tempTreatment?.aplicacion_hasta === undefined) || dateErrors
                    }
                    tempObj={tempTreatment}
                    setAtt={setAtt}
                    submit={submit}
                />
            </td>
            <td>
                {' '}
                <TempInput
                    att='familia_producto'
                    type='select'
                    error={showErrors && tempTreatment?.familia_producto === undefined}
                    options={opt_familia_producto ?? []}
                    className='csoc_mw mxh'
                    searchable={true}
                    tempObj={tempTreatment}
                    setAtt={setAtt}
                    submit={submit}
                />
            </td>
            <td>
                {' '}
                <TempInput
                    att='desde_dia'
                    type='uint'
                    min={1}
                    error={showErrors && tempTreatment?.desde_dia === undefined}
                    tempObj={tempTreatment}
                    setAtt={setAtt}
                    submit={submit}
                />
            </td>
            <td>
                {' '}
                <TempInput
                    att='frecuencia'
                    type='uint'
                    min={1}
                    error={showErrors && tempTreatment?.frecuencia === undefined}
                    tempObj={tempTreatment}
                    setAtt={setAtt}
                    submit={submit}
                    disabled={tempTreatment.aplicacion_desde === tempTreatment.aplicacion_hasta}
                />
            </td>
            <td>
                {' '}
                <TempInput
                    att='tipo_dosis'
                    type='select'
                    options={opt_tipo_dosis}
                    tempObj={tempTreatment}
                    setAtt={setAtt}
                    submit={submit}
                />
            </td>
            <td>
                {' '}
                <TempInput
                    att='dosis'
                    type='ufloat'
                    tempObj={tempTreatment}
                    setAtt={setAtt}
                    submit={submit}
                    error={showErrors && tempTreatment?.dosis === undefined}
                />
            </td>
            <td>
                <TempInput
                    att='dosis_maxima'
                    type='ufloat'
                    tempObj={tempTreatment}
                    setAtt={setAtt}
                    submit={submit}
                    disabled={tempTreatment.tipo_dosis !== 'P'}
                />
            </td>
            <td>
                <div className='action-container'>
                    <i className='icon-note' onClick={submit} />
                    <div className='action-pipe' />
                    <i className='icon-trash' onClick={() => deleteTreatment(treatment)} />
                </div>
            </td>
        </tr>
    ) : (
        <tr {...props}>
            <td
                className={
                    showErrors &&
                    (tempTreatment?.aplicacion_desde === undefined || dateErrors) &&
                    'error-td'
                }
            >
                {treatment.aplicacion_desde ?? '-'}
            </td>
            <td
                className={
                    showErrors &&
                    (isNaN(tempTreatment?.aplicacion_hasta) || dateErrors) &&
                    'error-td'
                }
            >
                {treatment.aplicacion_hasta ?? '-'}
            </td>
            <td
                className={
                    showErrors && tempTreatment?.familia_producto === undefined && 'error-td'
                }
            >
                {opt_familia_producto?.find((t) => t.value === treatment.familia_producto)?.label ??
                    '-'}
            </td>
            <td className={showErrors && tempTreatment?.desde_dia === undefined && 'error-td'}>
                {treatment.desde_dia ?? '-'}
            </td>
            <td className={showErrors && tempTreatment?.frecuencia === undefined && 'error-td'}>
                {treatment.frecuencia ?? '-'}
            </td>
            <td>{opt_tipo_dosis?.find((t) => t.value === treatment.tipo_dosis)?.label ?? '-'}</td>
            <td>{formatDosis(treatment.dosis)}</td>
            <td>{treatment.dosis_maxima ?? '-'}</td>
            <td>
                <div className='action-container'>
                    <i className='icon-pencil' onClick={() => !readOnly && setModify(true)} />
                    <div className='action-pipe' />
                    <i
                        className='icon-trash'
                        onClick={() => !readOnly && deleteTreatment(treatment)}
                    />
                </div>
            </td>
        </tr>
    );
};

const TreatmentTable = ({
    treatments,
    setTreatments,
    familia_producto,
    getProdFamily,

    showErrors,
    durationType,
    readOnly,
}) => {
    useEffect(() => {
        getProdFamily();
    }, []);

    const modifyTreatment = (treatment) => {
        const _treatments = treatments
            .map((t) => (t._id === treatment._id ? treatment : t))
            .sort(
                (a, b) =>
                    a.aplicacion_desde - b.aplicacion_desde ||
                    a.aplicacion_hasta - b.aplicacion_hasta ||
                    0,
            );

        setTreatments(_treatments);
    };

    const deleteTreatment = (treatment) => {
        setTreatments(treatments.filter((t) => t._id != treatment._id));
    };

    return (
        <table className='GreyTable TreatmentTable'>
            <thead>
                <tr>
                    <th colSpan={2}>
                        {I18n.t(
                            'indication.details.indication.durationTypes.' + (durationType ?? 'A'),
                        )}
                        <br />
                        {I18n.t('indication.details.products.table.aplicationTime')}
                    </th>
                    <th>{I18n.t('indication.details.products.table.family')}</th>
                    <th>{I18n.t('indication.details.products.table.fromDay')}</th>
                    <th>{I18n.t('indication.details.products.table.frequency')}</th>
                    <th>{I18n.t('indication.details.products.table.type')}</th>
                    <th>{I18n.t('indication.details.products.table.dose')}</th>
                    <th>{I18n.t('indication.details.products.table.maxDose')}</th>
                    <th>{I18n.t('indication.details.products.table.action')}</th>
                </tr>
            </thead>
            <tbody>
                {treatments?.map((treatment, i) => {
                    return (
                        <TreatmentRow
                            key={treatment._id}
                            treatment={treatment}
                            modifyTreatment={modifyTreatment}
                            deleteTreatment={deleteTreatment}
                            opt_familia_producto={familia_producto
                                .map((p) => ({ value: p.id, label: p.denominacion }))
                                .sort((a, b) => (a.label > b.label ? 1 : -1))}
                            showErrors={showErrors}
                            prevTreatment={treatments[i - 1]}
                            nextTreatment={treatments[i + 1]}
                            readOnly={readOnly}
                        />
                    );
                })}
            </tbody>
        </table>
    );
};

const mapStateToProps = (state) => ({
    familia_producto: state.indication.product_families,
});

const mapDispatchToProps = (dispatch) => ({
    getProdFamily: () => dispatch(actions.indication.getProdFamily()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentTable);
