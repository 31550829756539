import React from 'react';
import './TitleBar.scss';
import PropTypes from 'prop-types';

const TitleBar = ({
    classNameContainer,
    title,
    backgroundUnderline,
    titleClass,

    ...props
}) => {
    return (
        <>
            {title && (
                <div className={classNameContainer ?? 'container-underline'}>
                    <div className='wrapper-title-linkAccess'>
                        <div className='body-titleBar'>
                            <h5 className={titleClass ?? 'title'}>{title}</h5>
                            <hr
                                className={`underline-title ${
                                    backgroundUnderline ?? 'light-green'
                                }`}
                            />
                        </div>
                        {props.children}
                    </div>
                </div>
            )}
        </>
    );
};
TitleBar.propTypes = {
    title: PropTypes.string.isRequired,
    titleClass: PropTypes.string,
    backgroundUnderline: PropTypes.string,
};
export default TitleBar;
