import { connect } from 'react-redux';
import ProfileScreen from '../components/ProfileScreen';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    user: state.profile.user || {},
    osName: state.session.os_name,
    drugName: state.session.drug_name,
    saveKeysStatus: state.profile.saveKeysStatus,
    updateUserStatus: state.profile.updateUserStatus,
    validatePasswordStatus: state.profile.validatePasswordStatus,
    changePasswordStatus: state.profile.changePasswordStatus,
    migrationStatus: state.patient.patientMigrationStatus,
    userType: state.profile.user.type,
    osMigrated: state.session.migrated,
    osId: state.session.os_id,
});

const mapDispatchToProps = (dispatch) => ({
    saveKeys: (keys, address) => dispatch(actions.profile.saveKeys(keys, address)),
    updateUser: (user) => dispatch(actions.profile.updateUser(user)),
    validatePassword: (password) => dispatch(actions.profile.validatePassword(password)),
    changePassword: (currentPassword, newPassword, repeatNewPassword) =>
        dispatch(actions.profile.changePassword(currentPassword, newPassword, repeatNewPassword)),
    migratePatients: () => dispatch(actions.patient.patientMigration()),
    logout: () => dispatch(actions.session.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileScreen);
