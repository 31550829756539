import Papa from 'papaparse';
import { MONITOR_TRANSACTION_STATUS, TRANSACTION_STATUS } from '../constants';
import settings from '../settings/settings';
import { escapeChar } from './string';

const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/*
 * Form validators
 * */
export const validators = {
    isInteger: (str) => /^[0-9]*$/.test(str),
    validEmail: (email) => emailRegex.test(email),
    validateMinLength: (text, minLength) => text.length >= minLength,
};

/*
 * This method is used to parse the trace given by the scanner to an object format.
 *
 * GS1 format:
 * - gtin (starts with '01') --> 14 chars (if counting the prefix 16 chars)
 * - sn (starts with '21') --> up to 20 chars
 * - ven (starts with '17') --> 6 chars (YYMMDD) (if counting the prefix 8 chars)
 * - lot (starts with '10') --> up to 20 chars
 * */
export const parseGS1 = (trace) => {
    const traceWithoutEspecialSymbol = trace.replace('d2', '');
    const gtinAndSnSpecialCharacter = traceWithoutEspecialSymbol.indexOf('*') > -1 ? '*' : '}';
    const gtinAndSn = traceWithoutEspecialSymbol.split(gtinAndSnSpecialCharacter)[0];
    const venAndLot = traceWithoutEspecialSymbol.split(gtinAndSnSpecialCharacter)[1];

    const gtin = gtinAndSn.substring(0, gtinAndSn.indexOf('21'));
    const sn = gtinAndSn.substring(gtinAndSn.indexOf('21'), gtinAndSn.length);

    // note cause the date length is fixed
    const ven = venAndLot?.substring(0, 8);
    const lot = venAndLot?.substring(8, venAndLot.length);

    return {
        gtin,
        sn,
        ven,
        lot,
    };
};

export const parseToCsvAndDownload = (data, fileName, columnNames) => {
    const escapedData = data.map((x) => Object.values(x).map((v) => escapeChar(v)));
    const dataObj = columnNames ? { fields: columnNames, data: escapedData } : null;
    const csv = Papa.unparse(dataObj || escapedData);
    const file = new Blob([`"sep=,"\r\n${csv}`], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = url;
    a.download = fileName || 'list.csv';
    a.click();
};

export const parseDate = (date) => `${date.toLocaleDateString('es-AR')}`;

export const parseDateTime = (dateTime) =>
    `${dateTime.toLocaleDateString('es-AR')} - ${dateTime.toLocaleTimeString('es-AR')}`;

export const parseTransactionStatus = (statusCode) => {
    switch (statusCode) {
        case TRANSACTION_STATUS.PENDING:
            return 'pending';
        case TRANSACTION_STATUS.PROCESSING_PAYMENT:
            return 'processingPayment';
        case TRANSACTION_STATUS.REJECTED:
            return 'rejected';
        case TRANSACTION_STATUS.VALIDATED:
            return 'validated';
        case TRANSACTION_STATUS.VALIDATION_ERROR:
            return 'validationError';
        case TRANSACTION_STATUS.PAID:
            return 'paid';
        case TRANSACTION_STATUS.POSTED:
            return 'posted';
        case TRANSACTION_STATUS.INTERFACE_ERROR_SAP:
            return 'errorSAP';
        default:
            return 'pending'; // todo check if is correct
    }
};

export const parseMonitorTransactionStatus = (statusCode) => {
    switch (statusCode) {
        case 'Recibido':
            return MONITOR_TRANSACTION_STATUS.RECEIVED;
        case 'Enviado':
            return MONITOR_TRANSACTION_STATUS.SENT;
        case 'Fallo Envio':
            return MONITOR_TRANSACTION_STATUS.ERROR;
        default:
            return statusCode; // todo check if is correct
    }
};
export const reverseTransactionStatus = (statusCode) => {
    switch (statusCode) {
        case 'pending':
            return TRANSACTION_STATUS.PENDING;
        case 'processingPayment':
            return TRANSACTION_STATUS.PROCESSING_PAYMENT;
        case 'rejected':
            return TRANSACTION_STATUS.REJECTED;
        case 'validated':
            return TRANSACTION_STATUS.VALIDATED;
        case 'validationError':
            return TRANSACTION_STATUS.VALIDATION_ERROR;
        case 'paid':
            return TRANSACTION_STATUS.PAID;
        case 'posted':
            return TRANSACTION_STATUS.POSTED;
        case 'errorSAP':
            return TRANSACTION_STATUS.INTERFACE_ERROR_SAP;
        default:
            return statusCode; // todo check if is correct
    }
};

export const parseInstitutionType = (institutionType) => {
    switch (institutionType) {
        case 'F':
            return 'pharmacy';
        case 'I':
            return 'infusionCenters';
        case 'O':
            return 'others';
        case 'C':
            return 'takeCareOrPatient';
        default:
            return 'others'; // todo check if is correct
    }
};

export const openTermsAndConditions = () => {
    window.open(settings.termsAndConditionsPath, '_blank');
};
