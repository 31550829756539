import {
    GET_INSTITUTION_BY_CUIT,
    REGISTRAR_SIGN_UP,
    CREATE_INSTITUTION_AND_BRANCH,
    CREATE_BRANCH,
    CREATE_REGISTRAR,
    ACTIVATE_REGISTRAR_ACCOUNT,
} from './registrar.actions';
import { ACCEPT_TERMS_AND_CONDITIONS } from '../common/common.actions';
import { SAVE_KEYS, UPDATE_USER } from '../profile/profile.actions';
import actions from '../actions';
import { services } from './registrar.services';
import { I18n } from 'react-redux-i18n';
import { UserTypes } from '../constants';
import keysStorage from '../utils/storage/keys';
import { parseInstitutionType } from '../utils';

const registrarMiddleware =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
        next(action);
        switch (action.type) {
            case REGISTRAR_SIGN_UP:
                services
                    .registrarSignUp(createRegistrarSignUpBody(action))
                    .then((response) => {
                        dispatch(actions.registrar.registrarSignUpResponse(response));
                    })
                    .catch((error) => {
                        dispatch(
                            actions.registrar.registrarSignUpError(
                                translateSignUpError(error.data),
                            ),
                        );
                    });
                break;
            case GET_INSTITUTION_BY_CUIT:
                services
                    .getInstitutionByCuit(action.cuit)
                    .then((institutionResponse) => {
                        let institution = institutionResponse.results[0];
                        // if the institution exists, also search for its branches
                        if (institution) {
                            services
                                .getBranchByInstitutionId(institution.id)
                                .then((branchResponse) => {
                                    let branches = branchResponse.results[0].sucursal;
                                    dispatch(
                                        actions.registrar.getInstitutionByCuitResponse({
                                            institution,
                                            branches,
                                        }),
                                    );
                                });
                        } else {
                            dispatch(
                                actions.registrar.getInstitutionByCuitResponse({
                                    institution,
                                    branches: [],
                                }),
                            );
                        }
                    })
                    .catch((error) => {
                        dispatch(
                            actions.registrar.getInstitutionByCuitError(translateError(error)),
                        );
                    });
                break;
            case CREATE_BRANCH:
                services
                    .createBranch(createBranchBody(action, action.institutionId))
                    .then((response) => {
                        dispatch(actions.registrar.createBranchResponse({ branch: response }));
                    })
                    .catch((error) => {
                        dispatch(actions.registrar.createBranchError(error));
                    });
                break;
            case CREATE_INSTITUTION_AND_BRANCH:
                services
                    .createInstitution(createInstitutionBody(action))
                    .then((institutionResponse) => {
                        // when the institution was created successfully, create the branch
                        services
                            .createBranch(createBranchBody(action, institutionResponse.id))
                            .then((branchResponse) => {
                                dispatch(
                                    actions.registrar.createInstitutionAndBranchResponse({
                                        institution: institutionResponse,
                                        branch: branchResponse,
                                    }),
                                );
                            })
                            .catch((error) => {
                                dispatch(actions.registrar.createInstitutionAndBranchError(error));
                            });
                    })
                    .catch((error) => {
                        dispatch(actions.registrar.createInstitutionAndBranchError(error));
                    });
                break;
            case CREATE_REGISTRAR: {
                services
                    .createRegistrar(
                        createRegistrarBody(
                            getState().profile.user?.id,
                            getState().registrar.branch?.id,
                        ),
                    )
                    .then((response) => {
                        /*
                         * Here we calculate the user type, if the branch already have a public key, means that the user
                         * only has to import the private key, but if the branch doesn't have a public key created means
                         * that the user has to create a public and private pair of keys.
                         * */
                        const userType =
                            !response.sucursal_data.pubkey || response.sucursal_data.pubkey === ''
                                ? UserTypes.REGISTER_USER_WITHOUT_PUBLIC_KEY
                                : UserTypes.REGISTER_USER_WITHOUT_PRIVATE_KEY;

                        dispatch(
                            actions.registrar.createRegistrarResponse(
                                mapCreateRegistrarResponse(response, userType),
                            ),
                        );
                    })
                    .catch((error) => {
                        dispatch(actions.registrar.createRegistrarError(error));
                    });
                break;
            }
            case SAVE_KEYS:
                /*
                 * Due to the request is different depending of the user type, here we only handle the
                 * save keys action of the Register (without public key). The save keys action of the user
                 * OS (without public key) will be handle on the profile middleware.
                 * */
                if (getState().profile.user.type === UserTypes.REGISTER_USER_WITHOUT_PUBLIC_KEY) {
                    services
                        .updateBranchPubKey(getState().registrar.branch.id, {
                            pubkey: action.keys.public,
                        })
                        .then(() => {
                            // if successful, saves the private on local storage
                            // TODO remove
                            keysStorage.savePrivateByUsername(
                                action.keys.private,
                                getState().profile.user.username,
                            );
                            // also saves it on profile reducer
                            dispatch(
                                actions.profile.saveKeysResponse({
                                    privateKey: action.keys.private,
                                    publicKey: action.keys.public,
                                }),
                            );
                        })
                        .catch((error) => {
                            dispatch(actions.profile.saveKeysError(error));
                        });
                }
                break;
            case UPDATE_USER: {
                /*
                 * Due to the request is different depending of the user type, here we only handle the
                 * update user action of the Register. The update user action of the user
                 * OS will be handle on the profile middleware.
                 * */
                let userType = getState().profile.user.type;
                let isRegisterUser =
                    userType === UserTypes.REGISTER_USER ||
                    userType === UserTypes.REGISTER_USER_WITHOUT_PUBLIC_KEY ||
                    userType === UserTypes.REGISTER_USER_WITHOUT_PRIVATE_KEY;

                if (isRegisterUser) {
                    services
                        .updateRegistrar(createUpdateUserBody(action.user))
                        .then(() => {
                            // note: we return the action user because response is outdated
                            dispatch(
                                actions.profile.updateUserResponse({
                                    user: {
                                        email: action.user.email,
                                        first_name: action.user.name,
                                        last_name: action.user.lastName,
                                    },
                                }),
                            );
                        })
                        .catch((error) => dispatch(actions.profile.updateUserError(error)));
                }
                break;
            }
            case ACTIVATE_REGISTRAR_ACCOUNT:
                services
                    .activateRegistrarAccount(action.uid, action.token)
                    .then((response) => {
                        dispatch(actions.registrar.activateRegistrarAccountResponse(response));
                    })
                    .catch((error) => {
                        dispatch(actions.registrar.activateRegistrarAccountError(error));
                    });
                break;
            case ACCEPT_TERMS_AND_CONDITIONS: {
                /*
                 * Due to the request is different depending of the user type, here we only handle the
                 * accept terms and conditions action of the Register. The accept terms and conditions action action
                 * of the user OS will be handle on the Profile middleware.
                 * */
                let userType = getState().profile.user.type;
                let isRegisterUser =
                    userType === UserTypes.REGISTER_USER ||
                    userType === UserTypes.REGISTER_USER_WITHOUT_PUBLIC_KEY ||
                    userType === UserTypes.REGISTER_USER_WITHOUT_PRIVATE_KEY;

                if (isRegisterUser) {
                    services
                        .updateRegistrar(createAcceptTermsAndConditionsBody())
                        .then(() => {
                            dispatch(actions.common.acceptTermsAndConditionsResponse());
                        })
                        .catch((error) =>
                            dispatch(actions.common.acceptTermsAndConditionsError(error)),
                        );
                }
                break;
            }
            default:
        }
    };

const createRegistrarSignUpBody = (action) => ({
    email: action.email,
    username: action.username,
    first_name: action.firstName,
    last_name: action.lastName,
    password: action.password,
    re_password: action.passwordRepeat,
});

const createInstitutionBody = (action) => ({
    razon_social: action.institution.name,
    cuit: action.institution.cuit,
    tipo: action.institution.type,
});

const createBranchBody = (action, institucionId) => ({
    direccion: action.branch.address,
    telefono: action.branch.phone,
    pubkey: '', // todo ask why the public key is here.
    institucion: institucionId,
});

const createRegistrarBody = (userId, branchId) => ({
    user: userId,
    sucursal: branchId,
});

const createUpdateUserBody = (user) => {
    return {
        user: {
            first_name: user.name,
            last_name: user.lastName,
            email: user.email,
        },
    };
};

const createAcceptTermsAndConditionsBody = () => ({
    acepto_tyc: true,
});

const mapCreateRegistrarResponse = (response, userType) => ({
    institution: {
        id: response.sucursal_data.institucion?.id,
        name: response.sucursal_data.institucion?.razon_social,
        cuit: response.sucursal_data.institucion?.cuit,
        type: parseInstitutionType(response.sucursal_data.institucion?.tipo),
    },
    branch: {
        id: response.sucursal_data.id,
        address: response.sucursal_data.direccion,
        phone: response.sucursal_data.telefono,
        publicKey: response.sucursal_data.pubkey,
    },
    user: {
        id: response.user_data.id,
        username: response.user_data.username,
        first_name: response.user_data.first_name,
        last_name: response.user_data.last_name,
        email: response.user_data.email,
        type: userType,
    },
});

const translateError = (error) => {
    switch (error) {
        default:
            return I18n.t('errors.generic');
    }
};

const translateSignUpError = (error) => {
    try {
        const errorKeys = Object.keys(error);
        const firstErrorKey = errorKeys[0];
        const firstErrorArray = error[`${firstErrorKey}`];
        return firstErrorArray[0];
    } catch (e) {
        return I18n.t('errors.generic');
    }
};

export default registrarMiddleware;
