export const GET_WALLET_CONTACTS = 'GET_WALLET_CONTACTS';
export const GET_WALLET_CONTACTS_RESPONSE = 'GET_WALLET_CONTACTS_RESPONSE';
export const GET_WALLET_CONTACTS_ERROR = 'GET_WALLET_CONTACTS_ERROR';

export const GET_CONTACT = 'GET_CONTACT';
export const GET_CONTACT_RESPONSE = 'GET_CONTACT_RESPONSE';
export const GET_CONTACT_ERROR = 'GET_CONTACT_ERROR';

export const GET_WALLET_ADDRESS = 'GET_WALLET_ADDRESS';
export const GET_WALLET_ADDRESS_RESPONSE = 'GET_WALLET_ADDRESS';
export const GET_WALLET_ADDRESS_ERROR = 'GET_WALLET_ADDRESS_ERROR';

export const GET_WALLET_DETAILS = 'GET_WALLET_DETAILS';
export const GET_WALLET_DETAILS_RESPONSE = 'GET_WALLET_DETAILS_RESPONSE';
export const GET_WALLET_DETAILS_ERROR = 'GET_WALLET_DETAILS_ERROR';

export const GET_RECENT_TRANSACTIONS = 'GET_RECENT_TRANSACTIONS';
export const GET_RECENT_TRANSACTIONS_RESPONSE = 'GET_RECENT_TRANSACTIONS_RESPONSE';
export const GET_RECENT_TRANSACTIONS_ERROR = 'GET_RECENT_TRANSACTIONS_ERROR';

export const GET_ADDRESS_VALIDATION = 'GET_ADDRESS_VALIDATION';
export const GET_ADDRESS_VALIDATION_RESPONSE = 'GET_ADDRESS_RESPONSE';
export const GET_ADDRESS_VALIDATION_ERROR = 'GET_ADDRESS_VALIDATION_ERROR';

export const GET_TRANSACTION_VALIDATION = 'GET_TRANSACTION_VALIDATION';
export const GET_TRANSACTION_VALIDATION_RESPONSE = 'GET_TRANSACTION_VALIDATION_RESPONSE';
export const GET_TRANSACTION_VALIDATION_ERROR = 'GET_TRANSACTION_VALIDATION_ERROR';

export const SAVE_SEND_NEX = 'SAVE_SEND_NEX';

export const POST_SEND_NEX = 'POST_SEND_NEX';
export const POST_SEND_NEX_RESPONSE = 'POST_SEND_NEX_RESPONSE';
export const POST_SEND_NEX_ERROR = 'POST_SEND_NEX_ERROR';

export const CLEAR_ADDRESS = 'CLEAR_ADDRESS';

export const ADD_CONTACT = 'ADD_CONTACT';
export const ADD_CONTACT_RESPONSE = 'ADD_CONTACT_RESPONSE';
export const ADD_CONTACT_ERROR = 'ADD_CONTACT_ERROR';

export const EDIT_CONTACT = 'EDIT_CONTACT';
export const EDIT_CONTACT_RESPONSE = 'EDIT_CONTACT_RESPONSE';
export const EDIT_CONTACT_ERROR = 'EDIT_CONTACT_ERROR';

export const SET_CONTACT = 'SET_CONTACT';
export const SET_CONTACT_ID = 'SET_CONTACT_ID';

export const SEARCH_CONTACTS = 'SEARCH_CONTACTS';

export const DELETE_CONTACT = 'DELETE_CONTACT';
export const DELETE_CONTACT_RESPONSE = 'DELETE_CONTACT_RESPONSE';
export const DELETE_CONTACT_ERROR = 'DELETE_CONTACT_ERROR';

export const GET_BALANCE_NEX_MONETARY_BASE = 'GET_BALANCE_NEX_MONETARY_BASE';
export const GET_BALANCE_NEX_MONETARY_BASE_RESPONSE = 'GET_BALANCE_NEX_MONETARY_BASE_RESPONSE';
export const GET_BALANCE_NEX_MONETARY_BASE_ERROR = 'GET_BALANCE_NEX_MONETARY_BASE_ERROR';

export const GET_USER_ADDRESS = 'GET_USER_ADDRESS';
export const GET_USER_ADDRESS_RESPONSE = 'GET_USER_ADDRESS_RESPONSE';
export const GET_USER_ADDRESS_ERROR = 'GET_USER_ADDRESS_ERROR';

export const GET_EMIT_MINT = 'GET_EMIT_MINT';
export const GET_EMIT_MINT_RESPONSE = 'GET_EMIT_MINT_RESPONSE';
export const GET_EMIT_MINT_ERROR = 'GET_EMIT_MINT_ERROR';

export const GET_REMOVE_BURN = 'GET_REMOVE_BURN';
export const GET_REMOVE_BURN_RESPONSE = 'GET_REMOVE_BURN_RESPONSE';
export const GET_REMOVE_BURN_ERROR = 'GET_REMOVE_BURN_ERROR';

export const GET_WHITELISTERS = 'GET_WHITELISTERS';
export const GET_WHITELISTERS_RESPONSE = 'GET_WHITELISTERS_RESPONSE';
export const GET_WHITELISTERS_ERROR = 'GET_WHITELISTERS_ERROR';

export const ADD_WHITELISTERS = 'ADD_WHITELISTERS';
export const ADD_WHITELISTERS_RESPONSE = 'ADD_WHITELISTERS_RESPONSE';
export const ADD_WHITELISTERS_ERROR = 'ADD_WHITELISTERS_ERROR';

export const REMOVE_WHITELISTERS = 'REMOVE_WHITELISTERS';
export const REMOVE_WHITELISTERS_RESPONSE = 'REMOVE_WHITELISTERS_RESPONSE';
export const REMOVE_WHITELISTERS_ERROR = 'REMOVE_WHITELISTERS_ERROR';

export const GET_TOTAL_PUBLISHED = 'GET_TOTAL_PUBLISHED';
export const GET_TOTAL_PUBLISHED_RESPONSE = 'GET_TOTAL_PUBLISHED_RESPONSE';
export const GET_TOTAL_PUBLISHED_ERROR = 'GET_TOTAL_PUBLISHED_ERROR';

export const GET_W3MM = 'GET_W3MM';
export const GET_W3MM_RESPONSE = 'GET_W3MM_RESPONSE ';
export const GET_W3MM_ERROR = 'GET_W3MM_ERROR';

export const GET_PAYMENT_REGISTER = 'GET_PAYMENT_REGISTER';
export const GET_PAYMENT_REGISTER_RESPONSE = 'GET_PAYMENT_REGISTER_RESPONSE ';
export const GET_PAYMENT_REGISTER_ERROR = 'GET_PAYMENT_REGISTER_ERROR';

export default {
    getContacts: () => ({ type: GET_WALLET_CONTACTS }),
    getContactsResponse: (response) => ({ type: GET_WALLET_CONTACTS_RESPONSE, response }),
    getContactsError: (error) => ({ type: GET_WALLET_CONTACTS_ERROR, error }),

    getContact: (contactId) => ({ type: GET_CONTACT, contactId }),
    getContactResponse: (response) => ({ type: GET_CONTACT_RESPONSE, response }),
    getContactError: (error) => ({ type: GET_CONTACT_ERROR, error }),

    getAddress: (address) => ({ type: GET_WALLET_ADDRESS, address }),
    getAddressResponse: (response) => ({ type: GET_WALLET_ADDRESS_RESPONSE, response }),
    getAddressError: (error) => ({ type: GET_WALLET_ADDRESS_ERROR, error }),

    getWalletDetails: (response) => ({ type: GET_WALLET_DETAILS, response }),
    getWalletDetailsResponse: (response) => ({ type: GET_WALLET_DETAILS_RESPONSE, response }),
    getWalletDetailsError: (error) => ({ type: GET_WALLET_DETAILS_ERROR, error }),

    getRecentTransactions: () => ({ type: GET_RECENT_TRANSACTIONS }),
    getRecentTransactionsResponse: (response) => ({
        type: GET_RECENT_TRANSACTIONS_RESPONSE,
        response,
    }),
    getRecentTransactionsError: (error) => ({ type: GET_RECENT_TRANSACTIONS_ERROR, error }),

    getAddressValidation: (address) => ({ type: GET_ADDRESS_VALIDATION, address }),
    getAddressValidationResponse: (response) => ({
        type: GET_ADDRESS_VALIDATION_RESPONSE,
        response,
    }),
    getAddressValidationError: (error) => ({ type: GET_ADDRESS_VALIDATION_ERROR, error }),

    getTransactionValidation: (address) => ({ type: GET_TRANSACTION_VALIDATION, address }),
    getTransactionValidationResponse: (response) => ({
        type: GET_TRANSACTION_VALIDATION_RESPONSE,
        response,
    }),
    getTransactionValidationError: (error) => ({ type: GET_TRANSACTION_VALIDATION_ERROR, error }),

    saveSendNex: (body) => ({ type: SAVE_SEND_NEX, body }),

    postSendNex: (address) => ({ type: POST_SEND_NEX, address }),
    postSendNexResponse: (response) => ({ type: POST_SEND_NEX_RESPONSE, response }),
    postSendNexError: (error) => ({ type: POST_SEND_NEX_ERROR, error }),

    clearAddress: () => ({ type: CLEAR_ADDRESS }),

    addContact: (contact) => ({ type: ADD_CONTACT, contact }),
    addContactResponse: (response) => ({ type: ADD_CONTACT_RESPONSE, response }),
    addContactError: (error) => ({ type: ADD_CONTACT_ERROR, error }),

    editContact: (contact) => ({ type: EDIT_CONTACT, contact }),
    editContactResponse: (response) => ({ type: EDIT_CONTACT_RESPONSE, response }),
    editContactError: (error) => ({ type: EDIT_CONTACT_ERROR, error }),

    searchContacts: (search) => ({ type: SEARCH_CONTACTS, search }),

    deleteContact: (contactId) => ({ type: DELETE_CONTACT, contactId }),
    deleteContactResponse: (response) => ({ type: DELETE_CONTACT_RESPONSE, response }),
    deleteContactError: (error) => ({ type: DELETE_CONTACT_ERROR, error }),

    setContact: (contact) => ({ type: SET_CONTACT, contact }),
    setContactId: (contactId) => ({ type: SET_CONTACT_ID, contactId }),

    getBalanceNexMonetaryBase: (address) => ({ type: GET_BALANCE_NEX_MONETARY_BASE, address }),
    getBalanceNexMonetaryBaseResponse: (response) => ({
        type: GET_BALANCE_NEX_MONETARY_BASE_RESPONSE,
        response,
    }),
    getBalanceNexMonetaryBaseError: (error) => ({
        type: GET_BALANCE_NEX_MONETARY_BASE_ERROR,
        error,
    }),

    getUserAddress: () => ({ type: GET_USER_ADDRESS }),
    getUserAddressResponse: (address, funskey) => ({
        type: GET_USER_ADDRESS_RESPONSE,
        address,
        funskey,
    }),
    getUserAddressError: (error) => ({ type: GET_USER_ADDRESS_ERROR, error }),

    getTotalPublished: () => ({ type: GET_TOTAL_PUBLISHED }),
    getTotalPublishedResponse: (response) => ({ type: GET_TOTAL_PUBLISHED_RESPONSE, response }),
    getTotalPublishedError: (error) => ({ type: GET_TOTAL_PUBLISHED_ERROR, error }),

    getEmitMint: (mint) => ({ type: GET_EMIT_MINT, mint }),
    getEmitMintResponse: (response) => ({ type: GET_EMIT_MINT_RESPONSE, response }),
    getEmitMintError: (error) => ({ type: GET_EMIT_MINT_ERROR, error }),

    getRemoveBurn: (burn) => ({ type: GET_REMOVE_BURN, burn }),
    getRemoveBurnResponse: (response) => ({ type: GET_REMOVE_BURN_RESPONSE, response }),
    getRemoveBurnError: (error) => ({ type: GET_REMOVE_BURN_ERROR, error }),

    getWhitelisters: () => ({ type: GET_WHITELISTERS }),
    getWhitelistersResponse: (response) => ({ type: GET_WHITELISTERS_RESPONSE, response }),
    getWhitelistersError: (error) => ({ type: GET_WHITELISTERS_ERROR, error }),

    addWhitelisters: (address) => ({ type: ADD_WHITELISTERS, address }),
    addWhitelistersResponse: (response) => ({ type: ADD_WHITELISTERS_RESPONSE, response }),
    addWhitelistersError: (error) => ({ type: ADD_WHITELISTERS_ERROR, error }),

    removeWhitelisters: (address) => ({ type: REMOVE_WHITELISTERS, address }),
    removeWhitelistersResponse: (response) => ({ type: REMOVE_WHITELISTERS_RESPONSE, response }),
    removeWhitelistersError: (error) => ({ type: REMOVE_WHITELISTERS_ERROR, error }),

    getW3mm: (error) => ({ type: GET_W3MM, error }),
    getW3mmResponse: (error) => ({ type: GET_W3MM_RESPONSE, error }),
    getW3mmError: (error) => ({ type: GET_W3MM_ERROR, error }),

    getPaymentRegister: () => ({ type: GET_PAYMENT_REGISTER }),
    getPaymentRegisterResponse: (response) => ({ type: GET_PAYMENT_REGISTER_RESPONSE, response }),
    getPaymentRegisterError: (error) => ({ type: GET_PAYMENT_REGISTER_ERROR, error }),
};
