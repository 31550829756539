import { connect } from 'react-redux';
import Contract from '../components/Contract/Contract';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    selectedContract: state.contract.selectedContract,
    setContractStatus: state.contract.setContractStatus,

    osList: state.common.osList,
    indications: state.indication.results,
    auditorGroups: state.contract.auditorGroups,

    selectedIndication: state.indication.selectedIndication,
    contractTypes: state.contract.contractTypes,
});

const mapDispatchToProps = (dispatch) => ({
    getContract: (id) => dispatch(actions.contract.getContract(id)),
    putContract: (ind) => dispatch(actions.contract.putContract(ind)),
    postContract: (ind) => dispatch(actions.contract.postContract(ind)),

    getOsList: () => dispatch(actions.common.getOsList()),
    getAuditorGroups: () => dispatch(actions.contract.getAuditorGroups()),

    getIndications: (ind) => dispatch(actions.indication.getIndications(ind)),

    getFamilyProducts: (fam) => dispatch(actions.indication.getFamilyProducts(fam)),
    getProdFamily: () => dispatch(actions.indication.getProdFamily()),
    getContractTypes: () => dispatch(actions.contract.getContractTypes()),
    cleanContractTypes: () => dispatch(actions.contract.cleanContractTypes()),
    setIndicationSelected: (body) => dispatch(actions.indication.setIndicationSelected(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
