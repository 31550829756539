const _n = (x) => parseInt(x).toString(); // for normalizing dni

export const checkDuplicatedDNIPatient = (patient, patients, checkId) => {
    return patients.some((p) => {
        if (!patient?.dni) return false;
        if (checkId && p.id === patient.id) return false;
        const dniWithoutPoints = patient?.dni?.replace(/[^0-9]/g, '');
        return _n(p.dni) === _n(dniWithoutPoints);
    });
};
