export const states = [
    { value: 'P', label: 'pending' },
    { value: 'R', label: 'rejected' },
    { value: 'A', label: 'approved' },
];

export const defaultStatusSelected = [
    { id: 'P', checked: true },
    { id: 'R', checked: false },
    { id: 'A', checked: false },
];
