import React from 'react';
import './SelectBranchModal.scss';
import { SimpleModal } from '../../../common/components/Modal';
import PropTypes from 'prop-types';
import Button from '../../../common/components/Button';
import { I18n } from 'react-redux-i18n';

const SelectBranchModal = (props) => {
    const selectBranch = (branch) => {
        const mappedBranch = {
            id: branch.id,
            address: branch.direccion,
            phone: branch.telefono,
        };
        props.selectBranch(mappedBranch);
    };

    return (
        <div className='select-branch-modal'>
            <SimpleModal show={props.show}>
                <span className='title'>
                    {I18n.t('registrarNewAccount.selectBranchModal.title')}
                </span>
                <span className='subtitle'>
                    {I18n.t('registrarNewAccount.selectBranchModal.subtitle')}
                </span>
                {props.branches.map((branch) => (
                    <div
                        key={branch.id}
                        className='branch-selector'
                        onClick={() => selectBranch(branch)}
                    >
                        {/*<span>{I18n.t("registrarNewAccount.selectBranchModal.branch", {id: branch.id})}</span>*/}
                        <span>{branch.direccion}</span>
                    </div>
                ))}
                {props.branches.length === 0 && (
                    <span className='no-branches-found'>
                        {I18n.t('registrarNewAccount.selectBranchModal.noBranchesFound')}
                    </span>
                )}
                <Button
                    className='primary with-gradient'
                    onClick={() => props.newBranch()}
                    text={I18n.t('registrarNewAccount.selectBranchModal.button')}
                />
            </SimpleModal>
        </div>
    );
};

SelectBranchModal.propTypes = {
    show: PropTypes.bool.isRequired,
    branches: PropTypes.array.isRequired,
    selectBranch: PropTypes.func.isRequired,
    newBranch: PropTypes.func.isRequired,
};

export default SelectBranchModal;
