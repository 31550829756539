import React, { useEffect } from 'react';
import './WalletHome.scss';
import TransactionsListContainer from '../../containers/walletHome/TransactionsListContainer';
import WalletScreenWrapper from '../common/WalletScreen/WalletScreenWrapper';
import WalletHeader from './WalletHeader';

const WalletHomeScreen = ({ wallet, getWalletDetails, username }) => {
    useEffect(() => {
        getWalletDetails();
    }, [username]);

    return (
        <WalletScreenWrapper
            to=''
            className='wallet-home-card'
            classHead='wallet-card-header'
            classBody='wallet-card-body'
        >
            <WalletHeader wallet={wallet} />
            <TransactionsListContainer address={wallet?.address} />
        </WalletScreenWrapper>
    );
};
export default WalletHomeScreen;
