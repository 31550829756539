import {
    GET_TRANSACTIONS,
    GET_TRANSACTIONS_RESPONSE,
    GET_TRANSACTIONS_ERROR,
    GET_OS_AUDIT_TRANSACTIONS,
    GET_OS_AUDIT_TRANSACTIONS_RESPONSE,
    GET_OS_AUDIT_TRANSACTIONS_ERROR,
    GET_AUDITOR_TRANSACTIONS,
    GET_AUDITOR_TRANSACTIONS_RESPONSE,
    GET_AUDITOR_TRANSACTIONS_ERROR,
    POST_AUDITOR_TRANSACTIONS,
    POST_AUDITOR_TRANSACTIONS_RESPONSE,
    POST_AUDITOR_TRANSACTIONS_ERROR,
    SET_AUDITOR_PATIENT_DETAIL,
    GET_NEX_MONITOR_TRANSACTIONS,
    GET_NEX_MONITOR_TRANSACTIONS_RESPONSE,
    GET_NEX_MONITOR_TRANSACTIONS_ERROR,
    SEARCH_TRANSACTIONS,
    SEARCH_OS_AUDIT_TRANSACTIONS,
    SEARCH_AUDITOR_TRANSACTIONS,
    UPDATE_FILTERED_TRANSACTIONS_BY_DATE_RANGE,
    CLEAR_DATE_RANGE_SELECTION,
    UPDATE_TRANSACTIONS_PAGE,
    UPDATE_TRANSACTIONS_PAGE_SIZE,
    UPDATE_TRANSACTIONS_FILTER_VALUES,
    CLEAR_TRANSACTIONS_FILTERS,
    VALIDATE_TRANSACTIONS,
    VALIDATE_TRANSACTIONS_RESPONSE,
    VALIDATE_TRANSACTIONS_ERROR,
    REJECT_TRANSACTIONS,
    REJECT_TRANSACTIONS_RESPONSE,
    REJECT_TRANSACTIONS_ERROR,
    CLEAR_TRANSACTIONS_UI,
    DOWNLOAD_AUDITOR_TRANSACTIONS,
    DOWNLOAD_AUDITOR_TRANSACTIONS_RESPONSE,
    DOWNLOAD_AUDITOR_TRANSACTIONS_ERROR,
    DOWNLOAD_OS_TRANSACTIONS,
    DOWNLOAD_OS_TRANSACTIONS_RESPONSE,
    DOWNLOAD_OS_TRANSACTIONS_ERROR,
    DOWNLOAD_ROCHE_TRANSACTIONS,
    DOWNLOAD_ROCHE_TRANSACTIONS_RESPONSE,
    DOWNLOAD_ROCHE_TRANSACTIONS_ERROR,
    SEARCH_NEX_MONITOR_TRANSACTIONS,
    UPDATE_NEX_MONITOR_FILTERED_TRANSACTIONS_BY_DATE_RANGE,
    CLEAR_NEX_MONITOR_DATE_RANGE_SELECTION,
    UPDATE_NEX_MONITOR_TRANSACTIONS_FILTER_VALUES,
    UPDATE_NEX_MONITOR_TRANSACTIONS_PAGE,
    UPDATE_NEX_MONITOR_TRANSACTIONS_PAGE_SIZE,
    DOWNLOAD_NEX_MONITOR_TRANSACTIONS,
    DOWNLOAD_NEX_MONITOR_TRANSACTIONS_RESPONSE,
    DOWNLOAD_NEX_MONITOR_TRANSACTIONS_ERROR,
    VALIDATE_PAYMENTS,
    VALIDATE_PAYMENTS_RESPONSE,
    VALIDATE_PAYMENTS_ERROR,
    PAY,
    PAY_RESPONSE,
    PAY_ERROR,
    SEND_DATA_TO_SAP,
    SEND_DATA_TO_SAP_RESPONSE,
    SEND_DATA_TO_SAP_ERROR,
    GET_GRANTING_MONITOR_ADDRESSES,
    GET_GRANTING_MONITOR_ADDRESSES_RESPONSE,
    GET_GRANTING_MONITOR_ADDRESSES_ERROR,
    GRANT_ADDRESSES,
    GRANT_ADDRESSES_RESPONSE,
    GRANT_ADDRESSES_ERROR,
    SEARCH_GRANTING_MONITOR_ADDRESSES,
    UPDATE_GRANTING_MONITOR_ADDRESSES_FILTER_VALUES,
    UPDATE_GRANTING_MONITOR_PAGE,
    UPDATE_GRANTING_MONITOR_PAGE_SIZE,
    DOWNLOAD_GRANTING_MONITOR_ADDRESSES,
    DOWNLOAD_GRANTING_MONITOR_ADDRESSES_RESPONSE,
    DOWNLOAD_GRANTING_MONITOR_ADDRESSES_ERROR,
    TRACK_PAYMENTS_ERRORS,
    TRACK_PAYMENTS_ERRORS_RESPONSE,
    TRACK_PAYMENTS_ERRORS_ERROR,
    GET_PATIENT_AUDITOR,
    GET_PATIENT_AUDITOR_RESPONSE,
    GET_PATIENT_AUDITOR_ERROR,
    GET_TRANSACTION_MONITOR_TRANSACTIONS,
    GET_TRANSACTION_MONITOR_TRANSACTIONS_RESPONSE,
    GET_TRANSACTION_MONITOR_TRANSACTIONS_ERROR,
    UPDATE_TRANSACTION_MONITOR_TRANSACTIONS_FILTER_VALUES,
    SEARCH_TRANSACTION_MONITOR_TRANSACTIONS,
    UPDATE_SHARED_RISK_TRANSACTIONS,
    UPDATE_SHARED_RISK_TRANSACTIONS_RESPONSE,
    UPDATE_SHARED_RISK_TRANSACTIONS_ERROR,
    GET_SHARED_RISK_TRANSACTIONS,
    GET_SHARED_RISK_TRANSACTIONS_ERROR,
    GET_SHARED_RISK_TRANSACTIONS_RESPONSE,
    SEARCH_SHARED_RISK_TRANSACTIONS,
    SEND_NEX_ERROR,
    GET_WHITE_LISTED_ADDRESSES_RESPONSE,
    GET_WHITE_LISTED_ADDRESSES_ERROR,
    GET_WHITE_LISTED_ADDRESSES,
    SEND_NEX_MULTIPLE_RESPONSE,
    SEND_NEX_MULTIPLE_NEX,
    SEND_NEX_MULTIPLE_ERROR,
    MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS,
    MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS_RESPONSE,
    MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS_ERROR,
    GET_TRANSACTIONS_BY_FILTERS_RESPONSE,
    GET_TRANSACTIONS_BY_FILTERS_ERROR,
    GET_AUDITOR_MONITOR_TRANSACTIONS,
    GET_AUDITOR_MONITOR_TRANSACTIONS_RESPONSE,
    GET_AUDITOR_MONITOR_TRANSACTIONS_ERROR,
} from './transaction.actions';
import { REHYDRATE } from 'redux-persist';
import { LOGOUT } from '../session/session.actions';
import {
    AND_OPERATOR,
    OR_OPERATOR,
    parseConditionalStatement,
} from '../utils/conditionalStatementParser';
import { parseDate } from '../utils/';

const initialState = {
    results: [],
    nexMonitorResults: [],
    transactionMonitorResults: [],
    transactionMonitorFilteredResults: [],
    grantingMonitorResults: [],
    filteredResults: [],
    postAuditorTransactionStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
    postAuditorTransactionResponse: {},
    postSharedRiskTransactionStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
    postSharedRiskTransactionResponse: {},
    auditorPatientDetail: {},
    nexMonitorFilteredResults: [],
    grantingMonitorFilteredResults: [],
    filteredResultsByDateRange: [],
    nexMonitorFilteredResultsByDateRange: [],
    count: 0, // table rows (all transactions)
    nexMonitorCount: 0,
    grantingMonitorCount: 0,
    current: 1, // actual page
    nexMonitorCurrent: 1,
    grantingMonitorCurrent: 1,
    transactionMonitorCurrent: 1,
    total_pages: 1,
    nexMonitorTotalPages: 1,
    grantingMonitorTotalPages: 1,
    page_size: 10,
    nexMonitorPageSize: 10,
    grantingMonitorPageSize: 10,
    customFilter: {
        ordering: '',
        search: '',
        status: '',
    },
    nexMonitorCustomFilter: {
        ordering: '',
        search: '',
    },
    grantingMonitorCustomFilter: {
        ordering: '',
        search: '',
    },
    transactionMonitorCustomFilter: {
        ordering: '',
        search: '',
        status: '',
    },
    lastValidationResults: [],
    lastRejectionResults: [],
    lastValidatePaymentsResults: [],
    lastPayResults: [],
    lastSendDataToSAPResults: [],
    isWhiteLister: false,
    lastGrantAddressesResults: [],
    // UI related
    getTransactionsStatus: { error: false, loading: false, success: false, errorMessage: '' },
    getOsAuditTransactionsStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
    getAuditorTransactionsStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
    getSharedRiskTransactions: { error: false, loading: false, success: false, errorMessage: '' },
    updateSharedRiskTransactionStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
    getNexMonitorTransactionsStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
    getGrantingMonitorAddressesStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
    getTransactionMonitorTransactionsStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
    validateTransactionsStatus: { error: false, loading: false, success: false, errorMessage: '' },
    rejectTransactionsStatus: { error: false, loading: false, success: false, errorMessage: '' },
    validatePaymentsStatus: { error: false, loading: false, success: false, errorMessage: '' },
    sendNexStatus: { error: false, loading: false, success: false, responses: [] },
    sendMultipleNexStatus: { error: false, loading: false, success: false, responses: [] },
    payStatus: { error: false, loading: false, success: false, errorMessage: '' },
    trackPaymentsErrorsStatus: { error: false, loading: false, success: false, errorMessage: '' },
    sendDataToSAPStatus: { error: false, loading: false, success: false, errorMessage: '' },
    resendDataToSAPStatus: { error: false, loading: false, success: false, errorMessage: '' },
    grantAddressesStatus: { error: false, loading: false, success: false, errorMessage: '' },
    downloadTransactionsStatus: { error: false, loading: false, success: false, errorMessage: '' },
    downloadNexMonitorTransactionsStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
    downloadGrantingMonitorAddressesStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
    auditorTransactions: [],
    sharedRiskTransactions: [],
    filteredSharedRiskTransactions: [],
    filteredAuditorTransactions: [],
    osAuditTransactions: [],
    filteredOsAuditTransactions: [],
    patientAuditor: {},
    getPatientAuditorStatus: { error: false, loading: false, success: false, errorMessage: '' },
    auditorTransactionsNotifications: undefined,
};

const auditStates = {
    R: 'Rechazo Auditoría',
    P: 'Pendiente Auditoría',
    A: 'Aprobado Auditoría',
    Q: 'Requiere Auditoría',
};

const filterTransaction = (transaction, search, filter) => {
    const searchArray = parseConditionalStatement(search.toString().toLowerCase());
    return iterateArray(transaction, searchArray, filter);
};

const iterateArray = (transaction, searchArray, filter) => {
    if (Array.isArray(searchArray)) {
        if (searchArray[1] === AND_OPERATOR) {
            return (
                iterateArray(transaction, searchArray[0], filter) &&
                iterateArray(transaction, searchArray[2], filter)
            );
        } else if (searchArray[1] === OR_OPERATOR) {
            return (
                iterateArray(transaction, searchArray[0], filter) ||
                iterateArray(transaction, searchArray[2], filter)
            );
        }
    } else if (searchArray !== undefined) {
        return filterTransactionAux(transaction, searchArray.toString().trim(), filter);
    }
};

const filterTransactionAux = (transaction, search, filter) =>
    (transaction.id?.toString().toLowerCase().indexOf(search) > -1 ||
        transaction.dni?.toLowerCase().indexOf(search) > -1 ||
        transaction.name?.toLowerCase().indexOf(search) > -1 ||
        transaction.affiliate?.toLowerCase().indexOf(search) > -1 ||
        transaction.contract?.descripcion?.toLowerCase().indexOf(search) > -1 ||
        transaction.registrar?.toLowerCase().indexOf(search) > -1 ||
        transaction.paymentOrder?.toLowerCase().indexOf(search) > -1 ||
        transaction.tokenAmount?.toLowerCase().indexOf(search) > -1 ||
        transaction.currency?.toLowerCase().indexOf(search) > -1 ||
        transaction.patientId?.toString().toLowerCase().indexOf(search) > -1 ||
        transaction.os?.toLowerCase().indexOf(search) > -1 ||
        // nex monitor specific columns
        transaction.origin?.toLowerCase().indexOf(search) > -1 ||
        transaction.destination?.toLowerCase().indexOf(search) > -1 ||
        transaction.status?.toLowerCase().indexOf(search) > -1 ||
        // granting monitor specific columns
        transaction.businessName?.toLowerCase().indexOf(search) > -1 ||
        transaction.description?.toLowerCase().indexOf(search) > -1 ||
        transaction.address?.toLowerCase().indexOf(search) > -1 ||
        transaction.nex?.toString().toLowerCase().indexOf(search) > -1 ||
        transaction.jac?.toString().toLowerCase().indexOf(search) > -1 ||
        // transaction monitor specific columns
        transaction.guid?.toString().toLowerCase().indexOf(search) > -1 ||
        transaction.txnId?.toString().toLowerCase().indexOf(search) > -1 ||
        transaction.type?.toString().toLowerCase().indexOf(search) > -1 ||
        transaction.entity?.toString().toLowerCase().indexOf(search) > -1 ||
        transaction.detail?.toString().toLowerCase().indexOf(search) > -1) &&
    (applyFilter(transaction, filter) || filter);

const applyFilter = (transaction, filter) => {
    if (filter.status && transaction.statusAux)
        return filter.status.includes(transaction.statusAux); // for nex monitor
    if (filter.status) return filter.status.includes(transaction.status);
    return false;
};

const updateTransactions = (currentTransactions, transactionsToUpdate, filter) => {
    const transactionsIdToBeUpdated = transactionsToUpdate.map((transaction) => transaction.id);
    const result = [
        ...currentTransactions.map((transaction) => {
            if (transactionsIdToBeUpdated.includes(transaction.id)) {
                return transactionsToUpdate.filter((t) => t.id === transaction.id)[0];
            }
            return transaction;
        }),
    ];

    if (filter) return result.filter((transaction) => applyFilter(transaction, filter));
    return result;
};

const getFilteredResultsBySearch = (state, action) =>
    state.filteredResultsByDateRange?.length > 0
        ? action.search
            ? state.filteredResultsByDateRange.filter((transaction) =>
                  filterTransaction(transaction, action.search, state.customFilter),
              )
            : state.filteredResultsByDateRange.filter((transaction) =>
                  applyFilter(transaction, state.customFilter),
              )
        : action.search
        ? state.results.filter((transaction) =>
              filterTransaction(transaction, action.search, state.customFilter),
          )
        : state.results.filter((transaction) => applyFilter(transaction, state.customFilter));

const getNexMonitorFilteredResultsBySearch = (state, action) =>
    state.nexMonitorFilteredResultsByDateRange?.length > 0
        ? action.search
            ? state.nexMonitorFilteredResultsByDateRange.filter((transaction) =>
                  filterTransaction(transaction, action.search, state.nexMonitorCustomFilter),
              )
            : state.nexMonitorFilteredResultsByDateRange.filter((transaction) =>
                  applyFilter(transaction, state.nexMonitorCustomFilter),
              )
        : action.search
        ? state.nexMonitorResults.filter((transaction) =>
              filterTransaction(transaction, action.search, state.nexMonitorCustomFilter),
          )
        : state.nexMonitorResults.filter((transaction) =>
              applyFilter(transaction, state.nexMonitorCustomFilter),
          );
const filterSHaredRiskTransactions = (search, transactions) =>
    search
        ? transactions.filter(
              (transaction) =>
                  transaction.paciente_id.toString().includes(search) ||
                  transaction.id.toString().includes(search),
          )
        : transactions;

const getGrantingMonitorFilteredResultsBySearch = (state, action) =>
    action.search
        ? state.grantingMonitorResults.filter((address) =>
              filterTransaction(address, action.search, state.grantingMonitorCustomFilter),
          )
        : state.grantingMonitorResults;

const getTransactionMonitorFilteredResultsBySearch = (state, action) =>
    action.search
        ? state.transactionMonitorResults.filter((transaction) =>
              filterTransaction(transaction, action.search, state.transactionMonitorCustomFilter),
          )
        : state.transactionMonitorResults;

const transactionReducer = (state = initialState, action) => {
    const key = action.isRetry ? 'resendDataToSAPStatus' : 'sendDataToSAPStatus';
    switch (action.type) {
        case GET_TRANSACTIONS:
            return {
                ...state,
                getTransactionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case GET_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                results: action.response.results?.sort((a, b) => (a.id < b.id ? 1 : -1)),
                filteredResults: action.response?.results
                    ?.sort((a, b) => (a.id < b.id ? 1 : -1))
                    ?.filter((transaction) => applyFilter(transaction, state.customFilter)),
                filteredResultsByDateRange: action.response.results?.sort((a, b) =>
                    a.id < b.id ? 1 : -1,
                ),
                getTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
                nexMonitorResults: [],
                nexMonitorFilteredResults: [],
                downloadNexMonitorTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: false,
                    errorMessage: '',
                },
            };
        case GET_TRANSACTIONS_ERROR:
            return {
                ...state,
                postAuditorTransactionStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case GET_TRANSACTIONS_BY_FILTERS_RESPONSE:
            return {
                ...state,
                total: action.response.count,
                totalPages: action.response.total_pages,
                currentPage: action.response.current,
                results: action.response.results,
                filteredResults: action.response.results,
                // Note: commented because the back takes care of sending us filtered the result by state
                // ?.filter((transaction) => applyFilter(transaction, state.customFilter)),
                getTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case GET_TRANSACTIONS_BY_FILTERS_ERROR:
            return {
                ...state,
                postAuditorTransactionStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case POST_AUDITOR_TRANSACTIONS:
            return {
                ...state,
                postAuditorTransactionStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case POST_AUDITOR_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                postAuditorTransactionStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
                postAuditorTransactionResponse: action.response,
            };
        case POST_AUDITOR_TRANSACTIONS_ERROR:
            return {
                ...state,
                postAuditorTransactionStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: '',
                },
            };
        case UPDATE_SHARED_RISK_TRANSACTIONS:
            return {
                ...state,
                updateSharedRiskTransactionStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case UPDATE_SHARED_RISK_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                updateSharedRiskTransactionStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
                postAuditorTransactionResponse: action.response,
            };
        case UPDATE_SHARED_RISK_TRANSACTIONS_ERROR:
            return {
                ...state,
                updateSharedRiskTransactionStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error.data,
                },
            };
        case SET_AUDITOR_PATIENT_DETAIL:
            return {
                ...state,
                auditorPatientDetail: action.patient,
            };
        case GET_OS_AUDIT_TRANSACTIONS:
            return {
                ...state,
                getOsAuditTransactionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case GET_OS_AUDIT_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                osAuditTransactions: action.response,
                filteredOsAuditTransactions: action.response,
                getOsAuditTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case GET_OS_AUDIT_TRANSACTIONS_ERROR:
            return {
                ...state,
                getAuditorTransactionsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
                auditorTransactions: [],
            };
        case GET_AUDITOR_TRANSACTIONS:
            return {
                ...state,
                getAuditorTransactionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case GET_AUDITOR_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                auditorTransactions: action.response,
                filteredAuditorTransactions: action.response,
                auditorTransactionsNotifications: action.response.filter((x) => x.estado === 'P')
                    .length,
                getAuditorTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case GET_AUDITOR_TRANSACTIONS_ERROR:
            return {
                ...state,
                getOsAuditTransactionsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case GET_SHARED_RISK_TRANSACTIONS:
            return {
                ...state,
                getSharedRiskTransactionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case GET_SHARED_RISK_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                sharedRiskTransactions: action.response,
                filteredSharedRiskTransactions: action.response,
                auditorTransactionsNotifications: action.response.filter((x) => x.estado === 'P')
                    .length,
                getSharedRiskTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case GET_SHARED_RISK_TRANSACTIONS_ERROR:
            return {
                ...state,
                getSharedRiskTransactionsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case GET_NEX_MONITOR_TRANSACTIONS:
            return {
                ...state,
                getNexMonitorTransactionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case GET_NEX_MONITOR_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                nexMonitorResults: action.response.results?.sort((a, b) => (a.id < b.id ? 1 : -1)),
                nexMonitorFilteredResults: action.response.results
                    ?.sort((a, b) => (a.id < b.id ? 1 : -1))
                    ?.filter((transaction) =>
                        applyFilter(transaction, state.nexMonitorCustomFilter),
                    ),
                nexMonitorFilteredResultsByDateRange: action.response.results?.sort((a, b) =>
                    a.id < b.id ? 1 : -1,
                ),
                getNexMonitorTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case GET_NEX_MONITOR_TRANSACTIONS_ERROR:
            return {
                ...state,
                getNexMonitorTransactionsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case GET_GRANTING_MONITOR_ADDRESSES:
            return {
                ...state,
                getGrantingMonitorAddressesStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case GET_GRANTING_MONITOR_ADDRESSES_RESPONSE:
            return {
                ...state,
                grantingMonitorResults: action.response.results,
                grantingMonitorFilteredResults: action.response.results.filter((address) =>
                    applyFilter(address, state.grantingMonitorCustomFilter),
                ),
                getGrantingMonitorAddressesStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };

        case GET_GRANTING_MONITOR_ADDRESSES_ERROR:
            return {
                ...state,
                getGrantingMonitorAddressesStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case GET_WHITE_LISTED_ADDRESSES:
        case GET_WHITE_LISTED_ADDRESSES_RESPONSE:
        case GET_WHITE_LISTED_ADDRESSES_ERROR:
            return {
                ...state,
                grantingMonitorResults:
                    action.type === GET_WHITE_LISTED_ADDRESSES_RESPONSE
                        ? action.response
                        : state.grantingMonitorResults,
                grantingMonitorFilteredResults:
                    action.type === GET_WHITE_LISTED_ADDRESSES_RESPONSE
                        ? action.response.filter((address) =>
                              applyFilter(address, state.grantingMonitorCustomFilter),
                          )
                        : state.grantingMonitorFilteredResults,
                getGrantingMonitorAddressesStatus: {
                    error: action.type === GET_WHITE_LISTED_ADDRESSES_ERROR,
                    loading: action.type === GET_WHITE_LISTED_ADDRESSES,
                    success: action.type === GET_WHITE_LISTED_ADDRESSES_RESPONSE,
                    errorMessage: '',
                },
            };
        case GET_TRANSACTION_MONITOR_TRANSACTIONS:
            return {
                ...state,
                getTransactionMonitorTransactionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case GET_TRANSACTION_MONITOR_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                transactionMonitorResults: action.response.results.sort((a, b) =>
                    a.id < b.id ? 1 : -1,
                ),
                transactionMonitorFilteredResults: action.response.results.filter((address) =>
                    applyFilter(address, state.transactionMonitorCustomFilter),
                ),
                getTransactionMonitorTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case GET_TRANSACTION_MONITOR_TRANSACTIONS_ERROR:
            return {
                ...state,
                getTransactionMonitorTransactionsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };

        case VALIDATE_TRANSACTIONS:
            return {
                ...state,
                validateTransactionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case VALIDATE_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                lastValidationResults: action.response.results,
                results: updateTransactions(state.results, action.response.results),
                filteredResults: updateTransactions(
                    state.results,
                    action.response.results,
                    state.customFilter,
                ),
                validateTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case VALIDATE_TRANSACTIONS_ERROR:
            return {
                ...state,
                lastValidationResults: action.response.results,
                results: updateTransactions(state.results, action.response.results),
                filteredResults: updateTransactions(
                    state.results,
                    action.response.results,
                    state.customFilter,
                ),
                validateTransactionsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: '',
                },
            };
        case SEND_NEX_MULTIPLE_NEX:
            return {
                ...state,
                sendNexStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    responses: state.sendNexStatus.responses ?? [],
                },
            };
        case SEND_NEX_MULTIPLE_RESPONSE:
            return {
                ...state,
                sendNexStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    responses:
                        state.sendNexStatus.responses?.length > 0
                            ? [
                                  ...state.sendNexStatus.responses,
                                  {
                                      txId: action.response.transactionHash,
                                      id: action.response?.id,
                                  },
                              ]
                            : [
                                  {
                                      txId: action.response.transactionHash,
                                      id: action.response?.id,
                                  },
                              ],
                },
            };
        case SEND_NEX_MULTIPLE_ERROR:
            return {
                ...state,
                sendNexStatus: {
                    loading: false,
                    error: true,
                    success: false,
                    responses:
                        state.sendNexStatus.responses?.length > 0
                            ? [
                                  ...state.sendNexStatus.responses,
                                  {
                                      errorMessage: action.error?.reason,
                                      txId: null,
                                      id: action.error?.id,
                                  },
                              ]
                            : [
                                  {
                                      errorMessage: action.error?.reason,
                                      txId: null,
                                      id: action.error?.id,
                                  },
                              ],
                },
            };
        case SEND_NEX_ERROR:
            return {
                ...state,
                sendMultipleNexStatus: { loading: true, success: false, error: action.error },
            };
        case REJECT_TRANSACTIONS:
            return {
                ...state,
                rejectTransactionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case REJECT_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                lastRejectionResults: action.response.results,
                results: updateTransactions(state.results, action.response.results),
                filteredResults: updateTransactions(
                    state.results,
                    action.response.results,
                    state.customFilter,
                ),
                rejectTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case REJECT_TRANSACTIONS_ERROR:
            return {
                ...state,
                lastRejectionResults: action.response.results,
                results: updateTransactions(state.results, action.response.results),
                filteredResults: updateTransactions(
                    state.results,
                    action.response.results,
                    state.customFilter,
                ),
                rejectTransactionsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: '',
                },
            };
        case VALIDATE_PAYMENTS:
            return {
                ...state,
                validatePaymentsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case VALIDATE_PAYMENTS_RESPONSE:
            return {
                ...state,
                validatePaymentsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case VALIDATE_PAYMENTS_ERROR:
            return {
                ...state,
                validatePaymentsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.response.error,
                },
            };
        case PAY:
            return {
                ...state,
                payStatus: { error: false, loading: true, success: false, errorMessage: '' },
            };
        case PAY_RESPONSE:
            action.response.results.map((result) => (!result.errors ? (result.errors = []) : null));
            return {
                ...state,
                lastPayResults: action.response.results,
                results: updateTransactions(state.results, action.response.results),
                filteredResults: updateTransactions(
                    state.results,
                    action.response.results,
                    state.customFilter,
                ),
                payStatus: { error: false, loading: false, success: true, errorMessage: '' },
            };
        case PAY_ERROR:
            action.response.results.map((result) =>
                !result.errors ? (result.errors = [{ description: 'Error' }]) : null,
            );
            return {
                ...state,
                lastPayResults: action.response.results,
                results: updateTransactions(state.results, action.response.results),
                filteredResults: updateTransactions(
                    state.results,
                    action.response.results,
                    state.customFilter,
                ),
                payStatus: { error: true, loading: false, success: false, errorMessage: '' },
            };
        case TRACK_PAYMENTS_ERRORS:
            return {
                ...state,
                trackPaymentsErrorsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case TRACK_PAYMENTS_ERRORS_RESPONSE:
            return {
                ...state,
                trackPaymentsErrorsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: action.response,
                },
            };
        case TRACK_PAYMENTS_ERRORS_ERROR:
            return {
                ...state,
                trackPaymentsErrorsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.response.error,
                },
            };
        case SEND_DATA_TO_SAP:
            return {
                ...state,
                [key]: { error: false, loading: true, success: false, errorMessage: '' },
            };
        case SEND_DATA_TO_SAP_RESPONSE:
            return {
                ...state,
                [key]: { error: false, loading: false, success: true, errorMessage: '' },
                lastSendDataToSAPResults: action.response,
            };
        case SEND_DATA_TO_SAP_ERROR:
            return {
                ...state,
                [key]: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.response.error,
                },
            };
        case GRANT_ADDRESSES:
            return {
                ...state,
                grantAddressesStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case GRANT_ADDRESSES_RESPONSE:
            return {
                ...state,
                grantAddressesStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case GRANT_ADDRESSES_ERROR:
            return {
                ...state,
                grantAddressesStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.response.error,
                },
            };
        case SEARCH_TRANSACTIONS:
            return {
                ...state,
                filteredResults: getFilteredResultsBySearch(state, action),
                filteredResultsBySearch: getFilteredResultsBySearch(state, action),
            };
        case SEARCH_OS_AUDIT_TRANSACTIONS:
            return {
                ...state,
                filteredOsAuditTransactions: state.osAuditTransactions.filter((transaction) => {
                    return Object.values(transaction.paciente)
                        .join('')
                        .toLowerCase()
                        .replaceAll('[object object]', '')
                        .concat(
                            Object.values(transaction)
                                .join('')
                                .toLowerCase()
                                .replaceAll('[object object]', ''),
                        )
                        .concat(parseDate(new Date(transaction.created_at)))
                        .concat(auditStates[transaction.estado])
                        .toLowerCase()
                        .includes(action.search);
                }),
            };
        case SEARCH_AUDITOR_TRANSACTIONS:
            return {
                ...state,
                filteredAuditorTransactions: state.auditorTransactions.filter((transaction) => {
                    return Object.values(transaction.paciente)
                        .join('')
                        .toLowerCase()
                        .replaceAll('[object object]', '')
                        .concat(
                            Object.values(transaction)
                                .join('')
                                .toLowerCase()
                                .replaceAll('[object object]', ''),
                        )
                        .concat(parseDate(new Date(transaction.created_at)))
                        .concat(auditStates[transaction.estado])
                        .toLowerCase()
                        .includes(action.search);
                }),
            };
        case SEARCH_NEX_MONITOR_TRANSACTIONS:
            return {
                ...state,
                nexMonitorFilteredResults: getNexMonitorFilteredResultsBySearch(state, action),
                nexMonitorFilteredResultsBySearch: getNexMonitorFilteredResultsBySearch(
                    state,
                    action,
                ),
            };
        case SEARCH_GRANTING_MONITOR_ADDRESSES:
            return {
                ...state,
                grantingMonitorFilteredResults: getGrantingMonitorFilteredResultsBySearch(
                    state,
                    action,
                ),
                grantingMonitorFilteredResultsBySearch: getGrantingMonitorFilteredResultsBySearch(
                    state,
                    action,
                ),
            };
        case SEARCH_TRANSACTION_MONITOR_TRANSACTIONS:
            return {
                ...state,
                transactionMonitorFilteredResults: getTransactionMonitorFilteredResultsBySearch(
                    state,
                    action,
                ),
                transactionMonitorFilteredResultsBySearch:
                    getTransactionMonitorFilteredResultsBySearch(state, action),
            };
        case SEARCH_SHARED_RISK_TRANSACTIONS:
            return {
                ...state,
                filteredSharedRiskTransactions: filterSHaredRiskTransactions(
                    action.search,
                    state.sharedRiskTransactions,
                ),
            };

        case UPDATE_FILTERED_TRANSACTIONS_BY_DATE_RANGE:
            return {
                ...state,
                filteredResults: action.newResults.filter((transaction) =>
                    applyFilter(transaction, state.customFilter),
                ),
                filteredResultsByDateRange: action.newResults,
            };
        case UPDATE_NEX_MONITOR_FILTERED_TRANSACTIONS_BY_DATE_RANGE:
            return {
                ...state,
                nexMonitorFilteredResults: action.newResults.filter((transaction) =>
                    applyFilter(transaction, state.nexMonitorCustomFilter),
                ),
                nexMonitorFilteredResultsByDateRange: action.newResults,
            };
        case CLEAR_DATE_RANGE_SELECTION:
            return {
                ...state,
                filteredResultsByDateRange: state.results,
            };
        case CLEAR_NEX_MONITOR_DATE_RANGE_SELECTION:
            return {
                ...state,
                nexMonitorFilteredResultsByDateRange: state.nexMonitorResults,
            };
        case UPDATE_TRANSACTIONS_FILTER_VALUES:
            return {
                ...state,
                customFilter: { ...state.customFilter, ...action.filter },
                filteredResults: state?.filteredResultsByDateRange?.filter((transaction) =>
                    applyFilter(transaction, action.filter),
                ),
            };
        case UPDATE_NEX_MONITOR_TRANSACTIONS_FILTER_VALUES:
            return {
                ...state,
                nexMonitorCustomFilter: { ...state.nexMonitorCustomFilter, ...action.filter },
                nexMonitorFilteredResults: state.nexMonitorFilteredResultsByDateRange.filter(
                    (transaction) => applyFilter(transaction, action.filter),
                ),
            };
        case UPDATE_GRANTING_MONITOR_ADDRESSES_FILTER_VALUES: {
            let newGrantingMonitorFilteredResults = [...state.grantingMonitorFilteredResults];
            if (action.filter.ordering) {
                const isDesc = action.filter.ordering[0] === '-';
                const sortingId = isDesc
                    ? action.filter.ordering.substr(1)
                    : action.filter.ordering;
                newGrantingMonitorFilteredResults = newGrantingMonitorFilteredResults.sort(
                    (a, b) => {
                        let result = 0;
                        if (typeof a[sortingId] === 'number') {
                            result = a[sortingId] - b[sortingId];
                            if (a.status !== 'granted') result = -1;
                        } else {
                            result = a[sortingId].toString().localeCompare(b[sortingId]);
                        }
                        return result;
                    },
                );
            }
            return {
                ...state,
                grantingMonitorCustomFilter: {
                    ...state.grantingMonitorCustomFilter,
                    ...action.filter,
                },
                grantingMonitorFilteredResults: newGrantingMonitorFilteredResults,
            };
        }
        case UPDATE_TRANSACTION_MONITOR_TRANSACTIONS_FILTER_VALUES:
            return {
                ...state,
                transactionMonitorCustomFilter: {
                    ...state.transactionMonitorCustomFilter,
                    ...action.filter,
                },
                transactionMonitorFilteredResults: state.transactionMonitorFilteredResults,
            };
        case CLEAR_TRANSACTIONS_FILTERS:
            return { ...state, customFilter: initialState.customFilter };
        case UPDATE_TRANSACTIONS_PAGE:
            return { ...state, current: action.pageNumber };
        case UPDATE_NEX_MONITOR_TRANSACTIONS_PAGE:
            return { ...state, nexMonitorCurrent: action.pageNumber };
        case UPDATE_GRANTING_MONITOR_PAGE:
            return { ...state, grantingMonitorCurrent: action.pageNumber };
        case UPDATE_TRANSACTIONS_PAGE_SIZE:
            return { ...state, page_size: action.page_size };
        case UPDATE_NEX_MONITOR_TRANSACTIONS_PAGE_SIZE:
            return { ...state, nexMonitorPageSize: action.page_size };
        case UPDATE_GRANTING_MONITOR_PAGE_SIZE:
            return { ...state, grantingMonitorPageSize: action.page_size };
        case DOWNLOAD_AUDITOR_TRANSACTIONS:
        case DOWNLOAD_OS_TRANSACTIONS:
        case DOWNLOAD_ROCHE_TRANSACTIONS:
            return {
                ...state,
                downloadTransactionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case DOWNLOAD_NEX_MONITOR_TRANSACTIONS:
            return {
                ...state,
                downloadNexMonitorTransactionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case DOWNLOAD_GRANTING_MONITOR_ADDRESSES:
            return {
                ...state,
                downloadGrantingMonitorAddressesStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case DOWNLOAD_AUDITOR_TRANSACTIONS_RESPONSE:
        case DOWNLOAD_OS_TRANSACTIONS_RESPONSE:
        case DOWNLOAD_ROCHE_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                downloadTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case DOWNLOAD_NEX_MONITOR_TRANSACTIONS_RESPONSE:
            return {
                ...state,
                downloadNexMonitorTransactionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case DOWNLOAD_GRANTING_MONITOR_ADDRESSES_RESPONSE:
            return {
                ...state,
                downloadGrantingMonitorAddressesStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case DOWNLOAD_AUDITOR_TRANSACTIONS_ERROR:
        case DOWNLOAD_OS_TRANSACTIONS_ERROR:
        case DOWNLOAD_ROCHE_TRANSACTIONS_ERROR:
            return {
                ...state,
                downloadTransactionsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case DOWNLOAD_NEX_MONITOR_TRANSACTIONS_ERROR:
            return {
                ...state,
                downloadNexMonitorTransactionsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case DOWNLOAD_GRANTING_MONITOR_ADDRESSES_ERROR:
            return {
                ...state,
                downloadGrantingMonitorAddressesStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        case CLEAR_TRANSACTIONS_UI:
            return {
                ...state,
                getTransactionsStatus: initialState.getTransactionsStatus,
                getNexMonitorTransactionsStatus: initialState.getNexMonitorTransactionsStatus,
                getGrantingMonitorAddressesStatus: initialState.getGrantingMonitorAddressesStatus,
                validateTransactionsStatus: initialState.validateTransactionsStatus,
                rejectTransactionsStatus: initialState.rejectTransactionsStatus,
                validatePaymentsStatus: initialState.validatePaymentsStatus,
                payStatus: initialState.payStatus,
                trackPaymentsErrorsStatus: initialState.trackPaymentsErrorsStatus,
                sendDataToSAPStatus: initialState.sendDataToSAPStatus,
                grantAddressesStatus: initialState.grantAddressesStatus,
                lastPayResults: initialState.lastPayResults,
                sendNexStatus: initialState.sendNexStatus,
            };
        case LOGOUT:
            return { ...initialState };
        case REHYDRATE: {
            let incoming = action.payload;
            if (incoming) {
                return {
                    ...incoming.transaction,
                    getTransactionMonitorTransactionsStatus:
                        initialState.getTransactionMonitorTransactionsStatus,
                    transactionMonitorResults:
                        incoming.transaction?.transactionMonitorResults || [],
                    transactionMonitorCustomFilter:
                        incoming.transaction?.transactionMonitorCustomFilter ||
                        initialState.transactionMonitorCustomFilter,
                    getTransactionsStatus: initialState.getTransactionsStatus,
                    getNexMonitorTransactionsStatus: initialState.getNexMonitorTransactionsStatus,
                    getGrantingMonitorAddressesStatus:
                        initialState.getGrantingMonitorAddressesStatus,
                    validateTransactionsStatus: initialState.validateTransactionsStatus,
                    rejectTransactionsStatus: initialState.rejectTransactionsStatus,
                    validatePaymentsStatus: initialState.validatePaymentsStatus,
                    payStatus: initialState.payStatus,
                    trackPaymentsErrorsStatus: initialState.trackPaymentsErrorsStatus,
                    sendDataToSAPStatus: initialState.sendDataToSAPStatus,
                    resendDataToSAPStatus: initialState.resendDataToSAPStatus,
                    grantAddressesStatus: initialState.grantAddressesStatus,
                };
            } else {
                return state;
            }
        }
        case GET_PATIENT_AUDITOR:
        case GET_PATIENT_AUDITOR_RESPONSE:
        case GET_PATIENT_AUDITOR_ERROR:
            return {
                ...state,
                auditorPatientDetail:
                    action.type === GET_PATIENT_AUDITOR_RESPONSE
                        ? { ...state.auditorPatientDetail, _original: action.response }
                        : state.auditorPatientDetail,
                getPatientAuditorStatus: {
                    loading: action.type === GET_PATIENT_AUDITOR,
                    success: action.type === GET_PATIENT_AUDITOR_RESPONSE,
                    error: action.type === GET_PATIENT_AUDITOR_ERROR,
                    errorMessage: action.type === GET_PATIENT_AUDITOR_ERROR ? action.error : '',
                },
            };
        case MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS:
        case MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS_RESPONSE:
        case MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS_ERROR:
            return {
                ...state,
                commentMigrationStatus: {
                    inProgress: action.type === MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS,
                    error: action.type === MIGRATE_AUDITOR_TRANSACTIONS_COMMENTS_ERROR,
                    errorMessage: action.payload?.errorMessage && action.payload.errorMessage,
                },
            };
        default:
            return {
                ...state,
                sendNexStatus: initialState.sendNexStatus,
            };
    }
};

export default transactionReducer;
