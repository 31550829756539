export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGIN_RESPONSE_ERROR = 'LOGIN_RESPONSE_ERROR';

export const LOGIN_MIGRATION_REQUEST = 'LOGIN_MIGRATION_REQUEST';

export const LOGOUT = 'LOGOUT';

export const RESTORE_PASSWORD = 'RESTORE_PASSWORD';
export const RESTORE_PASSWORD_RESPONSE = 'RESTORE_PASSWORD_RESPONSE';
export const RESTORE_PASSWORD_ERROR = 'RESTORE_PASSWORD_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_RESPONSE = 'RESET_PASSWORD_RESPONSE';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const SAVE_REDIRECT_ROUTE = 'SAVE REDIRECT ROUTE';

export const RESET_SESSION_UI = 'RESET_SESSION_UI';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_RESPONSE = 'GET_NOTIFICATIONS_RESPONSE';
export const GET_NOTIFICATIONS_ERROR = 'GET_NOTIFICATIONS_ERROR';

export const CHECK_KEYSTORE = 'CHECK_KEYSTORE';
export const GET_IS_WHITE_LISTER = 'GET_IS_WHITE_LISTER';
export const GET_IS_WHITE_LISTER_RESPONSE = 'GET_IS_WHITE_LISTER_RESPONSE';
export const GET_IS_WHITE_LISTER_ERROR = 'GET_IS_WHITE_LISTER_ERROR';

export const UPDATE_MIGRATED_STATUS_RESPONSE = 'UPDATE_MIGRATED_STATUS_RESPONSE';
export const UPDATE_MIGRATED_STATUS_ERROR = 'UPDATE_MIGRATED_STATUS_ERROR';

const sessionActions = {
    loginRequest: (username, password) => ({
        type: LOGIN_REQUEST,
        email: username,
        password: password,
    }),
    loginResponse: (response) => ({ type: LOGIN_RESPONSE, response: response }),
    loginResponseError: (error) => ({ type: LOGIN_RESPONSE_ERROR, error }),
    loginMigrationRequest: (username, password) => ({
        type: LOGIN_MIGRATION_REQUEST,
        email: username,
        password: password,
    }),

    logout: (token) => ({ type: LOGOUT, token }),

    restorePassword: (email) => ({ type: RESTORE_PASSWORD, email }),
    restorePasswordResponse: (response) => ({ type: RESTORE_PASSWORD_RESPONSE, response }),
    restorePasswordError: (error) => ({ type: RESTORE_PASSWORD_ERROR, error }),

    resetPassword: (uid, token, password, passwordRepeat) => ({
        type: RESET_PASSWORD,
        uid,
        token,
        password,
        passwordRepeat,
    }),
    resetPasswordResponse: (response) => ({ type: RESET_PASSWORD_RESPONSE, response }),
    resetPasswordError: (error) => ({ type: RESET_PASSWORD_ERROR, error }),

    saveRedirectRoute: (path) => ({ type: SAVE_REDIRECT_ROUTE, path }),

    getNotifications: () => ({ type: GET_NOTIFICATIONS }),
    getNotificationsResponse: (response) => ({ type: GET_NOTIFICATIONS_RESPONSE, response }),
    getNotificationsError: (error) => ({ type: GET_NOTIFICATIONS_ERROR, error }),

    updateMigratedStatusResponse: (response) => ({
        type: UPDATE_MIGRATED_STATUS_RESPONSE,
        response,
    }),
    updateMigratedStatusError: (error) => ({ type: UPDATE_MIGRATED_STATUS_ERROR, error }),

    getIsWhiteLister: () => ({ type: GET_IS_WHITE_LISTER }),
    getIsWhiteListerResponse: (response) => ({ type: GET_IS_WHITE_LISTER_RESPONSE, response }),
    getIsWhiteListerError: (error) => ({ type: GET_IS_WHITE_LISTER_ERROR, error }),

    resetSessionUi: () => ({ type: RESET_SESSION_UI }),
};

export default sessionActions;
