import React from 'react';
import './LoginScreen.scss';
import LoginForm from './LoginForm';
import { I18n } from 'react-redux-i18n';
import roche_logo from '../../../assets/logos/roche-blue-small.png';
import nexus_logo from '../../../assets/logos/nexus-white-big.png';

const LoginScreen = (props) => {
    return (
        <div className='login-screen'>
            <div className='left-container'>
                <div className='logo'>
                    <img alt='Roche logo' src={roche_logo} />
                </div>
                <div className='form-container'>
                    <LoginForm login={props.login} loginStatus={props.loginStatus} />
                </div>
                <div className='power-by-container'>
                    <label className='power-by-text'>{I18n.t('login.powerBy')}</label>
                </div>
            </div>
            <div className='right-container'>
                <img alt='Nexus logo' src={nexus_logo} />
            </div>
        </div>
    );
};

export default LoginScreen;
