import { connect } from 'react-redux';
import TransactionsList from '../../components/walletHome/TransactionList/TransactionsList';
import actions from '../../../actions';

const mapStateToProps = (state) => ({
    transactions: state.wallet.recentTransactions,
    recentStatus: state.wallet.getRecentTransactionsStatus,
});

const mapDispatchToProps = (dispatch) => ({
    getRecentTransactions: () => dispatch(actions.wallet.getRecentTransactions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsList);
