import React, { useState, useEffect } from 'react';
import './DateRangePicker.scss';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';

const DateRangePicker = (props) => {
    const [allLastRegisteredDates, setAllLastRegisteredDates] = useState(undefined);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [loadedReceivedDates, setLoadedReceivedDates] = useState(false);

    useEffect(() => {
        registerLocale('es', es);
        setDefaultLocale('es');
        getAllLastRegisteredDates();
    }, []);

    useEffect(() => {
        if (props.receiveDates && !loadedReceivedDates) {
            setStartDate(props.receiveDates.startDate);
            setEndDate(props.receiveDates.endDate);
            setLoadedReceivedDates(true);
        }
    }, [props.receiveDates]);

    const getAllLastRegisteredDates = () => {
        let allLastRegisteredDates = [];
        props.allTransactions.map((transaction) => {
            allLastRegisteredDates.push(
                Array.isArray(transaction.dates)
                    ? transaction.dates[transaction.dates.length - 1].created_at
                    : transaction.timeReceived,
            );
        });
        setAllLastRegisteredDates(allLastRegisteredDates);
    };

    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    const handleStartDateChange = (selectedStartDate) => {
        setStartDate(selectedStartDate);
        //only user roche
        if (props.startDate) {
            props.startDate(moment(selectedStartDate).format('YYYY-MM-DD'));
            props.setPage(0);
        }

        filterDates(selectedStartDate, endDate);
        document.getElementById('end-date-react-picker').focus();
    };

    const handleEndDateChange = (selectedEndDate) => {
        setEndDate(selectedEndDate);
        //only user roche
        if (props.endDate) {
            props.endDate(moment(selectedEndDate).format('YYYY-MM-DD'));
            props.setPage(0);
        }

        filterDates(startDate, selectedEndDate);
    };

    const filterDates = (selectedStartDate, selectedEndDate) => {
        if (selectedStartDate) {
            const datesInRange = getDatesInRange(
                selectedStartDate,
                selectedEndDate ? selectedEndDate : selectedStartDate,
            );
            let transactionsInRange = props.allTransactions.filter((obj) =>
                datesInRange.includes(
                    obj.dates ? obj.dates[obj.dates.length - 1].created_at : obj.timeReceived,
                ),
            );
            if (props.textToSearch) {
                props.updateFilteredTransactionsByDateRange(transactionsInRange);
                props.searchText(props.textToSearch);
            } else {
                props.updateFilteredTransactionsByDateRange(transactionsInRange);
            }
        }
    };

    const getDatesInRange = (selectedStartDate, selectedEndDate) => {
        let datesInRange = [];
        const endDateFormatted = moment(
            moment(selectedEndDate).format('YYYY-MM-DD') + ' ' + '23:59',
        );
        allLastRegisteredDates.map((date) => {
            if (
                moment(date).isBetween(selectedStartDate, endDateFormatted, undefined, '[]') ||
                (selectedStartDate === endDateFormatted &&
                    moment(date).format('L') === moment(selectedStartDate).format('L'))
            ) {
                datesInRange.push(date);
            }
        });
        return datesInRange;
    };

    const clearDatesSelection = () => {
        props.clearDateRangeSelection();
        setStartDate(undefined);
        setEndDate(undefined);
    };

    return (
        <div className='date-range-picker'>
            {props.startDateLabel && <label>{props.startDateLabel}</label>}
            <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                onChangeRaw={handleDateChangeRaw}
                placeholderText={I18n.t(
                    'rocheTransaction.dateRangePicker.startDatePickerPlaceholder',
                )}
                maxDate={moment().endOf('day').toDate()}
                autoComplete='off'
                locale='es'
                showMonthYearPicker={props.showMonthYearPicker}
                dateFormat={props.showMonthYearPicker ? 'MMM yyyy' : undefined}
            />
            {props.endDateLabel && <label>{props.endDateLabel}</label>}
            <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                onChangeRaw={handleDateChangeRaw}
                placeholderText={I18n.t(
                    'rocheTransaction.dateRangePicker.endDatePickerPlaceholder',
                )}
                minDate={startDate}
                maxDate={moment().endOf('day').toDate()}
                autoComplete='off'
                locale='es'
                id='end-date-react-picker'
                showMonthYearPicker={props.showMonthYearPicker}
                dateFormat={props.showMonthYearPicker ? 'MMM yyyy' : undefined}
            />
            {(startDate || endDate) && !props.notShowReset && (
                <button
                    className='clear-dates-selection-button'
                    type='submit'
                    title={I18n.t(
                        'rocheTransaction.dateRangePicker.clearDatesSelectionButtonTooltip',
                    )}
                >
                    <i
                        className='icon-close clear-dates-selection-icon'
                        onClick={clearDatesSelection}
                    />
                </button>
            )}
        </div>
    );
};

DateRangePicker.propTypes = {
    allTransactions: PropTypes.array.isRequired,
    textToSearch: PropTypes.string.isRequired,
    searchText: PropTypes.func.isRequired,
    updateFilteredTransactionsByDateRange: PropTypes.func.isRequired,
    clearDateRangeSelection: PropTypes.func.isRequired,
    startDate: PropTypes.func,
    endDate: PropTypes.func,
    setPage: PropTypes.func,
    showMonthYearPicker: PropTypes.bool,
    receiveDates: PropTypes.object,
    notShowReset: PropTypes.bool,
    startDateLabel: PropTypes.string,
    endDateLabel: PropTypes.string,
};

export default DateRangePicker;
