import React, { useState, useEffect } from 'react';
// we are using the RegistrarInstitutionCard scss because this card is using almost the same styling
import '../RegistrarInstitutionCard/RegistrarInstitutionCard.scss';
import LineSeparator from '../../../assets/images/line-separator-with-green-indicator.png';
import { I18n } from 'react-redux-i18n';
import Input from '../../../common/components/Input';
import Button from '../../../common/components/Button';
import { validators } from '../../../utils';
import ErrorBox from '../../../common/components/ErrorBox';

const RegistrarCard = (props) => {
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [errorField, setErrorField] = useState();

    useEffect(() => {
        if (props.user) {
            setName(props.user.first_name);
            setLastName(props.user.last_name);
            setUsername(props.user.username);
            setEmail(props.user.email);
        }
    }, [props.user]);

    const validate = () => {
        if (!name || name === '') {
            setErrorField('name');
            setErrorMessage(I18n.t('registrarProfile.registrarCard.errors.nameIsRequired'));
            return false;
        }
        if (!lastName || lastName === '') {
            setErrorField('lastName');
            setErrorMessage(I18n.t('registrarProfile.registrarCard.errors.lastNameIsRequired'));
            return false;
        }
        if (!email || email === '') {
            setErrorField('email');
            setErrorMessage(I18n.t('registrarProfile.registrarCard.errors.emailIsRequired'));
            return false;
        }
        if (!validators.validEmail(email)) {
            setErrorField('email');
            setErrorMessage(I18n.t('registrarProfile.registrarCard.errors.emailNotValid'));
            return false;
        }
        setErrorField('');
        setErrorMessage('');
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (validate()) {
            props.updateUser({ email, name, lastName });
        }
    };

    return (
        <div className='registrar-institution-card'>
            {props.privateKeyActive && (
                <div className='active-key-box'>
                    <i className='icon-lock' />
                    <span>{I18n.t('profile.configurationCard.keyActive')}</span>
                </div>
            )}
            <span className='title'>{I18n.t('registrarProfile.registrarCard.title')}</span>
            <img alt='line separator' src={LineSeparator} />
            <div className='fix-height'>
                <ErrorBox show={errorMessage !== ''} errorMessage={errorMessage} />
            </div>
            <form className='inputs-container with-error' onSubmit={handleSubmit}>
                <div className='inputs-row'>
                    <div className='input-wrapper'>
                        <Input
                            className='white'
                            value={name}
                            label={I18n.t('registrarProfile.registrarCard.name')}
                            handleChange={setName}
                            error={errorField === 'name'}
                            icon={<i className='icon-user' />}
                        />
                    </div>
                    <div className='input-wrapper'>
                        <Input
                            className='white'
                            value={lastName}
                            label={I18n.t('registrarProfile.registrarCard.lastName')}
                            handleChange={setLastName}
                            error={errorField === 'lastName'}
                            icon={<i className='icon-user' />}
                        />
                    </div>
                    <div className='input-wrapper'>
                        <Input
                            className='white'
                            value={username}
                            label={I18n.t('registrarProfile.registrarCard.user')}
                            handleChange={() => {}}
                            icon={<i className='icon-user' />}
                            disabled
                        />
                    </div>
                </div>
                <div className='inputs-row'>
                    <div className='input-wrapper'>
                        <Input
                            className='white'
                            value={email}
                            label={I18n.t('registrarProfile.registrarCard.email')}
                            handleChange={setEmail}
                            error={errorField === 'email'}
                            icon={<i className='icon-envelope' />}
                        />
                    </div>
                </div>
                <div className='change-password'>
                    <i className='icon-key' />
                    <span onClick={props.showChangePasswordModal}>
                        {I18n.t('profile.card.changePassword')}
                    </span>
                </div>
                <Button
                    className='primary with-gradient small'
                    type='submit'
                    text={I18n.t('registrarProfile.registrarCard.button')}
                    loading={props.updateUserStatus.loading}
                />
            </form>
        </div>
    );
};

export default RegistrarCard;
