export const GET_PATIENT = 'GET_PATIENT';
export const GET_PATIENT_RESPONSE = 'GET_FMI_PATIENT_RESPONSE';
export const GET_PATIENT_ERROR = 'GET_FMI_PATIENT_ERROR';
export const CLEAR_SELECTED_FMI_PATIENT = 'CLEAR_SELECTED_FMI_PATIENT';
export const UPDATE_FMI_PATIENT = 'UPDATE_FMI_PATIENTS';
export const UPDATE_FMI_PATIENT_RESPONSE = 'UPDATE_FMI_PATIENTS_RESPONSE';
export const UPDATE_FMI_PATIENT_ERROR = 'UPDATE_FMI_PATIENTS_ERROR';
export const RESET_UPDATE_STATUS = 'RESET_UPDATE_STATUS';
export const GET_ACCESS = 'GET_ACCESS';
export const GET_ACCESS_RESPONSE = 'GET_ACCESS_RESPONSE';
export const GET_ACCESS_ERROR = 'GET_ACCESS_ERROR';
export const DELETE_FMI_CONTACT = 'DELETE_FMI_CONTACT';
export const GET_FMI_OPTIONS = 'GET_FMI_OPTIONS';
export const GET_OS_OPTIONS = 'GET_OS_OPTIONS';
export const GET_OPERATOR_OPTIONS = 'GET_OPERATOR_OPTIONS';
export const GET_MEDIC_OPTIONS = 'GET_MEDIC_OPTIONS';
export const GET_PATIENT_OPTIONS = 'GET_PATIENT_OPTIONS';
export const GET_AUDITOR_OPTIONS = 'GET_AUDITOR_OPTIONS';
export const GET_DRUGSTORE_OPTIONS = 'GET_DRUGSTORE_OPTIONS';
export const GET_ROLE_OPTIONS_RESPONSE = 'GET_ROLE_OPTIONS_RESPONSE';
export const GET_ROLE_OPTIONS_ERROR = 'GET_ROLE_OPTIONS_ERROR';
export const SEARCH_FMI_PATIENTS = 'SEARCH_FMI_PATIENTS';

/* Patients access */
export const ADD_PATIENT_ACCESS = 'ADD_PATIENT_ACCESS';
export const ADD_PATIENT_ACCESS_RESPONSE = 'ADD_PATIENT_ACCESS_RESPONSE';
export const ADD_PATIENT_ACCESS_ERROR = 'ADD_PATIENT_ACCESS_ERROR';

export const DELETE_PATIENT_ACCESS = 'DELETE_PATIENT_ACCESS';
export const DELETE_PATIENT_ACCESS_RESPONSE = 'DELETE_PATIENT_ACCESS_RESPONSE';
export const DELETE_PATIENT_ACCESS_ERROR = 'DELETE_PATIENT_ACCESS_ERROR';

export default {
    getPatient: (id, response) => ({ type: GET_PATIENT, id, response }),
    getPatientResponse: (response) => ({ type: GET_PATIENT_RESPONSE, response }),
    getPatientError: (error) => ({ type: GET_PATIENT_ERROR, error }),
    clearSelectedPatient: () => ({ type: CLEAR_SELECTED_FMI_PATIENT }),
    updatePatient: (patient, response) => ({ type: UPDATE_FMI_PATIENT, patient, response }),
    updatePatientResponse: (response) => ({ type: UPDATE_FMI_PATIENT_RESPONSE, response }),
    updatePatientError: (error) => ({ type: UPDATE_FMI_PATIENT_ERROR, error }),
    resetUpdateStatus: () => ({ type: RESET_UPDATE_STATUS }),
    getAccess: (id, response) => ({ type: GET_ACCESS, id, response }),
    getAccessResponse: (response) => ({ type: GET_ACCESS_RESPONSE, response }),
    getAccessError: (response) => ({ type: GET_ACCESS_ERROR, response }),
    deleteAccess: (id, response) => ({ type: DELETE_FMI_CONTACT, id, response }),
    getFmi: (response) => ({ type: GET_FMI_OPTIONS, response }),
    getOs: (response) => ({ type: GET_OS_OPTIONS, response }),
    getOperator: (response) => ({ type: GET_OPERATOR_OPTIONS, response }),
    getMedic: (response) => ({ type: GET_MEDIC_OPTIONS, response }),
    getPatients: (response) => ({ type: GET_PATIENT_OPTIONS, response }),
    getAuditor: (response) => ({ type: GET_AUDITOR_OPTIONS, response }),
    getDrugstore: (response) => ({ type: GET_DRUGSTORE_OPTIONS, response }),
    getRoleOptionsResponse: (response) => ({ type: GET_ROLE_OPTIONS_RESPONSE, response }),
    getRoleOptionsError: (error) => ({ type: GET_ROLE_OPTIONS_ERROR, error }),

    /* Patients access */
    addPatientAccess: (patientId, accessData, patientCse) => ({
        type: ADD_PATIENT_ACCESS,
        patientId,
        accessData,
        patientCse,
    }),
    addPatientAccessResponse: (response) => ({ type: ADD_PATIENT_ACCESS_RESPONSE, response }),
    addPatientAccessError: (error) => ({ type: ADD_PATIENT_ACCESS_ERROR, error }),

    deletePatientAccess: (accessId, body) => ({
        type: DELETE_PATIENT_ACCESS,
        accessId,
        body,
    }),
    deletePatientAccessResponse: (response) => ({ type: DELETE_PATIENT_ACCESS_RESPONSE, response }),
    deletePatientAccessError: (error) => ({ type: DELETE_PATIENT_ACCESS_ERROR, error }),
};
