import { connect } from 'react-redux';
import GrantList from '../../components/monetaryBase/grantList/GrantList';
import actions from '../../../actions';

const mapStateToProps = (state) => ({
    grants: state.wallet.addressWhiteListers,
    grantsStatus: state.wallet.addressWhiteListersStatus,
    addRemoveWhiteListersStatus: state.wallet.addRemoveWhiteListersStatus,
});

const mapDispatchToProps = (dispatch) => ({
    getWhitelisters: () => dispatch(actions.wallet.getWhitelisters()),

    addWhitelisters: (address) => dispatch(actions.wallet.addWhitelisters(address)),
    removeWhitelisters: (address) => dispatch(actions.wallet.removeWhitelisters(address)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GrantList);
