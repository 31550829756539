import React from 'react';
import './ProfileTooltip.scss';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import GreenBar from '../../../assets/images/profile-green-bar-small.png';
import { Link, useHistory } from 'react-router-dom';
import { UserTypes } from '../../../constants';
import KeyContainer from '../../../wallet/components/common/KeyContainer/KeyContainer';
import { check, copyKeyBtn } from '../../../wallet/components/common/PasteAddress/constants';
import Button from '../../../common/components/Button';
import settings from '../../../settings/settings';

const ProfileTooltip = (props) => {
    const history = useHistory();

    if (!props.show) return null;

    const { user, osName, drugName, myAddress } = props;

    const isOsUser = [
        UserTypes.OS_USER,
        UserTypes.OS_USER_WITHOUT_PUBLIC_KEY,
        UserTypes.OS_USER_WITHOUT_PRIVATE_KEY,
        UserTypes.OS_USER_WITHOUT_OLD_PRIVATE_KEY,
    ].includes(props.userType);

    const openInNewTab = (path) => window.open(path, '_blank');

    return (
        <div className='profile-tooltip'>
            <img className='bar' alt='green bar' src={GreenBar} />
            <div className='profile-tooltip-body'>
                <span className='welcome-title'>
                    {I18n.t('profile.tooltip.welcomeMessage', {
                        firstName: user.first_name,
                        lastName: user.last_name ?? '',
                    })}
                </span>
                {(user.type === UserTypes.OS_USER ||
                    user.type === UserTypes.DRUGSTORE ||
                    user.type === UserTypes.AUDITOR) && (
                    <span>
                        <span className='os-subtitle'>
                            {osName || drugName || user.audit_group.razon_social}
                        </span>
                        <Link
                            to='/profile'
                            style={{ textDecoration: 'none' }}
                            onClick={() => props.setShowProfile(false)}
                        >
                            <div className='clickable'>
                                <span className='text-data'>
                                    {I18n.t('profile.tooltip.goToProfile')}
                                </span>
                            </div>
                        </Link>
                    </span>
                )}
                {user.type === UserTypes.REGISTER_USER && (
                    <span>
                        <span className='os-subtitle'>{osName}</span>
                        <Link
                            to='/registrarProfile'
                            style={{ textDecoration: 'none' }}
                            onClick={() => props.setShowProfile(false)}
                        >
                            <div className='clickable'>
                                <span className='text-data'>
                                    {I18n.t('profile.tooltip.goToProfile')}
                                </span>
                            </div>
                        </Link>
                    </span>
                )}
                {isOsUser && (
                    <Button
                        className='small white bold'
                        text={I18n.t('dashboard.docsButtons.osTutorial')}
                        onClick={() => openInNewTab(settings.tutorialPathForOS)}
                    />
                )}
                <hr />
                <div className='icon-and-text-container'>
                    <i className='icon-user' />
                    <span className='text-data'>
                        {user.first_name} {user.last_name}
                    </span>
                </div>
                <div className='icon-and-text-container'>
                    <i className='icon-envelope' />
                    <span className='text-data underline'>{user.email}</span>
                </div>
                <div className='icon-and-text-container'>
                    <i className='icon-phone' />
                    <div>
                        <span className='text-data underline'>{user.phone}</span>
                        {/* todo get the int phone */}
                        {/*<span className={"text-data margin-left"}>Int 465</span>*/}
                    </div>
                </div>
                {user.type === UserTypes.ROCHE_USER && (
                    <>
                        <hr style={{ margin: '13px 0 0 0' }} />
                        <div className='active-key-box'>
                            <i className='icon-lock' />
                            <span>{I18n.t('profile.rocheUserKeyActive')}</span>
                        </div>
                        <div className='active-key-box'>
                            <KeyContainer
                                walletKey={myAddress}
                                iconStyleCopy={copyKeyBtn}
                                iconStyleCopyCheck={check}
                            />
                        </div>
                    </>
                )}
                {user.type === UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY && (
                    <div
                        className='private-key-button'
                        onClick={() => {
                            props.closeProfileTooltip();
                            history.push('importPrivateKey');
                        }}
                    >
                        <i className='icon-lock' />
                        <span className='text'>
                            {I18n.t('profile.configurationCard.recoverPrivateKey')}
                        </span>
                    </div>
                )}
                <hr />
                <div className='icon-and-text-container clickable' onClick={props.logout}>
                    <i className='icon-logout' />
                    <span className='text-data'>{I18n.t('profile.tooltip.exitFromPlatform')}</span>
                </div>
            </div>
        </div>
    );
};

ProfileTooltip.propTypes = {
    show: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    closeProfileTooltip: PropTypes.func,
};

export default ProfileTooltip;
