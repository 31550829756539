import React from 'react';
import './WalletIcon.scss';
import OpenWallet from '../../../../common/components/Icon/OpenWallet';
import { walletIcon } from './constants';
import RoundIconContainer from '../RoundIconContainer/RoundIconContainer';

const WalletIcon = () => {
    return (
        <RoundIconContainer color='white'>
            <OpenWallet
                width={walletIcon.width}
                height={walletIcon.height}
                color1={walletIcon.color1}
                color2={walletIcon.color2}
            />
        </RoundIconContainer>
    );
};
export default WalletIcon;
