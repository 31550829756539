import { connect } from 'react-redux';
import actions from '../../actions';
import NewAccessModal from '../Components/ContactsTableScreen/NewAccess/NewAccessModal';

const mapStateToProps = (state) => ({
    roleOptions: state.roleAccess.selectedRoleOptions,
    getOptionsStatus: state.roleAccess.getOptionsStatus,
    userId: state.profile.user.id,
    patient: state.patient.editionDetails,
    editPatientStatus: state.patient.editPatientStatus,
    getAccessStatus: state.patient.getAccessStatus,
    patientAccess: state.roleAccess.access,
});

const mapDispatchToProps = (dispatch) => ({
    getFmi: () => dispatch(actions.roleAccess.getFmi()),
    getOs: () => dispatch(actions.roleAccess.getOs()), // esta
    getOperator: () => dispatch(actions.roleAccess.getOperator()), //esta
    getMedic: () => dispatch(actions.roleAccess.getMedic()), // esta
    getPatients: () => dispatch(actions.roleAccess.getPatient()),
    getAuditor: () => dispatch(actions.roleAccess.getAuditor()),
    getDrugstore: () => dispatch(actions.roleAccess.getDrugstore()),
    editPatient: (id, patient) => dispatch(actions.patient.editPatient(id, patient)),
    resetUpdateStatus: () => dispatch(actions.roleAccess.resetUpdateStatus()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAccessModal);
