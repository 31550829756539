import { connect } from 'react-redux';
import actions from '../../actions';
import ModalAddressee from '../components/SendNex/ModalAddressee/ModalAddressee';

const mapStateToProps = (state) => ({
    directionSelection: state.wallet.directionSelection,
    addressValidationStatus: state.wallet.addressValidationStatus,
});

const mapDispatchToProps = (dispatch) => ({
    postSendNex: (addressNex) => dispatch(actions.wallet.postSendNex(addressNex)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAddressee);
