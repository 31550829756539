import React, { useEffect, useState } from 'react';
import './TempInput.scss';
import { decryptString, encryptString } from '../../../utils/encryption';
import { saveAs } from 'file-saver';
import { SimpleModal } from '../Modal';
import Button from '../Button';
import ReactTooltip from 'react-tooltip';
import { format } from 'date-fns';
import { fileSize, validFileExtensions } from './constants';
import Swal from 'sweetalert2';
import { I18n } from 'react-redux-i18n';

const FileInput = ({
    tempObj,
    setAtt,
    att,
    // submit,
    className,
    // privateKey,
    // publicKey,
    id,
    getFileToUploadUrl,
    // patientDetail,
    // auditGroupData,
    userType,
    patientCse,
    ...props
}) => {
    const filesAux = [];
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState('');

    //error notification
    const [showError, setShowError] = useState({
        error: false,
        title: '',
        msg: '',
    });

    useEffect(() => {
        if (showError.error)
            Swal.fire({
                icon: 'error',
                title: showError.title,
                text: showError.text,
            });
    }, [showError]);

    const processFile = async (file) => {
        const nameTemp = Date.now().toString() + file.name;
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = async function (e) {
                // posiblemente hay que encriptar a partir de la clave c descubrir la a y encriptar (en este caso la a es la privada de la os)
                const encrypted = encryptString(e.target.result, patientCse);
                const encryptedFile = new File([encrypted], nameTemp, {
                    type: file.type,
                    lastModified: file.lastModified,
                });
                const fileUrlToUpload = await getFileToUploadUrl(id, encryptedFile.name);
                resolve({ encryptedFile, fileUrlToUpload });
            };
            reader.readAsDataURL(file);
        });
    };

    const handleUpload = (file, cont) => {
        return processFile(file).then((res) => {
            filesAux.push({
                // numero: cont + tempObj[att]?.length ?? 0,
                numero: tempObj[att]?.length !== undefined ? cont + tempObj[att]?.length : 0,
                tipo_adjunto: 'A',
                contenido: res.encryptedFile.name,
                _file: res.encryptedFile,
                _fileUrlToUpload: res.fileUrlToUpload,
            });
        });
    };

    const addFiles = async (files) => {
        await Promise.all([...files].map((file, cont) => handleUpload(file, cont)));
        setAtt({
            [att]: [...(tempObj[att] ?? []), ...filesAux],
        });
    };

    const addComment = (comment) => {
        const attAux = [
            {
                numero: tempObj[att]?.length !== undefined ? tempObj[att]?.length + 1 : 0,
                tipo_adjunto: 'T',
                contenido: encryptString(comment, patientCse),
            },
        ];
        setAtt({
            [att]: [...(tempObj[att] ?? []), ...attAux],
        });
        setShowModal(false);
        setComment('');
    };

    const getFileName = (name) => {
        return name?.substring(13, name.length);
    };

    const downloadFile = (_file, contenido) => {
        const reader = new FileReader();
        reader.onloadend = function (e) {
            // posiblemente hay que encriptar a partir de la clave c descubrir la a y encriptar (en este caso la a es la privada de la os)
            const decrypted = decryptString(e.target.result, patientCse);
            const decryptedFile = new File([decrypted], getFileName(contenido), {
                type: decrypted.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0],
            });
            saveAs(decrypted, decryptedFile.name);
        };
        reader.readAsText(_file);
    };

    const deleteFile = (adjunto) => {
        return (
            <div
                className='_capture_file'
                onClick={() => {
                    deleteFile(adjunto);
                    setAtt(adjunto);
                }}
            >
                X
            </div>
        );
    };
    const validationFileToUpload = (files) => {
        let anyError = false;
        const validFiles = [];

        for (const file of files) {
            var fileExtension = file.name.split('.')[file.name.split('.').length - 1].toLowerCase();

            if (!validFileExtensions.includes(fileExtension)) {
                //file extension validation
                anyError = true;
                return setShowError({
                    error: true,
                    title: I18n.t('editPatient.evidences.files.fileAllowed.title'),
                    text: I18n.t('editPatient.evidences.files.fileAllowed.text', {
                        extensions: validFileExtensions.join(', '),
                    }),
                });
            }
            if (file && file.size > fileSize) {
                //size validation
                anyError = true;
                return setShowError({
                    error: true,
                    title: I18n.t('editPatient.evidences.files.sizeExceeded.title'),
                    text: I18n.t('editPatient.evidences.files.sizeExceeded.text'),
                });
            }

            validFiles.push(file);
        }
        if (!anyError) {
            addFiles(validFiles);
            setShowError({
                error: false,
            });
        }
    };

    return (
        <div
            className={'temp-input file-input ' + className}
            onClick={(e) => {
                if (e.target.classList.contains('_capture_file')) {
                    return;
                }
                e.preventDefault();
                e.currentTarget.classList.toggle('show-files');
            }}
        >
            <div className='file-container'>
                <div className='btn-close'>X</div>
                {tempObj[att]?.length > 0 &&
                    [...tempObj[att]]?.map((attachment, i) => {
                        //visualizacion de los files cargados
                        return attachment.tipo_adjunto === 'A' ? (
                            <div key={i} className='file-item'>
                                <div
                                    onClick={() => {
                                        !attachment.error &&
                                            downloadFile(attachment._file, attachment.contenido);
                                    }}
                                    className='label-comment'
                                >
                                    <div>
                                        {attachment.error
                                            ? attachment.contenido
                                            : getFileName(attachment._file?.name)}
                                    </div>
                                    <div style={attachment.error ? { color: 'red' } : {}}>
                                        {attachment.error
                                            ? 'Error'
                                            : parseInt(attachment._file?.size / 1000) + ' KB'}
                                    </div>
                                </div>
                                {attachment.updated_at && (
                                    <div className='date-comment-adj'>
                                        {format(
                                            new Date(attachment.updated_at),
                                            'dd-MM-yyyy HH:mm',
                                        )}
                                    </div>
                                )}
                                {props.showActions &&
                                    deleteFile({
                                        [att]: [...tempObj[att]].filter((e, j) => j !== i),
                                    })}
                            </div>
                        ) : (
                            //visualizacion de los comentarios cargados
                            <div key={i} className='file-item'>
                                <div className='label-comment'>
                                    <div className='date-comment-adj'>
                                        <a data-tip data-for='labelComment'>
                                            {decryptString(attachment.contenido, patientCse)}
                                        </a>
                                    </div>
                                    {attachment.updated_at && (
                                        <div className='date-comment-adj'>
                                            {format(
                                                new Date(attachment.updated_at),
                                                'dd-MM-yyyy HH:mm',
                                            )}
                                        </div>
                                    )}
                                    <ReactTooltip
                                        id='labelComment'
                                        backgroundColor='#008e99'
                                        effect='solid'
                                        className='action-button-tooltip'
                                    >
                                        {decryptString(attachment.contenido, patientCse)}
                                    </ReactTooltip>
                                </div>
                                {userType !== 'AUDITOR' && (
                                    <div
                                        className='_capture_file'
                                        onClick={() => {
                                            setAtt({
                                                [att]: [...tempObj[att]].filter((e, j) => j !== i),
                                            });
                                        }}
                                    ></div>
                                )}
                                {props.showActions &&
                                    deleteFile({
                                        [att]: [...tempObj[att]].filter((e, j) => j !== i),
                                    })}
                            </div>
                        );
                    })}
                {props.showActions && (
                    <div className='container-actions'>
                        <label className={'temp-input file-input _addFile' + className}>
                            <input
                                type='file'
                                className='_capture_file'
                                accept='.jpg, .png, .tiff, .pdf, .doc, .docx'
                                onChange={(e) => {
                                    validationFileToUpload(e.target.files);
                                }}
                                // value={tempObj[att]}
                                {...props}
                            />
                            {userType !== 'AUDITOR' && (
                                <div className='card-rlink _capture_file'>+ Adjunto</div>
                            )}
                        </label>
                        <label
                            className={'temp-input file-input _addFile ' + className}
                            onClick={() => setShowModal(true)}
                        >
                            {userType !== 'AUDITOR' && (
                                <div
                                    className='card-rlink _capture_file'
                                    style={{ paddingLeft: '10px' }}
                                >
                                    + Comentario
                                </div>
                            )}
                        </label>
                    </div>
                )}
            </div>
            <span>
                <i className='icon-paper-clip' />
                <div>Evidencias{tempObj[att]?.length ? '(' + tempObj[att].length + ')' : ''}</div>
            </span>
            {/* Add comentarios */}
            <SimpleModal show={showModal}>
                <div className='terms-and-conditions-modal'>
                    <div className='header'>
                        <span className='title'>Ingrese comentario</span>
                    </div>
                    <div className='footer' style={{ marginLeft: 0 }}>
                        <input
                            style={{ marginBottom: 45, color: 'black' }}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Button
                                className='primary with-gradient'
                                onClick={() => {
                                    addComment(comment);
                                }}
                                text='Guardar'
                            />
                            <Button
                                className='primary with-gradient'
                                onClick={() => {
                                    setShowModal(false);
                                }}
                                text='Cancelar'
                            />
                        </div>
                    </div>
                </div>
            </SimpleModal>
        </div>
    );
};

export default FileInput;
