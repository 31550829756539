import { connect } from 'react-redux';
import actions from '../../actions';
import RecoverPasswordScreen from '../components/RecoverPasswordScreen/RecoverPasswordScreen';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    restorePasswordStatus: state.session.restorePasswordStatus,
});

const mapDispatchToProps = (dispatch) => ({
    restorePassword: (email) => dispatch(actions.session.restorePassword(email)),
    resetUi: () => dispatch(actions.session.resetSessionUi()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordScreen));
