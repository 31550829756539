import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import Button from '../../../../common/components/Button';
import { SimpleModal } from '../../../../common/components/Modal';
import './ErrorModal.scss';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

const ErrorModal = (props) => {
    const openInNewTab = (path) => window.open(path, '_blank');
    return (
        <SimpleModal show={props.show}>
            <div className='monetaryBase-error-modal'>
                <div className='wrapper-modal'>
                    <FontAwesomeIcon icon={faInfo} className='icon-info' />
                </div>
                <div className='wrapper-modal'>
                    <span className='subtitle'>
                        {I18n.t('wallet.monetaryBase.installMetaMask.msg')}
                    </span>
                </div>
                <div className='wrapper-modal'>
                    <Button
                        className='primary with-gradient no-shadow'
                        text='Acceder'
                        onClick={() =>
                            openInNewTab(I18n.t('wallet.monetaryBase.installMetaMask.install'))
                        }
                    />
                </div>
            </div>
        </SimpleModal>
    );
};
export default ErrorModal;
