import {
    TOGGLE_SIDEBAR,
    GET_OS_CONTRACTS_RESPONSE,
    GET_OS_CONTRACT_RESPONSE,
    GET_ALL_OS_RESPONSE,
    ACCEPT_TERMS_AND_CONDITIONS,
    ACCEPT_TERMS_AND_CONDITIONS_RESPONSE,
    ACCEPT_TERMS_AND_CONDITIONS_ERROR,
    SET_BREADCRUMBS,
} from './common.actions';
import { LOGOUT } from '../session/session.actions';
import { REHYDRATE } from 'redux-persist/es/constants';

const initialState = {
    sidebarOpen: false,
    OSContracts: [],
    osList: [],
    productsByOs: [],
    acceptTermsAndConditionsStatus: {
        error: false,
        loading: false,
        success: false,
        errorMessage: '',
    },
    breadcrumbs: [],
};

const common = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_SIDEBAR:
            return { ...state, sidebarOpen: !state.sidebarOpen };
        case GET_OS_CONTRACTS_RESPONSE:
            return { ...state, OSContracts: action.response };
        case GET_OS_CONTRACT_RESPONSE: {
            const osCont = state.OSContracts.find((os) => os.id === action.response.id);
            return {
                ...state,
                osContracts: osCont
                    ? state.OSContracts.map((os) =>
                          os.id === action.response.id ? { ...os, ...action.response } : os,
                      )
                    : [...state.OSContracts, action.response],
            };
        }
        case GET_ALL_OS_RESPONSE:
            return { ...state, osList: action.response };
        case ACCEPT_TERMS_AND_CONDITIONS:
            return {
                ...state,
                acceptTermsAndConditionsStatus: {
                    error: false,
                    loading: true,
                    success: false,
                    errorMessage: '',
                },
            };
        case ACCEPT_TERMS_AND_CONDITIONS_RESPONSE:
            return {
                ...state,
                acceptTermsAndConditionsStatus: {
                    error: false,
                    loading: false,
                    success: true,
                    errorMessage: '',
                },
            };
        case ACCEPT_TERMS_AND_CONDITIONS_ERROR: {
            return {
                ...state,
                acceptTermsAndConditionsStatus: {
                    error: true,
                    loading: false,
                    success: false,
                    errorMessage: action.error,
                },
            };
        }
        case LOGOUT:
            return { ...initialState };
        case REHYDRATE: {
            let incoming = action.payload;
            if (incoming) {
                return {
                    ...initialState,
                    ...incoming.common,
                    user: localStorage['token'] ? incoming.profile.user : undefined,
                    acceptTermsAndConditionsStatus: initialState.acceptTermsAndConditionsStatus,
                };
            } else {
                return state;
            }
        }
        case SET_BREADCRUMBS:
            return {
                ...state,
                breadcrumbs: action.breadcrumbs,
            };
        default:
            return state;
    }
};

export default common;
