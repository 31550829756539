import React, { useState } from 'react';
import { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import Button from '../../../../common/components/Button';
import Card from '../../../../common/components/Card/Card';
import IconClose from '../../../../common/components/Icon/Close';
import Input from '../../../../common/components/Input';
import { close } from '../constants.js';
import CurrencyInputBox from '../CurrencyInputBox/CurrencyInputBox';
import './ModalAddressee.scss';

const ModalAddressee = ({ show, closeModal, directionSelection, postSendNex, handleClickSend }) => {
    const [infoAddress, setInfoAddress] = useState(directionSelection);

    useEffect(() => {
        setInfoAddress(directionSelection);
    }, [directionSelection]);

    const handleSend = () => {
        postSendNex(infoAddress);
        handleClickSend('false');
    };

    return (
        <>
            {show && (
                <div className='info-addressee-modal-background'>
                    <Card
                        className='info-addressee-modal'
                        classHead='modal-card-head'
                        classBody='card-body-modal'
                        detail={
                            <div className='icon-close-container' onClick={closeModal}>
                                <IconClose
                                    width={close.size}
                                    height={close.size}
                                    color={close.color}
                                />
                            </div>
                        }
                    >
                        <div className='container-info'>
                            <div className='body-dialog'>
                                <div className=''>{I18n.t('wallet.sendNex.modal.title')}</div>
                                {infoAddress.nombre && (
                                    <>
                                        <div className='padding-name'>
                                            <Input
                                                className='shadow wrapper-input'
                                                value={infoAddress.nombre}
                                                pillStyle='wallet style-text'
                                                handleChange={(v) =>
                                                    setInfoAddress({ ...infoAddress, nombre: v })
                                                }
                                            />
                                        </div>
                                    </>
                                )}
                                <div className='info-addressee-container style-text'>
                                    <span style={{ paddingRight: '5px' }}>
                                        {I18n.t('wallet.sendNex.modal.address')}
                                    </span>
                                    <span>{infoAddress.address}</span>
                                </div>
                                <div className='input-import' style={{ marginBottom: '30px' }}>
                                    <CurrencyInputBox
                                        titleValue={I18n.t('wallet.sendNex.import')}
                                        value={infoAddress.amount}
                                        typeCurrency={I18n.t('wallet.sendNex.typeCurrency')}
                                        className='input-bow-height'
                                        editable='false'
                                    />
                                </div>
                                <div className='btn-container'>
                                    <Button
                                        text={I18n.t('wallet.sendNex.modal.send')}
                                        className='button primary with-gradient big'
                                        onClick={() => handleSend()}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            )}
        </>
    );
};
export default ModalAddressee;
