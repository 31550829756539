import { connect } from 'react-redux';
import actions from '../../actions';
import ResetPasswordScreen from '../components/ResetPasswordScreen';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => ({
    resetPasswordStatus: state.session.resetPasswordStatus,
});

const mapDispatchToProps = (dispatch) => ({
    resetPassword: (uid, token, password, passwordRepeat) =>
        dispatch(actions.session.resetPassword(uid, token, password, passwordRepeat)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordScreen));
