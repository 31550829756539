import React, { useEffect, useState } from 'react';

import Checkbox from '../../../../common/components/Checkbox';
import Save from '../../../../common/components/Icon/Save';
import TempInput from '../../../../common/components/TempInput/TempInput';
import DetailedLabel from './DetailedLabel';
import '../PatientEvidenceScreen.css';
import { saveIcon } from '../constants';

const EvidenceRow = ({
    evidence,
    modifyEvidence,
    deleteEvidence,
    types,
    showErrors,
    // readOnly,
    privateKey,
    publicKey,
    userType,
    id,
    getFileToUploadUrl,
    auditGroupData,
    // evidenceTypes,
    patientCse,
    patientDetail,
    // deleteAction,
    disabled,
    evidences,
    ...props
}) => {
    const [modify, setModify] = useState(!evidence.tipo_evidencia);

    const [tempEvidence, setTempEvidence] = useState(evidence);
    const [error, setError] = useState(false);

    const [attachments, setAttachments] = useState({});

    const [duplicateCycle, setDuplicateCycle] = useState(false);

    useEffect(() => {
        setAttachments({ adjunto: tempEvidence.adjunto });
    }, [tempEvidence.adjunto]);

    const setAtt = (att) => setTempEvidence({ ...tempEvidence, ...att });

    const submit = () => {
        if (
            tempEvidence.aplicacion === undefined ||
            isNaN(tempEvidence.aplicacion) ||
            !tempEvidence.tipo_evidencia ||
            !tempEvidence.descripcion
        )
            return setError(true);

        if (
            evidences.some(
                (e) =>
                    e.aplicacion === tempEvidence.aplicacion &&
                    e.tipo_evidencia &&
                    tempEvidence.id !== e.id,
            )
        ) {
            setError(true);
            setDuplicateCycle(true);
            return;
        }

        modifyEvidence({ ...tempEvidence });
        setModify(false);
        setError(false);
        setDuplicateCycle(false);
        disabled(false);
    };

    return modify ? (
        <tr {...props}>
            <td>
                <TempInput
                    att='aplicacion'
                    type='uint'
                    tempObj={tempEvidence}
                    setAtt={setAtt}
                    submit={submit}
                    error={error || (isNaN(tempEvidence?.aplicacion) && duplicateCycle)}
                />
            </td>
            <td>
                <TempInput
                    att='tipo_evidencia'
                    type='select'
                    tempObj={tempEvidence}
                    setAtt={setAtt}
                    submit={submit}
                    options={types}
                    error={error && !tempEvidence?.tipo_evidencia}
                    className='csoc_mw'
                />
            </td>
            <td>
                <TempInput
                    att='descripcion'
                    type='text'
                    tempObj={tempEvidence}
                    setAtt={setAtt}
                    submit={submit}
                    error={error && !tempEvidence?.descripcion}
                />
            </td>
            <td>
                <Checkbox
                    className='small centered'
                    checked={tempEvidence.requerida}
                    disabled={true}
                />
            </td>
            <td>
                <TempInput
                    className={evidence.adjunto?.length ? 'green-attachment-btn' : ''}
                    privateKey={privateKey}
                    publicKey={publicKey}
                    patientDetail={patientDetail}
                    auditGroupData={auditGroupData}
                    userType={userType}
                    id={id}
                    getFileToUploadUrl={getFileToUploadUrl}
                    type='file'
                    tempObj={attachments}
                    setAtt={setAtt}
                    att='adjunto'
                    multiple
                    patientCse={patientCse}
                    showActions={true}
                />
            </td>
            <td>
                <div className='action-container'>
                    <div className='container-icon-save' onClick={submit}>
                        <Save width={saveIcon.size} height={saveIcon.size} color={saveIcon.color} />
                    </div>
                </div>
            </td>
        </tr>
    ) : (
        <tr {...props}>
            <td className={showErrors && tempEvidence?.numero === undefined ? 'error-td' : ''}>
                {evidence.notificar && <span className='red-dot-number'>{'\u2022'}</span>}
                {evidence.aplicacion}
            </td>
            <td className={showErrors && !tempEvidence?.tipo_evidencia ? 'error-td' : ''}>
                {types?.find((e) => e.value === evidence.tipo_evidencia)?.label ?? '-'}
            </td>
            <td className={showErrors && !tempEvidence?.descripcion ? 'error-td' : ''}>
                <DetailedLabel label={evidence.descripcion} detail={evidence.descripcion} />
            </td>
            <td>
                <Checkbox className='small centered' checked={evidence.requerida} disabled={true} />
            </td>
            <td>
                <TempInput
                    privateKey={privateKey}
                    publicKey={publicKey}
                    patientDetail={patientDetail}
                    auditGroupData={auditGroupData}
                    userType={userType}
                    id={id}
                    getFileToUploadUrl={getFileToUploadUrl}
                    type='file'
                    tempObj={evidence}
                    setAtt={(obj) => setAtt(obj)}
                    att='adjunto'
                    className='primaryText'
                    multiple
                    disabled={false}
                    patientCse={patientCse}
                    showActions={false}
                />
            </td>
            {userType !== 'AUDITOR' && (
                <td>
                    <div className='action-container'>
                        <i
                            className='icon-pencil'
                            onClick={() => {
                                setModify(true);
                                disabled(true);
                            }}
                        />
                        {!evidence.requerida && (
                            <i
                                className='icon-trash'
                                onClick={() => deleteEvidence(tempEvidence)}
                            />
                        )}
                        {evidence.notificar && <span className='red-dot-number'>{'\u2022'}</span>}
                    </div>
                </td>
            )}
        </tr>
    );
};
export default EvidenceRow;
