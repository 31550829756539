import React, { useState, useEffect } from 'react';
import './ProfileConfigurationCard.scss';
import { I18n } from 'react-redux-i18n';
import Input from '../../../common/components/Input';
import Button from '../../../common/components/Button';
import NoPhotoModal from '../NoPhotoModal';
import { withRouter, useHistory } from 'react-router-dom';
import { UserTypes } from '../../../constants';
import RecoverPrivateKeyModal from '../RecoverPrivateKeyModal';
import LineSeparatorWithGreenIndicator from '../../../assets/images/line-separator-with-green-indicator.png';
import { openTermsAndConditions } from '../../../utils';
import { userTypeHasNoPrivateKey, userTypeHasNoPublicKey } from '../../../utils/maps';

const ProfileConfigurationCard = (props) => {
    const [os, setOs] = useState('');
    const [user, setUser] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');

    const [showNoPhotoModal, setShowNoPhotoModal] = useState(false);
    const [showRecoverPrivateKeyModal, setShowRecoverPrivateKeyModal] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (props.user) {
            if (props.osName) setOs(props.osName);
            if (props.user.username) setUser(props.user.username);
            if (props.user.email) setEmail(props.user.email);
            if (props.user.first_name) setName(props.user.first_name);
            if (props.user.last_name) setLastName(props.user.last_name);
            if (props.user.phone) setPhone(props.user.phone);
        }
    }, [props.user]);

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        props.updateUser({ email, name, lastName, phone });

        // todo add validation
    };

    const hasPublicKey = () => !userTypeHasNoPublicKey(props.user?.type);
    const hasPrivateKey = () => !userTypeHasNoPrivateKey(props.user?.type);

    const isAuditor =
        props.user?.type === UserTypes.AUDITOR_WITHOUT_PUBLIC_KEY ||
        props.user?.type === UserTypes.AUDITOR_WITHOUT_PRIVATE_KEY ||
        props.user?.type === UserTypes.AUDITOR;

    return (
        <div>
            <div className='profile-configuration-card'>
                {hasPrivateKey() && hasPublicKey() && (
                    <div className='active-key-box'>
                        <i className='icon-lock' />
                        <span>{I18n.t('profile.configurationCard.keyActive')}</span>
                    </div>
                )}
                <span className='profile-configuration-title'>
                    {I18n.t('profile.configurationCard.title')}
                </span>
                <img alt='line separator' src={LineSeparatorWithGreenIndicator} />
                <form className='profile-configuration-user-form' onSubmit={handleSubmit}>
                    <div className='fields-container'>
                        <div className='left-column'>
                            <Input
                                className='white'
                                value={os}
                                label={
                                    isAuditor
                                        ? I18n.t('profile.configurationCard.inputLabels.auditGroup')
                                        : I18n.t('profile.configurationCard.inputLabels.os')
                                }
                                handleChange={setOs}
                                disabled
                            />
                            <Input
                                className='white'
                                value={name}
                                label={I18n.t('profile.configurationCard.inputLabels.name')}
                                handleChange={setName}
                                icon={<i className='icon-user' />}
                            />
                            <Input
                                className='white'
                                value={email}
                                label={I18n.t('profile.configurationCard.inputLabels.email')}
                                handleChange={setEmail}
                                icon={<i className='icon-envelope' />}
                            />
                        </div>
                        <div className='right-column'>
                            <Input
                                className='white'
                                value={user}
                                label={I18n.t('profile.configurationCard.inputLabels.user')}
                                handleChange={setUser}
                                icon={<i className='icon-user' />}
                                disabled
                            />
                            <Input
                                className='white'
                                value={lastName}
                                label={I18n.t('profile.configurationCard.inputLabels.lastName')}
                                handleChange={setLastName}
                                icon={<i className='icon-user' />}
                            />
                            <Input
                                className='white'
                                value={phone}
                                label={I18n.t('profile.configurationCard.inputLabels.phone')}
                                handleChange={setPhone}
                                icon={<i className='icon-phone' />}
                            />
                        </div>
                    </div>
                    <Button
                        className='primary with-gradient'
                        text={I18n.t('common.save')}
                        onClick={() => {}}
                        loading={props.updateUserStatus.loading}
                        type='submit'
                    />
                    {(!hasPublicKey() || !hasPrivateKey()) && <hr />}
                </form>
                <div className='private-key-buttons-container'>
                    {!hasPublicKey() && (
                        <div
                            className='private-key-button fill'
                            onClick={() => setShowNoPhotoModal(true)}
                        >
                            <i className='icon-lock' />
                            <span className='text'>
                                {I18n.t('profile.configurationCard.createPrivateKey')}
                            </span>
                        </div>
                    )}
                    {!hasPrivateKey() && (
                        <div
                            className='private-key-button'
                            onClick={() => history.push('importPrivateKey')}
                        >
                            <i className='icon-lock' />
                            <span className='text'>
                                {I18n.t('profile.configurationCard.recoverPrivateKey')}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            {hasPrivateKey() && hasPublicKey() && (
                <div className='show-key-container'>
                    <i className='icon-lock' />
                    <span onClick={() => setShowRecoverPrivateKeyModal(true)}>
                        {I18n.t('profile.configurationCard.showKey')}
                    </span>
                </div>
            )}
            <div className='show-key-container'>
                <label>
                    Ver{' '}
                    <a onClick={openTermsAndConditions}>
                        {I18n.t('termsAndConditionsModal.termsAndConditions')}.
                    </a>
                </label>
            </div>
            {/* Modals */}
            <NoPhotoModal
                show={showNoPhotoModal}
                onClick={() => {
                    setShowNoPhotoModal(false);
                    history.push('createPrivateKey');
                }}
            />
            <RecoverPrivateKeyModal
                show={showRecoverPrivateKeyModal}
                validatePasswordStatus={props.validatePasswordStatus}
                validatePassword={props.validatePassword}
                close={() => setShowRecoverPrivateKeyModal(false)}
                username={props.user?.username}
            />
        </div>
    );
};

export default withRouter(ProfileConfigurationCard);
