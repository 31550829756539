import CustomSelect from '../../../common/components/CustomSelect';
import * as React from 'react';
import { useEffect, useState } from 'react';
import './OperatorSelect.scss';
import { I18n } from 'react-redux-i18n';

const OperatorSelect = (props) => {
    const [branches, setBranches] = useState([]);
    const [socialReason, setSocialReason] = useState();

    useEffect(() => {
        setSocialReason(props.operator);
    }, [props.operator]);

    useEffect(() => {
        if (socialReason)
            setBranches(props.operators.find((op) => op.razon_social === socialReason)?.sucursales);
    }, [socialReason]);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
            }}
        >
            <div className='cb-mt' style={{ width: '100%' }}>
                <div key='input-label'>
                    {!props.hideLabels && (
                        <label className='input-label'>
                            {I18n.t('study.details.coordination.inputLabels.socialReason')}
                        </label>
                    )}
                </div>
                <CustomSelect
                    options={props.operators.map((s) => ({
                        value: s.razon_social,
                        label: s.razon_social,
                    }))}
                    value={socialReason}
                    className='rounded i-screen margin-right'
                    onSelect={(v) => {
                        setSocialReason(v);
                        props.setOperator(null);
                    }}
                    error={props.error}
                />
            </div>

            <div className='cb-mt' style={{ width: '100%' }}>
                <div key='input-label'>
                    {!props.hideLabels && (
                        <label className='input-label'>
                            {I18n.t('study.details.coordination.inputLabels.branch')}
                        </label>
                    )}
                </div>
                <CustomSelect
                    options={branches.map((b) => ({ value: b.id, label: b.direccion }))}
                    value={props.value}
                    onSelect={(v) => props.setOperator(v)}
                    className='rounded i-screen'
                    error={props.error}
                />
            </div>
        </div>
    );
};
export default OperatorSelect;
