import React, { useEffect, useState } from 'react';
import './CreatePrivateKeyWizard.scss';
import nexusLogo from '../../../assets/logos/nexus-color-small.png';
import Button from '../../../common/components/Button';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import WomanWriting from '../../../assets/images/create-key-first-card-image.png';
import LineSeparatorWithGreenIndicator from '../../../assets/images/line-separator-with-green-indicator.png';
import { KeyManager } from 'jasper-roche-crypto';

// Steps
const STEP_1 = 'STEP_1';
const STEP_2_A = 'STEP_2_A';
const STEP_2_B = 'STEP_2_B';
const STEP_2_C = 'STEP_2_C';
const STEP_3 = 'STEP_3';

const CreatePrivateKeyWizard = (props) => {
    const [currentStep, setCurrentStep] = useState(STEP_1);
    const [mnemonicWords, setMnemonicWords] = useState([]);
    const [wordsOrderByUser, setWordsOrderByUser] = useState([]);
    const [wordsDisorder, setWordsDisorder] = useState([]);
    const [orderedSuccessful, setOrderedSuccessful] = useState(true);
    const [keyManager, setKeyManager] = useState();
    const keys = {
        private: keyManager?.deriveDataKey(),
        public: keyManager?.getPublicKey(keyManager?.deriveDataKey()),
    };

    const history = useHistory();

    useEffect(() => {
        localStorage.removeItem(`keystore_${props.username}`);
        setKeyManager(new KeyManager(props.username));
    }, []);

    useEffect(() => {
        // checks if duplicate using Set, that avoids repeated items, so different sizes means
        // there was at least 1 duplicated
        const _hasNoRepeatedWordsInMnemonic = (words) => new Set(words).size === words.length;
        const MAX_TRIES = 100; // avoid infinite loop
        let tries = 0;
        let noRepeated = false;

        if (keyManager && mnemonicWords.length === 0) {
            const words = keyManager?.mnemonic.split(' ');
            while (!noRepeated && tries++ <= MAX_TRIES) {
                if (_hasNoRepeatedWordsInMnemonic(words)) noRepeated = true;
            }
            setMnemonicWords(words);
            setWordsDisorder(_.shuffle(words).map((word) => ({ value: word, show: true })));
        }
    }, [keyManager]);

    useEffect(() => {
        if (props.saveKeysStatus?.success) {
            history.push('/profile');
        } else setOrderedSuccessful(false);
    }, [props.saveKeysStatus]);

    const nextStep = () => {
        if (currentStep === STEP_1) setCurrentStep(STEP_2_A);
        else if (currentStep === STEP_2_A) setCurrentStep(STEP_2_B);
        else if (currentStep === STEP_2_B) setCurrentStep(STEP_2_C);
        else if (currentStep === STEP_2_C) setCurrentStep(STEP_3);
    };

    const previousStep = () => {
        if (currentStep === STEP_2_C) {
            resetWordsOrderByUser();
            setCurrentStep(STEP_2_B);
        }
    };

    const getCurrentStep = () => {
        if (currentStep === STEP_1) return 1;
        if (currentStep === STEP_2_A || currentStep === STEP_2_B || currentStep === STEP_2_C)
            return 2;
        if (currentStep === STEP_3) return 3;
    };

    const stepFinish = (step) => {
        if (step === 1 && currentStep !== STEP_1) return true;
        if (step === 2 && currentStep === STEP_2_C) return true;
        return false;
    };

    const selectWordFromDisorderContainer = (value) => {
        // hide the word from the disorder container
        const newWordsDisorder = wordsDisorder.map((word) => {
            if (word.value === value) return { value: word.value, show: false };
            else return word;
        });
        setWordsDisorder(newWordsDisorder);
        setWordsOrderByUser([...wordsOrderByUser, value]);
    };

    const selectWordFromOrderByUserContainer = (value) => {
        // show the word in the disorder container
        const newWordsDisorder = wordsDisorder.map((word) => {
            if (word.value === value) return { value: word.value, show: true };
            else return word;
        });
        setWordsDisorder(newWordsDisorder);
        // Remove the word form the order by user container
        const newWordsOrderByUser = [...wordsOrderByUser].filter((word) => word !== value);
        setWordsOrderByUser(newWordsOrderByUser);
    };

    const resetWordsOrderByUser = () => {
        const newWordsDisorder = wordsDisorder.map((word) => ({ ...word, show: true }));
        setWordsDisorder(newWordsDisorder);
        setWordsOrderByUser([]);
    };

    const confirmOrder = () => {
        const orderIsCorrect = _.isEqual(mnemonicWords, wordsOrderByUser);
        /*
         * We dont save the keys here because when the keys are saved the user type changes and the new user type
         * has NO access to this screen (to avoid keys creation when there is already one created).
         * */
        if (orderIsCorrect) {
            setOrderedSuccessful(true);
            nextStep();
        } else {
            setOrderedSuccessful(false);
            nextStep();
        }
    };

    // Due to the user type (without private key) the default redirect will be to the profile page.
    const returnToProfile = () => {
        history.push('/');
    };

    return (
        <div className='create-private-key-wizard'>
            <div className='steps-header'>
                <div className={`step ${getCurrentStep() === 1 && 'selected'}`}>
                    {stepFinish(1) ? (
                        <i className='icon-check' />
                    ) : (
                        <span className='step-number'>1</span>
                    )}
                    <span className='step-name'>
                        {I18n.t('profile.createPrivateKeyWizard.stepsName.init')}
                    </span>
                </div>
                <hr className='step-separator' />
                <div className={`step ${getCurrentStep() === 2 && 'selected'}`}>
                    {stepFinish(2) ? (
                        <i className='icon-check' />
                    ) : (
                        <span className='step-number'>2</span>
                    )}
                    <span className='step-name'>
                        {I18n.t('profile.createPrivateKeyWizard.stepsName.key')}
                    </span>
                </div>
                <hr className='step-separator' />
                <div className={`step ${getCurrentStep() === 3 && 'selected'}`}>
                    {stepFinish(3) ? (
                        <i className='icon-check' />
                    ) : (
                        <span className='step-number'>3</span>
                    )}
                    <span className='step-name'>
                        {I18n.t('profile.createPrivateKeyWizard.stepsName.end')}
                    </span>
                </div>
            </div>
            <div className='step-card'>
                {currentStep === STEP_1 && (
                    <div className='first-step'>
                        <div className='left-column'>
                            <img src={nexusLogo} alt='nexus logo' />
                            <p>
                                {I18n.t('profile.createPrivateKeyWizard.firstStep.clarification')}
                            </p>
                            <Button
                                className='primary with-gradient'
                                text={I18n.t('profile.createPrivateKeyWizard.firstStep.continue')}
                                onClick={nextStep}
                            />
                        </div>
                        <div className='right-column'>
                            <img alt='women writing' src={WomanWriting} />
                        </div>
                    </div>
                )}
                {currentStep === STEP_2_A && (
                    <div className='second-step-a'>
                        <span className='title'>
                            {I18n.t('profile.createPrivateKeyWizard.secondStepA.title')}
                        </span>
                        <span className='subtitle'>
                            {I18n.t('profile.createPrivateKeyWizard.secondStepA.subtitleFirstPart')}
                            <span className='green-highlight'>
                                {I18n.t(
                                    'profile.createPrivateKeyWizard.secondStepA.willNotBeStorageByNexus',
                                )}
                            </span>
                            {I18n.t(
                                'profile.createPrivateKeyWizard.secondStepA.subtitleSecondPart',
                            )}
                        </span>
                        <img
                            className='line-separator'
                            alt='line separator'
                            src={LineSeparatorWithGreenIndicator}
                        />
                        <div className='words-container'>
                            {mnemonicWords.map((word, index) => (
                                <span key={index} className='word'>
                                    {word}
                                </span>
                            ))}
                        </div>
                        <hr />
                        <div className='buttons-row'>
                            <Button
                                className='primary with-gradient'
                                onClick={returnToProfile}
                                text={I18n.t('profile.createPrivateKeyWizard.secondStepA.goBack')}
                            />
                            <Button
                                className='primary with-gradient'
                                onClick={nextStep}
                                text={I18n.t('profile.createPrivateKeyWizard.secondStepA.button')}
                            />
                        </div>
                    </div>
                )}
                {currentStep === STEP_2_B && (
                    <div className='second-step-b'>
                        <div className='step-header'>
                            <div className='first-column'>
                                <span className='title'>
                                    {I18n.t('profile.createPrivateKeyWizard.secondStepB.title')}
                                </span>
                                <span className='subtitle'>
                                    {I18n.t('profile.createPrivateKeyWizard.secondStepB.subtitle')}
                                </span>
                            </div>
                            <div className='second-column'>
                                <i className='icon-close' onClick={resetWordsOrderByUser} />
                            </div>
                        </div>
                        <img
                            className='line-separator'
                            alt='line separator'
                            src={LineSeparatorWithGreenIndicator}
                        />
                        <div className='words-to-order-container'>
                            {wordsOrderByUser.map((word, index) => (
                                <span
                                    key={index}
                                    className='word show'
                                    onClick={() => selectWordFromOrderByUserContainer(word)}
                                >
                                    {word}
                                </span>
                            ))}
                        </div>
                        <hr />
                        <div className='buttons-row'>
                            <Button
                                className='primary with-gradient'
                                onClick={returnToProfile}
                                text={I18n.t('profile.createPrivateKeyWizard.secondStepA.goBack')}
                            />
                            <Button
                                className='primary with-gradient'
                                onClick={confirmOrder}
                                text={I18n.t('profile.createPrivateKeyWizard.secondStepB.button')}
                            />
                        </div>
                        <div className='words-to-order-container last'>
                            {wordsDisorder.map((word, index) => (
                                <span
                                    key={index}
                                    className={`word ${word.show && 'show'}`}
                                    onClick={() => selectWordFromDisorderContainer(word.value)}
                                >
                                    {word.value}
                                </span>
                            ))}
                        </div>
                    </div>
                )}
                {currentStep === STEP_2_C && orderedSuccessful && (
                    <div className='second-step-c'>
                        <i className='icon-check green' />
                        <span className='result-text'>
                            {I18n.t('profile.createPrivateKeyWizard.secondStepC.successful.title')}
                        </span>
                        <hr />
                        <Button
                            className='primary with-gradient'
                            text={I18n.t(
                                'profile.createPrivateKeyWizard.secondStepC.successful.button',
                            )}
                            onClick={() => {
                                // Address has to be created from funds key
                                props.saveKeys(
                                    keys,
                                    keyManager.getAddress(
                                        keyManager.getPublicKey(keyManager.deriveFundsKey()),
                                    ),
                                    keyManager.mnemonic,
                                );
                            }}
                        />
                    </div>
                )}
                {currentStep === STEP_2_C && !orderedSuccessful && (
                    <div className='second-step-c'>
                        <i className='icon-exclamation red' />
                        <span className='result-text'>
                            {I18n.t('profile.createPrivateKeyWizard.secondStepC.error.title')}
                        </span>
                        <hr />
                        <Button
                            className='primary with-gradient'
                            onClick={previousStep}
                            text={I18n.t('profile.createPrivateKeyWizard.secondStepC.error.button')}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreatePrivateKeyWizard;
