import { connect } from 'react-redux';
import AddContactScreen from '../../components/AddUser/AddContactScreen';
import actions from '../../../actions';
const mapStateToProps = (state) => ({
    contact: state.wallet.selectedContact,
    contactId: state.wallet.selectedContactId,
    addContactStatus: state.wallet.addContactStatus,
});

const mapDispatchToProps = (dispatch) => ({
    addContact: (contact) => dispatch(actions.wallet.addContact(contact)),
    editContact: (contact) => dispatch(actions.wallet.editContact(contact)),
    getContact: (contactId) => dispatch(actions.wallet.getContact(contactId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddContactScreen);
