import { get, post, put, webApi } from '../utils/http';

export default {
    getFmiPatients: async () => get(webApi + `api/paciente`),
    getPatient: async (id) => get(webApi + `api/paciente/${id}`),
    updatePatient: async (patient) => put(webApi + `api/paciente/${patient.id}/`, patient),
    addPatient: async (patient) => post(webApi + 'api/paciente/', patient),
    getContacts: async (id) => get(webApi + `api/pacienteacceso/${id}/`),
    getFmiOptions: async () => get(webApi + `api/equipo`),
    getPatientOptions: async () => get(webApi + `api/equipo`),
    getAuditorOptions: async () => get(webApi + `api/grupoauditor/`),
    getDrugstoreOptions: async () => get(webApi + `api/drogueria/`),

    getStudies: () => get(webApi + `api/estudio/`),
    getDiagnoses: () => get(webApi + `api/diagnostico/`),
    getDoctors: () => get(webApi + `api/medico/`),
    getInstitutions: () => get(webApi + `api/institucion/`),
    getSocialCoverages: () => get(webApi + `api/os`),
    getPrograms: () => get(webApi + `api/programa/`),
    getFunders: () => get(webApi + `api/financiador`),
    getOperators: () => get(webApi + `api/operador`),
    getFileToUploadUrl: (id, name) =>
        get(webApi + `api/pacienteestudio/${id}/adjunto/${name}/uploadurl`),
    getFileToS3: (url) => get(url, true),
    /* Add:
        POST {{url}}/api/paciente/acceso/add/364/
        body: {
            "paciente": 364,
            "csee": "gfdgsdfvscvsdcdafd",
            "drogueria": 6,
            "owner": false
        }
    */
    addPatientAccess: (patientId, encryptedAccessData) =>
        post(`${webApi}api/paciente/acceso/add/${patientId}/`, encryptedAccessData),
    /* Delete:
        POST {{url}}/api/paciente/acceso/delete/354/
        {
            "paciente": 364,
            "drogueria": 6
        } 
    */
    deletePatientAccess: (accessId, body) =>
        post(`${webApi}api/paciente/acceso/delete/${accessId}/`, body),
};
