import { connect } from 'react-redux';
import IndicationsList from '../components/IndicationsList';
import actions from '../../actions';

const mapStateToProps = (state) => ({
    indications: state.indication.filteredResults,
    total: state.indication.filteredResults?.length,
    currentPage: state.indication.current,

    loading: state.indication.getIndicationsStatus.loading,
});

const mapDispatchToProps = (dispatch) => ({
    getIndications: () => dispatch(actions.indication.getIndications()),
    setIndicationSelected: (indication) =>
        dispatch(actions.indication.setIndicationSelected(indication)),

    updatePage: (pageNumber) => dispatch(actions.indication.updatePage(pageNumber)),
    updatePageSize: (pageSize) => dispatch(actions.indication.updatePageSize(pageSize)),

    searchIndications: (search) => dispatch(actions.indication.searchIndications(search)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndicationsList);
