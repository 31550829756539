import * as React from 'react';
import PropTypes from 'prop-types';

const IconAdd = (props) => (
    <svg
        width={props.width}
        height={props.height}
        viewBox='0 0 17 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={props.className}
    >
        <path
            d='M11.333 9.031H5.667a.535.535 0 0 1-.531-.531c0-.29.24-.531.53-.531h5.667c.29 0 .532.24.532.531 0 .29-.241.531-.532.531z'
            fill={props.color}
        />
        <path
            d='M8.5 11.864a.535.535 0 0 1-.531-.53V5.666c0-.29.24-.532.531-.532.29 0 .531.241.531.532v5.666c0 .29-.24.531-.531.531z'
            fill={props.color}
        />
        <path
            d='M10.625 16.114h-4.25c-3.846 0-5.49-1.643-5.49-5.49v-4.25C.885 2.53 2.53.886 6.375.886h4.25c3.846 0 5.49 1.644 5.49 5.49v4.25c0 3.846-1.644 5.49-5.49 5.49zM6.375 1.948c-3.265 0-4.427 1.161-4.427 4.427v4.25c0 3.265 1.162 4.427 4.427 4.427h4.25c3.265 0 4.427-1.162 4.427-4.427v-4.25c0-3.266-1.162-4.427-4.427-4.427h-4.25z'
            fill={props.color}
        />
    </svg>
);

IconAdd.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
};
export default IconAdd;
