import * as React from 'react';
import PropTypes from 'prop-types';

const Save = ({ width, height, color }) => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width={width} height={height}>
        <g>
            <path fill='none' d='M0 0h24v24H0z' />
            <path
                fill={color}
                d='M4 3h14l2.707 2.707a1 1 0 0 1 .293.707V20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm3 1v5h9V4H7zm-1 8v7h12v-7H6zm7-7h2v3h-2V5z'
            />
        </g>
    </svg>
);

Save.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
};
export default Save;
