import React, { useState } from 'react';
import './RegistrarProfileScreen.scss';
import RegistrarInstitutionCard from '../RegistrarInstitutionCard';
import RegistrarCard from '../RegistrarCard';
import { I18n } from 'react-redux-i18n';
import { UserTypes } from '../../../constants';
import NoPhotoModal from '../NoPhotoModal';
import { useHistory } from 'react-router-dom';
import RecoverPrivateKeyModal from '../RecoverPrivateKeyModal';
import ChangePasswordModal from '../ChangePasswordModal';
import { openTermsAndConditions } from '../../../utils';

const RegistrarProfileScreen = (props) => {
    const [showNoPhotoModal, setShowNoPhotoModal] = useState(false);
    const [showRecoverPrivateKeyModal, setShowRecoverPrivateKeyModal] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

    const history = useHistory();

    const hasPublicKey = () => !(props.user?.type === UserTypes.REGISTER_USER_WITHOUT_PUBLIC_KEY);
    const hasPrivateKey = () => !(props.user?.type === UserTypes.REGISTER_USER_WITHOUT_PRIVATE_KEY);

    return (
        <div className='registrar-profile-screen'>
            <div className='registrar-profile-main-container'>
                <RegistrarInstitutionCard institution={props.institution} branch={props.branch} />
                <RegistrarCard
                    user={props.user}
                    updateUser={props.updateUser}
                    updateUserStatus={props.updateUserStatus}
                    privateKeyActive={hasPublicKey() && hasPrivateKey()}
                    showChangePasswordModal={() => setShowChangePasswordModal(true)}
                />
                {!hasPublicKey() && (
                    <div
                        className='private-key-button fill'
                        onClick={() => setShowNoPhotoModal(true)}
                    >
                        <i className='icon-lock' />
                        <span className='text'>
                            {I18n.t('profile.configurationCard.createPrivateKey')}
                        </span>
                    </div>
                )}
                {hasPublicKey() && !hasPrivateKey() && (
                    <div
                        className='private-key-button fill'
                        onClick={() => history.push('importPrivateKey')}
                    >
                        <i className='icon-lock' />
                        <span className='text'>
                            {I18n.t('profile.configurationCard.recoverPrivateKey')}
                        </span>
                    </div>
                )}
                {hasPrivateKey() && hasPublicKey() && (
                    <div className='show-key-container'>
                        <i className='icon-lock' />
                        <span onClick={() => setShowRecoverPrivateKeyModal(true)}>
                            {I18n.t('profile.configurationCard.showKey')}
                        </span>
                    </div>
                )}
                <div className='show-key-container'>
                    <label>
                        Ver{' '}
                        <a onClick={openTermsAndConditions}>
                            {I18n.t('termsAndConditionsModal.termsAndConditions')}.
                        </a>
                    </label>
                </div>
            </div>
            {/* Modals */}
            <NoPhotoModal
                show={showNoPhotoModal}
                onClick={() => {
                    setShowNoPhotoModal(false);
                    history.push('createPrivateKey');
                }}
            />
            <RecoverPrivateKeyModal
                show={showRecoverPrivateKeyModal}
                validatePasswordStatus={props.validatePasswordStatus}
                validatePassword={props.validatePassword}
                close={() => setShowRecoverPrivateKeyModal(false)}
                username={props.user?.username}
            />
            <ChangePasswordModal
                show={showChangePasswordModal}
                onSubmit={props.changePassword}
                close={() => setShowChangePasswordModal(false)}
                changePasswordStatus={props.changePasswordStatus}
            />
        </div>
    );
};

export default RegistrarProfileScreen;
