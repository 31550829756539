import moment from 'moment';

export const parseDate = (date) =>
    `${date.toLocaleDateString('es-AR')} ${date.toLocaleTimeString('es-AR')}`;

/*
 * This method parse the ven date format to a Date object.
 * The ven date format on qr traces is "YYMMDD" (but client ask to invert as "DDMMYY")
 * */
export const parseVnDate = (date, isFromScanner) => {
    if (date.length !== 6) return null;
    else {
        const day = !isFromScanner ? date.substring(0, 2) : date.substring(4, 6);
        const month = date.substring(2, 4);
        const year = !isFromScanner ? date.substring(4, 6) : date.substring(0, 2);

        // this double check is done because Date lets you create a date like "30-02-22" (it transform it to march 1st)
        if (!moment(`20${year}-${month}-${day}`).isValid()) {
            return null;
        }
        return new Date(`20${year}-${month}-${day}`);
    }
};

/*
 * This method parse an string date on the DDMMYY format to an string date in format YYMMDD
 * */
export const parseVnDateToYYMMDD = (date_in_DDMMYY) => {
    if (date_in_DDMMYY.length !== 6) return '';
    const day = date_in_DDMMYY.substring(0, 2);
    const month = date_in_DDMMYY.substring(2, 4);
    const year = date_in_DDMMYY.substring(4, 6);
    return `${year}${month}${day}`;
};

/*
 * This method parse an string date on the YYMMDD format to an string date in format DDMMYY
 * */
export const parseVnDateToDDMMYY = (date_in_YYMMDD) => {
    if (date_in_YYMMDD.length !== 6) return '';
    const year = date_in_YYMMDD.substring(0, 2);
    const month = date_in_YYMMDD.substring(2, 4);
    const day = date_in_YYMMDD.substring(4, 6);
    return `${day}${month}${year}`;
};

/*
 * This method is used to parse the trace given by server (without special chars) to an object format.
 *
 * Trace format:
 * - gtin (starts with '01') --> 14 chars (if counting the prefix 16 chars)
 * - sn (starts with '21') --> up to 20 chars
 * - ven (starts with '17') --> 6 chars (YYMMDD) (if counting the prefix 8 chars)
 * - lot (starts with '10') --> up to 20 chars
 * */
export const parseTrace = (trace) => {
    let traceLeft;
    const gtin = trace.substring(0, 16); // because gtin is 16 chars length
    traceLeft = trace.substring(16, trace.length);
    let sn, ven, lot;
    if (traceLeft.length > 17) {
        // because for keyboard input sn is 16 chars long (14 + 2 of prefix), plus * char
        sn = traceLeft.substring(0, traceLeft.indexOf('17'));
        traceLeft = traceLeft.substring(traceLeft.indexOf('17'), traceLeft.length);
        ven = traceLeft.substring(0, 8); // because date is 8 chars length
        traceLeft = traceLeft.substring(8, traceLeft.length);
        lot = traceLeft;
    } else {
        sn = traceLeft.substring(0, traceLeft.length - 1); // to remove the * char
        ven = '';
        lot = '';
    }

    return {
        gtin,
        sn,
        ven,
        lot,
    };
};
