import React, { useState, useEffect, useLayoutEffect } from 'react';
import './RocheTransactionsScreen.scss';
import SearchBar from '../../../common/components/SearchBar';
import { I18n } from 'react-redux-i18n';
import Loader from '../../../common/components/Loader';
import ReactTable from 'react-table';
import TablePagination from '../../../common/components/TablePagination';
import { parseDate, parseTransactionStatus } from '../../../utils';
import ReactTooltip from 'react-tooltip';
import SelectMultiple from '../../../common/components/SelectMultiple';
import MultipleDownloadButton from '../MultipleDownloadButton';
import { RocheTransactionDownloadType, UserTypes } from '../../../constants';
import DateRangePicker from '../../../common/components/DateRangePicker';
import Checkbox from '../../../common/components/Checkbox';
import Button from '../../../common/components/Button';
import TransactionsSuccessModal from '../TransactionsSuccessModal';
import { ConfirmationModal } from '../../../common/components/Modal';
import TransactionsErrorModal from '../TransactionsErrorModal';
import * as bip39 from 'bip39';
import Swal from 'sweetalert2';
import LoadingOverlay from 'react-loading-overlay';
import { useDebounceInput } from '../../../utils/hooks/useDebounceInput';
bip39.setDefaultWordlist('spanish');

const defaultStatusSelected = [
    { id: 'all', checked: false },
    { id: 'validated', checked: true },
    { id: 'pending', checked: false },
    { id: 'rejected', checked: false },
    { id: 'paid', checked: true },
    { id: 'posted', checked: false },
    { id: 'validationError', checked: false },
    { id: 'errorSAP', checked: true },
    { id: 'processingPayment', checked: true },
];

const paymentResultsInitialState = {
    allSuccessful: false,
    successfulTotal: 0,
    errorTotal: 0,
    errors: [],
};

const RocheTransactionsScreen = (props) => {
    const [transactionsSelected, setTransactionsSelected] = useState([]);
    const [transactionsSelectedWithoutAllElement, setTransactionsSelectedWithoutAllElement] =
        useState([]);

    const [reactTable, setReactTable] = useState();

    const [paymentResults, setPaymentResults] = useState(paymentResultsInitialState);

    const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);

    const [nexBalance, setNexBalance] = useState(0);

    const [showingAllErrors, setShowingAllErrors] = useState(false);

    const [loadingPayment, setLoadingPayment] = useState(false);

    // Pagination states
    const [page, setPage] = useState(0);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();

    const [textToSearch, setTextToSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState(defaultStatusSelected);

    const [textToSearchDeb, setTextToSearchDeb] = useDebounceInput({
        initialValue: '',
        setPage: setPage,
        callback: setTextToSearch,
    });

    const [disable, setDisable] = useState(false);

    const [, setShowExceeded] = useState(false);
    const [, setTransactionsAmount] = useState([]);

    const [contractSortDirection, setContractSortDirection] = useState('asc');
    const [propertySort, setPropertySort] = useState('');

    useEffect(() => {
        if (props.userType !== UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY && props.userIsPayer)
            loadWallet();
    }, [props.payStatus]);

    useEffect(() => setNexBalance(props.walletDetails.balance), [props.walletDetails]);

    const loadWallet = () => {
        props.getWalletDetails();
    };

    useEffect(() => {
        if (props?.sendNexStatus?.responses.length > 0) handlePay();
    }, [props.sendNexStatus.responses]);

    const hasBalance = () => nexBalance > 0;

    const showErrorTransactions = (errorTxsIds) => {
        setShowingAllErrors(false);
        Swal.fire({
            icon: 'error',
            title: I18n.t('rocheTransaction.walletTransactionErrorsAlert.title'),
            text:
                I18n.t('rocheTransaction.walletTransactionErrorsAlert.body') +
                ': ' +
                errorTxsIds.join(', '),
        }).then((result) => {
            if (result.isConfirmed) resetUI();
        });
    };

    const showAmountExceeded = () => {
        setShowExceeded(false);
        Swal.fire({
            icon: 'error',
            title: I18n.t('rocheTransaction.walletTransactionAmountExceededAlert.title'),
            text: I18n.t('rocheTransaction.walletTransactionAmountExceededAlert.body'),
        });
    };

    /* filters */
    useEffect(() => {
        const allFiltersAreSelected = () => {
            return (
                statusFilter.filter((status) => status.id !== 'all' && status.checked).length ===
                statusFilter.length - 1
            );
        };
        const allFiltersAreDeselected = () => {
            return (
                statusFilter.filter((status) => status.id !== 'all' && !status.checked).length ===
                statusFilter.length - 1
            );
        };
        const allAreSelected = allFiltersAreSelected();
        const allAreDeselected = allFiltersAreDeselected();
        const isTheAllOptionChecked = statusFilter.filter((status) => status.id === 'all')[0]
            .checked;

        // this is done to auto check the 'all' checkbox if all the other options are selected (and vise versa)
        if (
            (allAreSelected && !isTheAllOptionChecked) ||
            (allAreDeselected && isTheAllOptionChecked)
        ) {
            setStatusFilter(
                statusFilter.map((status) => {
                    if (status.id === 'all') return { ...status, checked: !isTheAllOptionChecked };
                    return status;
                }),
            );
        }
        // Note: commented since its filtering store data, and pagination is server-side.
        // const statusSelectedArray = statusFilter
        //     .filter((status) => status.id !== 'all' && status.checked)
        //     .map((status) => status.id);
        // props.updateFilterValues({ status: statusSelectedArray });

        props.getTransactions(
            { statusFilter },
            page + 1,
            textToSearch,
            startDate,
            endDate,
            propertySort
                ? contractSortDirection === 'asc'
                    ? `order_by=${propertySort}`
                    : `order_by=-${propertySort}`
                : '',
        );
    }, [statusFilter, page, textToSearch, startDate, endDate, contractSortDirection]);

    useEffect(() => {
        if (props.validatePaymentsStatus?.errorMessage) setLoadingPayment(false);
        else if (props.validatePaymentsStatus?.success && !props.validatePaymentsStatus?.loading)
            continuePaymentAfterSuccessfulValidation();
    }, [props.validatePaymentsStatus]);

    useEffect(() => {
        if (
            (props.payStatus.success ||
                props.payStatus.error ||
                props.trackPaymentsErrorsStatus.success ||
                props.trackPaymentsErrorsStatus.error) &&
            !props.payStatus.loading &&
            !props.trackPaymentsErrorsStatus.loading
        ) {
            const mappedResults = mapResults(props.payResults);
            setPaymentResults(mappedResults);
        }
    }, [props.payStatus, props.trackPaymentsErrorsStatus]);

    useLayoutEffect(() => {
        if (loadingPayment) {
            document.body.style.overflow = 'hidden';
            document.body.style.height = '100%';
        }
        if (!loadingPayment) {
            document.body.style.overflow = 'auto';
            document.body.style.height = 'auto';
        }
    }, [loadingPayment]);

    useEffect(() => {
        const sumOfAmounts = props.transactions
            .filter((entry) => transactionsSelected.includes(entry.id.toString()))
            .map((entry) => entry.tokenAmount);
        setTransactionsAmount(sumOfAmounts);

        let amount = sumOfAmounts.reduce(
            (total, currentValue) => total + parseInt(currentValue),
            0,
        );
        if (amount > nexBalance) {
            setDisable(true);
            return showAmountExceeded();
        } else {
            setDisable(false);
        }
    }, [transactionsSelected]);

    /*
     * Maps the transaction payment results (quantity of success, of errors, and the errors strings).
     * */
    const mapResults = (transactions) => {
        setLoadingPayment(false);
        setShowingAllErrors(true);

        let transactionsErrored =
            props.trackPaymentsErrorsStatus.errorMessage?.length > 0
                ? props.trackPaymentsErrorsStatus.errorMessage
                : []; //Transactions that threw error

        if (transactionsErrored.length === 0 && transactions.length > 0)
            return {
                ...paymentResultsInitialState,
                allSuccessful: true,
                successfulTotal: transactions.length,
            }; // If select a transaction and it was successful
        if (transactionsErrored.length > 0 && transactionsSelected.length === 1)
            return showErrorTransactions(transactionsErrored.map((error) => error.id)); // If select a transaction and it throw an error

        const mapTransactionErrors = (transactionId, errors) =>
            errors.map((error) => `ID ${transactionId} - ${error?.description}`);

        return {
            allSuccessful: false,
            successfulTotal: transactions.length,
            errorTotal: transactionsErrored.length,
            errors: transactionsErrored.map((t) => mapTransactionErrors(t.id, t.errors)).flat(),
        };
    };

    const handleStatusFilterChange = (statusId) => {
        if (statusId === 'all') {
            const allIsSelected = statusFilter.filter((status) => status.id === 'all')[0].checked;
            setStatusFilter(
                statusFilter.map((status) => ({ id: status.id, checked: !allIsSelected })),
            );
        } else {
            const otherStatus = statusFilter.filter((status) => status.id !== statusId);
            const selectedStatus = statusFilter.filter((status) => status.id === statusId)[0];
            setStatusFilter([...otherStatus, { id: statusId, checked: !selectedStatus?.checked }]);
        }
    };

    const renderDate = (dates, date) => {
        const parseDateRow = (date) => {
            const stringDate = parseDate(new Date(date.created_at));
            const stringStatus = I18n.t(
                `transaction.table.status.${parseTransactionStatus(date.estado_txn)}`,
            );
            return `${stringDate} ${stringStatus}`;
        };

        const allDatesByStatus = `${dates
            .map((date) => `<span>${parseDateRow(date)}<span/><br>`)
            .join('')}`;

        return (
            <div>
                <a data-tip={allDatesByStatus} data-html={true} data-for='dateTooltip'>
                    <span className='text'>{parseDate(new Date(date))}</span>
                </a>
                <ReactTooltip
                    id='dateTooltip'
                    backgroundColor='#ffffff'
                    effect='solid'
                    className='transaction-tooltip paragraph'
                />
            </div>
        );
    };

    const renderStatus = (props) => {
        const status = props.value;
        const errors = props.original.errors;
        const translatedStatus = I18n.t(`rocheTransaction.table.status.${status}`);
        // if the status is different from "error", only render the span with the status
        if (status !== 'validationError' && status !== 'errorSAP') {
            return (
                <div className='status-container'>
                    <span className={`text status ${status.toString().toLowerCase()}`}>
                        {translatedStatus}
                    </span>
                </div>
            );
        }
        // if the status is an "error", also render the tooltip with the errors description
        const allErrors = `${errors
            ?.map((error) => `<span>ID ${error.errorCode} - ${error.description}<span/><br>`)
            .join('')}`;
        return (
            <div className='status-container'>
                <div
                    className='error-container'
                    data-tip={allErrors}
                    data-html={true}
                    data-for='statusTooltip'
                >
                    <span className={`text status ${status.toString().toLowerCase()}`}>
                        {translatedStatus}
                    </span>
                    <span className='status-error-indicator' />
                </div>
                <ReactTooltip
                    id='statusTooltip'
                    backgroundColor='#ffffff'
                    effect='solid'
                    className='transaction-tooltip paragraph'
                />
            </div>
        );
    };

    const renderRegistrar = (registrarName) => (
        <div>
            <a data-tip={registrarName} data-for='registrarTooltip'>
                <span className='text wrap'>{registrarName}</span>
            </a>
            <ReactTooltip
                id='registrarTooltip'
                backgroundColor='#ffffff'
                effect='solid'
                className='transaction-tooltip'
            />
        </div>
    );

    const renderCheckBox = (rowData) => {
        const tooltipMessage = rowData.osAddress
            ? null
            : I18n.t('rocheTransaction.table.ungrantedAddress.title');

        // setTransactionsSelected(transactionsSelected.filter((item) => item !== 'ALL'));
        return (
            <div
                className='action-checkbox-container'
                data-tip={tooltipMessage}
                data-html={true}
                data-for='dateTooltipAddress'
            >
                <Checkbox
                    id={rowData.id.toString()}
                    checked={isCheckboxSelected(rowData.id.toString())}
                    onChange={handleCheckboxChange}
                    disabled={
                        rowData.status !== 'validated' ||
                        rowData.tokenAmount == 0 ||
                        // disable ||
                        !rowData.osAddress
                    }
                />
                {tooltipMessage && (
                    <ReactTooltip
                        id='dateTooltipAddress'
                        backgroundColor='#ffffff'
                        effect='solid'
                        className='transaction-tooltip paragraph'
                    />
                )}
            </div>
        );
    };

    const handleCheckboxChange = (checkboxId, checked) => {
        if (checkboxId === 'ALL') {
            if (checked) {
                setTransactionsSelected([
                    ...props.transactions
                        .filter((t) => t.status === 'validated' && t.tokenAmount > 0 && t.osAddress)
                        .map((t) => `${t.id.toString()}`),
                    'ALL',
                ]);
            } else setTransactionsSelected([]);
        } else {
            const filtered = transactionsSelected.filter(
                (transactionId) => transactionId === checkboxId && transactionId !== 'ALL',
            );

            if (checked) {
                // if the checkbox was checked and the checkbox id is not on transactionsSelected, add to it
                if (filtered.length === 0)
                    setTransactionsSelected([
                        ...transactionsSelected.filter((item) => item !== 'ALL'),
                        `${checkboxId}`,
                    ]);
            } else {
                // if the checkbox was deselected and the checkbox id is on transactionsSelected, remove from it
                if (filtered.length === 1)
                    setTransactionsSelected([
                        ...transactionsSelected.filter(
                            (transactionId) =>
                                transactionId !== checkboxId && transactionId !== 'ALL',
                        ),
                    ]);
            }
        }
    };

    const isCheckboxSelected = (checkboxId) => {
        const filtered = transactionsSelected.filter(
            (transactionId) => transactionId === checkboxId,
        );
        return filtered.length === 1;
    };

    const handlePayButtonClick = () => {
        if (transactionsSelected.length > 0) {
            const transactionsSelectedWithoutAllElement = transactionsSelected.filter(function (
                item,
            ) {
                return item !== 'ALL';
            });
            if (transactionsSelectedWithoutAllElement.length > 0) {
                setLoadingPayment(true);
                setTransactionsSelectedWithoutAllElement(transactionsSelectedWithoutAllElement);
                props.validatePayments(transactionsSelectedWithoutAllElement);
            }
        }
    };

    const continuePaymentAfterSuccessfulValidation = () => {
        const filteredTransactions = props.transactions.filter(function (transaction) {
            return transactionsSelectedWithoutAllElement.includes(transaction.id.toString());
        });
        let transactionsToSubmit = [];
        filteredTransactions.map((transaction, index) => transactionsToSubmit.push([index, false]));
        props.sendMultipleNex(filteredTransactions);
    };

    const handlePay = () => {
        const filteredTransactions = props.transactions?.filter(function (transaction) {
            return transactionsSelectedWithoutAllElement.includes(transaction.id.toString());
        });
        if (props.sendNexStatus.responses.length >= filteredTransactions.length) {
            const permittedTxsIds = props.sendNexStatus.responses.filter((t) => t.txId !== null);
            if (permittedTxsIds.length > 0) {
                const transactionsToPay = [];
                permittedTxsIds.map((permittedTxId) => {
                    transactionsToPay.push({
                        id: permittedTxId.id,
                        block_txn_id: permittedTxId.txId,
                    });
                });
                props.pay(transactionsToPay); // Realiza PAY
                setTransactionsSelected([]);
                setTransactionsSelectedWithoutAllElement([]);
            }
            //track payments errors SEND_NEX
            const deniedTxsIds = props.sendNexStatus.responses.filter((t) => t.txId === null);
            const errorTxsIds = [];
            if (deniedTxsIds.length > 0) {
                deniedTxsIds.map((deniedTxId) => {
                    errorTxsIds[errorTxsIds.length] = deniedTxId.id;
                });
                props.trackPaymentsErrors(errorTxsIds); //Setea the errors of the SEND_NEX
            }
        }
    };

    const setOrderParam = (value) => {
        switch (value) {
            case 'id':
                return value;
            case 'dates':
                return 'updated_at';
            case 'contract':
                return 'contrato__descripcion';
            case 'product':
                return 'producto__denominacion';
            case 'tokenAmount':
                return 'importe_tokens';
            case 'status':
                return 'estado';
            case 'patientId':
                return 'paciente__id';
            case 'os':
                return 'obra_social__razon_social';
            case 'registrar':
                return 'registrado_por__username';
            case 'paymentOrder':
                return 'orden_pago';
            default:
                break;
        }
    };
    const changeSortDirection = (value) => {
        setContractSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
        setPropertySort(setOrderParam(value));
    };

    const getTableColumns = () => {
        // here could be some logic to show certain columns depending on user role.
        return [
            {
                id: 'id', // Required because our accessor is not a string
                Header: () => (
                    <h5
                        onClick={() => {
                            changeSortDirection('id');
                        }}
                    >
                        {I18n.t('rocheTransaction.table.headers.id')}
                        {getArrow('id')}
                    </h5>
                ),
                accessor: (data) => data.id, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                width: 100,
                sortable: false,
            },
            {
                id: 'patientId', // Required because our accessor is not a string
                Header: () => (
                    <h5
                        onClick={() => {
                            changeSortDirection('patientId');
                        }}
                    >
                        {I18n.t('rocheTransaction.table.headers.patientId')}
                        {getArrow(setOrderParam('patientId'))}
                    </h5>
                ),
                accessor: (data) => data.patientId, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
            {
                id: 'os', // Required because our accessor is not a string
                Header: () => (
                    <h5
                        onClick={() => {
                            changeSortDirection('os');
                        }}
                    >
                        {I18n.t('rocheTransaction.table.headers.os')}
                        {getArrow(setOrderParam('os'))}
                    </h5>
                ),
                accessor: (data) => data.os, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
            {
                id: 'dates', // Required because our accessor is not a string
                Header: () => (
                    <h5
                        onClick={() => {
                            changeSortDirection('dates');
                        }}
                    >
                        {I18n.t('rocheTransaction.table.headers.date')}
                        {getArrow(setOrderParam('dates'))}
                    </h5>
                ),
                Cell: ({ original }) => renderDate(original.dates, original.fecha_estado_actual),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
            {
                id: 'contract', // Required because our accessor is not a string
                Header: () => (
                    <h5
                        onClick={() => {
                            changeSortDirection('contract');
                        }}
                    >
                        {I18n.t('rocheTransaction.table.headers.contracts')}
                        {getArrow(setOrderParam('contract'))}
                    </h5>
                ),
                accessor: (d) => d.contract?.descripcion, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
            {
                id: 'registrar', // Required because our accessor is not a string
                Header: () => (
                    <h5
                        onClick={() => {
                            changeSortDirection('registrar');
                        }}
                    >
                        {I18n.t('rocheTransaction.table.headers.registrar')}
                        {getArrow(setOrderParam('registrar'))}
                    </h5>
                ),
                accessor: (data) => data.registrar, // Custom value accessors!
                Cell: (props) => renderRegistrar(props.value),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
            {
                id: 'product', // Required because our accessor is not a string
                Header: () => (
                    <h5
                        onClick={() => {
                            changeSortDirection('product');
                        }}
                    >
                        {I18n.t('rocheTransaction.table.headers.product')}
                        {getArrow(setOrderParam('product'))}
                    </h5>
                ),
                accessor: (data) => data.product, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
            {
                id: 'paymentOrder', // Required because our accessor is not a string
                Header: () => (
                    <h5
                        onClick={() => {
                            changeSortDirection('paymentOrder');
                        }}
                    >
                        {I18n.t('rocheTransaction.table.headers.paymentOrder')}
                        {getArrow(setOrderParam('paymentOrder'))}
                    </h5>
                ),
                accessor: (data) => data.paymentOrder, // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
            {
                id: 'tokenAmount', // Required because our accessor is not a string
                Header: () => (
                    <h5
                        onClick={() => {
                            changeSortDirection('tokenAmount');
                        }}
                    >
                        {I18n.t('rocheTransaction.table.headers.tokenAmount')}
                        {getArrow(setOrderParam('tokenAmount'))}
                    </h5>
                ),
                accessor: (data) => parseFloat(data.tokenAmount), // Custom value accessors!
                Cell: (props) => <span className='text'>{props.value.toFixed(2)}</span>,
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
            {
                id: 'status', // Required because our accessor is not a string
                Header: () => (
                    <h5
                        onClick={() => {
                            changeSortDirection('status');
                        }}
                    >
                        {I18n.t('rocheTransaction.table.headers.status')}
                        {getArrow(setOrderParam('status'))}
                    </h5>
                ),
                accessor: (data) => data.status, // Custom value accessors!
                Cell: (props) => renderStatus(props),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
            {
                id: 'pay', // Required because our accessor is not a string
                Header: () => (
                    <div className='header-with-checkbox'>
                        <h5>{I18n.t('rocheTransaction.table.headers.pay')}</h5>
                        <Checkbox
                            id='ALL'
                            showExceeded
                            checked={isCheckboxSelected('ALL')}
                            onChange={handleCheckboxChange}
                        />
                    </div>
                ),
                accessor: (data) => data.buttons, // Custom value accessors!
                Cell: (props) => renderCheckBox(props.original),
                resizable: false,
                headerClassName: 'nexus-header center',
                className: 'nexus-cell center',
                style: { whiteSpace: 'unset' },
                sortable: false,
            },
        ].filter((column) => (props.userIsPayer ? column : column.id !== 'pay'));
    };

    const resetUI = () => {
        setTransactionsSelected([]);
        setPaymentResults(paymentResultsInitialState);

        setPage(0);
        setStartDate('');
        setEndDate('');
        setTextToSearch('');

        setTransactionsAmount([]);
        setDisable(false);

        // todo check how to clear the ui now, this might not work
        props.clearUI();
    };
    const getArrow = (columnName) => {
        if (propertySort === columnName) return contractSortDirection === 'asc' ? '↑' : '↓';
    };

    return (
        <div className='roche-transactions-screen'>
            <LoadingOverlay active={loadingPayment} spinner text={I18n.t('common.loading')} />

            <div className='header'>
                <div
                    className={`balance-container ${
                        props.userIsPayer &&
                        props.userType !== UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY &&
                        'with-balance'
                    }`}
                >
                    {props.userIsPayer &&
                        props.userType !== UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY && (
                            <div className='tokens-balance'>
                                <span className='tokens-balance-text'>
                                    {I18n.t('rocheTransaction.balance')}:
                                </span>
                                <div className='tokens-balance-col-2'>
                                    <span>NEX: {nexBalance}</span>
                                </div>
                            </div>
                        )}
                </div>
                <div className='header-bar'>
                    <span className='title'>{I18n.t('rocheTransaction.title')}</span>
                    <SearchBar
                        textToSearch={textToSearchDeb}
                        onSearch={setTextToSearchDeb}
                        setPage={setPage}
                    />
                    <DateRangePicker
                        allTransactions={props.allTransactions}
                        textToSearch={textToSearch}
                        startDate={(date) => setStartDate(date)}
                        endDate={(date) => setEndDate(date)}
                        updateFilteredTransactionsByDateRange={() => {
                            // Note: commented since its filtering store data, and pagination is server-side.
                            // props.updateFilteredTransactionsByDateRange(newResults)
                        }}
                        clearDateRangeSelection={() => (
                            setStartDate(''), setEndDate(''), setPage(0)
                        )}
                        searchText={() => null}
                        setPage={setPage} //set page for each for every time the date value changes
                    />
                    <SelectMultiple
                        values={statusFilter}
                        onChange={handleStatusFilterChange}
                        className='rounded'
                        placeholder={I18n.t('rocheTransaction.table.headers.status')}
                        options={[
                            { value: 'all', label: I18n.t('rocheTransaction.table.status.all') },
                            {
                                value: 'validated',
                                label: I18n.t('rocheTransaction.table.status.validated'),
                            },
                            {
                                value: 'pending',
                                label: I18n.t('rocheTransaction.table.status.pending'),
                            },
                            {
                                value: 'rejected',
                                label: I18n.t('rocheTransaction.table.status.rejected'),
                            },
                            { value: 'paid', label: I18n.t('rocheTransaction.table.status.paid') },
                            {
                                value: 'posted',
                                label: I18n.t('rocheTransaction.table.status.posted'),
                            },
                            {
                                value: 'validationError',
                                label: I18n.t('rocheTransaction.table.status.validationError'),
                            },
                            {
                                value: 'errorSAP',
                                label: I18n.t('rocheTransaction.table.status.errorSAP'),
                            },
                            {
                                value: 'processingPayment',
                                label: I18n.t('rocheTransaction.table.status.processingPayment'),
                            },
                        ]}
                    />
                    <MultipleDownloadButton
                        options={[
                            {
                                label: I18n.t('rocheTransaction.downloadOptions.transactions'),
                                value: RocheTransactionDownloadType.ALL,
                            },
                            {
                                label: I18n.t('rocheTransaction.downloadOptions.tokensEmitted'),
                                value: RocheTransactionDownloadType.EMITTED,
                            },
                            {
                                label: I18n.t('rocheTransaction.downloadOptions.tokensReceived'),
                                value: RocheTransactionDownloadType.RECEIVED,
                            },
                        ]}
                        handleClick={(value) =>
                            props.downloadTransactions(
                                value,
                                reactTable.getResolvedState().sortedData,
                                statusFilter,
                                page + 1,
                                textToSearch,
                                startDate,
                                endDate,
                                propertySort
                                    ? contractSortDirection === 'asc'
                                        ? `order_by=${propertySort}`
                                        : `order_by=-${propertySort}`
                                    : '',
                            )
                        }
                        className='rounded'
                    />
                    {props.userIsPayer && (
                        <>
                            <Button
                                type='button'
                                className='primary small'
                                onClick={() => {
                                    if (transactionsSelected.length > 0)
                                        setShowConfirmPaymentModal(true);
                                }}
                                text={I18n.t('rocheTransaction.pay')}
                                disabled={
                                    props.userType === UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY ||
                                    !hasBalance() ||
                                    transactionsSelected.length === 0 ||
                                    disable
                                }
                                dataTip={
                                    props.userType === UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY
                                        ? I18n.t('rocheTransaction.payDisabledTooltip')
                                        : I18n.t('rocheTransaction.payDisabledNoBalanceToolTip')
                                }
                                dataFor='payDisabledTooltip'
                            />
                            <ReactTooltip
                                id='payDisabledTooltip'
                                place='top'
                                type='error'
                                effect='solid'
                                multiline={true}
                                disable={
                                    props.userType !== UserTypes.ROCHE_USER_WITHOUT_PRIVATE_KEY &&
                                    hasBalance()
                                }
                            />
                        </>
                    )}
                </div>
            </div>
            <div className='roche-transactions-table'>
                {props.loading ? (
                    <>
                        <div className='header' />
                        <div className='roche-transactions-table'>
                            <Loader />
                        </div>
                    </>
                ) : (
                    <ReactTable
                        className={
                            props.transactions?.length > 0 ? 'nexus-table' : 'nexus-table empty'
                        }
                        PaginationComponent={(paginationProps) => (
                            <TablePagination
                                {...paginationProps}
                                total={props.total}
                                totalPages={props.totalPages}
                                page={page}
                                onPageChange={(e) => setPage(e)}
                                pagedMessageTranslation='rocheTransaction.table.pagedMessage'
                                pagedMessageEmptyTranslation='rocheTransaction.table.pagedMessageEmpty'
                            />
                        )}
                        minRows={0}
                        pageSize={10}
                        data={props.transactions}
                        noDataText={I18n.t('rocheTransaction.table.noRows')}
                        // todo check if this prop is necessary
                        getTdProps={() => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    if (handleOriginal) {
                                        handleOriginal();
                                    }
                                },
                            };
                        }}
                        defaultSorted={[]}
                        columns={getTableColumns()}
                        ref={(r) => {
                            setReactTable(r);
                        }}
                    />
                )}
            </div>
            <ConfirmationModal
                show={showConfirmPaymentModal}
                icon={<i className='icon-exclamation red' />}
                title={I18n.t('rocheTransaction.confirmPaymentModal.text')}
                noBody={true}
                cancel={() => setShowConfirmPaymentModal(false)}
                submit={() => {
                    setShowConfirmPaymentModal(false);
                    handlePayButtonClick();
                }}
            />
            <TransactionsSuccessModal //Displayed when selected one or all transactions and was a successful
                show={paymentResults?.allSuccessful}
                onClick={() => resetUI()}
                text={
                    paymentResults?.successfulTotal === 1
                        ? I18n.t('rocheTransaction.transactionSuccessfulModal.transactionPayed')
                        : I18n.t('rocheTransaction.transactionsSuccessfulModal.transactionsPayed')
                }
            />
            {showingAllErrors && (
                <TransactionsErrorModal
                    show={paymentResults?.errorTotal > 0 && showingAllErrors}
                    errors={paymentResults?.errors}
                    isAValidationError={false}
                    isAPaymentError={true}
                    close={() => resetUI()}
                    successfulTotal={paymentResults?.successfulTotal}
                />
            )}
        </div>
    );
};

export default RocheTransactionsScreen;
